import { Box } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { Body1 } from "../Typography/Typographys";

export default function ViewMore({ children, onClick, className = "" }) {
  return (
    <Box className={classNames("viewMoreDefaultStyle", className)}>
      <Body1 onClick={onClick} light>
        {children}
      </Body1>
    </Box>
  );
}
