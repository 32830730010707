import React, { useState } from "react";
import { Box, Grid } from "@material-ui/core";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Body1 } from "../Typography/Typographys";
import { ReactComponent as Group } from "../../assets/Group.svg";
import LockIcon from "@mui/icons-material/Lock";
import { ReactComponent as EarthIcon } from "../../assets/EarthIcon.svg";
import zeroStateGroupIcon from "../../assets/img/zeroStateGroupIcon.png";
import UserAvatar from "../common/UserAvatar";
import GroupActionButtons from "./GroupActionButtons";
import H4 from "../Headings/H4";
import TooltipComponent from "../common/Tooltip";
import ViewAndEditGroupMembersDialog from "../Dialogs/ViewAndEditGroupMembersDialog";

export default function GroupDetailCard(props) {
  const { className = "", data, userData, type } = props;
  const history = useHistory();
  const groupMembers = data?.group_users
    ?.filter((user) => user?.status === "accepted")
    ?.map((item) => item?.user_id);
  const isAdmin = data?.admin_id === userData?.userInfo?.data?.id;
  const myGroupJoiningReq = data?.group_users?.filter(
    (user) => user?.user_id === userData?.userInfo?.data?.id
  );
  const [open, setOpen] = useState(false);

  return (
    <Box className={classNames("gdCardRoot", "lrComponent", className)}>
      <Box className="gdCardBgDiv">
        {isAdmin && (
          <Box className="gdCardBgDivActionBtns">
            <GroupActionButtons
              type="userGroupDetailCardEditAndDelete"
              groupData={data}
              userData={userData}
            />
          </Box>
        )}
        <Box className="gdCardGroupIconRoot center">
          <UserAvatar
            src={data?.icon || zeroStateGroupIcon}
            name={data?.name}
            onClick={() => history.push(`/group/${data?.slug}`)}
          />
        </Box>
      </Box>
      <Box className="gdCardDetailsDiv">
        <Grid container justifyContent="flex-end" className="gdCardMiddleDiv">
          {type === "myGroups" ? (
            <Body1>{isAdmin ? "Admin" : "Member"}</Body1>
          ) : type === "groupSuggestions" ? (
            <GroupActionButtons
              type="userGroupDetailCardJoin"
              groupData={data}
              userData={userData}
            />
          ) : type === "otherUserGroups" ? (
            myGroupJoiningReq?.length === 0 ? (
              <GroupActionButtons
                type="userGroupDetailCardJoin"
                groupData={data}
                userData={userData}
              />
            ) : myGroupJoiningReq[0]?.status === "pending" ? (
              <GroupActionButtons
                type="userGroupDetailCardWithdraw"
                groupData={{ ...data, req_id: myGroupJoiningReq[0]?.status }}
                userData={userData}
              />
            ) : null
          ) : null}
        </Grid>
        <H4
          medium
          multilineEllipsis
          line={2}
          onClick={() => history.push(`/group/${data?.slug}`)}
          title={data?.name}
        >
          {data?.name}
        </H4>
        <Body1 multilineEllipsis line={2}>
          {data?.description}
        </Body1>
        <Grid
          container
          justifyContent="space-between"
          className="gdCardFooterActionBtns"
        >
          <Grid container className="gdCardfabLeftSide">
            <Grid container onClick={() => setOpen(true)}>
              <Group />
              <Body1>{`${
                type === "groupSuggestions"
                  ? data?.members
                  : groupMembers?.length || data?.members
              } members`}</Body1>
            </Grid>
            <TooltipComponent
              title={data?.is_private ? "Private Group" : "Public Group"}
            >
              {data?.is_private ? <LockIcon fontSize="small" /> : <EarthIcon />}
            </TooltipComponent>
          </Grid>
          {!isAdmin && groupMembers?.includes(userData?.userInfo?.data?.id) && (
            <Grid container>
              <GroupActionButtons
                type="userGroupDetailCardLeaveGroup"
                groupData={data}
                userData={userData}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <ViewAndEditGroupMembersDialog
        open={open}
        handleClose={() => setOpen(false)}
        group={data}
        {...props}
      />
    </Box>
  );
}
