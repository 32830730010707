import connect from "../assets/carouselImages/connect.jpg";
import create from "../assets/carouselImages/create.jpg";
import collaborate from "../assets/carouselImages/collaborate.jpg";
import LoginOne from "../assets/img/login-1.png";
import LoginTwo from "../assets/img/login-2.png";
import LoginThree from "../assets/img/login-3.png";
import moment from "moment";

const imageExtensions = [
  "jpg",
  "svg",
  "png",
  "jpeg",
  "gif",
  "webp",
  "psd",
  "jfif",
  "apng",
  "avif",
  "pjpeg",
  "pjp",
  "bmp",
  "tif",
  "tiff",
  "ico",
  "cur",
];

const videoExtensions = [
  "mp4",
  "mkv",
  "webm",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "ogg",
  "m4p",
  "m4v",
  "avi",
  "wmv",
  "mov",
  "qt",
  "flv",
  "swt",
  "avchd",
];

const authDynamicImage = [
  {
    imageSrc: LoginOne,
    imageText:
      "<p class = 'image-Text'><span class = 'bold'>Collaborate</span> with the different types of users for your projects.</p>",
  },
  {
    imageSrc: LoginTwo,
    imageText:
      "<p class = 'image-Text'>Create things with and around <span class = 'bold'>WordPress To Help</span> others and of course yourselves</p>",
  },
  {
    imageSrc: LoginThree,
    imageText:
      "<p class = 'image-Text'>Connect with the geniuses of the WordPress and stay <span class = 'bold'> Updated With The Latest Trends.</span></p>",
  },
];

const signinCarouselImages = [
  {
    imageURL: connect,
    imageText:
      "Connect with the geniuses of the WordPress and stay updated with the latest trends.",
  },
  {
    imageURL: collaborate,
    imageText:
      "Collaborate with the different types of users for your projects.",
  },
  {
    imageURL: create,
    imageText:
      "Create things with and around WordPress to help others and ofcourse yourselves.",
  },
];

function toBase64(data) {
  return window.btoa(data);
}

function toNormalString(data) {
  return window.atob(data);
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const urlRegex = new RegExp(
  "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
);

const nth = function (d) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

function dobDate(date) {
  const d = new Date(date);
  const day = d.getDate();
  const month = monthNames[d.getMonth()];
  const year = d.getFullYear();
  return `${day + nth(day)} ${month} ${year}`;
}

function monthYearDate(date) {
  const d = new Date(date);
  const month = monthNames[d.getMonth()];
  const year = d.getFullYear();
  return `${month} ${year}`;
}

function yearOfDate(date) {
  const d = new Date(date);
  return d.getFullYear();
}

function getFeedTypeFromFeedArray(arr) {
  const videoArr = [];
  const imageArr = [];
  for (let item of arr) {
    if (imageExtensions.includes(item.split(".")[1])) {
      imageArr.push(item);
    } else {
      videoArr.push(item);
    }
  }
  if (imageArr.length === arr.length) {
    return 2;
  } else if (videoArr.length === arr.length) {
    return 3;
  } else {
    return 5;
  }
}

function getFeedTypeFromFeedFiles(files) {
  let imageArr = [];
  let videoArr = [];
  for (let file of files) {
    if (file.type.split("/")[0] === "image") {
      imageArr.push(file);
    } else {
      videoArr.push(file);
    }
  }
  if (imageArr.length === files.length) {
    return 2;
  } else if (videoArr.length === files.length) {
    return 3;
  } else {
    return 5;
  }
}

function getDate(date) {
  const d = new Date(date);
  return d.toLocaleDateString();
}

function getAuthRouts() {
  return [
    "signin",
    "signup",
    "reset-password",
    "forgot-password",
    "user-details",
    "signup-verification",
  ];
}

function getNotShowHeaderFooterRouts() {
  return ["user-chat", "share-post", "signup-verification"];
}

function getNotShowHeaderTopRouts() {
  return ["signup-verification"];
}

function getLocalStorageItems() {
  const token = localStorage.getItem("x-access-token");
  const email = localStorage.getItem("email");
  return {
    token: token ? token : null,
    email: email ? email : null,
  };
}

function sortNestedArr(fieldName, arr) {
  return arr.sort((a, b) => {
    if (a[fieldName] < b[fieldName]) {
      return -1;
    } else if (a[fieldName] > b[fieldName]) {
      return 1;
    } else {
      return 0;
    }
  });
}

function updateChatUnreadMessageData(str, user_id, msg_id) {
  try {
    let tempArr = JSON.parse(str);
    tempArr = tempArr.map((user) => {
      return user?.user_id === user_id
        ? { ...user, last_seen_message_id: msg_id }
        : user;
    });
    return JSON.stringify(tempArr);
  } catch (error) {}
}

let chatDate = "";
let todayDate = moment().format("DD MMMM yyyy");
let yesterdayDate = moment().add(-1, "days").format("DD MMMM yyyy");
const getChatMessageDate = (date) => {
  const currentDate = moment(date).format("DD MMMM yyyy");
  if (chatDate === "") {
    chatDate = currentDate;
    if (todayDate === currentDate) {
      return "Today";
    } else if (yesterdayDate === currentDate) {
      return "Yesterday";
    } else {
      return chatDate;
    }
  } else {
    if (chatDate !== currentDate) {
      chatDate = currentDate;
      if (todayDate === currentDate) {
        return "Today";
      } else if (yesterdayDate === currentDate) {
        return "Yesterday";
      } else {
        return chatDate;
      }
    } else {
      return null;
    }
  }
};

const isContinuousMessage = (cMData, mArr, cMIndex, isNewDay) => {
  if (isNewDay !== null) {
    return false;
  } else if (cMData?.user_id !== mArr[cMIndex - 1]?.user_id) {
    return false;
  } else if (
    moment(cMData?.createdAt).format("A") !==
    moment(mArr[cMIndex - 1]?.createdAt).format("A")
  ) {
    return false;
  } else {
    const preMTime = moment(mArr[cMIndex - 1]?.createdAt).valueOf();
    const cMTime = moment(cMData?.createdAt).valueOf();
    const diff = cMTime - preMTime;
    if (diff < 5 * 60 * 1000) {
      return true;
    } else {
      return false;
    }
  }
};

const updateFavicon = (status) => {
  let faviconEle = document.getElementById("favicon");
  if (status === "unread") {
    let unreadFavicon = "/favicon-notification.ico";
    faviconEle.href = unreadFavicon;
  } else {
    let readFavicon = "/favicon.ico";
    faviconEle.href = readFavicon;
  }
};

const utils = {
  imageExtensions,
  videoExtensions,
  signinCarouselImages,
  toBase64,
  toNormalString,
  monthNames,
  dobDate,
  monthYearDate,
  yearOfDate,
  getFeedTypeFromFeedArray,
  getFeedTypeFromFeedFiles,
  getDate,
  getLocalStorageItems,
  getAuthRouts,
  getNotShowHeaderFooterRouts,
  getNotShowHeaderTopRouts,
  urlRegex,
  sortNestedArr,
  updateChatUnreadMessageData,
  getChatMessageDate,
  updateFavicon,
  isContinuousMessage,
  authDynamicImage,
};

export default utils;
