import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./reducers";
import "./App.css";
import "./scss/base.scss";
import rootSaga from "./sagas";
import Main from "./Main.js";
import theme from "./theme";
import {
  SnackbarProviderComponent,
  SnackbarUtilsConfigurator,
} from "./components/common/Snackbars";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

function App() {
  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProviderComponent>
            <SnackbarUtilsConfigurator />
            <BrowserRouter>
              <Main />
            </BrowserRouter>
          </SnackbarProviderComponent>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;
