import { Box } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Body1 } from "../Typography/Typographys";
import { BackgroundProfileDetailsSkeleton } from "../common/Skeletons";
import classNames from "classnames";
import LeftRightComponent from "../common/LeftRightComponent";
import H6 from "../Headings/H6";

export default function ProfileDetails({ currentUser, myProfile, loading }) {
  const [status, setStatus] = useState("");

  const showProfileDetails = () => {
    return myProfile === 1 || status === "accepted";
  };

  useEffect(() => {
    if (currentUser?.userInfo?.status === "1") {
      setStatus(currentUser?.userInfo?.data?.status);
    }
  }, [currentUser]);
  return (
    <LeftRightComponent heading={"Profile Details"}>
      <Box className={classNames("updRoot", "manageMTAndDivLCMB")}>
        {loading ? (
          <BackgroundProfileDetailsSkeleton />
        ) : (
          <>
            {currentUser?.userInfo?.data?.createdAt && <Box>
              <H6>
                Member Since<span>:</span>
              </H6>
              <Body1 ellipsis>
                {moment(currentUser?.userInfo?.data?.createdAt).format(
                  "MMMM YYYY"
                )}
              </Body1>
            </Box>}
            {currentUser?.userInfo?.data?.email && <Box>
              <H6>
                Email<span>:</span>
              </H6>
              <Body1 ellipsis>
                {showProfileDetails() ? currentUser?.userInfo?.data?.email : ""}
              </Body1>
            </Box>}
            {currentUser?.userInfo?.data?.user_profile?.dob && <Box>
              <H6>
                Birthday<span>:</span>
              </H6>
              <Body1 ellipsis>
                {showProfileDetails()
                  ? moment(
                      currentUser?.userInfo?.data?.user_profile?.dob
                    ).format("DD-MM-YYYY")
                  : ""}
              </Body1>
            </Box>}
            {currentUser?.userInfo?.data?.user_profile?.gender && <Box>
              <H6>
                Gender<span>:</span>
              </H6>
              <Body1 ellipsis>
                {showProfileDetails()
                  ? currentUser?.userInfo?.data?.user_profile?.gender
                  : ""}
              </Body1>
            </Box>}
            {currentUser?.userInfo?.data?.user_profile?.country && <Box>
              <H6>
                Leaves In<span>:</span>
              </H6>
              <Body1 ellipsis>
                {showProfileDetails()
                  ? currentUser?.userInfo?.data?.user_profile?.country
                  : ""}
              </Body1>
            </Box>}
          </>
        )}
      </Box>
    </LeftRightComponent>
  );
}
