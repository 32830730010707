import React from "react";
import "../../scss/ZeroStateComponents.scss";
import { Box, Grid } from "@material-ui/core";
import H3 from "../Headings/H3";
import H6 from "../Headings/H6";
import ZeroStateConnectionImage from "../../assets/img/ZeroStateConnectionImage.png";
import { Body1 } from "../Typography/Typographys";
import SimpleButton from "../Buttons/SimpleButton";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import zeroStateGraduation from "../../assets/img/zeroStateGraduation.png";
import ParasTypography from "../Typography/ParasTypography";
import GroupActionButtons from "../GroupListing/GroupActionButtons";

export function ZeroStateMyConnection({ children }) {
  return (
    <Box className="zetoStateMyConnection">
      <H3 light>{children}</H3>
      <img src={ZeroStateConnectionImage} alt="zero state connection pic" />
    </Box>
  );
}

export function ZeroStateUserBackgroundTab() {
  return (
    <Box className="lrComponent zeroStateUserBackground">
      <Box className="center">
        <img src={ZeroStateConnectionImage} alt="zero state pic" />
        <H3 light>Details not available</H3>
      </Box>
    </Box>
  );
}

export function ZeroStateEducationAndWorkDetails({ handleOnClick, type }) {
  return (
    <Box className={"zeroStateEduAndWorkDetailsRoot"}>
      <Box className="center">
        <img
          src={
            type === "education"
              ? zeroStateGraduation
              : ZeroStateConnectionImage
          }
          alt="Zero State"
        />
        <H6>
          {type === "education"
            ? "Education details not available"
            : "Work details not available"}
        </H6>
        <Body1>
          {type === "education"
            ? "Add your education details so your peers can know you better. You can also keep it private."
            : "Add your work details so your peers / probable employer can better understand your work interests. You can also keep it private."}
        </Body1>
        <SimpleButton
          children="Add Details"
          endIcon={<AddCircleOutlineOutlinedIcon />}
          primaryColorBtn
          onClick={handleOnClick}
        />
      </Box>
    </Box>
  );
}

export function ZeroStateAbout({ handleOnClick }) {
  return (
    <Box className="center zeroStateAbout">
      <H6 children="Your description not available" />
      <Body1 children="Add your description so your peers can know you better." />
      <SimpleButton
        primaryColorBtn
        children="Add Description"
        endIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={handleOnClick}
      />
    </Box>
  );
}

export function ZeroStateNotification() {
  return (
    <Box className="center" sx={{ padding: "0px 20px" }}>
      <Body1 medium>You don't have any notification</Body1>
    </Box>
  );
}

export function ZeroStateUserGroups({ message, isMyGroups }) {
  return (
    <Grid container className="lrComponent zeroStateUserGroupsDiv">
      <Body1 medium>{message || "Groups not available."}</Body1>
      {isMyGroups && <GroupActionButtons type={"groupListingCreateBtn"} />}
    </Grid>
  );
}

export function ZeroStateMyBlogListing({ getPostListing }) {
  return (
    <Box className="center zeroStateMessage">
      <ParasTypography
        children={
          getPostListing?.message || "No blogs available. Create a new blog."
        }
        medium
      />
    </Box>
  );
}

export function ZeroStateUserBlogListing({ getUserPostListing }) {
  return (
    <Box className="center zeroStateMessage">
      <ParasTypography
        children={getUserPostListing?.message || "No blogs available."}
        medium
      />
    </Box>
  );
}

export function ZeroStateFCPCenter() {
  return (
    <Box className="center zeroStateFcpCenterDiv">
      <ParasTypography children="Active Chat Not Available" medium />
    </Box>
  );
}

export function ZeroStateFeedListing({ message }) {
  return (
    <Box className="lrComponent center" sx={{ padding: 20 }}>
      <Body1 medium>{message || "Feeds not available."}</Body1>
    </Box>
  );
}
