//Socket Notification Message Actions
export const NOTIFICATION_MESSAGE_SUCCESS = "NOTIFICATION_MESSAGE_SUCCESS";

//Get All Notifications Actions
export const GET_ALL_NOTIFICATIONS_START = "GET_ALL_NOTIFICATIONS_START";
export const GET_ALL_NOTIFICATIONS_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATIONS_ERROR = "GET_ALL_NOTIFICATIONS_ERROR";

//Socket Get Notification Actions
export const SOCKET_IO_GET_NEW_NOTIFICATION = "SOCKET_IO_GET_NEW_NOTIFICATION";

//Update Location History Actions
export const UPDATE_LOCATION_HISTORY = "UPDATE_LOCATION_HISTORY";

//React Notifications Actions
export const READ_NOTIFICATIONS_START = "READ_NOTIFICATIONS_START";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_ERROR = "READ_NOTIFICATIONS_ERROR";

export const UPDATE_LINK_PREVIEW = "UPDATE_LINK_PREVIEW";
export const EDIT_PROFILE_STATUS = "EDIT_PROFILE_STATUS";
export const ACTIVE_EDIT_PROFILE_FORM_INDEX = "ACTIVE_EDIT_PROFILE_FORM_INDEX";

//Get Unread Counts
export const GET_UNREAD_COUNTS_START = "GET_UNREAD_COUNTS_START";
export const GET_UNREAD_COUNTS_SUCCESS = "GET_UNREAD_COUNTS_SUCCESS";
export const GET_UNREAD_COUNTS_ERROR = "GET_UNREAD_COUNTS_ERROR";