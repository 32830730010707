import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_ALL_GROUPS_START,
  GET_ALL_GROUPS_ERROR,
  GET_ALL_GROUPS_SUCCESS,
  GET_GROUP_MEMBERS_ERROR,
  GET_GROUP_MEMBERS_SUCCESS,
  GET_GROUP_MEMBERS_START,
  CREATE_GROUP_ERROR,
  CREATE_GROUP_START,
  CREATE_GROUP_SUCCESS,
  UPLOAD_GROUP_ICON_ERROR,
  UPLOAD_GROUP_ICON_START,
  UPLOAD_GROUP_ICON_SUCCESS,
  GET_GROUP_SUGGESTIONS_ERROR,
  GET_GROUP_SUGGESTIONS_SUCCESS,
  GET_GROUP_SUGGESTIONS_START,
  JOIN_NEW_GROUP_ERROR,
  JOIN_NEW_GROUP_SUCCESS,
  JOIN_NEW_GROUP_START,
  UPDATE_GROUP_START,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_SUCCESS,
  GET_GROUP_JOINING_REQUESTS_ERROR,
  GET_GROUP_JOINING_REQUESTS_SUCCESS,
  GET_GROUP_JOINING_REQUESTS_START,
  CANCEL_GROUP_JOIN_REQUEST_ERROR,
  CANCEL_GROUP_JOIN_REQUEST_SUCCESS,
  CANCEL_GROUP_JOIN_REQUEST_START,
  UPDATE_GROUP_JOIN_REQUEST_SUCCESS,
  UPDATE_GROUP_JOIN_REQUEST_START,
  UPDATE_GROUP_JOIN_REQUEST_ERROR,
  GET_GROUP_DETAILS_SUCCESS,
  GET_GROUP_DETAILS_ERROR,
  GET_GROUP_DETAILS_START,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_MEMBERS_ERROR,
  DELETE_GROUP_MEMBERS_START,
  DELETE_GROUP_MEMBERS_SUCCESS,
  LEAVE_GROUP_ERROR,
  LEAVE_GROUP_START,
  LEAVE_GROUP_SUCCESS,
} from "../actions/GroupAction";
import toast from "../components/common/Snackbars";
import {
  GetAllGroups,
  GetGroupMembers,
  CreateGroup,
  UploadGroupIcon,
  GetGroupSuggestions,
  JoinNewGroup,
  UpdateGroup,
  GetGroupJoiningRequests,
  CancelGroupJoinRequest,
  UpdateGroupJoinRequest,
  GetGroupDetails,
  LeaveGroup,
  DeleteGroup,
  DeleteGroupMembers,
} from "../Apis/GroupApis";

function* GetAllGroupsWorker({ data }) {
  const response = yield call(GetAllGroups, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_GROUPS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_ALL_GROUPS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_ALL_GROUPS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAllGroupsWatcher() {
  yield takeEvery(GET_ALL_GROUPS_START, GetAllGroupsWorker);
}

function* GetGroupMembersWorker({ slug }) {
  const response = yield call(GetGroupMembers, slug);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_GROUP_MEMBERS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_GROUP_MEMBERS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_GROUP_MEMBERS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetGroupMembersWatcher() {
  yield takeEvery(GET_GROUP_MEMBERS_START, GetGroupMembersWorker);
}

function* CreateGroupWorker({ groupData }) {
  const response = yield call(CreateGroup, groupData);
  try {
    if (response?.status === "1") {
      yield put({ type: CREATE_GROUP_SUCCESS, data: response });
    } else {
      yield put({ type: CREATE_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CREATE_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CreateGroupWatcher() {
  yield takeEvery(CREATE_GROUP_START, CreateGroupWorker);
}

function* UploadGroupIconWorker({ slug, groupIconData }) {
  const response = yield call(UploadGroupIcon, slug, groupIconData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPLOAD_GROUP_ICON_SUCCESS, data: response });
    } else {
      yield put({ type: UPLOAD_GROUP_ICON_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPLOAD_GROUP_ICON_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UploadGroupIconWatcher() {
  yield takeEvery(UPLOAD_GROUP_ICON_START, UploadGroupIconWorker);
}

function* GetGroupSuggestionsWorker({ offset }) {
  const response = yield call(GetGroupSuggestions, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_GROUP_SUGGESTIONS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_GROUP_SUGGESTIONS_ERROR, data: response });
      toast.error(response?.message || 'Unauthorized!');
    }
  } catch (err) {
    yield put({ type: GET_GROUP_SUGGESTIONS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetGroupSuggestionsWatcher() {
  yield takeEvery(GET_GROUP_SUGGESTIONS_START, GetGroupSuggestionsWorker);
}

function* JoinNewGroupWorker({ group_id }) {
  const response = yield call(JoinNewGroup, group_id);
  try {
    if (response?.status === "1") {
      yield put({ type: JOIN_NEW_GROUP_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: JOIN_NEW_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: JOIN_NEW_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* JoinNewGroupWatcher() {
  yield takeEvery(JOIN_NEW_GROUP_START, JoinNewGroupWorker);
}

function* UpdateGroupWorker({ id, groupData }) {
  const response = yield call(UpdateGroup, id, groupData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_GROUP_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateGroupWatcher() {
  yield takeEvery(UPDATE_GROUP_START, UpdateGroupWorker);
}

function* GetGroupJoiningRequestsWorker({ data }) {
  const response = yield call(GetGroupJoiningRequests, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_GROUP_JOINING_REQUESTS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_GROUP_JOINING_REQUESTS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_GROUP_JOINING_REQUESTS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetGroupJoiningRequestsWatcher() {
  yield takeEvery(
    GET_GROUP_JOINING_REQUESTS_START,
    GetGroupJoiningRequestsWorker
  );
}

function* CancelGroupJoinRequestWorker({ group_id, admin_id }) {
  const response = yield call(CancelGroupJoinRequest, group_id, admin_id);
  try {
    if (response?.status === "1") {
      yield put({ type: CANCEL_GROUP_JOIN_REQUEST_SUCCESS, data: response });
    } else {
      yield put({ type: CANCEL_GROUP_JOIN_REQUEST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CANCEL_GROUP_JOIN_REQUEST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CancelGroupJoinRequestWatcher() {
  yield takeEvery(
    CANCEL_GROUP_JOIN_REQUEST_START,
    CancelGroupJoinRequestWorker
  );
}

function* UpdateGroupJoinRequestWorker({ id, user_id, group_id, status }) {
  const response = yield call(
    UpdateGroupJoinRequest,
    id,
    user_id,
    group_id,
    status
  );
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_GROUP_JOIN_REQUEST_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_GROUP_JOIN_REQUEST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_GROUP_JOIN_REQUEST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateGroupJoinRequestWatcher() {
  yield takeEvery(
    UPDATE_GROUP_JOIN_REQUEST_START,
    UpdateGroupJoinRequestWorker
  );
}

function* GetGroupDetailsWorker({ slug }) {
  const response = yield call(GetGroupDetails, slug);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_GROUP_DETAILS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_GROUP_DETAILS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_GROUP_DETAILS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetGroupDetailsWatcher() {
  yield takeEvery(GET_GROUP_DETAILS_START, GetGroupDetailsWorker);
}

function* LeaveGroupWorker({ data }) {
  const response = yield call(LeaveGroup, data);
  try {
    if (response?.status === "1") {
      yield put({ type: LEAVE_GROUP_SUCCESS, data: response });
    } else {
      yield put({ type: LEAVE_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: LEAVE_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* LeaveGroupWatcher() {
  yield takeEvery(LEAVE_GROUP_START, LeaveGroupWorker);
}

function* DeleteGroupWorker({ data }) {
  const response = yield call(DeleteGroup, data);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_GROUP_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteGroupWatcher() {
  yield takeEvery(DELETE_GROUP_START, DeleteGroupWorker);
}

function* DeleteGroupMembersWorker({ data }) {
  const response = yield call(DeleteGroupMembers, data);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_GROUP_MEMBERS_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_GROUP_MEMBERS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_GROUP_MEMBERS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteGroupMembersWatcher() {
  yield takeEvery(DELETE_GROUP_MEMBERS_START, DeleteGroupMembersWorker);
}
