import { Box } from "@material-ui/core";
import React from "react";
import { Body1 } from "../Typography/Typographys";
import { AboutSkeleton } from "../common/Skeletons";
import { ZeroStateAbout } from "../common/ZeroStateComponents";
import LeftRightComponent from "../common/LeftRightComponent";
import HtmlMessage from "../common/HtmlMessage";

export default function About({
  currentUser,
  updateEditProfileStatus,
  updateActiveEditProfileFormIndex,
  myProfile,
  loading,
}) {
  return currentUser?.userInfo?.data?.user_profile?.description ||
    myProfile ||
    loading ? (
    <LeftRightComponent heading={"About"}>
      <Box className={"manageMT15"}>
        {loading ? (
          <AboutSkeleton />
        ) : !currentUser?.userInfo?.data?.user_profile?.description &&
          myProfile ? (
          <ZeroStateAbout
            handleOnClick={() => {
              updateEditProfileStatus(true);
              updateActiveEditProfileFormIndex(0);
            }}
          />
        ) : (
          <Body1>
            <HtmlMessage
              text={currentUser?.userInfo?.data?.user_profile?.description}
              expanded={true}
            />
          </Body1>
        )}
      </Box>
    </LeftRightComponent>
  ) : (
    ""
  );
}
