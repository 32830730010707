import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  Box,
} from "@material-ui/core";
import classNames from "classnames";
import "../../scss/Dialogs.scss";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";


const Transition = React.forwardRef(function Transition({
  direction = "down",
  ref,
  ...props
}) {
  return <Slide direction={direction} ref={ref} {...props} />;
});

export function DialogComponent({
  children,
  className,
  fullScreen,
  maxWidth,
  handleClose,
  fullWidth = true,
  open,
  scrollPaperClass,
  paperScrollBodyClass,
  transitionDuration,
  ...props
}) {
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      onClose={() => handleClose(false)}
      open={open}
      TransitionComponent={Transition}
      transitionDuration={transitionDuration}
      classes={{
        scrollPaper: scrollPaperClass ? scrollPaperClass : "topScrollPaper",
        paperScrollBody: paperScrollBodyClass
          ? paperScrollBodyClass
          : "topPaperScrollBody",
      }}
      {...props}
    >
      <Box className={classNames("dialogDiv", className)}>{children}</Box>
    </Dialog>
  );
}

export const DialogHeader = ({
  className = "",
  children,
  handleClose,
  bold,
  medium = true,
  light,
  ...props
}) => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classNames("dialogTitleRoot", className)}
    >
      <DialogTitle
        className={classNames(
          bold && "fwBold",
          medium && "fwMedium",
          light && "fwLight"
        )}
        {...props}
      >
        {children}
      </DialogTitle>
      <CloseRoundedIcon fontSize="small" onClick={() => handleClose(false)} />
    </Grid>
  );
};

export const DialogBody = ({
  children,
  className = "",
  bold,
  medium,
  light = true,
  ...props
}) => {
  return (
    <DialogContent
      {...props}
      className={classNames(
        "dialogContentRoot",
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        className
      )}
    >
      {children}
    </DialogContent>
  );
};

export const DialogFooter = ({ children, className = "", ...props }) => {
  return (
    <DialogActions
      {...props}
      className={classNames("dialogActionsRoot", className)}
    >
      {children}
    </DialogActions>
  );
};
