import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { EditorState } from "draft-js";
import {
  CREATE_POST_START,
  UPDATE_BLOG_POST_START,
  GET_BLOG_DETAILS_START,
  GET_POST_START,
} from "../actions/PostAction";
import { useParams, useHistory } from "react-router-dom";
import CF from "../utils/CommonFunction";
import "../scss/Blog.scss";
import toast from "../components/common/Snackbars";
import { ReactComponent as ArrowIcon } from "../assets/Arrow.svg";
import { TwoColumnGridView } from "../components/common/GridViews";
const SimpleButton = React.lazy(() =>
  import("../components/Buttons/SimpleButton")
);
const LabelTypography = React.lazy(() =>
  import("../components/Typography/LabelTypography")
);
const AddPost = React.lazy(() => import("../components/AddPost/AddPost"));
const Publish = React.lazy(() => import("../components/AddPost/Publish"));
const Categories = React.lazy(() => import("../components/AddPost/Categories"));
const ImageUpload = React.lazy(() =>
  import("../components/AddPost/ImageUpload")
);
const MaxWidthComponent = React.lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = React.lazy(() => import("../components/common/Body"));

let addBlogPostFlag = false;
let updateBlogPostFlag = false;
let deleteBlogPrimaryImageFlag = false;

function AddPostContainer({
  createPost,
  createPostData,
  editPost,
  getBlogPostDetails,
  getBlogDetails,
  blogData,
  getPost,
  updateBlogPostData,
  updatPostData,
}) {
  const arrayOfUrlInfo = window.location.pathname.split("/");

  const pathName = arrayOfUrlInfo[1].trim();
  let modeOfPage = "add";
  if (pathName == "edit-post") {
    modeOfPage = "edit";
  }
  const { slug } = useParams();
  const history = useHistory();
  const [post_category_id, setPost_category_id] = useState([]);
  const [title, setTitle] = useState("");
  const [is_active, setIs_active] = useState(0);
  const [imageBlog, setImageBlog] = useState(null);
  const [convertedContent, setConvertedContent] = useState("");
  const initEditedBlogDetail = {};
  const [editedBlogDetail, setEditedBlogDetail] =
    useState(initEditedBlogDetail);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handlePublishBlogPost = (is_active) => {
    const isValidated = validateForm();
    if (isValidated) {
      const formData = new FormData();
      formData.append("is_active", is_active);
      formData.append("title", title);
      formData.append("content", convertedContent);
      formData.append("blog_primary_image", imageBlog);
      if (post_category_id.length > 0) {
        post_category_id.forEach((item, index) => {
          formData.append(`categories_ids[${index}]`, item);
        });
      }
      if (modeOfPage === "add") {
        createPost(formData);
        addBlogPostFlag = true;
      } else {
        editPost(slug, formData);
        updateBlogPostFlag = true;
      }
    }
  };

  const handleDeleteBlogPrimaryImage = () => {
    setImageBlog(null);
    if (modeOfPage === "edit") {
      const isValidated = validateForm();
      if (isValidated) {
        editPost(slug, {
          is_active,
          title,
          content: convertedContent,
          blog_primary_image: null,
          categories_ids: post_category_id,
        });
        deleteBlogPrimaryImageFlag = true;
      }
    }
  };

  //Client side validation
  const validateForm = () => {
    let inValidCounter = 0;
    if (title === "") {
      inValidCounter++;
      toast.error("Please enter blog title");
    }

    if (
      convertedContent === null ||
      CF.extractContent(convertedContent.trim()).trim() === ""
    ) {
      inValidCounter++;
      toast.error("Please enter blog content.");
    }
    return inValidCounter === 0;
  };

  useEffect(() => {
    if (modeOfPage == "edit") {
      const recordId = arrayOfUrlInfo[2];
      //Call api to get information
      getBlogPostDetails(slug);
    }
  }, []);

  //Edit UseEffect
  useEffect(() => {
    if (getBlogDetails?.getBlogPostData?.status === "1") {
      const editedBlogDetail = getBlogDetails.getBlogPostData.data;
      const { title, content, primary_image, post_categories, is_active } =
        editedBlogDetail;
      setTitle(title);
      setConvertedContent(content);
      setImageBlog(primary_image);
      if (post_categories.length > 0) {
        setPost_category_id(
          post_categories.map((item) => {
            return item.category_id;
          })
        );
      }
      setIs_active(is_active);
      setEditedBlogDetail(editedBlogDetail);
    } else if (getBlogDetails?.errorMsg?.status === "0") {
      setTimeout(function () {
        history.push("/home");
      }, 100);
    }
  }, [getBlogDetails]);

  //Create UseEffect
  useEffect(() => {
    if (
      (createPostData?.data?.status === "1" && addBlogPostFlag) ||
      (updateBlogPostData?.updatData?.status === "1" && updateBlogPostFlag)
    ) {
      addBlogPostFlag = false;
      updateBlogPostFlag = false;
      setTitle("");
      setConvertedContent(null);
      setImageBlog(null);
      setPost_category_id([]);
      setEditorState(() => EditorState.createEmpty());
      history.push("/my-blogs");
    }
    if (
      updateBlogPostData?.updatData?.status === "1" &&
      deleteBlogPrimaryImageFlag
    ) {
      deleteBlogPrimaryImageFlag = false;
      getBlogPostDetails(slug);
    }
  }, [createPostData, updateBlogPostData]);

  return (
    <>
      <Body>
        <MaxWidthComponent className="AddPostgridContainer">
          <div className="AddPostDiv">
            <LabelTypography className="addPostHeading" medium>
              {/* {modeOfPage === "add" ? "Add " : "Edit "}New Post */}
              {CF.capitalizeFirstLetter(modeOfPage)}&nbsp;New Post
            </LabelTypography>
            <div className="buttonContainer">
              <SimpleButton
                children="Back"
                primaryColorBtn={true}
                className="backBtn"
                onClick={() => history.push("/my-blogs")}
                endIcon={<ArrowIcon fill="#fff" />}
              />
            </div>
          </div>
          <TwoColumnGridView
            type={"singleRightColumn"}
            rightSide={
              <>
                <Publish
                  handlePublishBlogPost={handlePublishBlogPost}
                  createPostData={createPostData}
                  updateBlogPostData={updateBlogPostData}
                  modeOfPage={modeOfPage}
                  editedBlogDetail={editedBlogDetail}
                />
                <Categories
                  setPost_category_id={setPost_category_id}
                  post_category_id={post_category_id}
                  editedBlogDetail={editedBlogDetail}
                  getBlogDetails={getBlogDetails}
                  modeOfPage={modeOfPage}
                />
                <ImageUpload
                  imageBlog={imageBlog}
                  setImageBlog={setImageBlog}
                  editedBlogDetail={editedBlogDetail}
                  modeOfPage={modeOfPage}
                  editPost={editPost}
                  getBlogPostDetails={getBlogPostDetails}
                  getBlogDetails={getBlogDetails}
                  setEditedBlogDetail={setEditedBlogDetail}
                  handleDeleteBlogPrimaryImage={handleDeleteBlogPrimaryImage}
                />
              </>
            }
            leftSide={
              <>
                <AddPost
                  title={title}
                  setTitle={setTitle}
                  convertedContent={convertedContent}
                  setConvertedContent={setConvertedContent}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  modeOfPage={modeOfPage}
                  editedBlogDetail={editedBlogDetail}
                  setImageBlog={setImageBlog}
                />
              </>
            }
          />
        </MaxWidthComponent>
      </Body>
    </>
  );
}

const mapStateToProps = (state) => ({
  createPostData: state.PostData.createPostData,
  getBlogDetails: state.PostData.getBlogDetails,
  updateBlogPostData: state.PostData.updateBlogPostData,
});

const mapDispatchToProps = (dispatch) => ({
  createPost: (postData) =>
    dispatch({
      type: CREATE_POST_START,
      postData,
    }),
  editPost: (id, blogData) =>
    dispatch({
      type: UPDATE_BLOG_POST_START,
      id,
      blogData,
    }),
  getBlogPostDetails: (id) =>
    dispatch({
      type: GET_BLOG_DETAILS_START,
      id,
    }),
  getPost: () =>
    dispatch({
      type: GET_POST_START,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPostContainer);
