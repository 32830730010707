import { makeStyles, TextField } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
const useClasses = makeStyles((theme) => ({
  plainTextfieldRoot: {
    width: "100%",
    "&>div": {
      background: "rgba(255, 255, 255, 0.2)",
      borderRadius: "8px",
      padding: "5px 10px",
      border: `1px solid ${theme.palette.border.borderColor}`,
      "&>input,&>textarea": {
        lineHeight: "21px",
        fontSize: "14px",
        color: theme.palette.text.bold,
        fontWeight: theme.palette.fontweight.light400,
        fontFamily: theme.palette.font.boldText.fontFamily,
      },
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  plainTextfieldDisabled: {
    "&>div": {
      background: "rgba(0, 0, 0, 0.10)",
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.border.borderColor}`,
      },
    },
  },
  has_error: {
    "&>div": {
      border: `1px solid ${theme.palette.common.red} !important`,
    },
    "&>p": {
      color: theme.palette.common.red,
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.common.red} !important`,
      },
    },
  },
  backgroundTextfield: {
    "&>div": {
      border: `1px solid ${theme.palette.border.bordertopcolor}`,
      background: theme.palette.common.aliceBlue,
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.border.bordertopcolor}`,
      },
    },
  },
}));

export default function PlainTextfield({
  className,
  placeholder,
  inputProps,
  type,
  value,
  required = true,
  disabled = false,
  onChange,
  onClick,
  multiline = false,
  rows,
  id,
  onKeyDown,
  disableUnderline = true,
  maxRows,
  minRows,
  helperText,
  has_error = false,
  autoFocus,
  backgroundTextfield,
  style,
  autoComplete,
  setRef,
  setInputRef,
  ...props
}) {
  const classes = useClasses();
  return (
    <TextField
      className={classNames(
        classes.plainTextfieldRoot,
        disabled && classes.plainTextfieldDisabled,
        has_error && classes.has_error,
        backgroundTextfield && classes.backgroundTextfield,
        className
      )}
      placeholder={placeholder}
      inputProps={inputProps}
      InputProps={{
        disableUnderline: { disableUnderline },
      }}
      type={type}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      multiline={multiline}
      rows={rows}
      id={id}
      maxRows={maxRows}
      minRows={minRows}
      onKeyDown={onKeyDown}
      helperText={helperText}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      style={style}
      ref={setRef}
      inputRef={setInputRef}
      {...props}
    />
  );
}
