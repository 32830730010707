import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { VERIFY_EMAIL_START } from "../actions/AuthAction";
import { CircularProgress } from "@material-ui/core";
import { GET_USER_DATA_START } from "../actions/UserAction";
import utils from "../utils/commonUtils";
import { GetUserData } from "../Apis/UserApis";

export const VerifyEmail = ({ verifyEmail, verifyEmailData, getUserData }) => {
  const { token } = useParams();
  const history = useHistory();
  const { token: localToken } = utils.getLocalStorageItems();

  useEffect(() => {
    if (verifyEmailData?.data?.status === "1") {
      getUserData();
    }
    if (verifyEmailData?.data?.status === "3") {
      localStorage.clear();
      history.push("/signin");
    }
  }, [verifyEmailData, getUserData]);

  const ProcessVarification = async () => {
    if (localToken) {
      let userData = await GetUserData()
      if (userData?.data?.is_verified === true) {
        history.push("/home");
      } else {
        verifyEmail(token);
      }
    } else {
      verifyEmail(token);
    }
  }

  useEffect(() => {
    ProcessVarification()
  }, []);

  return (
    <div className="center" style={{ width: "100%", height: "100vh" }}>
      <CircularProgress size={20} color="primary" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  verifyEmailData: state.AuthData.verifyEmailData,
});

const mapDispatchToProps = (dispatch) => ({
  verifyEmail: (token) =>
    dispatch({
      type: VERIFY_EMAIL_START,
      token,
    }),
  getUserData: () =>
    dispatch({
      type: GET_USER_DATA_START,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
