import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Box, Divider } from "@material-ui/core";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import { ReactComponent as AddPeople } from "../../assets/AddPeople.svg";
import { ReactComponent as PendingConnectionRequestIcon } from "../../assets/PendingConnectionRequestIcon.svg";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import classNames from "classnames";
import Tooltip from "../common/Tooltip";
import {
  CANCEL_CONNECTION_REQUEST_START,
  NEW_CONNECTION_REQUEST_START,
  REMOVE_CONNECTION_USER_START,
  UPDATE_CONNECTION_REQUEST_START,
} from "../../actions/ConnectionsAction";
import CircularProgressComponent from "../common/CircularProgressComponent";
import AlertDialog from "../Dialogs/AlertDialog";
import utils from "../../utils/commonUtils";
import {
  CREATE_NEW_CHAT_ROOM_START,
  GET_CHAT_ROOM_START,
  UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
  DELETE_GROUP_CHAT_START,
} from "../../actions/ChatAction";
import SimpleButton from "../Buttons/SimpleButton";
import H6 from "../Headings/H6";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditChatGroupDialog from "../Dialogs/ChatGroupDialog";

const useStyle = makeStyles((theme) => ({
  pdActionBtn: {
    width: "100%",
    marginTop: 10,
    "&>hr": {
      margin: "0px 8px",
      height: 20,
    },
    "&>div": {
      "&:nth-child(1)": {
        marginRight: 10,
      },
    },
  },
  circularProgressStyle: {
    marginRight: 10,
  },
}));

let createNewChatRoomFlag = false;
let sendNewConnectionRequestFlag = false;
let cancelConnectionRequestFlag = false;
let updateConnectionRequestFlag = false;
let removeConnectionUserFlag = false;
let deleteGroupChatFlag = false;

export const ConnAndMsgComponent = ({
  type,
  currentUser,
  sendNewConnectionRequest,
  newConnectionRequestData,
  cancelConnectionRequest,
  cancelConnectionRequestData,
  updateConnectionRequest,
  updateConnectionRequestData,
  createNewChatRoom,
  createNewChatRoomData,
  updateChatMessageRootDiv,
  getChatRoom,
  removeConnectionUser,
  removeConnectionUserData,
  userData,
  myProfile,
  chatGroupData,
  deleteGroupChat,
  deleteChatGroupData,
}) => {
  const classes = useStyle();
  const [loading, setLoading] = useState("");
  const [loadingId, setLoadingId] = useState(0);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [dialogSuccessBtnText, setDialogSuccessBtnText] = useState("");
  const [alertDialogType, setAlertDialogType] = useState("");
  const [chatIsUpdate, setChatIsUpdate] = useState(false);
  const [chatDialogOpen, setChatDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setOpen(false);
    setTimeout(() => {
      setDialogTitle("");
      setDialogContent("");
      setDialogSuccessBtnText("");
      setAlertDialogType("");
    }, 1000);
  };

  const handleClose = (value) => {
    if (value) {
      if (alertDialogType === "withdraw") {
        cancelConnectionRequest(utils.toBase64(currentUser?.con_id));
        setLoading("withdraw");
        setLoadingId(currentUser?.con_id);
        cancelConnectionRequestFlag = true;
      } else if (alertDialogType === "rejected") {
        updateConnectionRequest(
          utils.toBase64(currentUser?.con_id),
          currentUser?.inviter,
          "rejected"
        );
        setLoading("rejected");
        setLoadingId(currentUser?.con_id);
        updateConnectionRequestFlag = true;
      } else if (alertDialogType === "remove") {
        removeConnectionUser(utils.toBase64(currentUser?.con_id));
        setLoading("remove");
        setLoadingId(currentUser?.con_id);
        removeConnectionUserFlag = true;
      } else if (alertDialogType === "deleteChatGroup") {
        deleteGroupChat(utils.toBase64(chatGroupData?.id));
        setLoading("deleteChatGroup");
        setLoadingId(chatGroupData?.id);
        deleteGroupChatFlag = true;
      } else if (alertDialogType === "pending") {
        sendNewConnectionRequest(currentUser?.id, "pending");
        setLoading("pending");
        setLoadingId(currentUser?.id);
        sendNewConnectionRequestFlag = true;
      }
    }
    handleDialogClose();
  };

  const handleAcceptConnectionRequest = () => {
    updateConnectionRequest(
      utils.toBase64(currentUser?.con_id),
      currentUser?.inviter,
      "accepted"
    );
    setLoading("accepted");
    setLoadingId(currentUser?.con_id);
    updateConnectionRequestFlag = true;
  };

  const handleSendNewConnectionRequest = () => {
    setAlertDialogType("pending");
    setDialogTitle("Add Connection User");
    setDialogContent("Are you sure you want to add this connection user?");
    setDialogSuccessBtnText("Connect");
    setOpen(true);
  };

  const handleCreateNewChatRoom = () => {
    createNewChatRoom({
      type: "personal",
      userIds: [currentUser?.id],
    });
    createNewChatRoomFlag = true;
    setLoading("message");
    setLoadingId(currentUser?.id);
  };

  const handleRemoveConnectionUserBtnClick = () => {
    setAlertDialogType("remove");
    setDialogTitle("Remove Connection User.");
    setDialogContent("Are you sure you want to remove this connection user?");
    setDialogSuccessBtnText("Remove");
    setOpen(true);
  };

  const handleDeleteChatGroupBtnClick = () => {
    setAlertDialogType("deleteChatGroup");
    setDialogTitle("Delete Chat Group");
    setDialogContent("Are you sure you want to delete this chat group?");
    setDialogSuccessBtnText("Delete");
    setOpen(true);
  };

  const handleWithdrawConnectionRequestBtnClick = () => {
    setDialogTitle("Withdraw Connection Request.");
    setDialogContent(
      "Are you sure you want to withdraw this connection request?"
    );
    setDialogSuccessBtnText("Withdraw");
    setAlertDialogType("withdraw");
    setOpen(true);
  };

  const handleDeclineConnectionRequestBtnClick = () => {
    setDialogTitle("Decline Connection Request.");
    setDialogContent(
      "Are you sure you want to decline this connection request?"
    );
    setDialogSuccessBtnText("Decline");
    setAlertDialogType("rejected");
    setOpen(true);
  };

  const handleLoadingClose = () => {
    setLoading("");
    setLoadingId(0);
  };

  useEffect(() => {
    if (createNewChatRoomData?.data?.status === "1" && createNewChatRoomFlag) {
      updateChatMessageRootDiv(true);
      getChatRoom(utils.toBase64(createNewChatRoomData?.data?.data?.id), {
        offset: 0,
      });
      createNewChatRoomFlag = false;
      handleLoadingClose();
    }
    if (deleteChatGroupData?.data?.status === "1" && deleteGroupChatFlag) {
      deleteGroupChatFlag = false;
      handleLoadingClose();
    }
    if (createNewChatRoomData?.data?.status === "3" && createNewChatRoomFlag) {
      updateChatMessageRootDiv(true);
      getChatRoom(
        utils.toBase64(createNewChatRoomData?.data?.data?.chatRoomId),
        { offset: 0 }
      );
      handleLoadingClose();
      createNewChatRoomFlag = false;
    }
    if (
      newConnectionRequestData?.data?.status === "1" &&
      sendNewConnectionRequestFlag
    ) {
      sendNewConnectionRequestFlag = false;
      handleLoadingClose();
    }
    if (
      cancelConnectionRequestData?.data?.status === "1" &&
      cancelConnectionRequestFlag
    ) {
      handleLoadingClose();
      cancelConnectionRequestFlag = false;
    }
    if (
      updateConnectionRequestData?.data?.status === "1" &&
      updateConnectionRequestFlag
    ) {
      updateConnectionRequestFlag = false;
      handleLoadingClose();
    }
    if (
      removeConnectionUserData?.data?.status === "1" &&
      removeConnectionUserFlag
    ) {
      handleLoadingClose();
      removeConnectionUserFlag = false;
    }
  }, [
    createNewChatRoomData,
    deleteChatGroupData,
    newConnectionRequestData,
    cancelConnectionRequestData,
    updateConnectionRequestData,
    removeConnectionUserData,
  ]);

  const chatDialogClose = () => {
    setChatIsUpdate(false);
    setChatDialogOpen(false);
  };

  const handleEditGroupBtnClick = () => {
    setChatIsUpdate(true);
    setChatDialogOpen(true);
  };

  const getComponent = (type) => {
    switch (type) {
      case "connectionIcon":
        return loading === "pending" &&
          newConnectionRequestData?.loading &&
          loadingId === currentUser?.id ? (
          <CircularProgressComponent customColor="secondary" />
        ) : (
          <Tooltip title={"Send connection request"}>
            <AddPeople onClick={handleSendNewConnectionRequest} />
          </Tooltip>
        );
      case "connectionButton":
        return (
          <SimpleButton
            transparentBorderBtn
            loading={
              loading === "pending" &&
              newConnectionRequestData?.loading &&
              loadingId === currentUser?.id
            }
            onClick={handleSendNewConnectionRequest}
          >
            Connect
          </SimpleButton>
        );
      case "followConnection":
        return (
          <H6
            medium
            className="followText"
            transparentBorderBtn
            loading={
              loading === "pending" &&
              newConnectionRequestData?.loading &&
              loadingId === currentUser?.id
            }
            onClick={handleSendNewConnectionRequest}
          >
            Connect
          </H6>
        );
      case "withdrawIcon":
        return loading === "withdraw" &&
          cancelConnectionRequestData?.loading &&
          loadingId === currentUser?.con_id ? (
          <CircularProgressComponent customColor="secondary" />
        ) : (
          <Tooltip title={"Withdraw connection request"}>
            <PendingConnectionRequestIcon
              onClick={handleWithdrawConnectionRequestBtnClick}
            />
          </Tooltip>
        );
      case "withdrawButton":
        return (
          <SimpleButton
            onClick={handleWithdrawConnectionRequestBtnClick}
            transparentBorderBtn
            loading={
              loading === "withdraw" &&
              cancelConnectionRequestData?.loading &&
              loadingId === currentUser?.con_id
            }
          >
            Withdraw
          </SimpleButton>
        );
      case "acceptIcon":
        return loading === "accepted" &&
          updateConnectionRequestData?.loading &&
          loadingId === currentUser?.con_id ? (
          <CircularProgressComponent
            className={classes.circularProgressStyle}
          />
        ) : (
          <Tooltip title={"Accept connection request"}>
            <Box
              className="center aOrdCRIconBtn"
              onClick={handleAcceptConnectionRequest}
            >
              <CheckOutlinedIcon fontSize="small" />
            </Box>
          </Tooltip>
        );
      case "acceptButton":
        return (
          <SimpleButton
            onClick={handleAcceptConnectionRequest}
            primaryColorBtn
            loading={
              loading === "accepted" &&
              updateConnectionRequestData?.loading &&
              loadingId === currentUser?.con_id
            }
          >
            Accept
          </SimpleButton>
        );
      case "declineIcon":
        return loading === "rejected" &&
          updateConnectionRequestData?.loading &&
          loadingId === currentUser?.con_id ? (
          <CircularProgressComponent />
        ) : (
          <Tooltip title={"Decline connection request"}>
            <Box
              className="center aOrdCRIconBtn"
              onClick={handleDeclineConnectionRequestBtnClick}
            >
              <CloseOutlinedIcon fontSize="small" />
            </Box>
          </Tooltip>
        );
      case "declineButton":
        return (
          <SimpleButton
            onClick={handleDeclineConnectionRequestBtnClick}
            transparentBorderBtn
            loading={
              loading === "rejected" &&
              updateConnectionRequestData?.loading &&
              loadingId === currentUser?.con_id
            }
          >
            Decline
          </SimpleButton>
        );
      case "messageIcon":
        return loading === "message" &&
          createNewChatRoomData?.loading &&
          loadingId === currentUser?.id ? (
          <CircularProgressComponent />
        ) : (
          <Tooltip title={`Chat with ${currentUser?.display_name}`}>
            <ForumOutlinedIcon
              onClick={handleCreateNewChatRoom}
              fontSize="small"
              className="tshSvg"
            />
          </Tooltip>
        );
      case "messageButton":
        return (
          <SimpleButton
            onClick={handleCreateNewChatRoom}
            transparentBorderBtn
            loading={
              loading === "message" &&
              createNewChatRoomData?.loading &&
              loadingId === currentUser?.id
            }
          >
            Message
          </SimpleButton>
        );
      case "simpleMessageText":
        return <span onClick={handleCreateNewChatRoom} className="openChatButton">Message</span>;
      case "removeButton":
        return (
          <SimpleButton
            onClick={handleRemoveConnectionUserBtnClick}
            endIcon={<DeleteOutlinedIcon />}
            transparentBorderBtn
            loading={
              loading === "remove" &&
              removeConnectionUserData?.loading &&
              loadingId === currentUser?.con_id
            }
          >
            Remove
          </SimpleButton>
        );
      case "deleteChatGroupIcon":
        return (
          <Box onClick={handleDeleteChatGroupBtnClick}>
            <DeleteOutlinedIcon />
          </Box>
        );
      case "editChatGroupIcon":
        return (
          <Box
            onClick={handleEditGroupBtnClick}
            loading={
              deleteChatGroupData?.loading &&
              loading === "deleteChatGroup" &&
              loadingId === chatGroupData?.id
            }
          >
            <EditOutlinedIcon />
          </Box>
        );
      default:
        return "";
    }
  };

  const getConnAndMsgComponent = (type) => {
    if (type === "profileDashboard") {
      return (
        <Box className={classNames(classes.pdActionBtn, "center")}>
          {currentUser?.status === "withdraw" ? (
            getComponent("withdrawIcon")
          ) : currentUser?.status === "pending" ? (
            <>
              {getComponent("acceptIcon")}
              {getComponent("declineIcon")}
            </>
          ) : currentUser?.status === "connection" ? (
            getComponent("connectionIcon")
          ) : null}
          {currentUser?.status !== "accepted" ? (
            <Divider orientation="vertical" />
          ) : null}
          {getComponent("messageIcon")}
        </Box>
      );
    } else if (type === "myConnection") {
      return myProfile
        ? getComponent("removeButton")
        : getComponent("messageButton");
    } else if (type === "myConnectionRequests" && myProfile) {
      return currentUser?.inviter === userData?.userInfo?.data?.id ? (
        getComponent("withdrawButton")
      ) : (
        <>
          {getComponent("acceptButton")}
          {getComponent("declineButton")}
        </>
      );
    } else if (type === "connectionSuggestion") {
      return currentUser?.status === "connection"
        ? getComponent("connectionIcon")
        : getComponent("withdrawIcon");
    } else if (type === "simpleMessageText") {
      return getComponent("simpleMessageText");
    } else if (type === "followConnection") {
      return getComponent("followConnection");
    } else if (type === "chatGroupDetailUpdate") {
      return getComponent("editChatGroupIcon");
    } else if (type === "chatGroupDelete") {
      return getComponent("deleteChatGroupIcon");
    }
  };

  return (
    <>
      {getConnAndMsgComponent(type)}
      <AlertDialog
        open={open}
        dialogTitle={dialogTitle}
        dialogContent={dialogContent}
        successBtnText={dialogSuccessBtnText}
        handleClose={handleClose}
      />
      <EditChatGroupDialog
        open={chatDialogOpen}
        handleClose={chatDialogClose}
        isUpdate={chatIsUpdate}
        data={chatGroupData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  newConnectionRequestData: state.ConnectionsData.newConnectionRequestData,
  cancelConnectionRequestData: state.ConnectionsData.cancelConnectionRequest,
  updateConnectionRequestData:
    state.ConnectionsData.updateConnectionRequestData,
  createNewChatRoomData: state.ChatData.createNewChatRoomData,
  removeConnectionUserData: state.ConnectionsData.removeConnectionUserData,
  userData: state.UserData.getUserData,
  deleteChatGroupData: state.ChatData.deleteChatGroupData,
});

const mapDispatchToProps = (dispatch) => ({
  sendNewConnectionRequest: (invitee_id, status) =>
    dispatch({
      type: NEW_CONNECTION_REQUEST_START,
      invitee_id,
      status,
    }),
  cancelConnectionRequest: (con_id) =>
    dispatch({
      type: CANCEL_CONNECTION_REQUEST_START,
      con_id,
    }),
  updateConnectionRequest: (con_id, inviter_id, status) =>
    dispatch({
      type: UPDATE_CONNECTION_REQUEST_START,
      con_id,
      inviter_id,
      status,
    }),
  getChatRoom: (room_id, data) =>
    dispatch({
      type: GET_CHAT_ROOM_START,
      room_id,
      data,
    }),
  createNewChatRoom: (chatData) =>
    dispatch({
      type: CREATE_NEW_CHAT_ROOM_START,
      chatData,
    }),
  updateChatMessageRootDiv: (data) =>
    dispatch({
      type: UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
      data,
    }),
  removeConnectionUser: (con_id) =>
    dispatch({
      type: REMOVE_CONNECTION_USER_START,
      con_id,
    }),
  deleteGroupChat: (room_id) =>
    dispatch({
      type: DELETE_GROUP_CHAT_START,
      room_id,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnAndMsgComponent);
