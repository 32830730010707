import {
  GET_POST_ERROR,
  GET_POST_START,
  GET_POST_SUCCESS,
  CREATE_POST_ERROR,
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  GET_POST_CATEGORIES_SUCCESS,
  GET_POST_AUTOFILL_START,
  GET_POST_AUTOFILL_SUCCESS,
  GET_POST_AUTOFILL_ERROR,
  DELETE_CATEGORIES_START,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_ERROR,
  UPDATE_BLOG_POST_START,
  UPDATE_BLOG_POST_SUCCESS,
  UPDATE_BLOG_POST_ERROR,
  DELETE_BLOG_POST_START,
  DELETE_BLOG_POST_SUCCESS,
  DELETE_BLOG_POST_ERROR,
  GET_BLOG_DETAILS_START,
  GET_BLOG_DETAILS_SUCCESS,
  GET_BLOG_DETAILS_ERROR,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_START,
  CREATE_CATEGORY_SUCCESS,
  GET_ALL_BLOGS_POST_START,
  GET_ALL_BLOGS_POST_SUCCESS,
  GET_ALL_BLOGS_POST_ERROR,
  GET_USER_POST_START,
  GET_USER_POST_SUCCESS,
  GET_USER_POST_ERROR,
  RESET_POST_REDUCER,
} from "../actions/PostAction";

const initialState = {
  getPostData: {
    loading: false,
    data: {
      data: [],
      offset: 0,
      offsetStatus: true,
      status: "",
      message: "",
    },
    errorMsg: "",
  },
  createPostData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getPostCategoriesData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getAutofillCategories: {
    loading: false,
    data: [],
    errorMsg: "",
  },
  deleteCategoriesData: {
    loading: false,
    deletedData: {},
    errorMsg: "",
  },
  updateBlogPostData: {
    loading: false,
    updatData: {},
    errorMsg: "",
  },
  deleteBlogPostData: {
    loading: false,
    deletedPostData: {},
    errorMsg: "",
  },
  getBlogDetails: {
    loading: false,
    getBlogPostData: {},
    errorMsg: "",
  },
  createCategoryData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getAllBlogsPostData: {
    loading: false,
    data: {
      data: [],
      offset: 0,
      offsetStatus: true,
      status: "",
      message: "",
    },
    errorMsg: "",
  },
};

const PostReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case RESET_POST_REDUCER: {
      return {
        ...previousState,
        [action.key]: action.payload || previousState[action.key]
      }
    }
    case GET_POST_START: {
      return {
        ...previousState,
        getPostData: {
          loading: true,
          data: {
            ...previousState.getPostData.data,
          },
          errorMsg: "",
        },
        getBlogDetails: {
          loading: false,
          getBlogPostData: {},
          errorMsg: "",
        },
      };
    }
    case GET_POST_SUCCESS: {
      const oldData = { ...previousState.getPostData.data };
      const offset = action.data.offset;
      return {
        ...previousState,
        getPostData: {
          loading: false,
          data: {
            ...previousState.getPostData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_POST_ERROR: {
      return {
        ...previousState,
        getPostData: {
          loading: false,
          data: {
            ...previousState.getPostData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case GET_USER_POST_START: {
      return {
        ...previousState,
        getPostData: {
          loading: true,
          data: {
            ...previousState.getPostData.data,
          },
          errorMsg: "",
        },
        getBlogDetails: {
          loading: false,
          getBlogPostData: {},
          errorMsg: "",
        },
      };
    }
    case GET_USER_POST_SUCCESS: {
      const oldData = { ...previousState.getPostData.data };
      const offset = action.data.offset;
      return {
        ...previousState,
        getPostData: {
          loading: false,
          data: {
            ...previousState.getPostData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_USER_POST_ERROR: {
      return {
        ...previousState,
        getPostData: {
          loading: false,
          data: {
            ...previousState.getPostData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case CREATE_POST_START: {
      return {
        ...previousState,
        createPostData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CREATE_POST_SUCCESS: {
      return {
        ...previousState,
        createPostData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case CREATE_POST_ERROR: {
      return {
        ...previousState,
        createPostData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_POST_CATEGORIES_SUCCESS: {
      return {
        ...previousState,
        getPostCategoriesData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    //START :Get autofill Categories
    case GET_POST_AUTOFILL_START: {
      return {
        ...previousState,
        getAutofillCategories: {
          loading: true,
          data: [],
          errorMsg: "",
        },
      };
    }
    case GET_POST_AUTOFILL_SUCCESS: {
      return {
        ...previousState,
        getAutofillCategories: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_POST_AUTOFILL_ERROR: {
      return {
        ...previousState,
        getAutofillCategories: {
          loading: false,
          data: [],
          errorMsg: action.data,
        },
      };
    }
    //END :Get autofill Categories
    //START: Delete Categories
    case DELETE_CATEGORIES_START: {
      return {
        ...previousState,
        deleteCategoriesData: {
          loading: true,
          deletedData: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_CATEGORIES_SUCCESS: {
      return {
        ...previousState,
        deleteCategoriesData: {
          loading: false,
          deletedData: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_CATEGORIES_ERROR: {
      return {
        ...previousState,
        deleteCategoriesData: {
          loading: false,
          deletedData: {},
          errorMsg: action.data,
        },
      };
    }
    case CREATE_CATEGORY_START: {
      return {
        ...previousState,
        createCategoryData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CREATE_CATEGORY_SUCCESS: {
      const oldData = { ...previousState?.getPostCategoriesData?.data };
      return {
        ...previousState,
        createCategoryData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
        getPostCategoriesData: {
          ...previousState.getPostCategoriesData,
          data: {
            ...previousState?.getPostCategoriesData?.data,
            data:
              oldData?.data !== undefined
                ? [...oldData?.data, action.data.data]
                : [action.data.data],
          },
        },
      };
    }
    case CREATE_CATEGORY_ERROR: {
      return {
        ...previousState,
        createCategoryData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    //START Update Blog post
    case UPDATE_BLOG_POST_START: {
      return {
        ...previousState,
        updateBlogPostData: {
          loading: true,
          updatData: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_BLOG_POST_SUCCESS: {
      return {
        ...previousState,
        updateBlogPostData: {
          loading: false,
          updatData: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_BLOG_POST_ERROR: {
      return {
        ...previousState,
        updateBlogPostData: {
          loading: false,
          updatData: {},
          errorMsg: action.data,
        },
      };
    }
    //END:Update Blog post
    //START: Delete Blog post
    case DELETE_BLOG_POST_START: {
      return {
        ...previousState,
        deleteBlogPostData: {
          loading: true,
          deletedPostData: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_BLOG_POST_SUCCESS: {
      return {
        ...previousState,
        deleteBlogPostData: {
          loading: false,
          deletedPostData: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_BLOG_POST_ERROR: {
      return {
        ...previousState,
        deleteBlogPostData: {
          loading: false,
          deletedPostData: {},
          errorMsg: action.data,
        },
      };
    }
    //END: Delete Blog Post
    //START: Get Blog Details
    case GET_BLOG_DETAILS_START: {
      return {
        ...previousState,
        getBlogDetails: {
          loading: true,
          getBlogPostData: {},
          errorMsg: "",
        },
      };
    }
    case GET_BLOG_DETAILS_SUCCESS: {
      return {
        ...previousState,
        getBlogDetails: {
          loading: false,
          getBlogPostData: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_BLOG_DETAILS_ERROR: {
      return {
        ...previousState,
        getBlogDetails: {
          loading: false,
          getBlogPostData: {},
          errorMsg: action.data,
        },
      };
    }
    //END: Get Blog Details
    //START :Get All Blogs
    case GET_ALL_BLOGS_POST_START: {
      return {
        ...previousState,
        getAllBlogsPostData: {
          loading: true,
          data: {
            ...previousState.getAllBlogsPostData.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_ALL_BLOGS_POST_SUCCESS: {
      const oldData = { ...previousState.getAllBlogsPostData.data };
      const offset = action.data.offset;
      return {
        ...previousState,
        getAllBlogsPostData: {
          loading: false,
          data: {
            ...previousState.getAllBlogsPostData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_ALL_BLOGS_POST_ERROR: {
      return {
        ...previousState,
        getAllBlogsPostData: {
          loading: false,
          data: {
            ...previousState.getAllBlogsPostData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    //END: Get All Blogs
    default: {
      return previousState || initialState;
    }
  }
};

export default PostReducers;
