import { Box } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import utils from "../../utils/commonUtils";
import { DELETE_EDUCATION_HISTORY_START } from "../../actions/UserAction";
import { EduAndWorkDetailsSkeleton } from "../common/Skeletons";
import { ZeroStateEducationAndWorkDetails } from "../common/ZeroStateComponents";
import UserDetailCard from "../StepperComponents/UserDetailCard";
import LeftRightComponent from "../common/LeftRightComponent";

function EducationDetails({
  removeEducationHistory,
  setUpdateId,
  myProfile,
  currentUser,
  loading,
  updateActiveEditProfileFormIndex,
  updateEditProfileStatus,
  userData,
}) {
  return currentUser?.userInfo?.data?.user_education_histories?.length !== 0 ||
    myProfile ||
    loading ? (
    <LeftRightComponent heading={"Education"}>
      <Box className={"manageMTAndDivLCMB"}>
        {loading ? (
          <EduAndWorkDetailsSkeleton />
        ) : currentUser?.userInfo?.data?.user_education_histories?.length >
          0 ? (
          currentUser?.userInfo?.data?.user_education_histories?.map(
            (item, key) => {
              return (
                <UserDetailCard
                  key={key}
                  userData={userData}
                  currentUser={currentUser}
                  type="education"
                  data={{
                    heading: item?.university,
                    message: item?.message,
                    date: `${item?.from_date} - ${
                      item?.to_date === new Date().getFullYear().toString()
                        ? "Present"
                        : item?.to_date
                    }`,
                  }}
                  onDelete={() => {
                    removeEducationHistory(utils.toBase64(item?.id));
                  }}
                  onEdit={() => {
                    setUpdateId(item.id);
                    updateEditProfileStatus(true);
                    updateActiveEditProfileFormIndex(1);
                  }}
                />
              );
            }
          )
        ) : (
          <ZeroStateEducationAndWorkDetails
            handleOnClick={() => {
              updateEditProfileStatus(true);
              updateActiveEditProfileFormIndex(1);
            }}
            type="education"
          />
        )}
      </Box>
    </LeftRightComponent>
  ) : (
    ""
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  removeEducationHistory: (id) =>
    dispatch({
      type: DELETE_EDUCATION_HISTORY_START,
      id,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EducationDetails);
