import React from "react";
import { makeStyles, Paper, Tabs, Tab } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  tabsPaper: {
    boxShadow: "none",
    background: "transparent",
  },
  tabs: {
    "&>div": {
      "&>span": {
        background: theme.palette.secondary.main,
        height: 2,
        borderRadius: "2px 2px 0px 0px",
        minWidth: "50%",
      },
    },
  },
  tabBtn: {
    "&>span": {
      fontSize: 12,
      lineHeight: "18px",
      textTransform: "capitalize",
      fontWeight: theme.palette.fontweight.light400,
      fontFamily: theme.palette.font.boldText.fontFamily,
      color: `${theme.palette.common.black}!important`,
    },
  },
}));

export default function GroupTabsComponent({
  activeStep = 0,
  setActiveStep,
  tabsList = [],
  className,
  onClick = () => {},
}) {
  const classes = useStyle();
  return (
    <Paper className={classNames(classes.tabsPaper, className)}>
      <Tabs
        value={activeStep}
        className={classes.tabs}
        indicatorColor="primary"
      >
        {tabsList?.map((item, key) => {
          return (
            <Tab
              label={item?.label}
              onClick={() => {
                setActiveStep(key);
                onClick(key);
              }}
              className={classes.tabBtn}
              key={key}
            />
          );
        })}
      </Tabs>
    </Paper>
  );
}
