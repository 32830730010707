import React from "react";
import { Skeleton, Box, Grid } from "@mui/material";
import {
  makeStyles,
  withStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import "../../scss/Skeletons.scss";

const useStyle = makeStyles((theme) => ({
  pdbiDiv: {
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
    "&>div": {
      position: "absolute",
      top: 40,
      left: "calc(50% - 40px)",
    },
    "&>span": {
      borderRadius: "10px 10px 0px 0px",
    },
  },
  pdudDiv: {
    boxSizing: "border-box",
    padding: "50px 20px 20px 20px",
    width: "100%",
    flexDirection: "column",
    "&>span": {
      margin: "5px 0px",
    },
    "&>div": {
      marginTop: 5,
      "&>span": {
        margin: "0px 5px",
      },
    },
  },
  eawdSkeletonRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    boxSizing: "border-box",
    "&>div": {
      boxSizing: "border-box",
      marginBottom: 10,
      padding: "15px 20px 15px 20px",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      borderRadius: "15px",
      border: `1px solid ${theme.palette.border.borderColor}`,
      "&>div": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "5px 0px",
      },
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
  userSkeleton: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: 15,
    "&>div": {
      marginLeft: 10,
      width: "calc(100% - 50px)",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },
  loadingMessageStyle: {
    padding: 20,
    "&>div": {
      marginLeft: 10,
      width: "calc(100% - 50px)",
      "&>span": {
        width: "100%",
      },
    },
  },
}));

const StyledSkeleton = withStyles((theme) => ({
  root: {
    transform: "unset !important",
  },
}))(({ variant = "text", width, height, ...props }) => (
  <Skeleton
    animation={"wave"}
    variant={variant}
    width={width}
    height={height}
    {...props}
  />
));

export function SkeletonComponent({ width, height, ...props }) {
  return <StyledSkeleton width={width} height={height} {...props} />;
}

export function GroupRequestSkeleton({ num = 1 }) {
  return [...Array(num)].map((item, key) => {
    return (
      <Box key={key} className={"ldrabRoot groupRequestSkeleton"}>
        <Box>
          <StyledSkeleton width={44} height={44} />
          <Grid container>
            <StyledSkeleton
              width={"100%"}
              height={20}
              style={{ marginBottom: "10px" }}
            />
            <StyledSkeleton width={"30%"} height={15} />
          </Grid>
        </Box>
        <Box className="center">
          <StyledSkeleton width={"100%"} height={40} />
        </Box>
      </Box>
    );
  });
}

export function GroupCardSkeleton({ num = 1, setRef }) {
  return [...Array(num)].map((item, key) => {
    return (
      <Box key={key} ref={setRef} className="gdCardRoot">
        <StyledSkeleton width={"100%"} height={300} />
      </Box>
    );
  });
}

export function UserSkeleton({ num = 1, setRef }) {
  const classes = useStyle();
  return [...Array(num)].map(() => {
    return (
      <Box className={classes.userSkeleton} ref={setRef}>
        <StyledSkeleton width={40} height={40} />
        <Box>
          <StyledSkeleton
            width={"100%"}
            height={15}
            style={{ marginBottom: "10px" }}
          />
          <StyledSkeleton width={"100%"} height={15} />
        </Box>
      </Box>
    );
  });
}

export function MyConncetionSkeleton() {
  return [...Array(12)].map(() => {
    return (
      <StyledSkeleton
        width={180}
        height={200}
        style={{
          margin: "0px 20px 20px 0px",
          borderRadius: "10px",
          marginBottom: "10px",
        }}
      />
    );
  });
}

export function EduAndWorkDetailsSkeleton() {
  const classes = useStyle();
  return (
    <Box className={classes.eawdSkeletonRoot}>
      {[...Array(2)].map(() => {
        return (
          <Box>
            {[...Array(3)].map(() => {
              return (
                <Box>
                  <StyledSkeleton width={"30%"} height={20} />
                  <StyledSkeleton width={"65%"} height={20} />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );
}

export function BackgroundProfileDetailsSkeleton() {
  return [...Array(5)].map(() => {
    return (
      <Box>
        <StyledSkeleton width={"45%"} height={20} />
        <StyledSkeleton width={"45%"} height={20} />
      </Box>
    );
  });
}

export function AboutSkeleton() {
  return [...Array(5)].map((item, key) => {
    return (
      <StyledSkeleton
        width={"100%"}
        height={20}
        style={{ marginBottom: key === 4 ? 0 : 10 }}
      />
    );
  });
}

export function ProfileDashboardSkeleton() {
  const classes = useStyle();
  return (
    <>
      <Box className={classes.pdbiDiv}>
        <StyledSkeleton width={"100%"} height={80} />
        <Box>
          <StyledSkeleton width={80} height={80} />
        </Box>
      </Box>
      <Box className={classNames(classes.pdudDiv, "center")}>
        <StyledSkeleton width={"60%"} height={20} />
        <StyledSkeleton width={"100%"} height={10} />
        <StyledSkeleton width={"100%"} height={12} />
        <Box className={"center"}>
          <StyledSkeleton width={25} height={25} />
          <StyledSkeleton width={25} height={25} />
        </Box>
      </Box>
    </>
  );
}

export function FeedSkeleton({ setRef }) {
  return (
    <Box className="lrComponent feedSkeletonRoot" ref={setRef} sx={{ p: 2 }}>
      <Grid
        container
        sx={{ borderBottom: "1px solid #F3F3F3", padding: "10px 0px" }}
      >
        <StyledSkeleton width={"40px"} height={"40px"} />
        <Grid
          flexDirection={"column"}
          sx={{
            pl: 1,
            width: "calc(100% - 40px)",
            "&>span:nth-child(1)": {
              mb: 1,
            },
          }}
        >
          <StyledSkeleton width={"100%"} height={20} />
          <StyledSkeleton width={"30%"} height={10} />
        </Grid>
      </Grid>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          padding: "10px 0px",
          "&>span": {
            mb: 1,
          },
        }}
      >
        {[...Array(4)].map((item, index) => {
          return (
            <StyledSkeleton width={"100%"} height={index === 3 ? 200 : 15} />
          );
        })}
      </Grid>
      <Grid
        container
        sx={{
          padding: "10px 0px",
          borderTop: "1px solid #F3F3F3",
          "&>span": {
            mr: 1,
          },
        }}
      >
        {[...Array(3)].map(() => {
          return <StyledSkeleton width={"15%"} height={15} />;
        })}
      </Grid>
    </Box>
  );
}

export function LinkPreviewSkeleton() {
  return (
    <Box sx={{ border: "1px solid #E5E5E5", borderRadius: "10px" }}>
      <StyledSkeleton width={"100%"} height={250} />
      <Box
        sx={{
          padding: "10px",
          "&>span": {
            marginBottom: "10px",
          },
        }}
      >
        <StyledSkeleton width={"100%"} height={15} />
        <StyledSkeleton width={"100%"} height={15} />
        <StyledSkeleton width={"100%"} height={15} />
      </Box>
    </Box>
  );
}

export function UserDetailsSkeleton({
  udLeftSide,
  udrsLeftDiv,
  udRightSide,
  udrsRightDiv,
}) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      <Box className={udLeftSide}>
        <StyledSkeleton
          variant="rect"
          width={match ? 120 : 150}
          height={match ? 120 : 150}
        />
      </Box>
      <Box className={udRightSide}>
        <Box
          className={udrsLeftDiv}
          sx={{
            "&>span": {
              margin: "5px 0px",
            },
          }}
        >
          <StyledSkeleton width={"30%"} height={30} />
          <StyledSkeleton width={"50%"} height={20} />
          <StyledSkeleton width={"100%"} height={20} />
        </Box>
        <Box className={udrsRightDiv}>
          <StyledSkeleton width={120} height={40} />
        </Box>
      </Box>
    </>
  );
}

export function BlogViewSkeleton() {
  return (
    <Box sx={{ p: 2 }}>
      <Grid
        container
        flexDirection={"column"}
        sx={{
          padding: "10px 0px",
          "&>span": {
            mb: 1,
          },
        }}
      >
        <StyledSkeleton width={"100%"} height={200} borderRadius={"10px"} />
        <StyledSkeleton width={"100%"} height={15} />
        {[...Array(10)].map(() => {
          return <StyledSkeleton width={"100%"} height={15} />;
        })}
      </Grid>
    </Box>
  );
}

export function LoadingChatRooms({ setRef }) {
  const classes = useStyle();
  return (
    <div
      ref={setRef}
      className={classNames(classes.loadingMessageStyle, "flex-hrs")}
    >
      <SkeletonComponent variant="circular" width={40} height={40} />
      <div className="flex-hcs">
        <SkeletonComponent height={15} style={{ marginBottom: "10px" }} />
        <SkeletonComponent height={15} />
      </div>
    </div>
  );
}
