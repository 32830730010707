import { post, get, deleteM } from "../Api";

export const GetAllConnections = async (user_id) => {
  return await get(`/connection/get-all/${user_id}`);
};

export const GetConnectionSuggestions = async () => {
  return await get(`/connection/suggestions`);
};

export const GetConnectionRequests = async (data) => {
  return await post(`/connection/get-connection-requests`, data);
};

export const NewConnectionRequest = async (invitee_id, status) => {
  return await post(`/connection/new`, { invitee_id, status });
};

export const UpdateConnectionRequest = async (con_id, inviter_id, status) => {
  return await post(`/connection/update/${con_id}`, { inviter_id, status });
};

export const CancelConnectionRequest = async (con_id) => {
  return await get(`/connection/cancel-connection-request/${con_id}`);
};

export const RemoveConnectionUser = async (con_id) => {
  return await deleteM(`/connection/remove-connection/${con_id}`);
};
