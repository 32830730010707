import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import SimpleButton from "../Buttons/SimpleButton";
import utils from "../../utils/commonUtils";
import AlertDialog from "../Dialogs/AlertDialog";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { socket } from "../../utils/socketioSetup";
import {
  CANCEL_CONNECTION_REQUEST_START,
  NEW_CONNECTION_REQUEST_START,
  UPDATE_CONNECTION_REQUEST_START,
} from "../../actions/ConnectionsAction";
import {
  CREATE_NEW_CHAT_ROOM_START,
  GET_CHAT_ROOM_START,
  UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
} from "../../actions/ChatAction";
import "../../scss/Search.scss";
import UserRoles from "../common/UserRoles";
import { ReactComponent as VerifiedTick } from "../../assets/VerifiedTick.svg";
const UserAvatar = React.lazy(() => import("../common/UserAvatar"));
const TooltipComponent = React.lazy(() => import("../common/Tooltip"));
const H4 = React.lazy(() => import("../Headings/H4"));


let createChatRoomFlag = false;

function SearchCommonComponent(props) {
  const {
    updateConnectionRequest,
    updateConnectionRequestData,
    newConnectionRequestData,
    createNewChatRoom,
    createNewChatRoomData,
    updateChatMessageRootDiv,
    getChatRoom,
    cancelConnectionRequest,
    sendNewConnectionRequest,
    userData,
    getSearchData,
  } = props;
  const history = useHistory();
  const [searchData, setSearchData] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [user_id, setUser_id] = useState(null);
  const [type, setType] = useState(null);
  const [con_id, setCon_id] = useState(null);
  const [dialogSuccessBtnText, setDialogSuccessBtnText] = useState("");
  const [dialogCloseBtnText, setDialogCloseBtnText] = useState("");
  const [loadingId, setLoadingId] = useState(null);

  const handleMessage = (uid) => {
    createNewChatRoom({ type: "personal", userIds: [uid] });
    createChatRoomFlag = true;
  };

  const handleDialogClose = (value) => {
    if (value && type === "cancel") {
      cancelConnectionRequest(utils.toBase64(con_id));
    }
    if (value && type === "Decline") {
      updateConnectionRequest(utils.toBase64(con_id), user_id, "rejected");
    }
    if (value && type === "pending") {
      sendNewConnectionRequest(con_id, "pending");
      setLoadingId(con_id);
    }
    setType(null);
    setUser_id(null);
    setCon_id(null);
    setConfirmDialogOpen(false);
    setDialogTitle("");
  };

  const handleRequestedBtnClick = (con_id) => {
    setDialogTitle("Are you sure want to withdraw this connection request?");
    setType("cancel");
    setCon_id(con_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Yes");
    setDialogCloseBtnText("No");
  };

  const handleSendNewConnectionRequest = (con_id) => {
    setDialogTitle("Are you sure you want to add this connection user?");
    setType("pending");
    setCon_id(con_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Connect");
    setDialogCloseBtnText("No");
  };

  const handleDeclineBtnClick = (con_id, user_id) => {
    setDialogTitle("Are you sure want to decline this connection request?");
    setType("Decline");
    setCon_id(con_id);
    setUser_id(user_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Decline");
    setDialogCloseBtnText("Cancel");
  };

  useEffect(() => {
    if (getSearchData?.data?.status === "1") {
      setSearchData(getSearchData?.data?.data);
    }
  }, [getSearchData]);

  useEffect(() => {
    if (createNewChatRoomData?.data?.status === "1" && createChatRoomFlag) {
      updateChatMessageRootDiv(true);
      getChatRoom(utils.toBase64(createNewChatRoomData?.data?.data?.id), {
        offset: 0,
      });
      createChatRoomFlag = false;
    } else if (
      createNewChatRoomData?.data?.status === "3" &&
      createChatRoomFlag
    ) {
      updateChatMessageRootDiv(true);
      getChatRoom(
        utils.toBase64(createNewChatRoomData?.data?.data?.chatRoomId),
        { offset: 0 }
      );
      createChatRoomFlag = false;
    }
  }, [createNewChatRoomData]);

  const updateSearchData = (data, type) => {
    const conUserId =
      data?.inviter === userData?.userInfo?.data?.id
        ? data?.invitee
        : data?.inviter;
    setSearchData((pre) =>
      pre.map((item) => {
        if (item?.id === conUserId) {
          if (type === "new") {
            return {
              ...item,
              status:
                data?.inviter === userData?.userInfo?.data?.id
                  ? "withdraw"
                  : "pending",
              con_id: data?.id,
            };
          } else if (["cancel", "update"].includes(type)) {
            delete item?.con_id;
            return { ...item, status: null };
          } else if (type === "accept") {
            delete item?.con_id;
            return { ...item, status: "accepted" };
          } else {
            return item;
          }
        } else {
          return item;
        }
      })
    );
  };

  useEffect(() => {
    socket.on("newConnectionRequest", (data) => {
      updateSearchData(data, "new");
    });
    socket.on("cancelConnectionRequest", (data) => {
      updateSearchData(data, "cancel");
    });
    socket.on("acceptConnectionRequest", (data) => {
      updateSearchData(data, "accept");
    });
    socket.on("updateConnectionRequest", (data) => {
      updateSearchData(data, "update");
    });
  }, [userData]);

  const getActionBtn = (user) => {
    if (user?.status === "withdraw") {
      return (
        <SimpleButton
          children="Withdraw"
          psTransparentBorderBtn={true}
          onClick={() => handleRequestedBtnClick(user?.con_id)}
        />
      );
    } else if (user?.status === "accepted") {
      return (
        <SimpleButton
          children="Message"
          psTransparentBorderBtn={true}
          onClick={() => handleMessage(user?.id)}
        />
      );
    } else if (user?.status === "pending") {
      return (
        <div>
          <SimpleButton
            children="Accept"
            primaryColorBtn
            onClick={() => {
              updateConnectionRequest(
                utils.toBase64(user?.con_id),
                user?.id,
                "accepted"
              );
              setLoadingId(user?.con_id);
            }}
            loading={
              updateConnectionRequestData?.loading && loadingId === user?.con_id
            }
            disabled={
              updateConnectionRequestData?.loading && loadingId === user?.con_id
            }
          />
          <SimpleButton
            children="Decline"
            psTransparentBorderBtn={true}
            onClick={() => handleDeclineBtnClick(user?.con_id, user?.id)}
          />
        </div>
      );
    } else if (user?.status === null) {
      return (
        <SimpleButton
          children="Connect"
          psTransparentBorderBtn
          onClick={() => handleSendNewConnectionRequest(user?.id)}
          loading={newConnectionRequestData?.loading && loadingId === user?.id}
          disabled={newConnectionRequestData?.loading && loadingId === user?.id}
          circularProgressColor="#023047"
        />
      );
    }
  };

  return (
    <>
      <Grid container className="userListDiv" spacing={2}>
        {searchData !== null &&
          searchData?.map((item, key) => {
            return (
              <Grid item md={2} sm={3} xs={6} className="userBox" key={key}>
                <div></div>
                <div>
                  <UserAvatar
                    src={item?.profile_picture}
                    name={item?.display_name}
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />
                  <TooltipComponent
                    children={
                      <span>
                        <H4
                          className="textEllipsis userName"
                          children={<>{item?.display_name} {item?.is_wkleap_verified && <VerifiedTick style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />}</>}
                          medium
                        />
                      </span>
                    }
                    title={item?.display_name}
                    arrow
                    placement="top"
                    className="tooltipComponent"
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />
                  {item?.user_performing_roles && item?.user_performing_roles?.length !== 0 && <TooltipComponent
                    children={
                      <span>
                        <UserRoles
                          align="center"
                          roles={item?.user_performing_roles}
                        />
                      </span>
                    }
                    title={item?.user_performing_roles[0].role}
                    arrow
                    placement="top"
                    className="tooltipComponent"
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />}
                  {getActionBtn(item)}
                </div>
              </Grid>
            );
          })}
      </Grid>
      <AlertDialog
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        open={confirmDialogOpen}
        dialogSuccessBtnText={dialogSuccessBtnText}
        dialogCloseBtnText={dialogCloseBtnText}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  getSearchData: state.UserData.getSearchData,
  userData: state.UserData.getUserData,
  createNewChatRoomData: state.ChatData.createNewChatRoomData,
  newConnectionRequestData: state.ConnectionsData.newConnectionRequestData,
  updateConnectionRequestData:
    state.ConnectionsData.updateConnectionRequestData,
});

const mapDispatchToProps = (dispatch) => ({
  sendNewConnectionRequest: (invitee_id, status) =>
    dispatch({
      type: NEW_CONNECTION_REQUEST_START,
      invitee_id,
      status,
    }),
  cancelConnectionRequest: (con_id) =>
    dispatch({
      type: CANCEL_CONNECTION_REQUEST_START,
      con_id,
    }),
  updateConnectionRequest: (con_id, inviter_id, status) =>
    dispatch({
      type: UPDATE_CONNECTION_REQUEST_START,
      con_id,
      inviter_id,
      status,
    }),
  getChatRoom: (room_id, data) =>
    dispatch({
      type: GET_CHAT_ROOM_START,
      room_id,
      data,
    }),
  createNewChatRoom: (chatData) =>
    dispatch({
      type: CREATE_NEW_CHAT_ROOM_START,
      chatData,
    }),
  updateChatMessageRootDiv: (data) =>
    dispatch({
      type: UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
      data,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchCommonComponent);
