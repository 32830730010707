import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import {
  DialogBody,
  DialogComponent,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";
import "../../scss/Dialogs.scss";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  CREATE_GROUP_START,
  UPDATE_GROUP_START,
  UPLOAD_GROUP_ICON_START,
} from "../../actions/GroupAction";
import toast from "../common/Snackbars";
import utils from "../../utils/commonUtils";
const SimpleButton = lazy(() => import("../Buttons/SimpleButton"));
const EditMediaIcon = lazy(() => import("../common/EditMediaIcon"));
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));
const EndIcon = lazy(() => import("../Textfields/EndIcon"));
const CheckboxComponent = lazy(() => import("../input/CheckboxComponent"));
const TooltipComponent = lazy(() => import("../common/Tooltip"));

let createGroupFlag = false;
let updateGroupFlag = false;
let uploadGroupIconFlag = false;

export const CreateOrEditGroupDialog = ({
  open,
  handleClose,
  isUpdate = false,
  data,
  updateGroupData,
  createGroupData,
  uploadGroupIconData,
  createGroup,
  updateGroup,
  uploadGroupIcon,
}) => {
  const [icon, setIcon] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [is_private, setIs_private] = useState(false);
  const [oldIcon, setOldIcon] = useState(null);

  const handleSubmit = (e) => {
    if (isUpdate) {
      let formData = new FormData();
      if (icon) {
        formData.append("group_icon", icon);
      }
      formData.append("name", name);
      formData.append("description", description);
      formData.append("is_private", is_private);
      updateGroup(utils.toBase64(data?.id), formData);
      updateGroupFlag = true;
    } else {
      createGroup({ name, description, is_private });
      createGroupFlag = true;
    }
  };

  useEffect(() => {
    if (createGroupData?.data?.status === "1" && createGroupFlag) {
      createGroupFlag = false;
      if (icon !== null) {
        const formData = new FormData();
        formData.append("group_icon", icon);
        uploadGroupIcon(createGroupData?.data?.data?.slug, formData);
        uploadGroupIconFlag = true;
      } else {
        toast.success(createGroupData?.data?.message);
        handleClose();
      }
    }
    if (uploadGroupIconData?.data?.status === "1" && uploadGroupIconFlag) {
      toast.success(createGroupData?.data?.message);
      uploadGroupIconFlag = false;
      handleClose();
    }
    if (updateGroupData?.data?.status === "1" && updateGroupFlag) {
      updateGroupFlag = true;
      handleClose();
    }
  }, [createGroupData, uploadGroupIconData, updateGroupData]);

  useEffect(() => {
    if (open && isUpdate) {
      setOldIcon(data?.icon);
      setName(data?.name);
      setDescription(data?.description);
      setIs_private(data?.is_private);
    } else {
      setIcon(null);
      setName("");
      setDescription("");
      setIs_private(false);
    }
  }, [open]);
  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>
        {isUpdate ? "Update A Group" : "Create A Group"}
      </DialogHeader>
      <DialogBody className="dialogHeader">
        <Box className="coegDialogRoot">
          <Box className="coegdBgImageDiv">
            <Box className="coeGroupIconDiv center">
              <Box className="coeGroupIcon center">
                {isUpdate && oldIcon !== null ? (
                  <img alt="Group Icon" src={oldIcon} />
                ) : icon !== null ? (
                  <img alt="Group Icon" src={URL.createObjectURL(icon)} />
                ) : (
                  <AddPhotoAlternateOutlinedIcon />
                )}

                <EditMediaIcon
                  onChange={(e) => {
                    if (
                      e.target.files !== undefined &&
                      e.target.files.length > 0
                    ) {
                      const file = e.target.files[0];
                      if (file.type.split("/")[0] === "image") {
                        setIcon(file);
                        if (isUpdate) {
                          setOldIcon(null);
                        }
                      }
                    }
                  }}
                  inputId="upload-group-icon"
                  accept="image/*"
                />
              </Box>
            </Box>
          </Box>
          <Box className="coeFormDiv">
            <Box className="manageMB20">
              <LabelTypography children="Group Name" required />
              <EndIcon
                value={name}
                onChange={(e) => {
                  if (e.target.value.length < 101) {
                    setName(e.target.value);
                  }
                }}
                Icon={`${name?.length < 101 ? name?.length : "100"}/100`}
                newDesign={true}
                className="EndIconTitle"
              />
            </Box>
            <Box className="manageMB20">
              <LabelTypography children="Description" required />
              <EndIcon
                value={description}
                onChange={(e) => {
                  if (e.target.value.length < 1501) {
                    setDescription(e.target.value);
                  } else {
                    setDescription((e.target.value).substring(0, 1500));
                  }
                }}
                Icon={`${description?.length < 1501 ? description?.length : "1500"
                  }/1500`}
                newDesign={true}
                multiline
                minRows={4}
                maxRows={5}
              />
            </Box>
            <Grid container justifyContent="flex-start" alignItems="center">
              <CheckboxComponent
                label={"Private"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIs_private(true);
                  } else {
                    setIs_private(false);
                  }
                }}
                checked={is_private}
              />
              <TooltipComponent
                children={<InfoOutlinedIcon fontSize="small" />}
                title={
                  "This group content is only available for my group members."
                }
                arrow={true}
                placement="right"
              />
            </Grid>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter className="dialogFooter">
        <SimpleButton
          primaryColorBtn
          onClick={handleSubmit}
          loading={
            isUpdate
              ? updateGroupData?.loading
              : createGroupData?.loading || uploadGroupIconData?.loading
          }
          disabled={
            name.trim() === "" ||
            description.trim() === "" ||
            createGroupData?.loading ||
            uploadGroupIconData?.loading ||
            updateGroupData?.loading
          }
        >
          {isUpdate ? "Update" : "Create"}
        </SimpleButton>
      </DialogFooter>
    </DialogComponent>
  );
};

const mapStateToProps = (state) => ({
  createGroupData: state.GroupData.createGroupData,
  uploadGroupIconData: state.GroupData.uploadGroupIconData,
  updateGroupData: state.GroupData.updateGroupData,
});

const mapDispatchToProps = (dispatch) => ({
  createGroup: (groupData) =>
    dispatch({
      type: CREATE_GROUP_START,
      groupData,
    }),
  uploadGroupIcon: (slug, groupIconData) =>
    dispatch({
      type: UPLOAD_GROUP_ICON_START,
      slug,
      groupIconData,
    }),
  updateGroup: (id, groupData) =>
    dispatch({
      type: UPDATE_GROUP_START,
      id,
      groupData,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateOrEditGroupDialog);
