import {
  GET_ALL_GROUPS_ERROR,
  GET_ALL_GROUPS_START,
  GET_ALL_GROUPS_SUCCESS,
  GET_GROUP_MEMBERS_ERROR,
  GET_GROUP_MEMBERS_START,
  GET_GROUP_MEMBERS_SUCCESS,
  CREATE_GROUP_ERROR,
  CREATE_GROUP_START,
  CREATE_GROUP_SUCCESS,
  UPLOAD_GROUP_ICON_ERROR,
  UPLOAD_GROUP_ICON_START,
  UPLOAD_GROUP_ICON_SUCCESS,
  GET_GROUP_SUGGESTIONS_ERROR,
  GET_GROUP_SUGGESTIONS_START,
  GET_GROUP_SUGGESTIONS_SUCCESS,
  JOIN_NEW_GROUP_ERROR,
  JOIN_NEW_GROUP_START,
  JOIN_NEW_GROUP_SUCCESS,
  UPDATE_GROUP_ERROR,
  UPDATE_GROUP_START,
  UPDATE_GROUP_SUCCESS,
  GET_GROUP_JOINING_REQUESTS_ERROR,
  GET_GROUP_JOINING_REQUESTS_START,
  GET_GROUP_JOINING_REQUESTS_SUCCESS,
  CANCEL_GROUP_JOIN_REQUEST_ERROR,
  CANCEL_GROUP_JOIN_REQUEST_START,
  CANCEL_GROUP_JOIN_REQUEST_SUCCESS,
  UPDATE_GROUP_JOIN_REQUEST_ERROR,
  UPDATE_GROUP_JOIN_REQUEST_START,
  UPDATE_GROUP_JOIN_REQUEST_SUCCESS,
  SOCKET_IO_ADD_NEW_GROUP_MEMBER,
  SOCKET_IO_NEW_GROUP_JOINING_REQUEST,
  SOCKET_IO_JOIN_NEW_GROUP,
  SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST,
  SOCKET_IO_UPDATE_GROUP,
  GET_GROUP_DETAILS_ERROR,
  GET_GROUP_DETAILS_START,
  GET_GROUP_DETAILS_SUCCESS,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_START,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_MEMBERS_ERROR,
  DELETE_GROUP_MEMBERS_START,
  DELETE_GROUP_MEMBERS_SUCCESS,
  LEAVE_GROUP_ERROR,
  LEAVE_GROUP_START,
  LEAVE_GROUP_SUCCESS,
  SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS,
} from "../actions/GroupAction.js";

const initialState = {
  getAllGroupsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getGroupMembersData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  createGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  uploadGroupIconData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getGroupSuggestionsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  joinNewGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  updateGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  groupJoiningRequestsData: {
    loading: false,
    data: {
      data: [],
      status: "",
      message: "",
      offset: 0,
      offsetStatus: false,
    },
    errorMsg: "",
  },
  cancelGroupJoinRequestData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  updateGroupJoinRequestData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getGroupDetailsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  leaveGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  deleteGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  deleteGroupMembersData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
};

const GroupReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_GROUPS_START: {
      return {
        ...previousState,
        getAllGroupsData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_ALL_GROUPS_SUCCESS: {
      return {
        ...previousState,
        getAllGroupsData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_ALL_GROUPS_ERROR: {
      return {
        ...previousState,
        getAllGroupsData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_GROUP_MEMBERS_START: {
      return {
        ...previousState,
        getGroupMembersData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_MEMBERS_SUCCESS: {
      return {
        ...previousState,
        getGroupMembersData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_MEMBERS_ERROR: {
      return {
        ...previousState,
        getGroupMembersData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case CREATE_GROUP_START: {
      return {
        ...previousState,
        createGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CREATE_GROUP_SUCCESS: {
      const oldGroups = { ...previousState.getAllGroupsData.data };
      let oldGroupArr = oldGroups?.data;
      if (oldGroupArr !== undefined) {
        oldGroupArr = [action?.data?.data, ...oldGroupArr];
      }
      return {
        ...previousState,
        createGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: {
            ...previousState.getAllGroupsData.data,
            data: oldGroupArr,
          },
        },
      };
    }
    case CREATE_GROUP_ERROR: {
      return {
        ...previousState,
        createGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case UPLOAD_GROUP_ICON_START: {
      return {
        ...previousState,
        uploadGroupIconData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPLOAD_GROUP_ICON_SUCCESS: {
      const oldGroups = { ...previousState.getAllGroupsData.data };
      let oldGroupArr = oldGroups?.data;
      let updatedGroupArr;
      if (oldGroupArr !== undefined) {
        updatedGroupArr = oldGroupArr.map((item) => {
          if (item?.id !== action.data.data.group_id) {
            return item;
          } else {
            return {
              ...item,
              icon: action.data.data.icon,
            };
          }
        });
      }
      return {
        ...previousState,
        uploadGroupIconData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: {
            ...previousState.getAllGroupsData.data,
            data: updatedGroupArr,
          },
        },
      };
    }
    case UPLOAD_GROUP_ICON_ERROR: {
      return {
        ...previousState,
        uploadGroupIconData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_GROUP_SUGGESTIONS_START: {
      return {
        ...previousState,
        getGroupSuggestionsData: {
          loading: true,
          data: {
            ...previousState.getGroupSuggestionsData.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_SUGGESTIONS_SUCCESS: {
      const oldData = { ...previousState.getGroupSuggestionsData.data };
      const offset = action.data.offset;
      return {
        ...previousState,
        getGroupSuggestionsData: {
          loading: false,
          data: {
            ...previousState.getGroupSuggestionsData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
          },
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_SUGGESTIONS_ERROR: {
      return {
        ...previousState,
        getGroupSuggestionsData: {
          loading: false,
          data: { ...previousState.getGroupSuggestionsData.data },
          errorMsg: action.data,
        },
      };
    }
    case JOIN_NEW_GROUP_START: {
      return {
        ...previousState,
        joinNewGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case JOIN_NEW_GROUP_SUCCESS: {
      const { data, groupUserData } = action?.data;
      const oldGroupSuggestion = {
        ...previousState.getGroupSuggestionsData.data,
      };
      oldGroupSuggestion["data"] = oldGroupSuggestion?.data?.filter((item) => {
        return item?.id !== data?.group_id;
      });
      const oldGroups = { ...previousState.getAllGroupsData.data };
      const selectedGroup = oldGroups?.data?.filter(
        (group) => group?.id === data?.group_id
      );
      if (selectedGroup?.length > 0) {
        oldGroups["data"] = oldGroups?.data?.map((group) => {
          return group?.id === data?.group_id && data?.group
            ? data?.group
            : group;
        });
      } else {
        if (oldGroups?.data && data?.group) {
          oldGroups["data"] = [data?.group, ...oldGroups?.data];
        }
      }
      const groupDetail = { ...previousState.getGroupDetailsData.data };
      if (groupDetail?.data?.id === groupUserData?.group_id) {
        groupDetail["verifyMyJoinRequest"] = groupUserData;
      }
      return {
        ...previousState,
        joinNewGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
        getGroupSuggestionsData: {
          ...previousState.getGroupSuggestionsData,
          data: oldGroupSuggestion,
        },
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: oldGroups,
        },
        getGroupDetailsData: {
          ...previousState.getGroupDetailsData,
          data: groupDetail,
        },
      };
    }
    case JOIN_NEW_GROUP_ERROR: {
      return {
        ...previousState,
        joinNewGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_GROUP_START: {
      return {
        ...previousState,
        updateGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_GROUP_SUCCESS: {
      return {
        ...previousState,
        updateGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_GROUP_ERROR: {
      return {
        ...previousState,
        updateGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_GROUP_JOINING_REQUESTS_START: {
      return {
        ...previousState,
        groupJoiningRequestsData: {
          ...previousState.groupJoiningRequestsData,
          loading: true,
        },
      };
    }
    case GET_GROUP_JOINING_REQUESTS_SUCCESS: {
      const { data, offset, offsetStatus, status, message } = action?.data;
      return {
        ...previousState,
        groupJoiningRequestsData: {
          loading: false,
          data: {
            data:
              offset === 0
                ? data
                : [
                    ...previousState.groupJoiningRequestsData?.data?.data,
                    ...data,
                  ],
            offset,
            offsetStatus,
            status,
            message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_JOINING_REQUESTS_ERROR: {
      return {
        ...previousState,
        groupJoiningRequestsData: {
          ...previousState.groupJoiningRequestsData,
          loading: false,
          errorMsg: action.data,
        },
      };
    }
    case CANCEL_GROUP_JOIN_REQUEST_START: {
      return {
        ...previousState,
        cancelGroupJoinRequestData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CANCEL_GROUP_JOIN_REQUEST_SUCCESS: {
      return {
        ...previousState,
        cancelGroupJoinRequestData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case CANCEL_GROUP_JOIN_REQUEST_ERROR: {
      return {
        ...previousState,
        cancelGroupJoinRequestData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_GROUP_JOIN_REQUEST_START: {
      return {
        ...previousState,
        updateGroupJoinRequestData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_GROUP_JOIN_REQUEST_SUCCESS: {
      return {
        ...previousState,
        updateGroupJoinRequestData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_GROUP_JOIN_REQUEST_ERROR: {
      return {
        ...previousState,
        updateGroupJoinRequestData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_GROUP_DETAILS_START: {
      return {
        ...previousState,
        getGroupDetailsData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_DETAILS_SUCCESS: {
      return {
        ...previousState,
        getGroupDetailsData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_DETAILS_ERROR: {
      return {
        ...previousState,
        getGroupDetailsData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case LEAVE_GROUP_START: {
      return {
        ...previousState,
        leaveGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case LEAVE_GROUP_SUCCESS: {
      return {
        ...previousState,
        leaveGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case LEAVE_GROUP_ERROR: {
      return {
        ...previousState,
        leaveGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case DELETE_GROUP_START: {
      return {
        ...previousState,
        deleteGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_SUCCESS: {
      return {
        ...previousState,
        deleteGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_ERROR: {
      return {
        ...previousState,
        deleteGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case DELETE_GROUP_MEMBERS_START: {
      return {
        ...previousState,
        deleteGroupMembersData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_MEMBERS_SUCCESS: {
      return {
        ...previousState,
        deleteGroupMembersData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_MEMBERS_ERROR: {
      return {
        ...previousState,
        deleteGroupMembersData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case SOCKET_IO_NEW_GROUP_JOINING_REQUEST: {
      const oldGroupJoiningRequest = {
        ...previousState.groupJoiningRequestsData.data,
      };
      let data = oldGroupJoiningRequest?.data;
      if (data !== undefined) {
        data = [action.data, ...data];
      }
      const oldGroups = { ...previousState.getAllGroupsData?.data };
      oldGroups["data"] = oldGroups?.data?.map((group) => {
        return group?.id === action?.data?.group_id
          ? {
              ...group,
              group_users: [
                ...group?.group_users,
                {
                  user_id: action?.data?.user_id,
                  status: action?.data?.status,
                },
              ],
            }
          : group;
      });
      return {
        ...previousState,
        groupJoiningRequestsData: {
          ...previousState.groupJoiningRequestsData,
          data: {
            ...previousState.groupJoiningRequestsData.data,
            data,
          },
        },
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: oldGroups,
        },
      };
    }
    case SOCKET_IO_ADD_NEW_GROUP_MEMBER: {
      const oldGroups = { ...previousState.getAllGroupsData.data };
      let groups = oldGroups?.data;
      if (groups !== undefined) {
        groups = groups.map((item) => {
          if (item?.id !== action.data.group_id) {
            return item;
          } else {
            return { ...item, members: item?.members + 1 };
          }
        });
      }
      return {
        ...previousState,
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: {
            ...previousState.getAllGroupsData.data,
            data: groups,
          },
        },
      };
    }
    case SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST: {
      const oldGroupJoiningRequests = {
        ...previousState.groupJoiningRequestsData.data,
      };
      let requestArr = oldGroupJoiningRequests?.data;
      if (requestArr !== undefined) {
        requestArr = requestArr.filter((item) => {
          return !(
            item?.group_id === action.data.group_id &&
            item?.user_id === action.data.user_id
          );
        });
      }
      const oldGroups = { ...previousState.getAllGroupsData.data };
      if (action?.data?.status !== "accepted") {
        oldGroups["data"] = oldGroups?.data?.map((group) => {
          return group?.id === action?.data?.group_id
            ? {
                ...group,
                group_users: group?.group_users?.filter(
                  (user) => user?.user_id !== action?.data?.user_id
                ),
              }
            : group;
        });
      }
      const groupDetail = { ...previousState.getGroupDetailsData.data };
      if (groupDetail?.data?.id === action?.data?.group_id) {
        if (action?.data?.status) {
          groupDetail["verifyMyJoinRequest"] = {
            ...groupDetail?.verifyMyJoinRequest,
            status: action?.data?.status,
          };
        } else {
          groupDetail["verifyMyJoinRequest"] = null;
        }
      }
      return {
        ...previousState,
        groupJoiningRequestsData: {
          ...previousState.groupJoiningRequestsData,
          data: {
            ...previousState.groupJoiningRequestsData.data,
            data: requestArr,
          },
        },
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: oldGroups,
        },
        getGroupDetailsData: {
          ...previousState.getGroupDetailsData,
          data: groupDetail,
        },
      };
    }
    case SOCKET_IO_JOIN_NEW_GROUP: {
      const oldGroups = { ...previousState.getAllGroupsData.data };
      const selectedGroup = oldGroups?.data?.filter(
        (group) => group?.id === action?.data?.id
      );
      if (selectedGroup?.length > 0) {
        oldGroups["data"] = oldGroups?.data?.map((group) => {
          return group?.id === action?.data?.id ? action?.data : group;
        });
      } else {
        if (oldGroups?.data?.length > 0) {
          oldGroups["data"] = [action?.data, ...oldGroups?.data];
        } else {
          oldGroups["data"] = [action?.data];
        }
      }
      return {
        ...previousState,
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: oldGroups,
        },
      };
    }
    case SOCKET_IO_UPDATE_GROUP: {
      const oldGroups = { ...previousState.getAllGroupsData.data };
      const suggestionsGroups = {
        ...previousState.getGroupSuggestionsData.data,
      };
      const oldGroupRequests = {
        ...previousState.groupJoiningRequestsData.data,
      };
      let oldRequests = oldGroupRequests?.data;
      let updatedSuggestionsGroup = suggestionsGroups?.data;
      let groups = oldGroups?.data;
      if (groups !== undefined) {
        let index = null;
        for (let i = 0; i < groups.length; i++) {
          const item = groups[i];
          if (action.data.id === item.id) {
            index = i;
            break;
          }
        }
        if (index !== null) {
          groups.splice(index, 1, action.data);
        }
      }
      if (updatedSuggestionsGroup !== undefined) {
        let index = null;
        for (let i = 0; i < updatedSuggestionsGroup.length; i++) {
          const item = updatedSuggestionsGroup[i];
          if (item?.id === action.data.id) {
            index = i;
            break;
          }
        }
        if (index !== null) {
          updatedSuggestionsGroup.splice(index, 1, action.data);
        }
      }
      if (oldRequests !== undefined) {
        oldRequests = oldRequests.map((item) => {
          if (item?.group?.id !== action.data.id) {
            return item;
          } else {
            return {
              ...item,
              group: action.data,
            };
          }
        });
      }
      // Group detail
      const groupDetail = { ...previousState.getGroupDetailsData.data };
      if (groupDetail?.data?.id === action?.data?.id) {
        const { icon, name, description, is_private } = action?.data;
        groupDetail["data"]["icon"] = icon;
        groupDetail["data"]["name"] = name;
        groupDetail["data"]["description"] = description;
        groupDetail["data"]["is_private"] = is_private;
      }
      return {
        ...previousState,
        getAllGroupsData: {
          ...previousState.getAllGroupsData,
          data: {
            ...previousState.getAllGroupsData.data,
            data: groups,
          },
        },
        getGroupSuggestionsData: {
          ...previousState.getGroupSuggestionsData,
          data: {
            ...previousState.getGroupSuggestionsData.data,
            data: updatedSuggestionsGroup,
          },
        },
        groupJoiningRequestsData: {
          ...previousState.groupJoiningRequestsData,
          data: {
            ...previousState.groupJoiningRequestsData.data,
            data: oldRequests,
          },
        },
        getGroupDetailsData: {
          ...previousState.getGroupDetailsData,
          data: groupDetail,
        },
      };
    }
    case SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS: {
      const { type, user_ids, group_id, userId } = action.data;
      let myGroups = { ...previousState?.getAllGroupsData?.data };
      let groupMembers = { ...previousState?.getGroupMembersData?.data };
      let suggestionGroups = {
        ...previousState?.getGroupSuggestionsData?.data,
      };
      let groupRequests = { ...previousState?.groupJoiningRequestsData?.data };
      let groupDetail = { ...previousState?.getGroupDetailsData?.data };
      if (type === "delete_group_members") {
        if (user_ids.includes(userId)) {
          myGroups["data"] = myGroups?.data?.filter((group, index) => {
            return group?.id !== group_id;
          });
        } else {
          myGroups["data"] = myGroups?.data?.map((group, index) => {
            return group?.id === group_id
              ? {
                  ...group,
                  members: group?.members - user_ids?.length,
                }
              : group;
          });
          suggestionGroups["data"] = suggestionGroups?.data?.map(
            (group, index) => {
              return group?.id === group_id
                ? {
                    ...group,
                    members: group?.members - user_ids?.length,
                  }
                : group;
            }
          );
        }
        if (groupMembers?.group?.id === group_id) {
          groupMembers["data"] = groupMembers?.data?.filter((user, index) => {
            return !user_ids.includes(user?.id);
          });
        }
        if (groupDetail?.data?.id === group_id) {
          groupDetail["data"]["group_users"] =
            groupDetail?.data?.group_users?.filter(
              (user) => !user_ids.includes(user?.user_id)
            );
          groupDetail["verifyMyJoinRequest"] = null;
        }
      }
      if (type === "delete_group") {
        myGroups["data"] = myGroups?.data?.filter((group, index) => {
          return group?.id !== group_id;
        });
        suggestionGroups["data"] = suggestionGroups?.data?.filter(
          (group, index) => {
            return group?.id !== group_id;
          }
        );
        groupRequests["data"] = groupRequests?.data?.filter(
          (request) => request?.group_id !== group_id
        );
      }
      return {
        ...previousState,
        getAllGroupsData: {
          ...previousState?.getAllGroupsData,
          data: myGroups,
        },
        getGroupMembersData: {
          ...previousState?.getGroupMembersData,
          data: groupMembers,
        },
        getGroupSuggestionsData: {
          ...previousState?.getGroupSuggestionsData,
          data: suggestionGroups,
        },
        groupJoiningRequestsData: {
          ...previousState?.groupJoiningRequestsData,
          data: groupRequests,
        },
        getGroupDetailsData: {
          ...previousState?.getGroupDetailsData,
          data: groupDetail,
        },
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default GroupReducers;
