import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  maxWidthRoot: {
    width: "100%",
    boxSizing: "border-box",
    "&>div": {
      maxWidth: 1200,
      margin: "0px auto",
    },
    [theme.breakpoints.down(1200)]: {
      padding: "0% 2%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 15px",
    },
  },
}));

export default function MaxWidthComponent({ children, className }) {
  const classes = useStyle();
  return (
    <Box className={classNames(classes.maxWidthRoot, className)}>
      <Box>{children}</Box>
    </Box>
  );
}
