import {
  Divider,
  Container,
  Grid,
  CssBaseline,
  FormHelperText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { SIGNUP_START } from "../actions/AuthAction";
import { connect } from "react-redux";
import { ReactComponent as BlackLogo } from "../assets/blackLogo.svg";
import app from "../config/firebase";
import { getAuth, deleteUser, signInWithPopup } from "firebase/auth";
import CF from "../utils/CommonFunction";
import utils from "../utils/commonUtils";
import "../scss/Auth.scss";
import toast from "./common/Snackbars";
const SimpleButton = React.lazy(() =>
  import("../components/Buttons/SimpleButton")
);
const PlainTextfield = React.lazy(() => import("./Textfields/PlainTextfield"));
const H2 = React.lazy(() => import("./Headings/H2"));
const Body1 = React.lazy(() => import("./Typography/Body1"));
const SocialIcons = React.lazy(() => import("./common/SocialIcon"));
const auth = getAuth();

let signupFlag = false;
function SignUp(props) {
  const history = useHistory();
  const [socialType, setSocialType] = useState("normal");
  const { dispatchRegistration, signupData } = props;
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [signUpValidation, setSignUpValidation] = useState("");

  const handleSubmitClick = (e) => {
    e.preventDefault();
    dispatchRegistration({
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      confirm_password,
      social_type: "normal",
    });
    signupFlag = true;
  };

  const [randomImage, setRanddomImage] = useState({
    imageSrc: "",
    imageText: "",
  });

  const handleSocialSignin = (type, provider) => {
    setSocialType(type);
    signInWithPopup(auth, provider)
      .then((result) => {
        let myNames = result?.user?.displayName.split(" ");
        let signupData = {
          first_name: myNames[0],
          last_name: myNames[1],
          email: result?.user?.email,
          platform_id: result?.user?.providerData[0]?.uid,
          social_type: type,
        };
        localStorage.setItem("email", result?.user?.email);
        dispatchRegistration(signupData);
        signupFlag = true;
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    const { imageSrc, imageText } =
      utils.authDynamicImage[CF.randomNumberWithRange(0, 2)];
    setRanddomImage({
      imageSrc: imageSrc,
      imageText: imageText,
    });
  }, []);

  useEffect(() => {
    if (signupData?.userInfo?.status === "1" && signupFlag) {
      if (socialType !== "normal") {
        const user = auth.currentUser;
        if (user?.email === localStorage.getItem("email")) {
          deleteUser(user)
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });
        }
        localStorage.removeItem("email");
      }
      window.location.reload();
      setFirstname("");
      setLastname("");
      setEmail("");
      setPassword("");
      setConfirm_password("");
      signupFlag = false;
    } else {
      setSignUpValidation(signupData.errorMsg);
    }
  }, [signupData]);

  function createMarkup(text) {
    return { __html: text };
  }

  const formComponent = () => {
    return (
      <form onSubmit={handleSubmitClick}>
        <PlainTextfield
          placeholder="First Name"
          type="text"
          id="firstname"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          error
          helperText={
            signUpValidation == "Firstname should be atleast 3 characters" &&
            signUpValidation
          }
        />
        <PlainTextfield
          placeholder="Last Name"
          type="text"
          id="lastname"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          error
          helperText={
            signUpValidation == "Lastname should be atleast 3 characters" &&
            signUpValidation
          }
        />
        <PlainTextfield
          placeholder="Email Address"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error
          helperText={
            signUpValidation == "Email must be a valid email"
              ? "Email must be a valid email"
              : signUpValidation == "Failed! Email is already in use!"
                ? "Sorry,but an account with provided email address is already exists. Please try a different email address or use the password recovery option if you don't remember your password"
                : ""
          }
        />
        <PlainTextfield
          placeholder="Password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error
          helperText={
            signUpValidation == "Password should be atleast 6 character" &&
            signUpValidation
          }
        />
        <PlainTextfield
          placeholder="Confirm Password"
          onChange={(e) => setConfirm_password(e.target.value)}
          type="password"
          id="confirm_password"
          value={confirm_password}
          error
          helperText={
            signUpValidation == "Confirm password should be same as password" &&
            signUpValidation
          }
        />
        <SimpleButton
          children="Submit"
          type="submit"
          primaryColorBtn
          disabled={signupData?.loading ? true : false}
          loading={signupData?.loading}
          authBtn
          className="SubmitBtns"
        />
      </form>
    );
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className="Container">
        <CssBaseline />
        <div className="MainContainer">
          <div className="DivContainer">
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              className="MainGridContainer"
            >
              <Grid item xs={12} sm={12} md={5}>
                <div className="AuthLeftSideDiv">
                  <div className="WkleapLogo">
                    <BlackLogo />
                  </div>
                  <div className="AuthMainDiv">
                    <div className="AuthFormRoot">
                      <div>
                        <H2 children="Sign Up" className="HTag" medium />
                      </div>
                      {formComponent()}
                      <div className="DividerContainer">
                        <Divider className="DividerLine" />
                        <Body1 children="Or" light />
                        <Divider className="DividerLine" />
                      </div>
                      <SocialIcons handleSocialSignin={handleSocialSignin} />
                      <SimpleButton
                        children="Log In"
                        psTransparentBorderBtn
                        className="AuthBtns"
                        onClick={() => history.push("/signin")}
                        authBtn
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="RightSideImageContainer">
                  <div>
                    <img
                      src={randomImage.imageSrc}
                      alt=""
                      className="AuthImage"
                    />
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        randomImage.imageText
                      )}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  signupData: state.AuthData.signupData,
  userData: state.UserData.getUserData,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRegistration: (signupData) =>
    dispatch({
      type: SIGNUP_START,
      signupData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
