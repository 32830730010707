import {
  Container,
  Grid,
  CssBaseline,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FORGOT_PASSWORD_START } from "../actions/AuthAction";
import { connect } from "react-redux";
import { ReactComponent as BlackLogo } from "../assets/blackLogo.svg";
import CF from "../utils/CommonFunction";
import utils from "../utils/commonUtils";

const SimpleButton = React.lazy(() =>
  import("../components/Buttons/SimpleButton")
);
const PlainTextfield = React.lazy(() => import("./Textfields/PlainTextfield"));
const H2 = React.lazy(() => import("./Headings/H2"));

function ForgotPassword(props) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const { forgotPasswordData, dispatchForgotPassword } = props;
  //State for random image
  const [randomImage, setRanddomImage] = useState({
    imageSrc: "",
    imageText: "",
  });
  const [forgotPasswordValidation, setForgotPasswordValidation] = useState("");

  //  Forgot password onclick event
  const handleSubmit = (e) => {
    e.preventDefault();
    email !== "" && dispatchForgotPassword(email);
  };

  //UseEffect for setstate value in random image
  useEffect(() => {
    const { imageSrc, imageText } =
      utils.authDynamicImage[CF.randomNumberWithRange(0, 2)];
    setRanddomImage({
      imageSrc: imageSrc,
      imageText: imageText,
    });
  }, []);

  //Forgot password useEffect
  useEffect(() => {
    if (forgotPasswordData?.userInfo?.status === "1") {
      setEmail("");
      history.push("/signin");
    } else {
      setForgotPasswordValidation(forgotPasswordData.errorMsg);
    }
  }, [forgotPasswordData]);

  function createMarkup(text) {
    return { __html: text };
  }

  const formComponent = () => {
    return (
      <form onSubmit={handleSubmit} >
        <PlainTextfield
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          autofocus="true"
          value={email}
          type="email"
          error
          helperText={
            forgotPasswordValidation
          }
        />
        <SimpleButton
          children="Submit"
          disabled={forgotPasswordData?.loading}
          loading={forgotPasswordData?.loading}
          type="submit"
          primaryColorBtn
          authBtn
          className="SubmitBtns"
        />
      </form>
    );
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className="Container">
        <CssBaseline />
        <div className="MainContainer">
          <div className="DivContainer">
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              className="MainGridContainer"
            >
              <Grid item xs={12} sm={12} md={5}>
                <div className="AuthLeftSideDiv">
                  <div className="WkleapLogo">
                    <BlackLogo />
                  </div>
                  <div className="AuthMainDiv">
                    <div className="AuthFormRoot">
                      <div>
                        <H2
                          children="Forgot password"
                          className="HTag"
                          medium
                        />
                      </div>
                      {formComponent()}
                      <SimpleButton
                        children="Cancel"
                        psTransparentBorderBtn
                        className="AuthBtns"
                        onClick={() => history.push("/signin")}
                        authBtn
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="RightSideImageContainer">
                  <div>
                    <img
                      src={randomImage.imageSrc}
                      alt=""
                      className="AuthImage"
                    />
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        randomImage.imageText
                      )}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}

//Get redux information
const mapStateToProps = (state) => ({
  forgotPasswordData: state.AuthData.forgotPasswordData,
});

//Call action from here
const mapDispatchToProps = (dispatch) => ({
  dispatchForgotPassword: (email) =>
    dispatch({
      type: FORGOT_PASSWORD_START,
      email,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
