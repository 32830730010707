import React, { useState, useEffect } from "react";
import { makeStyles, CssBaseline } from "@material-ui/core";
import { connect } from "react-redux";
import Circle from "../../assets/img/circle.png";
import EdgeCircle from "../../assets/img/edge_circle.png";
import { ReactComponent as ProgressConfirmIcon } from "../../assets/ProgressConfirm.svg";
import { GET_USER_DATA_START } from "../../actions/UserAction";
import { getAuth } from "firebase/auth";
import Dots from "../../assets/img/Dots.png";
import ProgressConfirm from "../AnimatedIcons/ProgressConfirm";
import { RESEND_VERIFICATION_EMAIL_START } from "../../actions/AuthAction";
import utils from "../../utils/commonUtils";
import { ReactComponent as WhiteLogo } from "../../assets/whiteLogo.svg";

const ParasTypography = React.lazy(() =>
  import("../Typography/ParasTypography")
);
const H2 = React.lazy(() => import("../Headings/H2"));
const SimpleButton = React.lazy(() => import("../Buttons/SimpleButton"));

const auth = getAuth();

const useClasses = makeStyles((theme) => ({
  nextBtn: {
    borderRadius: "25px",
  },
  resendVerificationEmailBtn: {
    padding: "5px 15px",
    marginTop: 15,
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "9px",
      lineHeight: "21px",
    },
  },
  hTwoHeading: {
    color: "#fff !important",
    fontSize: "24px",
    fontStyle: "normal",
    lineHeight: "36px",
    letterSpacing: "0em",
    textAlign: "center",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "36px",
    },
    [theme.breakpoints.down("640")]: {
      fontSize: "17px",
      lineHeight: "36px",
    },
  },
  emailText: {
    color: theme.palette.common.white,
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px",
    },
  },
  titleText: {
    color: theme.palette.common.white,
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
    paddingBottom: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: "11px",
      lineHeight: "21px",
    },
  },
  subTitlePending: {
    color: theme.palette.common.white,
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  progressIcon: {
    width: "80px",
    height: "80px",
  },
  whiteWKLeapLogo: {
    marginBottom: 20,
  },
}));

function SignUpVerification(props) {
  const classes = useClasses();
  const { userData, resendVerificationEmail, resendVerificationEmailData } =
    props;
  const [is_verified, setIs_verified] = useState("");
  const [email, setEmail] = useState("");
  const { token } = utils.getLocalStorageItems();

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      if (userData?.userInfo?.data?.is_verified) {
        setIs_verified(true);
      } else {
        setIs_verified(false);
      }
      setEmail(userData?.userInfo?.data?.email);
    }
  }, [userData]);

  return (
    <>
      <CssBaseline />
      <div className="verification_page">
        <div class="email_verification pending">
          <div class="verification_content">
            <div class="shapes">
              <img src={EdgeCircle} class="edge_circle" alt="edgecircle" />
              <img src={Dots} class="dots" alt="dots" />
              <img src={Circle} class="circle" alt="circle" />
            </div>
            <div class="content">
              <WhiteLogo className={classes.whiteWKLeapLogo} />
              {is_verified ? (
                <div>
                  <ProgressConfirm />
                  <H2
                    children="Verification Completed"
                    className={classes.hTwoHeading}
                    medium
                  />
                  <ParasTypography
                    children={`A verification email has been sent to : ${userData?.userInfo?.data?.email}`}
                    className={classes.titleText}
                    light
                  />
                  <ParasTypography
                    children={localStorage.getItem("email")}
                    className={classes.emailText}
                    medium
                  />
                  <ParasTypography
                    children="Yay! You’re verified. Complete your profile to continue to the
                  next step"
                    className={classes.subTitle}
                    medium
                  />
                </div>
              ) : (
                <div>
                  <ProgressConfirmIcon className={classes.progressIcon} />
                  <H2
                    children="Verification Pending"
                    className={classes.hTwoHeading}
                    medium
                  />
                  <ParasTypography
                    children={`A verification email has been sent to :`}
                    className={classes.titleText}
                    light
                  />
                  <ParasTypography
                    children={userData?.userInfo?.data?.email}
                    className={classes.emailText}
                    medium
                  />
                  <ParasTypography
                    children="Once verified, return here to Continue with the next step"
                    className={classes.subTitlePending}
                    light
                  />
                  <SimpleButton
                    children="Resend Verification Email"
                    secondaryColorBtn
                    className={classes.resendVerificationEmailBtn}
                    disabled={resendVerificationEmailData?.loading}
                    loading={resendVerificationEmailData?.loading}
                    onClick={() => {
                      resendVerificationEmail(token);
                    }}
                    medium
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  resendVerificationEmailData: state.AuthData.resendVerificationEmailData,
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () =>
    dispatch({
      type: GET_USER_DATA_START,
    }),
  resendVerificationEmail: (token) =>
    dispatch({
      type: RESEND_VERIFICATION_EMAIL_START,
      token,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpVerification);
