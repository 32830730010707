import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import utils from "../../utils/commonUtils";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { Body1 } from "../Typography/Typographys";
import CarouselComponent from "../common/CarouselComponent";
import H4 from "../Headings/H4";
import ImageVideoLightbox from "../common/ImageVideoLightbox";
import HtmlMessage from "../common/HtmlMessage";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  FeedMediaContainer: {
    gap: "4px 4px",
    width: "100%",
    display: "grid",
    padding: "8px",
    maxWidth: "100%",
    boxSizing: "border-box",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
    gridTemplateRows: "172px 132px",
    "& > div > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "8px",
    },
    "& > div > video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      borderRadius: "8px",
    },
    "& > div" : {
      cursor : "pointer"
    },
  },
  PostMoreAttachments: {
    display: "flex",
    gridArea: "2 / 5 / 3 / 7",
    background: "rgba(0,0,0,0.5)",
    borderRadius: 8,
    cursor: "pointer",
    "&>p": {
      color: "#FFFFFF",
      fontSize: 40,
      margin: "auto",
    },
  },
}));

export default function FeedContent(props) {
  const { feedData } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClose = () => setOpen(false);

  let feedMessage = null;
  if (feedData?.feed_type_id === 6) {
    try {
      feedMessage = JSON.parse(feedData?.message);
    } catch (error) {}
  } else {
    feedMessage = feedData?.message;
  }
  let feedFiles = [];
  if (feedData?.feed_file !== null) {
    try {
      feedFiles = JSON.parse(feedData?.feed_file);
    } catch (err) {}
  }

  const getGridArea = (totalImg, index) => {
    if (totalImg == 1) {
      return "1 / 1 / 3 / 7"
    }
    if (totalImg == 2) {
      switch (index) {
        case 0:
          return "1 / 1 / 3 / 4";
        case 1:
          return "1 / 4 / 3 / 7";
        default:
          return null;
      }
    }
    if (totalImg == 3) {
      switch (index) {
        case 0:
          return "1 / 1 / 2 / 7";
        case 1:
          return "2 / 1 / 3 / 4";
        case 2:
          return "2 / 4 / 3 / 7";
        default:
          return null;
      }
    }
    if (totalImg == 4) {
      switch (index) {
        case 0:
          return "1 / 1 / 2 / 7";
        case 1:
          return "2 / 1 / 3 / 3";
        case 2:
          return "2 / 3 / 3 / 5";
        case 3:
          return "2 / 5 / 3 / 7";
        default:
          return null;
      }
    }
    if (totalImg >= 5) {
      switch (index) {
        case 0:
          return "1 / 1 / 2 / 4";
        case 1:
          return "1 / 4 / 2 / 7";
        case 2:
          return "2 / 1 / 3 / 3";
        case 3:
          return "2 / 3 / 3 / 5";
        case 4:
          return "2 / 5 / 3 / 7";
        default:
          return null;
      }
    }
  };

  return (
    <Grid className="feedContentRoot">
      {feedData?.feed_type_id !== 6 && feedMessage !== null ? (
        feedMessage?.length > 250 ? (
          //Plain Text field
          <HtmlMessage text={feedMessage} isHtml showLinkPreview />
        ) : (
          //Link preview feed
          <HtmlMessage text={feedMessage} isHtml showLinkPreview />
        )
      ) : null}
      {/* 
      {feedFiles?.length !== 0 && feedData?.feed_type_id !== 6 && (
        <CarouselComponent
          children={feedFiles.map((item, key) => {
            if (
              utils.imageExtensions.includes(
                item?.split(".").pop().toLowerCase()
              )
            ) {
              return (
                <img
                  key={key}
                  src={item}
                  alt="Feed posts"
                  onClick={() => {
                    setActiveIndex(key);
                    setOpen(true);
                  }}
                />
              );
            } else {
              return (
                <video
                  key={key}
                  src={item}
                  autoPlay={true}
                  muted={true}
                  controls={true}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveIndex(key);
                    setOpen(true);
                  }}
                ></video>
              );
            }
          })}
          indicators={feedFiles.length > 1 ? true : false}
          cycleNavigation={feedFiles.length > 1 ? true : false}
          autoPlay={false}
          className={feedMessage !== null ? "manageMT10" : ""}
          swipe={true}
          animation={"slide"}
        />
      )} */}

     {feedFiles.length !==0 && feedData?.feed_type_id !== 6 && 
     <div className={classes.FeedMediaContainer}>
        {feedFiles.slice(0,5).map((item, key) => {
          if (
            utils.imageExtensions.includes(item?.split(".").pop().toLowerCase())
          ) {
            return (
              <div
                key={key}
                style={{
                  gridArea: getGridArea(feedFiles?.length, key),
                }}
              >
                <img
                  key={key}
                  src={item}
                  alt="Feed posts"
                  onClick={() => {
                    setActiveIndex(key);
                    setOpen(true);
                  }}
                />
              </div>
            );
          } else {
            return (
              <div
                key={key}
                style={{
                  gridArea: getGridArea(feedFiles?.length, key),
                }}
              >
                <video
                  key={key}
                  src={item}
                  autoPlay={true}
                  muted={true}
                  controls={true}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveIndex(key);
                    setOpen(true);
                  }}
                ></video>
              </div>
            );
          }
        })}
       {feedFiles.length > 5 && <div
                className={classes.PostMoreAttachments}
                onClick={() => {
                  setActiveIndex(4);
                  setOpen(true);
                }}
              >
                <Body1 semibold>
                  {"+"}
                  {feedFiles.length - 5}
                </Body1>
              </div>}
      </div>}

      {feedData?.feed_type_id === 6 && (
        <Grid
          className="blogFeedContentRootDiv"
          onClick={() => history.push(`/blog-view/${feedMessage?.slug}`)}
        >
          {feedFiles.length > 0 && (
            <Grid className={classNames("center", "blogFeedContentImageDiv")}>
              {feedFiles.length > 0 ? (
                <img src={feedFiles[0]} alt="Blog Primary Pic" />
              ) : (
                <Body1 medium>Blog image not available</Body1>
              )}
            </Grid>
          )}

          <Grid className="blogFeedContentDiv">
            <H4 medium children={feedMessage?.title} />
            <div
              dangerouslySetInnerHTML={{
                __html: feedMessage?.content,
              }}
              className="multilineTextEllipsis"
            ></div>
            <Body1>View More</Body1>
          </Grid>
        </Grid>
      )}
      {open ? (
        <ImageVideoLightbox
          handleClose={handleClose}
          open={open}
          data={feedFiles}
          type="db"
          activeIndex={activeIndex}
        />
      ) : null}
    </Grid>
  );
}
