import React from "react";

export default function ProgressConfirm() {
  return (
    <div className="confirm">
      <svg className="confirm__progress">
        <circle className="confirm__value" cx="50%" cy="50%" r="54" />
      </svg>
      <div className="confirm__inner" />
    </div>
  );
}
