import { post, get } from "../Api";

export const PostSignin = async (signinData) => {
  return await post(`/auth/signin/${signinData.social_type}`, signinData);
};

export const PostSignup = async (signupData) => {
  return await post(`/auth/signup`, signupData);
};

export const ForgotPassword = async (email) => {
  return await post(`/auth/forgotpassword`, { email });
};

export const ResetPassword = async (token, password, confirm_password) => {
  return await post(`/auth/reset-password/${token}`, {
    password,
  });
};

export const ResetTokenVerify = async (token, password, confirm_password) => {
  return await post(`/auth/reset-password/${token}/check`);
};

export const Logout = async () => {
  return await get(`/auth/logout`);
};

export const VerifyEmail = async (token) => {
  return await get(`/auth/verify-email/${token}`);
};

export const ResendVerificationEmail = async (token) => {
  return await post(`/auth/resend-verification-email`, {
    token,
  });
};

export const ChangePassword = async (data) => {
  return await post(`/auth/change-password`, data);
};
