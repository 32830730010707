import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { connect } from "react-redux";
import { FeedSkeleton } from "../common/Skeletons";
import { ZeroStateFeedListing } from "../common/ZeroStateComponents";
import FeedHeader from "./FeedHeader";
import FeedFooter from "./FeedFooter";
import FeedContent from "./FeedContent";

let scrollDivFlag = false;

function Feeds({ feedData, setTarget, feedsType }) {
  let divRef = useRef(null);
  const [feeds, setFeeds] = useState([]);
  const [scrollFeedId, setScrollFeedID] = useState(0);
  const [scrollFeedIndex, setScrollFeedIndex] = useState("");

  useEffect(() => {
    if (divRef.current) {
      if (scrollDivFlag && feedsType === "liked") {
        divRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        scrollDivFlag = false;
        setScrollFeedIndex("");
        setScrollFeedID(0);
      }
    }
  }, [divRef, scrollFeedIndex]);

  useEffect(() => {
    if (feedData?.data?.status === "1") {
      if (scrollFeedId !== 0 && feedsType === "liked") {
        for (let item in feeds) {
          item = parseInt(item);
          if (feeds[item]?.id === scrollFeedId) {
            if (feedData?.data?.data?.length > item) {
              setScrollFeedIndex(item);
              scrollDivFlag = true;
            } else {
              setScrollFeedIndex(item - 1);
              scrollDivFlag = true;
            }
          }
        }
      }
    }
    if (feedData?.data?.data && feedData?.data?.data?.length !== 0) {
      setFeeds(feedData?.data?.data);
    }
  }, [feedData]);

  return feedData?.loading && feedData?.data?.data?.length === 0 ? (
    [...Array(2)].map((item, key) => {
      return <FeedSkeleton key={key} />;
    })
  ) : feeds.length !== 0 ? (
    <>
      {feeds?.map((item, key) => {
        return (
          <Box
            className="feedRoot lrComponent"
            key={key}
            ref={scrollFeedIndex === key ? divRef : null}
          >
            <FeedHeader feedData={item} />
            <FeedContent feedData={item} />
            <FeedFooter feedData={item} setScrollFeedID={setScrollFeedID} />
            {key === feeds.length - 3 ? <Box ref={setTarget}></Box> : null}
          </Box>
        );
      })}
      {feedData?.data?.offsetStatus && <FeedSkeleton />}
    </>
  ) : (
    <ZeroStateFeedListing message={feedData?.data?.message} />
  );
}

const mapStateToProps = (state) => ({
  feedsType: state.FeedData.feedsType,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Feeds);
