// Create Feed Action
export const CREATE_FEED_START = "CREATE_FEED_START";
export const CREATE_FEED_SUCCESS = "CREATE_FEED_SUCCESS";
export const CREATE_FEED_ERROR = "CREATE_FEED_ERROR";

// Get Feed Action
export const GET_FEED_START = "GET_FEED_START";
export const GET_FEED_SUCCESS = "GET_FEED_SUCCESS";
export const GET_FEED_ERROR = "GET_FEED_ERROR";

// New Feed Socket
export const SOCKET_IO_GET_NEW_FEED = "SOCKET_IO_GET_NEW_FEED";

// Profile Feed Action
export const GET_PROFILE_FEED_START = "GET_PROFILE_FEED_START";
export const GET_PROFILE_FEED_SUCCESS = "GET_PROFILE_FEED_SUCCESS";
export const GET_PROFILE_FEED_ERROR = "GET_PROFILE_FEED_ERROR";

//Update Feed Action
export const UPDATE_FEED_START = "UPDATE_FEED_START";
export const UPDATE_FEED_SUCCESS = "UPDATE_FEED_SUCCESS";
export const UPDATE_FEED_ERROR = "UPDATE_FEED_ERROR";

//Feed Like Actions
export const FEED_LIKE_START = "FEED_LIKE_START";
export const FEED_LIKE_SUCCESS = "FEED_LIKE_SUCCESS";
export const FEED_LIKE_ERROR = "FEED_LIKE_ERROR";

//Feed Comment Actions
export const FEED_COMMENT_START = "FEED_COMMENT_START";
export const FEED_COMMENT_SUCCESS = "FEED_COMMENT_SUCCESS";
export const FEED_COMMENT_ERROR = "FEED_COMMENT_ERROR";

//Delete Feed Actions
export const DELETE_FEED_START = "DELETE_FEED_START";
export const DELETE_FEED_SUCCESS = "DELETE_FEED_SUCCESS";
export const DELETE_FEED_ERROR = "DELETE_FEED_ERROR";

//Socket Io Update Feed Actions
export const SOCKET_IO_UPDATE_FEED = "SOCKET_IO_UPDATE_FEED";
export const SOCKET_IO_DELETE_FEED = "SOCKET_IO_DELETE_FEED";
export const SOCKET_IO_FEED_LIKE = "SOCKET_IO_FEED_LIKE";
export const SOCKET_IO_FEED_DISLIKE = "SOCKET_IO_FEED_DISLIKE";
export const SOCKET_IO_NEW_FEED_COMMENT = "SOCKET_IO_NEW_FEED_COMMENT";

//Get Likded Feed Actions
export const GET_LIKED_FEED_START = "GET_LIKED_FEED_START";
export const GET_LIKED_FEED_SUCCESS = "GET_LIKED_FEED_SUCCESS";
export const GET_LIKED_FEED_ERROR = "GET_LIKED_FEED_ERROR";

//Get Feed Likes Actions
export const GET_FEED_LIKES_START = "GET_FEED_LIKES_START";
export const GET_FEED_LIKES_SUCCESS = "GET_FEED_LIKES_SUCCESS";
export const GET_FEED_LIKES_ERROR = "GET_FEED_LIKES_ERROR";

//Get Feed Comments Actions
export const GET_FEED_COMMENTS_START = "GET_FEED_COMMENTS_START";
export const GET_FEED_COMMENTS_SUCCESS = "GET_FEED_COMMENTS_SUCCESS";
export const GET_FEED_COMMENTS_ERROR = "GET_FEED_COMMENTS_ERROR";

//Get Feed Comment Likes Actions
export const GET_FEED_COMMENT_LIKES_START = "GET_FEED_COMMENT_LIKES_START";
export const GET_FEED_COMMENT_LIKES_SUCCESS = "GET_FEED_COMMENT_LIKES_SUCCESS";
export const GET_FEED_COMMENT_LIKES_ERROR = "GET_FEED_COMMENT_LIKES_ERROR";

//Post Feed Comment Reply Actions
export const POST_FEED_COMMENT_REPLY_START = "POST_FEED_COMMENT_REPLY_START";
export const POST_FEED_COMMENT_REPLY_SUCCESS =
  "POST_FEED_COMMENT_REPLY_SUCCESS";
export const POST_FEED_COMMENT_REPLY_ERROR = "POST_FEED_COMMENT_REPLY_ERROR";

//Get Feed Comment Reply Actions
export const GET_FEED_COMMENT_REPLY_LIKES_START =
  "GET_FEED_COMMENT_REPLY_LIKES_START";
export const GET_FEED_COMMENT_REPLY_LIKES_SUCCESS =
  "GET_FEED_COMMENT_REPLY_LIKES_SUCCESS";
export const GET_FEED_COMMENT_REPLY_LIKES_ERROR =
  "GET_FEED_COMMENT_REPLY_LIKES_ERROR";

//Socket Io New Feed Comment Reply Actions
export const SOCKET_IO_NEW_FEED_COMMENT_REPLY =
  "SOCKET_IO_NEW_FEED_COMMENT_REPLY";

//Socket Io New Feed Comment Like Actions
export const SOCKET_IO_NEW_FEED_COMMENT_LIKE =
  "SOCKET_IO_NEW_FEED_COMMENT_LIKE";

//Socket Io Dislike Feed Comment Actions
export const SOCKET_IO_DISLIKE_FEED_COMMENT = "SOCKET_IO_DISLIKE_FEED_COMMENT";

//Post Feed Comment Like Actions
export const POST_FEED_COMMENT_LIKE_START = "POST_FEED_COMMENT_LIKE_START";
export const POST_FEED_COMMENT_LIKE_SUCCESS = "POST_FEED_COMMENT_LIKE_SUCCESS";
export const POST_FEED_COMMENT_LIKE_ERROR = "POST_FEED_COMMENT_LIKE_ERROR";

//Post Feed Comment Reply Like Actions
export const POST_FEED_COMMENT_REPLY_LIKE_START =
  "POST_FEED_COMMENT_REPLY_LIKE_START";
export const POST_FEED_COMMENT_REPLY_LIKE_SUCCESS =
  "POST_FEED_COMMENT_REPLY_LIKE_SUCCESS";
export const POST_FEED_COMMENT_REPLY_LIKE_ERROR =
  "POST_FEED_COMMENT_REPLY_LIKE_ERROR";

//Socket Io New Feed Comment Reply Actions
export const SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE =
  "SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE";

//Socket Io Dislike Feed Comment Reply Actions
export const SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY =
  "SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY";

//Get Search Feeds Actions
export const GET_SEARCH_FEEDS_START = "GET_SEARCH_FEEDS_START";
export const GET_SEARCH_FEEDS_SUCCESS = "GET_SEARCH_FEEDS_SUCCESS";
export const GET_SEARCH_FEEDS_ERROR = "GET_SEARCH_FEEDS_ERROR";

//Set Feeds Type Actions
export const SET_FEEDS_TYPE = "SET_FEEDS_TYPE";

//Get Group Feeds Actions
export const GET_GROUP_FEEDS_START = "GET_GROUP_FEEDS_START";
export const GET_GROUP_FEEDS_SUCCESS = "GET_GROUP_FEEDS_SUCCESS";
export const GET_GROUP_FEEDS_ERROR = "GET_GROUP_FEEDS_ERROR";

//Socket Io Delete Group Actions
export const SOCKET_IO_DELETE_GROUP_FEED = "SOCKET_IO_DELETE_GROUP_FEED";

//Update Comment & Reply  Actions
export const UPDATE_COMMENT_AND_REPLY_START = "UPDATE_COMMENT_AND_REPLY_START";
export const UPDATE_COMMENT_AND_REPLY_SUCCESS =
  "UPDATE_COMMENT_AND_REPLY_SUCCESS";
export const UPDATE_COMMENT_AND_REPLY_ERROR = "UPDATE_COMMENT_AND_REPLY_ERROR";

//Delete Comment & Reply Actions
export const DELETE_COMMENT_AND_REPLY_START = "DELETE_COMMENT_AND_REPLY_START";
export const DELETE_COMMENT_AND_REPLY_SUCCESS =
  "DELETE_COMMENT_AND_REPLY_SUCCESS";
export const DELETE_COMMENT_AND_REPLY_ERROR = "DELETE_COMMENT_AND_REPLY_ERROR";

//Get Feed Details Actions
export const GET_FEED_DETAILS_START = "GET_FEED_DETAILS_START";
export const GET_FEED_DETAILS_SUCCESS = "GET_FEED_DETAILS_SUCCESS";
export const GET_FEED_DETAILS_ERROR = "GET_FEED_DETAILS_ERROR";
