import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#023047",
    },
    secondary: {
      main: "#FB8500",
    },
    text: {
      bold: "#333333",
      medium: "#666666",
      light: "#999999",
      gray: "#EEEEEE",
      blue: "#0073AA",
    },
    common: {
      white: "#fff",
      black: "#000",
      lightgray: "#C4C4C4",
      lightgrayone: "#F2F2F2",
      primaryBtnHover: "#2D5C74",
      secondaryHover: "#FB85001A",
      aliceBlue: "#EEF3F6",
      darkgray: "#808080",
      lightBlue: "#D3F1FF",
      whitesmoke: "#f5f5f5",
      red: "#FF0000",
    },
    line: {
      lightgray: "#E6E6E6",
      grayColor: "#EFEFEF",
    },
    border: {
      borderColor: "#DDDDDD",
      bordertopcolor: "#E5E5E5",
    },
    fontweight: {
      small300: "300",
      light400: "400",
      medium500: "500",
      large700: "700",
      medium600: "600",
    },
    font: {
      boldText: {
        fontFamily: "Poppins",
        robotoFontFamily: "Roboto",
      },
    },
  },
});

export default theme;
