import React from "react";
import { Box } from "@material-ui/core";
import "../../scss/ConnectionComponents.scss";
import UserAvatar from "../common/UserAvatar";
import { useHistory } from "react-router-dom";
import H4 from "../Headings/H4";
import TooltipComponent from "../common/Tooltip";
import ConnAndMsgComponent from "./ConnAndMsgComponent";
import DisplayName from "../common/DisplayName";

export default function ConnectionCard({ user, myProfile, location, data }) {
  const history = useHistory();

  return (
    <Box className="connectionCardRoot">
      <Box className="connectionCardTopDiv">
        <Box className="center">
          <UserAvatar
            name={user?.display_name}
            src={user?.profile_picture}
            onClick={() => history.push(`/user-profile/${user?.slug}/0`)}
          />
        </Box>
      </Box>
      <Box className="connectionCardBottomDiv">
        <TooltipComponent title={user?.display_name}>
          <H4
            medium
            ellipsis
            onClick={() => history.push(`/user-profile/${user?.slug}/0`)}
          >
            <DisplayName user={user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
          </H4>
        </TooltipComponent>
        <Box>
          <ConnAndMsgComponent
            currentUser={{
              ...user,
              con_id: data?.id,
              inviter: data?.inviter,
              invitee: data?.invitee,
              status: data?.status,
            }}
            type={location}
            myProfile={myProfile}
          />
        </Box>
      </Box>
    </Box>
  );
}
