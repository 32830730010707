import React from "react";
import { UserDetailsSkeleton } from "../common/Skeletons";
import { makeStyles, Box } from "@material-ui/core";
import { Body2 } from "../Typography/Typographys";
import classNames from "classnames";
import UserAvatar from "../common/UserAvatar";
import H3 from "../Headings/H3";
import UserRoles from "../common/UserRoles";
import DisplayName from "../common/DisplayName";

const useStyle = makeStyles((theme) => ({
  udrsLeftDiv: {
    width: "calc(100% - 30px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "100%",
    },
  },
  udRightSide: {
    width: "calc(100% - 180px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "calc(100% - 150px)",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
      width: "calc(100% - 160px)",
    },
  },
  udrsRightDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    "&>button:nth-child(2)": {
      marginLeft: 10,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
}));

export default function UserDetails({ data, loading, type, actionBtns }) {

  const classes = useStyle();
  return (
    <Box className="udRoot">
      {loading ? (
        <UserDetailsSkeleton
          udLeftSide={classes.udLeftSide}
          udRightSide={classes.udRightSide}
          udrsLeftDiv={classes.udrsLeftDiv}
          udrsRightDiv={classes.udrsRightDiv}
        />
      ) : (
        <>
          <Box className={classNames("udLeftSide", "center")}>
            <UserAvatar
              src={data?.image}
              name={data?.is_company_account ? data?.company_profile_name : data?.name}
              variant="square"
            />
          </Box>
          <Box className={classes.udRightSide}>
            <Box className={classes.udrsLeftDiv}>
              <H3 medium>
                <DisplayName user={data} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
              </H3>
              {type === "userProfile" && (
                <UserRoles roles={data?.roles} multiple align="left" />
              )}
              <Body2 className="description" multilineEllipsis line={3}>
                {data?.description}
              </Body2>
            </Box>
            <Box className={classes.udrsRightDiv}>
              {actionBtns ? actionBtns : null}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
