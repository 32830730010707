import { makeStyles } from "@material-ui/core";
import { Autocomplete, TextField } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";

const useStyle = makeStyles((theme) => ({
  autocompleteTextfield: {},
  has_error: {
    "&>div": {
      "&>input": {
        border: `1px solid ${theme.palette.common.red} !important`,
      },
    },
    "&>p": {
      color: theme.palette.common.red,
      margin: "5px 0px",
    },
    "&:hover": {
      "&>div>input": {
        border: `1px solid ${theme.palette.common.red} !important`,
      },
    },
  },
}));

const defaultFunction = () => {};

export const AutocompleteTextfield = ({
  options,
  placeholder,
  onKeyPress,
  onChange = defaultFunction,
  autoComplete = false,
  autoHighlight = false,
  autoSelect = false,
  blurOnSelect = false,
  ChipProps,
  clearIcon,
  clearOnBlur,
  clearOnEscape = false,
  clearText,
  closeText,
  componentsProps,
  defaultValue,
  disableClearable = false,
  disableCloseOnSelect = false,
  disabled = false,
  disabledItemsFocusable = false,
  disableListWrap = false,
  disablePortal = false,
  filterOptions,
  filterSelectedOptions = false,
  forcePopupIcon,
  freeSolo = false,
  fullWidth = false,
  getLimitTagsText,
  getOptionDisabled,
  getOptionLabel,
  groupBy,
  handleHomeEndKeys,
  id,
  includeInputInList = false,
  inputValue,
  isOptionEqualToValue,
  limitTags,
  ListboxComponent,
  ListboxProps,
  loading = false,
  loadingText,
  multiple = false,
  noOptionsText,
  onClose = defaultFunction,
  onHighlightChange,
  onInputChange,
  onOpen,
  open,
  openOnFocus,
  openText,
  PaperComponent,
  PopperComponent,
  popupIcon,
  renderGroup,
  renderOption,
  renderTags,
  selectOnFocus,
  size,
  sx,
  value,
  className,
  helperText,
  has_error = false,
  textfieldOnChange = () => {},
}) => {
  const classes = useStyle();
  return (
    <Autocomplete
      options={options}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          className={classNames(
            classes.autocompleteTextfield,
            className,
            has_error && classes.has_error
          )}
          onChange={textfieldOnChange}
          helperText={helperText}
        />
      )}
      sx={sx}
      value={value}
      size={size}
      selectOnFocus={selectOnFocus}
      renderTags={renderTags}
      renderOption={renderOption}
      renderGroup={renderGroup}
      popupIcon={popupIcon}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
      openText={openText}
      openOnFocus={openOnFocus}
      open={open}
      onOpen={onOpen}
      onInputChange={onInputChange}
      onHighlightChange={onHighlightChange}
      onClose={onClose}
      noOptionsText={noOptionsText}
      multiple={multiple}
      loadingText={loadingText}
      loading={loading}
      onKeyPress={onKeyPress}
      ListboxProps={ListboxProps}
      ListboxComponent={ListboxComponent}
      limitTags={limitTags}
      isOptionEqualToValue={isOptionEqualToValue}
      inputValue={inputValue}
      includeInputInList={includeInputInList}
      id={id}
      handleHomeEndKeys={handleHomeEndKeys}
      groupBy={groupBy}
      getOptionLabel={getOptionLabel}
      getOptionDisabled={getOptionDisabled}
      getLimitTagsText={getLimitTagsText}
      fullWidth={fullWidth}
      freeSolo={freeSolo}
      forcePopupIcon={forcePopupIcon}
      filterSelectedOptions={filterSelectedOptions}
      filterOptions={filterOptions}
      disablePortal={disablePortal}
      disableListWrap={disableListWrap}
      disabledItemsFocusable={disabledItemsFocusable}
      disabled={disabled}
      autoComplete={autoComplete}
      autoHighlight={autoHighlight}
      autoSelect={autoSelect}
      blurOnSelect={blurOnSelect}
      ChipProps={ChipProps}
      clearIcon={clearIcon}
      clearOnBlur={clearOnBlur}
      clearOnEscape={clearOnEscape}
      clearText={clearText}
      closeText={closeText}
      componentsProps={componentsProps}
      defaultValue={defaultValue}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutocompleteTextfield);
