// Reset
export const RESET_POST_REDUCER = "RESET_POST_REDUCER"

// Add post Actions
export const CREATE_POST_START = "CREATE_POST_START";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";

//Get Post Listing Actions
export const GET_POST_START = "GET_POST_START";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_ERROR = "GET_POST_ERROR";

// Get Post Categories actions
export const GET_POST_CATEGORIES_START = "GET_POST_CATEGORIES_START";
export const GET_POST_CATEGORIES_SUCCESS = "GET_POST_CATEGORIES_SUCCESS";
export const GET_POST_CATEGORIES_ERROR = "GET_POST_CATEGORIES_ERROR";

// Get Autofill Categories Actions
export const GET_POST_AUTOFILL_START = "GET_POST_AUTOFILL_START";
export const GET_POST_AUTOFILL_SUCCESS = "GET_POST_AUTOFILL_SUCCESS";
export const GET_POST_AUTOFILL_ERROR = "GET_POST_AUTOFILL_ERROR";

//Get Delete Categories Actions
export const DELETE_CATEGORIES_START = "DELETE_CATEGORIES_START";
export const DELETE_CATEGORIES_SUCCESS = "DELETE_CATEGORIES_SUCCESS";
export const DELETE_CATEGORIES_ERROR = "DELETE_CATEGORIES_ERROR";

export const CREATE_CATEGORY_START = "CREATE_CATEGORY_START";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";

//Update Blog Post Actions
export const UPDATE_BLOG_POST_START = "UPDATE_BLOG_POST_START";
export const UPDATE_BLOG_POST_SUCCESS = "UPDATE_BLOG_POST_SUCCESS";
export const UPDATE_BLOG_POST_ERROR = "UPDATE_BLOG_POST_ERROR";

//Get Blog Details Actions
export const GET_BLOG_DETAILS_START = "GET_BLOG_DETAILS_START";
export const GET_BLOG_DETAILS_SUCCESS = "GET_BLOG_DETAILS_SUCCESS";
export const GET_BLOG_DETAILS_ERROR = "GET_BLOG_DETAILS_ERROR";

//Delete Blog Post Actions
export const DELETE_BLOG_POST_START = "DELETE_BLOG_POST_START";
export const DELETE_BLOG_POST_SUCCESS = "DELETE_BLOG_POST_SUCCESS";
export const DELETE_BLOG_POST_ERROR = "DELETE_BLOG_POST_ERROR";

//Get all Blog Post Actions
export const GET_ALL_BLOGS_POST_START = "GET_ALL_BLOGS_POST_START";
export const GET_ALL_BLOGS_POST_SUCCESS = "GET_ALL_BLOGS_POST_SUCCESS";
export const GET_ALL_BLOGS_POST_ERROR = "GET_ALL_BLOGS_POST_ERROR";

//Get Post Listing by User Slug
export const GET_USER_POST_START = "GET_USER_POST_START";
export const GET_USER_POST_SUCCESS = "GET_USER_POST_SUCCESS";
export const GET_USER_POST_ERROR = "GET_USER_POST_ERROR";
