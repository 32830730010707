import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import { Body1 } from "../Typography/Typographys";
import { useHistory } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  JOIN_NEW_GROUP_START,
  DELETE_GROUP_START,
  CANCEL_GROUP_JOIN_REQUEST_START,
  LEAVE_GROUP_START,
  UPDATE_GROUP_JOIN_REQUEST_START,
} from "../../actions/GroupAction";
import { GET_GROUP_FEEDS_START } from "../../actions/FeedAction";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import "../../scss/Groups.scss";
import AlertDialog from "../Dialogs/AlertDialog";
import CreateOrEditGroupDialog from "../Dialogs/CreateOrEditGroupDialog";
import CircularProgressComponent from "../common/CircularProgressComponent";
import SimpleButton from "../Buttons/SimpleButton";
import TooltipComponent from "../common/Tooltip";

let joinNewGroupFlag = false;
let deleteGroupFlag = false;
let cancelGroupJoiningReqFlag = false;
let leaveGroupFlag = false;
let acceptGroupJoiningRequestFlag = false;
let declineGroupJoiningRequestFlag = false;

export const GroupActionButtons = ({
  type,
  groupData,
  userData,
  joinNewGroup,
  joinNewGroupData,
  deleteGroup,
  deleteGroupData,
  cancelGroupJoinRequest,
  cancelGroupJoinRequestData,
  leaveGroup,
  leaveGroupData,
  getGroupFeeds,
  updateGroupJoinRequest,
  updateGroupJoinRequestData,
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [dialogSuccessBtnText, setDialogSuccessBtnText] = useState("");
  const [dialogType, setDialogType] = useState("");
  const [loading, setLoading] = useState("");
  const [loadingId, setLoadingId] = useState(0);
  const [coeDialogOpen, setCoeDialogOpen] = useState(false);
  const [coeIsUpdate, setCoeIsUpdate] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      // setDialogContent("");
      // setDialogTitle("");
      // setDialogSuccessBtnText("");
      setDialogType("");
    }, 1000);
  };

  const handleDialogClose = (value) => {
    if (value) {
      if (dialogType === "deleteGroup") {
        deleteGroup({ slug: groupData?.slug });
        setLoading("deleteGroup");
        setLoadingId(groupData?.id);
        deleteGroupFlag = true;
      } else if (dialogType === "withdraw") {
        cancelGroupJoinRequest(groupData?.id, groupData?.admin_id);
        setLoading("withdraw");
        setLoadingId(groupData?.req_id);
        cancelGroupJoiningReqFlag = true;
      } else if (dialogType === "leaveGroup") {
        leaveGroup({ slug: groupData?.slug });
        setLoading("leaveGroup");
        setLoadingId(groupData?.id);
        leaveGroupFlag = true;
      } else if (dialogType === "join") {
        joinNewGroup(groupData?.id);
        joinNewGroupFlag = true;
        setLoading("join");
        setLoadingId(groupData?.id);
      }
    }
    handleClose();
  };

  const coeDialogClose = () => {
    setCoeDialogOpen(false);
    setCoeIsUpdate(false);
  };

  const handleJoinGroupBtnClick = (e) => {
    setDialogType("join");
    setDialogTitle("Group Join");
    setDialogContent("Are you sure you want to join this group?");
    setDialogSuccessBtnText("Join");
    setOpen(true);
  };

  const handleEditGroupBtnClick = () => {
    setCoeIsUpdate(true);
    setCoeDialogOpen(true);
  };

  const handleCreateGroupBtnClick = () => {
    setCoeDialogOpen(true);
  };

  const handleAcceptBtnClick = () => {
    updateGroupJoinRequest(
      groupData?.req_id,
      groupData?.user?.id,
      groupData?.id,
      "accepted"
    );
    acceptGroupJoiningRequestFlag = true;
    setLoading("accepted");
    setLoadingId(groupData?.req_id);
  };

  const handleDeclineBtnClick = () => {
    updateGroupJoinRequest(
      groupData?.req_id,
      groupData?.user?.id,
      groupData?.id,
      "rejected"
    );
    declineGroupJoiningRequestFlag = true;
    setLoading("rejected");
    setLoadingId(groupData?.req_id);
  };

  const handleDeleteGroupBtnClick = () => {
    setDialogType("deleteGroup");
    setDialogTitle("Delete Group.");
    setDialogContent("Are you sure you want to delete this group?");
    setDialogSuccessBtnText("Delete");
    setOpen(true);
  };

  const handleWithdrawJoinReqBtnClick = () => {
    setDialogType("withdraw");
    setDialogTitle("Withdraw Group Joining Request.");
    setDialogContent(
      "Are you sure you want to withdraw this group joining request?"
    );
    setDialogSuccessBtnText("Withdraw");
    setOpen(true);
  };

  const handleLeaveGroupBtnClick = () => {
    setDialogType("leaveGroup");
    setDialogTitle("Leave Group");
    setDialogContent("Are you sure you want to leave this group?");
    setDialogSuccessBtnText("Leave");
    setOpen(true);
  };

  const handleStopLoading = () => {
    setLoading("");
    setLoadingId(0);
  };

  useEffect(() => {
    const location = history.location.pathname.split("/")[1];
    if (joinNewGroupData?.data?.status === "1" && joinNewGroupFlag) {
      joinNewGroupFlag = false;
      handleStopLoading();
      if (!groupData?.is_private && location === "group") {
        getGroupFeeds(groupData?.slug, 0);
      }
    }
    if (deleteGroupData?.data?.status === "1" && deleteGroupFlag) {
      deleteGroupFlag = false;
      handleStopLoading();
    }
    if (
      cancelGroupJoinRequestData?.data?.status === "1" &&
      cancelGroupJoiningReqFlag
    ) {
      cancelGroupJoiningReqFlag = false;
      handleStopLoading();
    }
    if (leaveGroupData?.data?.status === "1" && leaveGroupFlag) {
      leaveGroupFlag = false;
      handleStopLoading();
      if (location === "group") {
        getGroupFeeds(groupData?.slug, 0);
      }
    }
    if (
      updateGroupJoinRequestData?.data?.status === "1" &&
      (acceptGroupJoiningRequestFlag || declineGroupJoiningRequestFlag)
    ) {
      handleStopLoading();
      acceptGroupJoiningRequestFlag = false;
      declineGroupJoiningRequestFlag = false;
    }
  }, [
    joinNewGroupData,
    deleteGroupData,
    cancelGroupJoinRequestData,
    leaveGroupData,
    updateGroupJoinRequestData,
  ]);

  const getIconsOrButtons = (type) => {
    switch (type) {
      case "joinIcon":
        return joinNewGroupData?.loading &&
          loading === "join" &&
          loadingId === groupData?.id ? (
          <CircularProgressComponent customColor="secondary" />
        ) : (
          <TooltipComponent title={`Join ${groupData?.name} group`}>
            <GroupAddOutlinedIcon
              className="tshSvg"
              onClick={handleJoinGroupBtnClick}
            />
          </TooltipComponent>
        );
      case "joinButton":
        return (
          <SimpleButton
            primaryColorBtn
            endIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleJoinGroupBtnClick}
            loading={
              joinNewGroupData?.loading &&
              loading === "join" &&
              loadingId === groupData?.id
            }
          >
            Join
          </SimpleButton>
        );
      case "editGroupIcon":
        return (
          <Box className="edGroupIcon center" onClick={handleEditGroupBtnClick}>
            <EditOutlinedIcon />
          </Box>
        );
      case "editGroupButton":
        return (
          <SimpleButton
            onClick={handleEditGroupBtnClick}
            transparentBorderBtn
            endIcon={<EditOutlinedIcon />}
          >
            Edit
          </SimpleButton>
        );
      case "createGroupButton":
        return (
          <SimpleButton
            primaryColorBtn
            endIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={handleCreateGroupBtnClick}
            className="createBtn"
          >
            Create Group
          </SimpleButton>
        );
      case "deleteGroupIcon":
        return (
          <Box
            className="edGroupIcon center"
            onClick={handleDeleteGroupBtnClick}
          >
            <DeleteOutlinedIcon />
          </Box>
        );
      case "deleteGroupButton":
        return (
          <SimpleButton
            onClick={handleDeleteGroupBtnClick}
            transparentBorderBtn
            endIcon={<DeleteOutlinedIcon />}
            loading={
              deleteGroupData?.loading &&
              loading === "deleteGroup" &&
              loadingId === groupData?.id
            }
          >
            Delete
          </SimpleButton>
        );
      case "withdrawButton":
        return (
          <SimpleButton
            primaryColorBtn
            onClick={handleWithdrawJoinReqBtnClick}
            loading={
              cancelGroupJoinRequestData?.loading &&
              loading === "withdraw" &&
              loadingId === groupData?.req_id
            }
          >
            Withdraw
          </SimpleButton>
        );
      case "leaveGroupButton":
        return (
          <SimpleButton
            transparentBorderBtn
            loading={
              leaveGroup?.loading &&
              loading === "leaveGroup" &&
              loadingId === groupData?.id
            }
            onClick={handleLeaveGroupBtnClick}
          >
            Leave Group
          </SimpleButton>
        );
      case "leaveGroupText":
        return leaveGroup?.loading &&
          loading === "leaveGroup" &&
          loadingId === groupData?.id ? (
          <CircularProgressComponent customColor="secondary" />
        ) : (
          <Body1
            className="leaveGroupTextBtn"
            onClick={handleLeaveGroupBtnClick}
          >
            Leave Group
          </Body1>
        );
      case "acceptButton":
        return (
          <SimpleButton
            primaryColorBtn
            loading={
              updateGroupJoinRequestData?.loading &&
              loading === "accepted" &&
              loadingId === groupData?.req_id
            }
            onClick={handleAcceptBtnClick}
          >
            Accept
          </SimpleButton>
        );
      case "declineButton":
        return (
          <SimpleButton
            transparentBorderBtn
            loading={
              updateGroupJoinRequestData?.loading &&
              loading === "rejected" &&
              loadingId === groupData?.req_id
            }
            onClick={handleDeclineBtnClick}
          >
            Decline
          </SimpleButton>
        );
      default:
        return "";
    }
  };

  const getButtonsAccordingToTypes = (type) => {
    if (type === "groupDetailPageTop") {
      if (groupData?.admin_id === userData?.userInfo?.data?.id) {
        return (
          <>
            {getIconsOrButtons("editGroupButton")}
            {getIconsOrButtons("deleteGroupButton")}
          </>
        );
      } else {
        if (groupData?.status) {
          if (groupData?.status === "pending") {
            return getIconsOrButtons("withdrawButton");
          } else {
            return getIconsOrButtons("leaveGroupButton");
          }
        } else {
          return getIconsOrButtons("joinButton");
        }
      }
    } else if (type === "miniGroupSuggestionComponent") {
      return getIconsOrButtons("joinIcon");
    } else if (type === "groupListingCreateBtn") {
      return getIconsOrButtons("createGroupButton");
    } else if (type === "userGroupDetailCardEditAndDelete") {
      return (
        <>
          {getIconsOrButtons("editGroupIcon")}
          {getIconsOrButtons("deleteGroupIcon")}
        </>
      );
    } else if (type === "userGroupDetailCardLeaveGroup") {
      return getIconsOrButtons("leaveGroupText");
    } else if (type === "userGroupDetailCardJoin") {
      return getIconsOrButtons("joinButton");
    } else if (type === "groupRequests") {
      if (groupData?.user?.id === userData?.userInfo?.data?.id) {
        return getIconsOrButtons("withdrawButton");
      } else {
        return (
          <>
            {getIconsOrButtons("acceptButton")}
            {getIconsOrButtons("declineButton")}
          </>
        );
      }
    } else if (type === "userGroupDetailCardWithdraw") {
      return getIconsOrButtons("withdrawButton");
    } else {
      return "";
    }
  };
  return (
    <>
      {getButtonsAccordingToTypes(type)}
      <AlertDialog
        open={open}
        dialogContent={dialogContent}
        dialogTitle={dialogTitle}
        successBtnText={dialogSuccessBtnText}
        handleClose={handleDialogClose}
      />
      <CreateOrEditGroupDialog
        open={coeDialogOpen}
        handleClose={coeDialogClose}
        isUpdate={coeIsUpdate}
        data={groupData}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  joinNewGroupData: state.GroupData.joinNewGroupData,
  deleteGroupData: state.GroupData.deleteGroupData,
  leaveGroupData: state.GroupData.leaveGroupData,
  cancelGroupJoinRequestData: state.GroupData.cancelGroupJoinRequestData,
  updateGroupJoinRequestData: state.GroupData.updateGroupJoinRequestData,
});

const mapDispatchToProps = (dispatch) => ({
  joinNewGroup: (group_id) =>
    dispatch({
      type: JOIN_NEW_GROUP_START,
      group_id,
    }),
  deleteGroup: (data) =>
    dispatch({
      type: DELETE_GROUP_START,
      data,
    }),
  cancelGroupJoinRequest: (group_id, admin_id) =>
    dispatch({
      type: CANCEL_GROUP_JOIN_REQUEST_START,
      group_id,
      admin_id,
    }),
  leaveGroup: (data) =>
    dispatch({
      type: LEAVE_GROUP_START,
      data,
    }),
  getGroupFeeds: (slug, offset) =>
    dispatch({
      type: GET_GROUP_FEEDS_START,
      slug,
      offset,
    }),
  updateGroupJoinRequest: (id, user_id, group_id, status) =>
    dispatch({
      type: UPDATE_GROUP_JOIN_REQUEST_START,
      id,
      user_id,
      group_id,
      status,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupActionButtons);
