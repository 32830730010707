import { all } from "redux-saga/effects";
import {
  SigninWatcher,
  SignupWatcher,
  ForgotPasswordWatcher,
  ResetPasswordWatcher,
  ResetTokenVarifyWatcher,
  LogoutWatcher,
  VerifyEmailWatcher,
  ResendVerificationEmailWatcher,
  ChangePasswordWatcher,
} from "./AuthSaga";
import {
  EducationHistoryWatcher,
  WorkHistoryWatcher,
  GetEducationHistoryWatcher,
  UpdateEducationHistoryWatcher,
  DeleteEducationHistoryWatcher,
  GetWorkHistoryWatcher,
  DeleteWorkHistoryWatcher,
  UpdateWorkHistoryWatcher,
  GetUserDataWatcher,
  GetProfileDetailWatcher,
  GetPerformingRolesWatcher,
  UpdateProfileDetailWatcher,
  GetProfileUserDataWatcher,
  UpdateUserDataWatcher,
  UpdateUserProfilePictureWatcher,
  GetAllUsersWatcher,
  GetSearchDataWatcher,
  DeleteCompanyPerformingRolesWatcher,
  GetCompanyPerformingRolesWatcher,
  GetCompanyProfileWatcher,
} from "./UserSaga";
import {
  CreateNewFeedWatcher,
  GetFeedWatcher,
  GetProfileFeedWatcher,
  UpdateFeedWatcher,
  FeedLikeWatcher,
  FeedCommentWatcher,
  DeleteFeedWatcher,
  GetLikedFeedWatcher,
  GetFeedLikesWatcher,
  GetFeedCommentsWatcher,
  GetFeedCommentLikesWatcher,
  PostFeedCommentReplyWatcher,
  GetFeedCommentReplyLikesWatcher,
  PostFeedCommentLikeWatcher,
  PostFeedCommentReplyLikeWatcher,
  SearchFeedsWatcher,
  GetGroupFeedsWatcher,
  UpdateCommentAndReplyWatcher,
  DeleteCommentAndReplyWatcher,
  GetFeedDetailsWatcher,
} from "./FeedSaga";
import {
  GetAllChatRoomWatcher,
  AddNewChatMessageWatcher,
  GetChatRoomWatcher,
  CreateNewChatRoomWatcher,
  UpdateChatGroupWatcher,
  AddUpdateDeleteChatGroupWatcher,
  DeleteChatGroupWatcher,
} from "./ChatSaga";
import {
  GetPostWatcher,
  GetUserPostWatcher,
  CreatePostWatcher,
  GetPostCategoriesWatcher,
  GetAutofillCategoriesWatcher,
  DeleteCategoriesWatcher,
  UpdateBlogPostWatcher,
  DeleteBlogPostWatcher,
  GetBlogDetailsWatcher,
  CreateCategoryWatcher,
  GetAllBlogsPostWatcher,
} from "./PostSaga";
import {
  GetConnectionsWatcher,
  NewConnectionRequestWatcher,
  GetConnectionSuggestionsWatcher,
  GetConnectionRequestsWatcher,
  UpdateConnectionRequestWatcher,
  CancelConnectionRequestWatcher,
  RemoveConnectionUserWatcher,
} from "./ConnectionsSaga";
import {
  GetAllNotificationsWatcher,
  ReadNotificationsWatcher,
  GetUnReadCountsWatcher,
} from "./CommonSaga";
import {
  GetAllGroupsWatcher,
  GetGroupMembersWatcher,
  CreateGroupWatcher,
  UploadGroupIconWatcher,
  GetGroupSuggestionsWatcher,
  JoinNewGroupWatcher,
  UpdateGroupWatcher,
  GetGroupJoiningRequestsWatcher,
  CancelGroupJoinRequestWatcher,
  UpdateGroupJoinRequestWatcher,
  GetGroupDetailsWatcher,
  LeaveGroupWatcher,
  DeleteGroupWatcher,
  DeleteGroupMembersWatcher,
} from "./GroupSaga";

export default function* rootSaga() {
  yield all([
    DeleteGroupMembersWatcher(),
    DeleteGroupWatcher(),
    LeaveGroupWatcher(),
    SigninWatcher(),
    SignupWatcher(),
    ForgotPasswordWatcher(),
    ResetPasswordWatcher(),
    ResetTokenVarifyWatcher(),
    VerifyEmailWatcher(),
    LogoutWatcher(),
    EducationHistoryWatcher(),
    WorkHistoryWatcher(),
    GetEducationHistoryWatcher(),
    UpdateEducationHistoryWatcher(),
    DeleteEducationHistoryWatcher(),
    GetWorkHistoryWatcher(),
    DeleteWorkHistoryWatcher(),
    UpdateWorkHistoryWatcher(),
    CreateNewFeedWatcher(),
    GetFeedWatcher(),
    GetProfileFeedWatcher(),
    GetUserDataWatcher(),
    GetProfileDetailWatcher(),
    GetPerformingRolesWatcher(),
    UpdateProfileDetailWatcher(),
    GetProfileUserDataWatcher(),
    UpdateUserDataWatcher(),
    GetAllChatRoomWatcher(),
    AddNewChatMessageWatcher(),
    UpdateFeedWatcher(),
    GetPostWatcher(),
    GetUserPostWatcher(),
    CreatePostWatcher(),
    GetPostCategoriesWatcher(),
    GetAutofillCategoriesWatcher(),
    DeleteCategoriesWatcher(),
    UpdateBlogPostWatcher(),
    DeleteBlogPostWatcher(),
    GetBlogDetailsWatcher(),
    FeedLikeWatcher(),
    FeedCommentWatcher(),
    DeleteFeedWatcher(),
    GetChatRoomWatcher(),
    CreateNewChatRoomWatcher(),
    GetConnectionsWatcher(),
    NewConnectionRequestWatcher(),
    GetConnectionSuggestionsWatcher(),
    GetConnectionRequestsWatcher(),
    UpdateConnectionRequestWatcher(),
    UpdateUserProfilePictureWatcher(),
    GetLikedFeedWatcher(),
    GetFeedLikesWatcher(),
    GetFeedCommentsWatcher(),
    GetFeedCommentLikesWatcher(),
    PostFeedCommentReplyWatcher(),
    GetFeedCommentReplyLikesWatcher(),
    PostFeedCommentLikeWatcher(),
    PostFeedCommentReplyLikeWatcher(),
    CancelConnectionRequestWatcher(),
    SearchFeedsWatcher(),
    GetAllNotificationsWatcher(),
    GetUnReadCountsWatcher(),
    CreateCategoryWatcher(),
    GetAllUsersWatcher(),
    GetSearchDataWatcher(),
    GetAllGroupsWatcher(),
    GetGroupMembersWatcher(),
    CreateGroupWatcher(),
    UploadGroupIconWatcher(),
    GetGroupSuggestionsWatcher(),
    JoinNewGroupWatcher(),
    UpdateGroupWatcher(),
    GetGroupJoiningRequestsWatcher(),
    CancelGroupJoinRequestWatcher(),
    UpdateGroupJoinRequestWatcher(),
    RemoveConnectionUserWatcher(),
    GetGroupDetailsWatcher(),
    GetGroupFeedsWatcher(),
    DeleteCompanyPerformingRolesWatcher(),
    GetCompanyPerformingRolesWatcher(),
    GetCompanyProfileWatcher(),
    ResendVerificationEmailWatcher(),
    ReadNotificationsWatcher(),
    GetAllBlogsPostWatcher(),
    UpdateCommentAndReplyWatcher(),
    DeleteCommentAndReplyWatcher(),
    ChangePasswordWatcher(),
    GetFeedDetailsWatcher(),
    UpdateChatGroupWatcher(),
    AddUpdateDeleteChatGroupWatcher(),
    DeleteChatGroupWatcher(),
  ]);
}
