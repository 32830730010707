import React, { lazy, useEffect } from "react";
import { useParams } from "react-router-dom";
import utils from "../utils/commonUtils";
import "../scss/Search.scss";
import { connect } from "react-redux";
import { GET_SEARCH_DATA_START } from "../actions/UserAction";
import SearchCommonComponent from "./common/SearchCommonComponent";
const H2 = lazy(() => import("./Headings/H2"));

export const SearchComponent = ({ getSearchDataAction }) => {
  const { queryString } = useParams();

  useEffect(() => {
    getSearchDataAction(queryString);
  }, [queryString]);

  return (
    <div className="root searchResultsRoot">
      <H2
        children={`Search results for "${utils.toNormalString(queryString)}"`}
      />
      <SearchCommonComponent />
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSearchDataAction: (queryString) =>
    dispatch({
      type: GET_SEARCH_DATA_START,
      queryString,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
