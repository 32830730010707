import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_POST_ERROR,
  GET_POST_SUCCESS,
  GET_POST_START,
  CREATE_POST_SUCCESS,
  CREATE_POST_START,
  CREATE_POST_ERROR,
  GET_POST_CATEGORIES_SUCCESS,
  GET_POST_CATEGORIES_START,
  GET_POST_CATEGORIES_ERROR,
  GET_POST_AUTOFILL_START,
  GET_POST_AUTOFILL_SUCCESS,
  GET_POST_AUTOFILL_ERROR,
  DELETE_CATEGORIES_START,
  DELETE_CATEGORIES_SUCCESS,
  DELETE_CATEGORIES_ERROR,
  UPDATE_BLOG_POST_START,
  UPDATE_BLOG_POST_SUCCESS,
  UPDATE_BLOG_POST_ERROR,
  DELETE_BLOG_POST_START,
  DELETE_BLOG_POST_SUCCESS,
  DELETE_BLOG_POST_ERROR,
  GET_BLOG_DETAILS_START,
  GET_BLOG_DETAILS_SUCCESS,
  GET_BLOG_DETAILS_ERROR,
  CREATE_CATEGORY_ERROR,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_START,
  GET_ALL_BLOGS_POST_START,
  GET_ALL_BLOGS_POST_SUCCESS,
  GET_ALL_BLOGS_POST_ERROR,
  GET_USER_POST_START,
  GET_USER_POST_SUCCESS,
  GET_USER_POST_ERROR,
} from "../actions/PostAction";
import {
  GetPost,
  CreatePost,
  GetAllCategories,
  GetAutofillCategories,
  deleteCategory,
  UpdateBlogPost,
  deleteBlogPost,
  GetBlogDetails,
  CreateCategory,
  GetAllBlogsPost,
  GetUserPost,
} from "../Apis/PostApis";
import toast from "../components/common/Snackbars";

// Get Post
function* GetPostWorker({ offset }) {
  const response = yield call(GetPost, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_POST_SUCCESS, data: response });
    } else {
      yield put({ type: GET_POST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetPostWatcher() {
  yield takeEvery(GET_POST_START, GetPostWorker);
}

// Create
function* CreatePostWorker({ postData }) {
  const response = yield call(CreatePost, postData);
  try {
    if (response?.status === "1") {
      yield put({ type: CREATE_POST_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: CREATE_POST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CREATE_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CreatePostWatcher() {
  yield takeEvery(CREATE_POST_START, CreatePostWorker);
}

// Get Post Categories
function* GetPostCategoriesWorker() {
  const response = yield call(GetAllCategories);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_POST_CATEGORIES_SUCCESS, data: response });
    } else {
      yield put({ type: GET_POST_CATEGORIES_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_POST_CATEGORIES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetPostCategoriesWatcher() {
  yield takeEvery(GET_POST_CATEGORIES_START, GetPostCategoriesWorker);
}

// Get autofill categories
function* GetAutofillCategoriesWorker({ query, newCategory }) {
  const response = yield call(GetAutofillCategories, query, newCategory);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_POST_AUTOFILL_SUCCESS, data: response });
      // toast.success(response?.message);
    } else {
      yield put({ type: GET_POST_AUTOFILL_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_POST_AUTOFILL_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAutofillCategoriesWatcher() {
  yield takeEvery(GET_POST_AUTOFILL_START, GetAutofillCategoriesWorker);
}

//Get delete categories
function* DeleteCategoriesWorker({ id }) {
  const response = yield call(deleteCategory, id);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_CATEGORIES_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_CATEGORIES_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_CATEGORIES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteCategoriesWatcher() {
  yield takeEvery(DELETE_CATEGORIES_START, DeleteCategoriesWorker);
}

function* CreateCategoryWorker({ name }) {
  const response = yield call(CreateCategory, name);
  try {
    if (response?.status === "1") {
      yield put({ type: CREATE_CATEGORY_SUCCESS, data: response });
    } else {
      yield put({ type: CREATE_CATEGORY_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CREATE_CATEGORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CreateCategoryWatcher() {
  yield takeEvery(CREATE_CATEGORY_START, CreateCategoryWorker);
}

//Edit Blog Post
function* UpdateBlogPostWorker({ id, blogData }) {
  const response = yield call(UpdateBlogPost, id, blogData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_BLOG_POST_SUCCESS, data: response });
    } else {
      yield put({
        type: UPDATE_BLOG_POST_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_BLOG_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateBlogPostWatcher() {
  yield takeEvery(UPDATE_BLOG_POST_START, UpdateBlogPostWorker);
}

//Get Blog Details
function* GetBlogDetailsWorker({ id }) {
  const response = yield call(GetBlogDetails, id);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_BLOG_DETAILS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_BLOG_DETAILS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_BLOG_DETAILS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}
export function* GetBlogDetailsWatcher() {
  yield takeEvery(GET_BLOG_DETAILS_START, GetBlogDetailsWorker);
}

//Delete Blog Post
function* DeleteBlogPostWorker({ slug }) {
  const response = yield call(deleteBlogPost, slug);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_BLOG_POST_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_BLOG_POST_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_BLOG_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}
export function* DeleteBlogPostWatcher() {
  yield takeEvery(DELETE_BLOG_POST_START, DeleteBlogPostWorker);
}

//Get All Blog Post
function* GetAllBlogsPostWorker({ offset }) {
  const response = yield call(GetAllBlogsPost, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_BLOGS_POST_SUCCESS, data: response });
    } else {
      yield put({ type: GET_ALL_BLOGS_POST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_ALL_BLOGS_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAllBlogsPostWatcher() {
  yield takeEvery(GET_ALL_BLOGS_POST_START, GetAllBlogsPostWorker);
}

// Get Post by UserSlug
function* GetUserPostWorker({ user_slug, offset }) {
  const response = yield call(GetUserPost, user_slug, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_USER_POST_SUCCESS, data: { ...response, offset } });
    } else {
      yield put({ type: GET_USER_POST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_USER_POST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetUserPostWatcher() {
  yield takeEvery(GET_USER_POST_START, GetUserPostWorker);
}
