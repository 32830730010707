import React, { useState, lazy } from "react";
import { FormHelperText, Grid, Typography, makeStyles } from "@material-ui/core";
import gravatar from "gravatar";
import moment from "moment";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import ImageSelectDialog from "../common/ImageSelectDialog";
const DatePicker = lazy(() => import("../DateTimePickers/DatePicker"));
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));
const PlainTextfield = lazy(() => import("../Textfields/PlainTextfield"));
const RadioButtons = lazy(() => import("../input/RadioButtons"));

const useStyles = makeStyles((theme) => ({
  profileDetailForm: {
    "&>div": {

    },
  },
  dobDiv: {
    display: "flex",
    flexDirection: "column",
    "&>div": {
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  locationDiv: {
    display: "flex",
    "&>div": {
      marginBottom: "14px",
      width: "49%",
      "&:nth-child(1)": {
        marginRight: "2%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "&>div": {
        width: "100%",
        "&:nth-child(1)": {
          marginRight: 0,
        },
      },
    },
  },
  profileImageDiv: {
    display: "flex",
    "&>div": {
      "&:nth-child(1)": {
        width: "max-content",
        marginRight: 20,
      },
      "&:nth-child(2)": {
        "&>img,&>label>img": {
          width: 170,
          height: 170,
          cursor: "pointer",
        },
        "&>svg,&>label>svg": {
          width: 100,
          height: 100,
        },
      },
    },
  },
  addPhotoIcon: {
    cursor: "pointer",
  },
  pictureProfileErrorMsg: {
    fontSize: "12px",
    whiteSpace: "nowrap",
  }
}));

export default function ProfileDetail({
  dob,
  gender,
  country,
  city,
  setDob,
  setGender,
  setCountry,
  setCity,
  profile_picture,
  setProfile_picture,
  userData,
  errors,
  setErrors,
}) {
  const classes = useStyles();
  const [profile_picture_radio, setProfile_picture_radio] = useState(
    profile_picture === null && profile_picture === ""
      ? "none"
      : typeof profile_picture === "object"
        ? "upload"
        : profile_picture?.includes("gravatar")
          ? "gravatar"
          : "upload"
  );
  const [isValidGravatarImage, setIsValidGravatarImage] = useState(true);
  const [open, setOpen] = useState(false);

  const handleGravatarError = (e) => {
    setIsValidGravatarImage(false);
  };

  const handleProfileImageUpload = (e) => {
    if (e.target.files) {
      setProfile_picture(e.target.files[0]);
    }
  };

  const handleProfileUploadRadio = (e) => {
    let value = e.target.value;
    if (value === "gravatar") {
      setProfile_picture(
        gravatar.url(
          userData?.userInfo?.data?.email,
          { s: "100", r: "x", d: "retro" },
          true
        )
      );
    } else {
      setProfile_picture(null);
      if (value === "none") {
        setProfile_picture(value);
      }
    }
    setProfile_picture_radio(value);
  };

  return (
    <div>
      <div className={classes.profileDetailForm}>
        <div className={classes.dobDiv}>
          <LabelTypography
            children="Date of Birth"
            required
            has_error={errors !== null && errors["dob"] ? true : false}
          />
          <DatePicker
            value={dob}
            format="dd/MM/yyyy"
            onChange={(date) => {
              setDob(date);
              setErrors({ ...errors, dob: false });
            }}
            maxDate={moment().subtract(1, "days").toString()}
            helperText={
              (errors !== null && errors["dob"])
                ? "Please enter your date of birth."
                : (errors !== null && errors["inValidDob"]) ? "Date of birth should be always past date."
                  : ""
            }
            has_error={errors !== null && (errors["dob"] || errors["inValidDob"]) ? true : false}
          />
        </div>
        <RadioButtons
          label={"Gender"}
          required
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          radioBtnList={[
            { label: "Male", value: "male" },
            { label: "Female", value: "female" },
            { label: "Others", value: "others" },
          ]}
        />
        <div className={classes.locationDiv}>
          <div>
            <LabelTypography
              children="Town"
              required
              has_error={errors !== null && errors["city"] ? true : false}
            />
            <PlainTextfield
              value={city}
              style={{ marginBottom: "10px !important" }}
              onChange={(e) => {
                setCity(e.target.value);
                setErrors({ ...errors, city: false });
              }}
              placeholder="Town"
              helperText={
                errors !== null && errors["city"]
                  ? "Please enter your town."
                  : ""
              }
              has_error={errors !== null && errors["city"] ? true : false}
            />
          </div>
          <div>
            <LabelTypography
              children="Nation"
              required
              has_error={errors !== null && errors["country"] ? true : false}
            />
            <PlainTextfield
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
                setErrors({ ...errors, country: false });
              }}
              placeholder="Nation"
              helperText={
                errors !== null && errors["country"]
                  ? "Please enter your nation."
                  : ""
              }
              has_error={errors !== null && errors["country"] ? true : false}
            />
          </div>
        </div>
        <div className={classes.profileImageDiv}>
          <RadioButtons
            row={false}
            label={"Upload Your Profile Picture"}
            value={profile_picture_radio}
            onChange={handleProfileUploadRadio}
            radioBtnList={[
              { label: "Upload", value: "upload" },
              { label: "Gravatar", value: "gravatar" },
              { label: "None", value: "none" },
            ].filter((item, key) => {
              return key === 1 ? (isValidGravatarImage ? true : false) : true;
            })}
          />
          {profile_picture_radio !== "none" &&
            <div className="center" style={{ flexDirection: "column" }}>
              {profile_picture_radio === "upload" ?
                (
                  <label htmlFor="stepper-profile-picture" onClick={() => setOpen(true)}>
                    {typeof profile_picture === "string" ? (
                      <img src={profile_picture} alt="Your Profile Pic" />
                    ) : profile_picture !== null ? (
                      <img
                        src={URL.createObjectURL(profile_picture)}
                        alt="Your Profile Pic"
                      />
                    ) : (
                      <AddPhotoAlternateIcon className={classes.addPhotoIcon} />
                    )}
                  </label>
                )
                :
                profile_picture !== null ?
                  (<img src={profile_picture} alt="Your Profile Pic" />)
                  : (
                    <AddPhotoAlternateIcon />
                  )
              }
              {profile_picture_radio === "upload" && profile_picture === null ?
                <LabelTypography
                  has_error={errors !== null && (errors["profile_picture"] || errors["inValidProfilePictue"]) ? true : false}
                  className={classes.pictureProfileErrorMsg}
                >
                  {(errors !== null && errors["profile_picture"])
                    ? "Profile picture is compulsory whie upload is selected"
                    : ""
                  }
                </LabelTypography> : ""}
            </div>
          }
        </div>
      </div>
      <ImageSelectDialog
        open={open}
        onClose={() => setOpen(false)}
        onCrop={(croppedImage) => handleProfileImageUpload({ target: { files: [croppedImage] } })}
      />
      <img
        src={gravatar.url(
          userData?.userInfo?.data?.email,
          { s: "100", r: "x", d: "404" },
          true
        )}
        onError={handleGravatarError}
        className="display-none"
        alt="Verify Gravatar Pic"
      />
    </div>
  );
}
