// SignIn Actions
export const SIGNIN_START = "SIGNIN_START";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";

//SignUp Actions
export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

// Forgot Password Actions
export const FORGOT_PASSWORD_START = "FORGOT_PASSWORD_START";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

// Reset Password Actions
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

// Reset Password Token Validator
export const RESET_TOEKN_VARIFY_START = "RESET_TOEKN_VARIFY_START";
export const RESET_TOEKN_VARIFY_SUCCESS = "RESET_TOEKN_VARIFY_SUCCESS";
export const RESET_TOEKN_VARIFY_ERROR = "RESET_TOEKN_VARIFY_ERROR";

// Logout Actions
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

//Verify Email Actions
export const VERIFY_EMAIL_START = "VERIFY_EMAIL_START";
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

//Resend Email Verification Actions
export const RESEND_VERIFICATION_EMAIL_START =
  "RESEND_VERIFICATION_EMAIL_START";
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  "RESEND_VERIFICATION_EMAIL_SUCCESS";
export const RESEND_VERIFICATION_EMAIL_ERROR =
  "RESEND_VERIFICATION_EMAIL_ERROR";

//Change Password Actions
export const CHANGE_PASSWORD_START = "CHANGE_PASSWORD_START";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
