import React from "react";
import { Box, makeStyles, Grid, Button } from "@material-ui/core";
import "../../scss/Dialogs.scss";
import { DialogBody, DialogHeader } from "../Dialogs/DialogComponents";
import Dialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import { ReactComponent as TwitterIcon } from "../../assets/Twitter.svg";
import { ReactComponent as FacebookIcon } from "../../assets/Facebook.svg";
import { ReactComponent as LinkdinIcon } from "../../assets/Linkedin.svg";

const useStyles = makeStyles((theme) => ({
  mainBody: {
    display: "flex",
    alignItems: "center",
  },
  socialIcons: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    display: "flex",
    margin: "0 5px",
    alignItems: "center",
    justifyContent: "center",
    "&>svg": {
      width: "50%",
      height: "50%",
      objectFit: "contain",
      cursor: "pointer",
    },
    [theme.breakpoints.down("769")]: {
      width: 40,
      height: 40,
      margin: "0 5px",
    },
  },
  gridMain: {
    width: "100%",
    backgroundColor: "lightgrey",
    borderRadius: 20,
    padding: "0 10px",
    marginTop: 10,
  },
  urlText: {
    border: "none",
    background: "inherit",
    width: "0",
  },
  gridBtn: {
    background: "lightgrey",
    position: "relative",
    right: "-15px",
    borderRadius: "50px",
  },
  dialogBody: {
    marginBottom: "9px",
  },
}));

export default function FeedSharePopup({ open, handleClose }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>Share Feed</DialogHeader>
      <DialogBody className={classes.dialogBody}>
        <Box className={classes.mainBody}>
          <Box className={classes.socialIcons}>
            <FacebookIcon />
          </Box>
          <Box className={classes.socialIcons}>
            <TwitterIcon />
          </Box>
          <Box className={classes.socialIcons}>
            <LinkdinIcon />
          </Box>
        </Box>
        <Grid
          container
          alignItems="center"
          className={classes.gridMain}
          justify="space-between"
        >
          <Grid item>
            <input type="text" id="txt_share_url" className={classes.urlText} />
          </Grid>
          <Grid item className={classes.gridBtn}>
            <Button>Copy</Button>
          </Grid>
        </Grid>
      </DialogBody>
    </Dialog>
  );
}
