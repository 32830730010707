import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_START,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_START,
  READ_NOTIFICATIONS_ERROR,
  GET_UNREAD_COUNTS_START,
  GET_UNREAD_COUNTS_SUCCESS,
  GET_UNREAD_COUNTS_ERROR,
} from "../actions/CommonAction";
import { GetAllNotifications, ReadNotifications, GetUnReadCounts } from "../Apis/CommonApis";
import toast from "../components/common/Snackbars";

function* GetAllNotificationsWorker({ data }) {
  const response = yield call(GetAllNotifications, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_NOTIFICATIONS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_ALL_NOTIFICATIONS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_ALL_NOTIFICATIONS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAllNotificationsWatcher() {
  yield takeEvery(GET_ALL_NOTIFICATIONS_START, GetAllNotificationsWorker);
}

function* ReadNotificationsWorker({ data }) {
  const response = yield call(ReadNotifications, data);
  try {
    if (response?.status === "1") {
      yield put({ type: READ_NOTIFICATIONS_SUCCESS, data: response });
      yield put({ type: GET_UNREAD_COUNTS_START })
    } else {
      yield put({ type: READ_NOTIFICATIONS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: READ_NOTIFICATIONS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ReadNotificationsWatcher() {
  yield takeEvery(READ_NOTIFICATIONS_START, ReadNotificationsWorker);
}

function* GetUnReadCountsWorker() {
  const response = yield call(GetUnReadCounts);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_UNREAD_COUNTS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_UNREAD_COUNTS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_UNREAD_COUNTS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetUnReadCountsWatcher() {
  yield takeEvery(GET_UNREAD_COUNTS_START, GetUnReadCountsWorker);
}