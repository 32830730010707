import { Box, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  GET_ALL_GROUPS_START,
  GET_GROUP_SUGGESTIONS_START,
  GET_GROUP_JOINING_REQUESTS_START,
} from "../../actions/GroupAction";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { useIntersection } from "../../hooks/customHooks";
import "../../scss/Groups.scss";
import { ZeroStateUserGroups } from "../common/ZeroStateComponents";
import { GroupCardSkeleton, GroupRequestSkeleton } from "../common/Skeletons";
import { Body1, Body2 } from "../Typography/Typographys";
import GroupTabsComponent from "../common/GroupTabsComponent";
import H3 from "../Headings/H3";
import UserAvatar from "../common/UserAvatar";
import GroupActionButtons from "./GroupActionButtons";
import GroupDetailCard from "./GroupDetailCard";
import { GroupListingBreadCrumb } from "../common/BreadCrumbCommonComponent";

export const GroupListing = (props) => {
  const {
    getAllGroups,
    getAllGroupsData,
    getGroupSuggestions,
    getGroupSuggestionsData,
    userData,
    getGroupJoiningRequests,
    groupJoiningRequestsData,
  } = props;
  const { user_slug, tab_index } = useParams();
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(parseInt(tab_index) || 0);
  const [myGroups, setMyGroups] = useState([]);
  const [groupJoiningRequests, setGroupJoiningRequests] = useState([]);
  const [groupSuggestions, setGroupSuggestions] = useState([]);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1,
  });
  const [isMyGroups, setIsMyGroups] = useState(true);

  // Group Joining Request
  const [setGJRTarget, gjrEntry] = useIntersection({
    threshold: 0.1,
  });

  useEffect(() => {
    const { intersectionRatio } = gjrEntry;
    if (
      intersectionRatio >= 0.1 &&
      groupJoiningRequestsData?.data?.offsetStatus
    ) {
      getGroupJoiningRequests({
        offset: groupJoiningRequestsData?.data?.data?.length,
      });
    }
  }, [gjrEntry]);

  useEffect(() => {
    if (parseInt(tab_index) !== activeIndex) {
      history.push(`/groups/${user_slug}/${activeIndex}`);
    }
  }, [activeIndex]);

  useEffect(() => {
    if (parseInt(tab_index) !== activeIndex) {
      setActiveIndex(parseInt(tab_index));
    }
  }, [tab_index]);

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (
      intersectionRatio >= 0.1 &&
      getGroupSuggestionsData?.data?.offsetStatus
    ) {
      getGroupSuggestions(getGroupSuggestionsData?.data?.data?.length);
    }
  }, [entry]);

  useEffect(() => {
    if (getAllGroupsData?.data?.status === "1") {
      setMyGroups(getAllGroupsData?.data?.data);
    }
  }, [getAllGroupsData]);

  useEffect(() => {
    if (getGroupSuggestionsData?.data?.status === "1") {
      setGroupSuggestions(getGroupSuggestionsData?.data?.data);
    }
  }, [getGroupSuggestionsData]);

  useEffect(() => {
    if (groupJoiningRequestsData?.data?.status === "1") {
      setGroupJoiningRequests(groupJoiningRequestsData?.data?.data);
      if (groupJoiningRequestsData?.data?.data?.length === 0) {
        setActiveIndex(0);
      }
    }
  }, [groupJoiningRequestsData]);

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      if (user_slug !== userData?.userInfo?.data?.slug) {
        setIsMyGroups(false);
      } else {
        setIsMyGroups(true);
      }
    }
  }, [userData, user_slug]);

  useEffect(() => {
    getAllGroups({ slug: user_slug });
    getGroupSuggestions(0);
    getGroupJoiningRequests({ offset: 0 });
  }, [user_slug]);

  const tabList = [
    {
      label: `${
        isMyGroups ? "My" : getAllGroupsData?.data?.user?.first_name + "'s"
      } Groups${myGroups?.length > 0 ? `(${myGroups?.length})` : ""}`,
    },
  ];

  if (isMyGroups && groupJoiningRequests?.length > 0) {
    tabList.push({
      label: `Requests${
        groupJoiningRequests?.length > 0
          ? `(${groupJoiningRequests?.length})`
          : ""
      }`,
    });
  }

  return (
    <Box>
      <Grid container>
        <GroupListingBreadCrumb />
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <GroupTabsComponent
          activeStep={activeIndex}
          setActiveStep={setActiveIndex}
          tabsList={tabList}
        />
        {activeIndex === 0 && myGroups?.length > 0 && isMyGroups && (
          <GroupActionButtons type={"groupListingCreateBtn"} />
        )}
      </Grid>
      {activeIndex === 0 && (
        <>
          <Grid container className="groupDetailCardRoot">
            {getAllGroupsData?.loading ? (
              <GroupCardSkeleton num={6} />
            ) : myGroups?.length !== 0 ? (
              myGroups?.map((item, key) => {
                return (
                  <GroupDetailCard
                    data={item}
                    key={key}
                    userData={userData}
                    type={isMyGroups ? "myGroups" : "otherUserGroups"}
                  />
                );
              })
            ) : (
              <ZeroStateUserGroups
                isMyGroups={isMyGroups}
                message={getAllGroupsData?.data?.message}
              />
            )}
          </Grid>
          {isMyGroups && groupSuggestions?.length !== 0 && (
            <>
              <H3 medium className="groupSuggestionHeading">
                Suggested Groups for you
              </H3>
              <Grid container className="groupDetailCardRoot">
                {groupSuggestions?.length !== 0
                  ? groupSuggestions?.map((item, key) => {
                      return (
                        <GroupDetailCard
                          data={item}
                          key={key}
                          userData={userData}
                          type="groupSuggestions"
                        />
                      );
                    })
                  : ""}
                {getGroupSuggestionsData?.data?.offsetStatus && (
                  <GroupCardSkeleton setRef={setTarget} num={6} />
                )}
              </Grid>
            </>
          )}
        </>
      )}
      {activeIndex === 1 || groupJoiningRequestsData?.loading ? (
        <Grid
          container
          className="lrComponent groupDetailCardRoot groupRequestRootDiv"
        >
          {groupJoiningRequestsData?.loading ? (
            <GroupRequestSkeleton num={8} />
          ) : (
            groupJoiningRequests?.map((item, key) => {
              const isMyRequest =
                item?.user_id === userData?.userInfo?.data?.id;
              return (
                <Box className="ldrabRoot" key={key}>
                  <Box>
                    <UserAvatar
                      src={item?.user?.profile_picture}
                      name={item?.user?.display_name}
                      onClick={() =>
                        history.push("/user-profile/" + item?.user?.slug + "/0")
                      }
                    />
                    <Box>
                      <Body1 medium ellipsis className="groupRequestMsgText">
                        {isMyRequest ? (
                          "You"
                        ) : (
                          <span
                            className="link"
                            onClick={() =>
                              history.push(
                                "/user-profile/" + item?.user?.slug + "/0"
                              )
                            }
                          >
                            {item?.user?.display_name}
                          </span>
                        )}{" "}
                        {isMyRequest
                          ? "have sent join request to"
                          : "want to join your"}{" "}
                        <span
                          className="link"
                          onClick={() =>
                            history.push("/group/" + item?.group?.slug)
                          }
                        >
                          {item?.group?.name}{" "}
                        </span>
                        group.
                      </Body1>
                      <Body2>
                        {moment(item?.createdAt).format("DD MMMM YYYY")}
                      </Body2>
                    </Box>
                  </Box>
                  <Box className="center">
                    <GroupActionButtons
                      type={"groupRequests"}
                      groupData={{
                        ...item?.group,
                        req_id: item?.id,
                        user: item?.user,
                      }}
                      {...props}
                    />
                  </Box>
                </Box>
              );
            })
          )}
          {groupJoiningRequestsData?.data?.offsetStatus && (
            <Grid
              innerRef={setGJRTarget}
              container
              className="groupDetailCardRoot groupRequestRootDiv"
            >
              <GroupRequestSkeleton />
            </Grid>
          )}
        </Grid>
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  getAllGroupsData: state.GroupData.getAllGroupsData,
  getGroupSuggestionsData: state.GroupData.getGroupSuggestionsData,
  userData: state.UserData.getUserData,
  groupJoiningRequestsData: state.GroupData.groupJoiningRequestsData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllGroups: (data) =>
    dispatch({
      type: GET_ALL_GROUPS_START,
      data,
    }),
  getGroupSuggestions: (offset) =>
    dispatch({
      type: GET_GROUP_SUGGESTIONS_START,
      offset,
    }),
  getGroupJoiningRequests: (data) =>
    dispatch({
      type: GET_GROUP_JOINING_REQUESTS_START,
      data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupListing);
