import { Divider, Container, Grid, CssBaseline, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { RESET_TOEKN_VARIFY_ERROR, SIGNIN_START } from "../actions/AuthAction";
import { connect } from "react-redux";
import { ReactComponent as BlackLogo } from "../assets/blackLogo.svg";
import utils from "../utils/commonUtils";
import app from "../config/firebase";
import { getAuth, deleteUser, signInWithPopup } from "firebase/auth";
import CF from "../utils/CommonFunction";
import "../scss/Auth.scss";
import toast from "./common/Snackbars";
import LabelTypography from "./Typography/LabelTypography";
const SimpleButton = React.lazy(() =>
  import("../components/Buttons/SimpleButton")
);
const PlainTextfield = React.lazy(() => import("./Textfields/PlainTextfield"));
const H2 = React.lazy(() => import("./Headings/H2"));
const Body1 = React.lazy(() => import("./Typography/Body1"));
const SocialIcons = React.lazy(() => import("./common/SocialIcon"));
const auth = getAuth();

const useStyles = makeStyles((theme) => ({
  WkUserResetTokenValidation: {
    fontSize: "0.75rem",
  }
}))

let signinFlag = false;
function SignIn(props) {
  const { signinData, userSigning, resetTokenVarifyData, userResetToken } = props;
  const history = useHistory();
  const classes = useStyles();

  const [signinSocialType, setSigninSocialType] = useState("normal")
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [signInValidation, setSignInValidation] = useState("")
  const [userResetPwdValidation, setUserResetPwdValidation] = useState("")

  const handleSubmit = (e) => {
    e.preventDefault();
    userSigning({ username, password, social_type: "normal" });
    signinFlag = true
  };

  //State for random image
  const [randomImage, setRanddomImage] = useState({
    imageSrc: "",
    imageText: "",
  });

  const handleSocialSignin = (type, provider) => {
    setSigninSocialType(type)
    signInWithPopup(auth, provider)
      .then((result) => {
        localStorage.setItem("email", result?.user?.email);
        userSigning({
          email: result?.user?.email,
          platform_id: result?.user?.providerData[0]?.uid,
          social_type: type,
        });
        signinFlag = true
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  //UseEffect for setstate value in random image
  useEffect(() => {
    const { imageSrc, imageText } =
      utils.authDynamicImage[CF.randomNumberWithRange(0, 2)];
    setRanddomImage({
      imageSrc: imageSrc,
      imageText: imageText,
    });
  }, []);

  useEffect(() => {
    if (signinData?.userInfo?.status === "1" && signinFlag) {
      if (signinSocialType !== "normal") {
        const user = auth.currentUser;
        if (user?.email === localStorage.getItem("email")) {
          deleteUser(user)
            .then(() => { })
            .catch((error) => {
              console.log(error);
            });
        }
        localStorage.removeItem("email");
      }
      window.location.reload();
      setUsername("")
      setPassword("")
      signinFlag = false
    } else {
      setSignInValidation(signinData.errorMsg)
      setUserResetPwdValidation(resetTokenVarifyData.errorMsg)
    }
    if (signinData?.userInfo?.status === "1") {
      document.location.href = "/home";
    }
  }, [signinData]);

  function createMarkup(text) {
    return { __html: text };
  }

  const formComponent = () => {
    return (
      <form onSubmit={handleSubmit} autoComplete="off">
        <PlainTextfield
          placeholder="Email"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          type="email"
          id="email"
        />
        <PlainTextfield
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          id="password"
          error
          helperText={
            signInValidation !== "" &&
            "Invalid credentials,Please verify your login information and try again."}
        />
        <Body1
          children="Forgot Password?"
          onClick={() => history.push("/forgot-password")}
          light
        />
        <SimpleButton
          children="Submit"
          type="submit"
          primaryColorBtn
          disabled={signinData?.loading}
          loading={signinData?.loading}
          authBtn
          className="SubmitBtns"
        />
      </form>
    );
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className="Container">
        <CssBaseline />
        <div className="MainContainer">
          <div className="DivContainer">
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              className="MainGridContainer"
            >
              <Grid item xs={12} sm={12} md={5}>
                <div className="AuthLeftSideDiv">
                  <div className="WkleapLogo">
                    <BlackLogo />
                  </div>
                  <div className="AuthMainDiv">
                    <div className="AuthFormRoot">
                      <div>
                        {userResetPwdValidation ?
                          <LabelTypography
                            className={classes.WkUserResetTokenValidation}
                            has_error={(userResetPwdValidation !== null) ? "The link is already used, please try again" : ""}
                            helperText={userResetPwdValidation}
                          >
                            {(userResetPwdValidation !== null) ? "The link is already used, please try again" : ""}
                          </LabelTypography> : ""
                        }
                        <H2 children="Log In" className="HTag" medium />
                      </div>
                      {formComponent()}
                      <div className="DividerContainer">
                        <Divider className="DividerLine" />
                        <Body1 children="Or" light />
                        <Divider className="DividerLine" />
                      </div>
                      <SocialIcons handleSocialSignin={handleSocialSignin} />
                      <SimpleButton
                        children="Sign Up"
                        psTransparentBorderBtn
                        className="AuthBtns"
                        onClick={() => history.push("/signup")}
                        authBtn
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="RightSideImageContainer">
                  <div>
                    <img
                      src={randomImage.imageSrc}
                      alt=""
                      className="AuthImage"
                    />
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        randomImage.imageText
                      )}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  signinData: state.AuthData.signinData,
  resetTokenVarifyData: state.AuthData.resetTokenVarifyData,
});

const mapDispatchToProps = (dispatch) => ({
  userSigning: (signinData) =>
    dispatch({
      type: SIGNIN_START,
      signinData,
    }),
  userResetToken: (resetTokenVarifyData) =>
    dispatch({
      type: RESET_TOEKN_VARIFY_ERROR,
      resetTokenVarifyData,
    })
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
