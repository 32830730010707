import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from "@material-ui/core";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { ImageUploadCommon } from './BlogCommonComponent';
import SimpleButton from "../Buttons/SimpleButton"

const useStyle = makeStyles((theme) => ({
    DropzoneContainer: {
        "& > div": {
            minWidth: "560px",
            minHeight: "340px",
            maxWidth: "100%",
            "& > div": {
                marginTop: "14%"
            },
            [theme.breakpoints.down("sm")]: {
                minWidth: "400px",
                minHeight: "300px",
            },
            [theme.breakpoints.down("xs")]: {
                minWidth: "60vw",
                "& > div": {
                    marginTop: "18%"
                },
            },
        }
    },
}));


const ImageSelectDialog = ({ open, onClose, onCrop }) => {
    const classes = useStyle();
    const [selectedImage, setSelectedImage] = useState(null);
    const [storeUserProfile, setStoreUserProfile] = useState("")
    const [cropper, setCropper] = useState();

    const handleClose = () => {
        setSelectedImage(null);
        onClose();
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            let croppedCanvas = cropper
                .getCroppedCanvas()
            croppedCanvas.toBlob((blob) => {
                const file = new File([blob], storeUserProfile.name, { type: storeUserProfile.type });
                onCrop(file)
                handleClose()
            });
        }
    };

    const handleImageSelect = (event) => {
        if (event?.target?.files) {
            const file = event.target.files[0];
            const reader = new FileReader();
            setStoreUserProfile(file)
            reader.onload = (e) => {
                setSelectedImage(e.target.result);
            };

            if (file) {
                reader.readAsDataURL(file);
            }
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Select and Crop Image</DialogTitle>
            <DialogContent >
                {!selectedImage &&
                    <div className={classes.DropzoneContainer}>
                        <ImageUploadCommon
                            handleOnchange={(files) => handleImageSelect({ target: { files } })}
                            selectedFeaturedImage={[]}
                            onClickDeleteImage={() => { }}
                            dropZoneText="Drop image here"
                        />
                    </div>}

                {selectedImage && (
                    <Cropper
                        dragMode={"move"}
                        highlight={false}
                        initialAspectRatio={1}
                        aspectRatio={1}
                        preview=".img-preview"
                        src={selectedImage}
                        viewMode={1}
                        guides={false}
                        responsive={true}
                        scalable={true}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                            setCropper(instance);
                        }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <SimpleButton transparentBorderBtn onClick={handleClose} color="primary">
                    Cancel
                </SimpleButton>
                {selectedImage &&
                    <SimpleButton
                        // loading={updateCommentAndReplyData?.loading}
                        primaryColorBtn
                        onClick={getCropData}
                    >
                        Crop
                    </SimpleButton>}
            </DialogActions>
        </Dialog>
    );
};


export default ImageSelectDialog