import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import Linkify from "react-linkify";
import { makeStyles, Box } from "@material-ui/core";
import DefaultLinkPreview from "./DefaultLinkPreview";
import { LinkPreviewSkeleton } from "./Skeletons";
import { connect } from "react-redux";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  message: {
    fontWeight: theme.palette.fontweight.light400,
    fontSize: 12,
    lineHeight: "18px",
    fontFamily: theme.palette.font.boldText.fontFamily,
    color: theme.palette.text.medium,
    "&>span>span>p": {
      margin: 0,
      padding: 0,
    },
  },
  showMoreAnchorText: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  links: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: "bold",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkPreview: {
    "&>div:nth-child(2)": {
      "&>h3": {
        color: "#333 !important",
        lineHeight: "27px",
        fontWeight: 500,
        textTransform: "capitalize",
      },
      "&>span,&>div>span": {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
      },
      "&>h3,&>span,&>div>span": {
        fontFamily: "Poppins",
      },
    },
  },
}));

const nl2br = require("nl2br");

function HtmlMessage({ text, isHtml = false, showLinkPreview = false }) {
  const classes = useStyle();
  const [link, setLink] = useState(null);
  const checkWrappedTag = ["<ul>", "<ol>"];
  let [showMore, setShowMore] = useState("");
  let handleChange = () => {
    setShowMore(!showMore);
  };

  let feedMsg = text;
  let htmlInfo = manipulateHtml(feedMsg);
  let html = htmlInfo.html;

  useEffect(() => {
    setShowMore(htmlInfo.displaySeeMore);
  }, [text]);

  function manipulateHtml(html) {
    let splitHtml = html.split("\n");
    let splitHtmlLength = splitHtml.length;
    let firstIndex = splitHtml[0];
    let lastIndex = splitHtml.length - 1;
    let lineCounter = 0;
    let finalHtml = "";
    let bindFirstAndLastIndex = checkWrappedTag.indexOf(firstIndex) !== -1;
    if (bindFirstAndLastIndex) {
      finalHtml = finalHtml + splitHtml[0]; //start tag
      if (splitHtml[1] !== "undefined") {
        finalHtml = finalHtml + splitHtml[1];
        lineCounter++;
      }
      if (splitHtml[2] !== "undefined" && splitHtmlLength > 2) {
        finalHtml = finalHtml + splitHtml[2];
        lineCounter++;
      }
      if (splitHtml[3] !== "undefined" && splitHtmlLength > 3) {
        finalHtml = finalHtml + splitHtml[3];
        lineCounter++;
      }
      //Only for see more
      if (splitHtml[4] !== "undefined") {
        lineCounter++;
      }
      finalHtml = finalHtml + splitHtml[splitHtmlLength > 3 ? lastIndex : 3]; //end tag
    } else {
      let htmlInfo = concatHtml(splitHtml);
      finalHtml += htmlInfo.html;
      lineCounter = htmlInfo.lineCounter;
    }
    return {
      html: finalHtml,
      displaySeeMore: lineCounter > 3,
    };
  }

  function concatHtml(arrayOfHtml) {
    let html = "";
    let lineCounter = 0;
    for (var i = 0; i < arrayOfHtml.length; i++) {
      lineCounter++;
      if (i === 3) {
        break;
      } else {
        html += arrayOfHtml[i];
      }
    }
    return {
      html,
      lineCounter,
    };
  }

  return (
    <Box>
      <div className={classNames(classes.message)}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => {
            setLink(decoratedHref);
            return (
              <a
                key={key}
                href={decoratedHref}
                alt="Link"
                rel="noreferrer"
                target={"_blank"}
                className={classes.links}
              >
                {decoratedText}
              </a>
            );
          }}
          id="hello1"
        >
          {showMore
            ? isHtml
              ? ReactHtmlParser(html)
              : ReactHtmlParser(nl2br(html))
            : isHtml
            ? ReactHtmlParser(feedMsg)
            : ReactHtmlParser(nl2br(feedMsg))}
        </Linkify>
        {/* {!link && showMore && (
          <span className={classes.showMoreAnchorText} onClick={handleChange}>
            show more
          </span>
        )} */}
        {showMore && (
          <span className={classes.showMoreAnchorText} onClick={handleChange}>
            show more
          </span>
        )}
      </div>
      {showLinkPreview ? (
        link ? (
          <Box sx={{ marginTop: "10px" }}>
            <LinkPreview
              url={link}
              target="_blank"
              rel="noreferrer"
              href={link}
              customLoader={<LinkPreviewSkeleton />}
              fallback={<DefaultLinkPreview link={link} text={link} />}
              className={classes.linkPreview}
            >
              {link}
            </LinkPreview>
          </Box>
        ) : null
      ) : null}
    </Box>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HtmlMessage);
