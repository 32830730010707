import React, { useEffect, useState, lazy } from "react";
import { connect } from "react-redux";
import { Badge, Grid, FormHelperText } from "@material-ui/core";
import {
  DialogBody,
  DialogComponent,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";
import { UPDATE_FEED_START } from "../../actions/FeedAction";
import { ReactComponent as EarthIcon } from "../../assets/EarthIcon.svg";
import { ReactComponent as Group } from "../../assets/Group.svg";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import toast from "../common/Snackbars";
import utils from "../../utils/commonUtils";
import CloseIcon from "@mui/icons-material/Close";
const SelectComponent = lazy(() => import("../input/SelectComponent"));
const SimpleButton = lazy(() => import("../Buttons/SimpleButton"));
const DraftEditor = lazy(() => import("../common/DraftEditor"));
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));

export const EditFeedDialog = ({
  open,
  setOpen,
  updateFeedData,
  updateFeed,
  feedData,
}) => {
  const [feedPrivacy, setFeedPrivacy] = useState(1);
  const [selectBoxOpen, setSelectBoxOpen] = useState(false);
  const [message, setMessage] = useState(EditorState.createEmpty());
  const [oldFeedFiles, setOldFeedFiles] = useState([]);
  const [feedFile, setFeedFile] = useState([]);
  const [deletedFeedFileUrls, setDeletedFeedFileUrls] = useState([]);
  const [formOpen, setFormOpen] = useState(false)
  const [fileSizeValidation, setFileSizeValidation] = useState(false)

  const handleClose = () => {
    setFeedFile([]);
    setOpen(false);
    setMessage(EditorState.createEmpty());
    setFeedPrivacy(0);
    setOldFeedFiles([]);
    setDeletedFeedFileUrls([]);
    setFormOpen(false)
  };

  const handleDeleteOldImage = (i) => {
    setDeletedFeedFileUrls((pre) => [...pre, oldFeedFiles.at(i)]);
    setOldFeedFiles((pre) => pre?.filter((item, index) => index !== i));
  };

  const handleUpdate = () => {
    const contentState = message?.getCurrentContent();
    let messageIsEmpty = false;
    if (contentState?.hasText()) {
      messageIsEmpty = true;
    } else {
      messageIsEmpty = false;
    }
    const messageHtml = stateToHTML(contentState);
    if (!messageIsEmpty && oldFeedFiles.length === 0 && feedFile.length === 0) {
      return;
    }
    let formData = new FormData();
    if (messageIsEmpty) {
      formData.append("message", messageHtml?.trim());
    }
    formData.append(
      "feed_privacy",
      feedData?.feed_type_id === 7 ? 1 : feedPrivacy
    );
    formData.append("deletedFeedFileUrls", JSON.stringify(deletedFeedFileUrls));
    if (feedData?.feed_type_id === 7) {
      formData.append("group_id", feedData?.group_id);
    }
    if (feedFile.length !== 0) {
      for (let item of feedFile) {
        formData.append("feedFile", item);
      }
    }
    updateFeed(utils.toBase64(feedData?.id), formData);
  };

  const handleFeedFileUpload = (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let totalFileSize = 0;
      for (let file of [...feedFile, ...files]) {
        totalFileSize += file.size;
      }
      if (totalFileSize > 40 * 1024 * 1024) {
        setFileSizeValidation(true)
        setTimeout(() => {
          setFileSizeValidation(false)
        }, 4000)
        return;
      }
      setFeedFile((pre) => [...pre, ...files]);
    }
  };

  useEffect(() => {
    if (updateFeedData?.feedData?.status === "1") {
      handleClose();
    }
  }, [updateFeedData]);

  useEffect(() => {
    if (open) {
      try {
        if (feedData?.feed_file !== null) {
          setOldFeedFiles(JSON.parse(feedData?.feed_file));
        }
      } catch (error) { }
      if (feedData?.message) {
        setMessage(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(
              convertFromHTML(feedData?.message)
            )
          )
        );
      } else {
        setMessage(EditorState.createEmpty());
      }
      setFeedPrivacy(feedData?.feed_privacy);
      setFeedFile([]);
      setDeletedFeedFileUrls([]);
    }
  }, [open]);

  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>Edit Feed</DialogHeader>
      <DialogBody>
        <Grid>
          <LabelTypography>Feed Text</LabelTypography>
          <DraftEditor
            placeholder="Feed message..."
            editorState={message}
            setEditorState={setMessage}
            location="dialog"
            onClick={() => setFormOpen(true)}
            formOpen={formOpen}
          />
        </Grid>
        <Grid container className="uploadFeedCenterDiv">
          {oldFeedFiles?.map((item, key) => {
            return utils.imageExtensions.includes(
              item.split(".").pop().toLowerCase()
            ) ? (
              <Badge
                key={key}
                badgeContent={<CloseIcon onClick={() => handleDeleteOldImage(key)} />}
                className="uploadFeedMediaPreviewDiv"
              >
                <img alt="Feeds Media" src={item} />
              </Badge>
            ) : (
              <Badge
                key={key}
                badgeContent={<CloseIcon onClick={() => handleDeleteOldImage(key)} />}
                className="uploadFeedMediaPreviewDiv"
              >
                <video controls autoPlay={false} src={item}></video>
              </Badge>
            );
          })}
          {feedFile?.map((file, index) => {
            return (
              <Badge
                badgeContent={
                  <CloseIcon
                    onClick={() =>
                      setFeedFile((pre) => pre?.filter((preItem, i) => i !== index))
                    }
                  />}
                key={index}
                className="uploadFeedMediaPreviewDiv"
              >
                {file.type.split("/")[0] === "image" ? (
                  <img src={URL.createObjectURL(file)} alt="feed" />
                ) : (
                  <video controls>
                    <source src={URL.createObjectURL(file)} type={file.type} />
                  </video>
                )}
              </Badge>
            );
          })}
        </Grid>
      </DialogBody>
      <DialogFooter>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid className="center">
            <label htmlFor="feed-update-image/video">
              <AddPhotoAlternateIcon className="tshSvg" />
            </label>
          </Grid>
          <Grid>
            {fileSizeValidation && <FormHelperText style={{ width: "100%", color: "red" }}>You cannot upload files more then 40 MB.</FormHelperText>}
          </Grid>
          <Grid className="editFeedDialogFooterRightSide">
            {feedData?.feed_type_id !== 7 && (
              <SelectComponent
                value={feedPrivacy}
                onChange={(e) => {
                  setFeedPrivacy(e.target.value);
                }}
                open={selectBoxOpen}
                setOpen={setSelectBoxOpen}
                menuItems={[
                  { value: 1, icon: <EarthIcon />, label: "Anyone" },
                  { value: 2, icon: <Group />, label: "My Connections" },
                ]}
                feedPrivacySelectBox
              />
            )}
            <SimpleButton
              onClick={handleUpdate}
              children="Update"
              primaryColorBtn
              loading={updateFeedData?.loading}
              disabled={!message?.getCurrentContent().hasText() && oldFeedFiles.length === 0 && feedFile.length === 0}
            />
          </Grid>
        </Grid>
      </DialogFooter>
      <input
        accept="image/*,video/*"
        className="display-none"
        id="feed-update-image/video"
        multiple
        type="file"
        onChange={handleFeedFileUpload}
        onClick={(e) => (e.target.value = null)}
      />
    </DialogComponent>
  );
};

const mapStateToProps = (state) => ({
  updateFeedData: state.FeedData.updateFeedData,
});

const mapDispatchToProps = (dispatch) => ({
  updateFeed: (feed_id, feedData) =>
    dispatch({
      type: UPDATE_FEED_START,
      feed_id,
      feedData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditFeedDialog);
