import React, { lazy, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import {
  DialogBody,
  DialogComponent,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";
import "../../scss/Dialogs.scss";
import utils from "../../utils/commonUtils";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { UPDATE_CHAT_GROUP_START } from "../../actions/ChatAction";
const SimpleButton = lazy(() => import("../Buttons/SimpleButton"));
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));
const EndIcon = lazy(() => import("../Textfields/EndIcon"));
const EditMediaIcon = lazy(() => import("../common/EditMediaIcon"));

let updateChatGroupFlag = false;

export const EditChatGroupDialog = ({
  open,
  handleClose,
  updateChatGroup,
  isUpdate = false,
  data,
  updateChatGroupData,
}) => {
  const [chatGroupIcon, setChatGroupIcon] = useState(null);
  const [oldchatGroupIcon, setOldIconchatGroupIcon] = useState(null);
  const [chatGroupName, setChatGroupName] = useState("");

  const handleSubmit = (e) => {
    if (isUpdate) {
      let formData = new FormData();
      formData.append("name", chatGroupName);
      formData.append("chatroom_profile_image", chatGroupIcon);
      updateChatGroup(utils.toBase64(data?.id), formData);
      updateChatGroupFlag = true;
    }
  };

  useEffect(() => {
    if (updateChatGroupData?.data?.status === "1" && updateChatGroupFlag) {
      updateChatGroupFlag = true;
      handleClose();
    }
  }, [updateChatGroupData]);

  useEffect(() => {
    if (open && isUpdate) {
      setOldIconchatGroupIcon(data?.profile_picture);
      setChatGroupName(data?.name);
    }
  }, [open]);

  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>Update Chat Group</DialogHeader>
      <DialogBody className="dialogHeader">
        <Box className="coegDialogRoot">
          <Box className="coegdBgImageDiv">
            <Box className="coeGroupIconDiv center">
              <Box className="coeGroupIcon center">
                {isUpdate && oldchatGroupIcon !== null ? (
                  <img alt="Group Icon" src={oldchatGroupIcon} />
                ) : chatGroupIcon !== null ? (
                  <img
                    alt="Group Icon"
                    src={URL.createObjectURL(chatGroupIcon)}
                  />
                ) : (
                  <AddPhotoAlternateOutlinedIcon />
                )}
                <EditMediaIcon
                  onChange={(e) => {
                    if (
                      e.target.files !== undefined &&
                      e.target.files.length > 0
                    ) {
                      const file = e.target.files[0];
                      if (file.type.split("/")[0] === "image") {
                        setChatGroupIcon(file);
                        if (isUpdate) {
                          setOldIconchatGroupIcon(null);
                        }
                      }
                    }
                  }}
                  inputId="upload-group-icon"
                  accept="image/*"
                />
              </Box>
            </Box>
          </Box>
          <Box className="coeFormDiv">
            <Box className="manageMB20">
              <LabelTypography children="Name" required />
              <EndIcon
                value={chatGroupName}
                onChange={(e) => setChatGroupName(e.target.value)}
                newDesign={true}
                className="EndIconTitle"
              />
            </Box>
          </Box>
        </Box>
      </DialogBody>
      <DialogFooter className="dialogFooter">
        <SimpleButton
          primaryColorBtn
          onClick={handleSubmit}
          loading={updateChatGroupData?.loading}
        >
          Update
        </SimpleButton>
      </DialogFooter>
    </DialogComponent>
  );
};

const mapStateToProps = (state) => ({
  updateChatGroupData: state.ChatData.updateChatGroupData,
});

const mapDispatchToProps = (dispatch) => ({
  updateChatGroup: (room_id, chatGroupData) =>
    dispatch({
      type: UPDATE_CHAT_GROUP_START,
      room_id,
      chatGroupData,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditChatGroupDialog);
