import { Box } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import utils from "../../utils/commonUtils";
import { DELETE_WORK_HISTORY_START } from "../../actions/UserAction";
import { EduAndWorkDetailsSkeleton } from "../common/Skeletons";
import { ZeroStateEducationAndWorkDetails } from "../common/ZeroStateComponents";
import UserDetailCard from "../StepperComponents/UserDetailCard";
import LeftRightComponent from "../common/LeftRightComponent";

function WorkDetails({
  setWorkUpdateId,
  deleteUserWorkHistory,
  myProfile,
  currentUser,
  loading,
  updateActiveEditProfileFormIndex,
  updateEditProfileStatus,
  userData,
}) {
  return currentUser?.userInfo?.data?.user_work_histories?.length !== 0 ||
    myProfile ||
    loading ? (
    <LeftRightComponent heading={"Employment"}>
      <Box className="manageMTAndDivLCMB">
        {loading ? (
          <EduAndWorkDetailsSkeleton />
        ) : currentUser?.userInfo?.data?.user_work_histories?.length !== 0 ? (
          currentUser?.userInfo?.data?.user_work_histories?.map((item, key) => {
            return (
              <UserDetailCard
                key={key}
                type={"workDetails"}
                userData={userData}
                currentUser={currentUser}
                data={{
                  heading: item?.org_name,
                  date: `${item?.from_date} - ${
                    item?.to_date === new Date().getFullYear().toString()
                      ? "Present"
                      : item?.to_date
                  }`,
                  message: item?.message,
                }}
                onEdit={() => {
                  setWorkUpdateId(item.id);
                  updateEditProfileStatus(true);
                  updateActiveEditProfileFormIndex(2);
                }}
                onDelete={() => deleteUserWorkHistory(utils.toBase64(item?.id))}
              />
            );
          })
        ) : (
          <ZeroStateEducationAndWorkDetails
            handleOnClick={() => {
              updateEditProfileStatus(true);
              updateActiveEditProfileFormIndex(2);
            }}
            type="work"
          />
        )}
      </Box>
    </LeftRightComponent>
  ) : (
    ""
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  deleteUserWorkHistory: (id) =>
    dispatch({
      type: DELETE_WORK_HISTORY_START,
      id,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkDetails);
