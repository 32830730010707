import React, { lazy, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import "../../scss/Dialogs.scss";
import {
  DialogComponent,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";
import { UserSkeleton } from "../common/Skeletons";
import { connect } from "react-redux";
import {
  DELETE_GROUP_MEMBERS_START,
  GET_GROUP_MEMBERS_START,
} from "../../actions/GroupAction";
import { useHistory } from "react-router-dom";
import DisplayName from "../common/DisplayName";
const UserRoles = lazy(() => import("../common/UserRoles"));
const H6 = lazy(() => import("../Headings/H6"));
const UserAvatar = lazy(() => import("../common/UserAvatar"));
const CheckBoxComponent = lazy(() => import("../common/CheckBoxComponent"));
const AlertDialog = lazy(() => import("./AlertDialog"));
const SimpleButton = lazy(() => import("../Buttons/SimpleButton"));

function ViewAndEditGroupMembersDialog({
  open,
  handleClose,
  group,
  userData,
  getGroupMembers,
  getGroupMembersData,
  deleteGroupMembersData,
  deleteGroupMembers,
}) {
  const history = useHistory();
  const [selectedGroupMembers, setSelectedGroupMembers] = useState([]);
  const [members, setMembers] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const handleAlertDialogClose = (value) => {
    if (value) {
      deleteGroupMembers({
        slug: group?.slug,
        user_ids: selectedGroupMembers,
      });
    }
    setOpenAlertDialog(false);
  };

  const handleSelectAllCheckBoxClick = (e) => {
    if (e.target.checked) {
      setSelectAll(true);
      setSelectedGroupMembers(members?.map((item) => item?.id));
    } else {
      setSelectAll(false);
      setSelectedGroupMembers([]);
    }
  };

  const handleCheckBoxClick = (e, id) => {
    if (e.target.checked) {
      let arr = [...selectedGroupMembers];
      arr.push(id);
      setSelectedGroupMembers(arr);
      if (arr.length === members?.length - 1) {
        setSelectAll(true);
      }
    } else {
      setSelectedGroupMembers((pre) => pre?.filter((userId) => userId !== id));
      if (selectAll) {
        setSelectAll(false);
      }
    }
  };

  useEffect(() => {
    if (getGroupMembersData?.data?.status === "1") {
      setMembers(
        getGroupMembersData?.data?.data?.group_users?.map((user) => user?.user)
      );
    }
  }, [getGroupMembersData]);

  useEffect(() => {
    if (open && group?.slug) {
      getGroupMembers(group?.slug);
    }
  }, [open]);

  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>Group Members</DialogHeader>
      <DialogBody>
        <Box>
          {getGroupMembersData?.loading ? (
            <UserSkeleton num={10} />
          ) : (
            <>
              {group?.admin_id === userData?.userInfo?.data?.id &&
                members?.length > 1 ? (
                <Grid container justifyContent="flex-end">
                  <CheckBoxComponent
                    label="Select All"
                    labelPlacement={"start"}
                    checked={selectAll}
                    onChange={handleSelectAllCheckBoxClick}
                  />
                </Grid>
              ) : null}
              {members?.map((user, key) => {
                return (
                  <Box className="ldrabRoot" key={key}>
                    <Box>
                      <UserAvatar
                        src={user?.profile_picture}
                        name={user?.display_name}
                        onClick={() =>
                          history.push(`/user-profile/${user?.slug}/0`)
                        }
                      />
                      <Box>
                        <H6
                          medium
                          ellipsis
                          onClick={() =>
                            history.push(`/user-profile/${user?.slug}/0`)
                          }
                          title={user?.display_name}
                        >
                          <DisplayName user={user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                        </H6>
                        <UserRoles
                          align="left"
                          roles={user?.user_performing_roles}
                        />
                      </Box>
                    </Box>
                    {group?.admin_id === userData?.userInfo?.data?.id &&
                      group?.admin_id !== user?.id ? (
                      <Box>
                        <CheckBoxComponent
                          checked={selectedGroupMembers.includes(user?.id)}
                          onChange={(e) => handleCheckBoxClick(e, user?.id)}
                        />
                      </Box>
                    ) : null}
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </DialogBody>
      {group?.admin_id === userData?.userInfo?.data?.id ? (
        <DialogFooter className="dialogFooter">
          <SimpleButton
            primaryColorBtn
            onClick={() => setOpenAlertDialog(true)}
            loading={deleteGroupMembersData?.loading}
            disabled={selectedGroupMembers?.length === 0}
          >
            Remove
          </SimpleButton>
        </DialogFooter>
      ) : null}
      <AlertDialog
        open={openAlertDialog}
        dialogTitle="Remove Group Members."
        dialogContent={
          "Are you sure you want to remove selected group members from this group?"
        }
        successBtnText={"Remove"}
        handleClose={handleAlertDialogClose}
      />
    </DialogComponent>
  );
}

const mapStateToProps = (state) => ({
  deleteGroupMembersData: state.GroupData.deleteGroupMembersData,
  getGroupMembersData: state.GroupData.getGroupMembersData,
});

const mapDispatchToProps = (dispatch) => ({
  deleteGroupMembers: (data) =>
    dispatch({
      type: DELETE_GROUP_MEMBERS_START,
      data,
    }),
  getGroupMembers: (slug) =>
    dispatch({
      type: GET_GROUP_MEMBERS_START,
      slug,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAndEditGroupMembersDialog);
