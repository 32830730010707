import React from "react";
import Carousel from "react-material-ui-carousel";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  carouselRoot: {
    "& > div:nth-child(1)": {
      "& > div": {
        "& > div": {
          "& > div": {
            "& > div": {
              "& img": {
                objectFit: "contain",
                width: "-webkit-fill-available",
                borderRadius: 10,
                cursor: "pointer",
                maxHeight: 630,
              },
              "& video": {
                objectFit: "contain",
                width: "-webkit-fill-available",
                borderRadius: 10,
                cursor: "pointer",
                maxHeight: 630,
              },
            },
          },
        },
      },
    },
    "& > div:nth-child(2)": {
        width: "20%",
        height: "calc(100% - 20px)",
        "& button": {
          width: 36,
          height: 36,
          borderRadius: 36,
          marginLeft: "auto",
          marginRight: "8px",
          display: "flex",
          "& svg": {
            "& path": {
              fill: "#FFFFFF",
            },
          },
        },
      },
      "& > div:nth-child(3)": {
        width: "20%",
        height: "calc(100% - 20px)",
        "& button": {
          width: 36,
          height: 36,
          borderRadius: 36,
          marginRight: "auto",
          marginLeft: "8px",
          display: "flex",
          "& svg": {
            "& path": {
              fill: "#FFFFFF",
            },
          },
        },
      },
    },
  indicators : {
    "& svg" : {
      width : "0.75em",
      height : "0.75em",
      color :  theme.palette.common.lightgray
    }
  },
  activeIndicator : {
    "& svg" : {
      color :  theme.palette.common.darkgray
    }
  }
}));

const defaultFunction = () => {};

export default function CarouselComponent({
  children,
  className,
  onClick = defaultFunction,
  onChange = defaultFunction,
  autoPlay = true,
  stopAutoPlayOnHover = true,
  interval = 4000,
  animation,
  reverseEdgeAnimationDirection = true,
  timeout = 0,
  swipe = false,
  indicators = false,
  navButtonsAlwaysVisible = false,
  navButtonsAlwaysInvisible = false,
  cycleNavigation = true,
  fullHeightHover = true,
  NextIcon,
  PrevIcon,
  indicatorContainerProps,
  activeIndicatorIconButtonProps,
  next = defaultFunction,
  prev = defaultFunction,
  index,
  ...props
}) {
  const classes = useStyle();
  return (
    <Carousel
      onClick={onClick}
      className={classNames(className, classes.carouselRoot)}
      autoPlay={autoPlay}
      next={next}
      prev={prev}
      indicatorContainerProps={indicatorContainerProps ?? {
        className : classes.indicators
      }}
      activeIndicatorIconButtonProps={activeIndicatorIconButtonProps ?? {
        className : classes.activeIndicator
      }}
      stopAutoPlayOnHover={stopAutoPlayOnHover}
      interval={interval}
      animation={animation}
      reverseEdgeAnimationDirection={reverseEdgeAnimationDirection}
      timeout={timeout}
      swipe={swipe}
      indicators={indicators}
      navButtonsAlwaysVisible={navButtonsAlwaysVisible}
      navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}
      cycleNavigation={cycleNavigation}
      fullHeightHover={fullHeightHover}
      NextIcon={NextIcon}
      PrevIcon={PrevIcon}
      onChange={onChange}
      index={index}
      {...props}
    >
      {children}
    </Carousel>
  );
}
