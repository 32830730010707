import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGOUT_ERROR,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  RESEND_VERIFICATION_EMAIL_ERROR,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  RESEND_VERIFICATION_EMAIL_START,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  RESET_TOEKN_VARIFY_START,
RESET_TOEKN_VARIFY_SUCCESS,
RESET_TOEKN_VARIFY_ERROR,
} from "../actions/AuthAction";

const initialState = {
  signinData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  signupData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  forgotPasswordData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  resetPasswordData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  resetTokenVarifyData: {
    loading: true,
    data: {},
    errorMsg: "",
  },
  logoutData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  verifyEmailData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  resendVerificationEmailData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  changePasswordData: {
    loading: false,
    status: "",
    message: "",
  },
};

const AuthReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case SIGNIN_START: {
      return {
        ...previousState,
        signinData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case SIGNIN_SUCCESS: {
      return {
        ...previousState,
        signinData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case SIGNIN_ERROR: {
      return {
        ...previousState,
        signinData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case SIGNUP_START: {
      return {
        ...previousState,
        signupData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...previousState,
        signupData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case SIGNUP_ERROR: {
      return {
        ...previousState,
        signupData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case FORGOT_PASSWORD_START: {
      return {
        ...previousState,
        forgotPasswordData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...previousState,
        forgotPasswordData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case FORGOT_PASSWORD_ERROR: {
      return {
        ...previousState,
        forgotPasswordData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case RESET_PASSWORD_START: {
      return {
        ...previousState,
        resetPasswordData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...previousState,
        resetPasswordData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case RESET_PASSWORD_ERROR: {
      return {
        ...previousState,
        resetPasswordData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case RESET_TOEKN_VARIFY_START: {
      return {
        ...previousState,
        resetTokenVarifyData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case RESET_TOEKN_VARIFY_SUCCESS: {
      return {
        ...previousState,
        resetTokenVarifyData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case RESET_TOEKN_VARIFY_ERROR: {
      return {
        ...previousState,
        resetTokenVarifyData: {
          loading: false,
          data: action.data,
          errorMsg: action.msg,
        },
      };
    }
    case LOGOUT_START: {
      return {
        ...previousState,
        logoutData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case LOGOUT_SUCCESS: {
      return {
        ...previousState,
        logoutData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case LOGOUT_ERROR: {
      return {
        ...previousState,
        logoutData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case VERIFY_EMAIL_START: {
      return {
        ...previousState,
        verifyEmailData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case VERIFY_EMAIL_SUCCESS: {
      return {
        ...previousState,
        verifyEmailData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case VERIFY_EMAIL_ERROR: {
      return {
        ...previousState,
        verifyEmailData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case RESEND_VERIFICATION_EMAIL_START: {
      return {
        ...previousState,
        resendVerificationEmailData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case RESEND_VERIFICATION_EMAIL_SUCCESS: {
      return {
        ...previousState,
        resendVerificationEmailData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case RESEND_VERIFICATION_EMAIL_ERROR: {
      return {
        ...previousState,
        resendVerificationEmailData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case CHANGE_PASSWORD_START: {
      return {
        ...previousState,
        changePasswordData: {
          loading: true,
          status: "",
          message: "",
        },
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      const { status, message } = action.data;
      return {
        ...previousState,
        changePasswordData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case CHANGE_PASSWORD_ERROR: {
      const { status, message } = action.data;
      return {
        ...previousState,
        changePasswordData: {
          loading: false,
          status,
          message,
        },
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default AuthReducers;
