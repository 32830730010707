import React, { useState, useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import gravatar from "gravatar";
import CompanyDetail from "../components/StepperComponents/CompanyDetail";
import LeftRightComponent from "../components/common/LeftRightComponent";
import SimpleButton from "../components/Buttons/SimpleButton";
import { UpdateCompanyProfileDetails } from "../Apis/UserApis";
import toast from "../components/common/Snackbars";

const useStyles = makeStyles((theme) => ({

}));

let companyDataLoaded = false

export default function EditCompanyDetails({
    userData,
    updateEditProfileStatus,
}) {
    const classes = useStyles();
    const [errors, setErrors] = useState(null);
    const [description, setDesscription] = useState("");
    const [companyCountry, setCompanyContry] = useState("");
    const [companyCity, setCompanyCity] = useState("");
    const [company_picture, setCompany_picture] = useState(null);
    const [company_picture_radio, setCompany_picture_radio] = useState(
        company_picture === null && company_picture === ""
            ? "none"
            : typeof company_picture === "object"
                ? "upload"
                : company_picture?.includes("gravatar")
                    ? "gravatar"
                    : "none"
    );

    const [companyName, setCompanyName] = useState("");
    const [companySize, setCompanySize] = useState("1-10");
    const [companyUpdateLoading, setCompanyUpdateLoading] = useState(false)

    const handleCompanyUpdate = async () => {
        let status = 0
        let error = {};
        setCompanyUpdateLoading(true)
        if (companyCity == "") {
            status = 1
            error["companyCity"] = true
        }

        if (companyCountry == "") {
            status = 1
            error["companyCountry"] = true
        }

        if (companyName == "") {
            status = 1
            error["companyName"] = true
        }

        setErrors(error)
        if (status == 0) {
            const formData = new FormData();
            formData.append("description", description);
            formData.append("country", companyCountry);
            formData.append("city", companyCity);
            formData.append("name", companyName)
            formData.append("size", companySize)
            await UpdateCompanyProfileDetails(formData).then(res => res.status == "0" ? toast.error(res.message) : updateEditProfileStatus(false))
        }
        setCompanyUpdateLoading(false)
    }

    useEffect(() => {
        if (userData?.userInfo?.data?.is_company_account && !companyDataLoaded) {
            if (userData?.userInfo?.data && userData?.userInfo?.data?.company_profile) {
                let { description, city, country, name, size } = userData?.userInfo?.data?.company_profile
                setDesscription(description || "")
                setCompanyCity(city || "")
                setCompanyContry(country || "")
                setCompanyName(name || "")
                setCompanySize(size || "1-10")
                companyDataLoaded = true
            }
        }
    }, [userData])


    return (
        <LeftRightComponent heading={"Company Info"}>
            <Box className={"manageMT15"}>
                <CompanyDetail
                    description={description}
                    setDesscription={setDesscription}
                    companyCountry={companyCountry}
                    setCompanyContry={setCompanyContry}
                    companyCity={companyCity}
                    setCompanyCity={setCompanyCity}
                    company_picture={company_picture}
                    setCompany_picture={setCompany_picture}
                    errors={errors}
                    setErrors={setErrors}
                    userData={userData}
                    company_picture_radio={company_picture_radio}
                    setCompany_picture_radio={setCompany_picture_radio}
                    companyName={companyName}
                    setCompanyName={setCompanyName}
                    companySize={companySize}
                    setCompanySize={setCompanySize}
                    hideImageUpload
                />
                <SimpleButton
                    children="Update"
                    secondaryColorBtn
                    onClick={() => handleCompanyUpdate()}
                    loading={companyUpdateLoading}
                />
                <span style={{ marginLeft: 8 }}>
                    <SimpleButton
                        children="close"
                        transparentBorderBtn
                        onClick={() => updateEditProfileStatus(false)}
                    />
                </span>
            </Box>
        </LeftRightComponent>
    );
}
