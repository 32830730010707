import { makeStyles } from "@material-ui/core";
import React from "react";
import { Body2 } from "../Typography/Typographys";

const useStyle = makeStyles((theme) => ({
  userRolesRoot: {
    width: "100%",
  },
}));

export default function UserRoles({
  roles = [],
  multiple = false,
  align = "center",
}) {
  const classes = useStyle();
  let rolesStr = "";
  roles?.forEach((element, index) => {
    rolesStr +=
      roles?.length - 1 === index ? element?.role : element?.role + ", ";
  });
  return roles?.length > 0 ? (
    <Body2
      className={classes.userRolesRoot}
      ellipsis
      style={{ textAlign: align }}
      title={rolesStr}
    >
      {multiple ? rolesStr : roles[0]?.role}
    </Body2>
  ) : null;
}
