import screenResolutionConstants from "../constants/screenResolution";

function isEmptyObject(objectVal) {
  return (
    Object.keys(objectVal).length === 0 && objectVal.constructor === Object
  ); // 👈 constructor check
}

function showWebScreen(width) {
  width = width || screenWidth();
  return width > screenResolutionConstants.MOBILE_SCREEN_TILL.width;
}

function screenWidth() {
  return window.screen.availWidth;
}

function screenHeight() {
  return window.screen.availHeight;
}

function screenResolution() {
  return {
    width: screenWidth(),
    height: screenHeight(),
  };
}

function setLocalStorage(key, value) {
  if (typeof Storage !== "undefined") {
    let storeValue = value;
    if (value.constructor === Object) {
      storeValue = JSON.stringify(value);
    }
    localStorage.setItem(key, storeValue);
  } else {
    // No web storage Support.
    console.warn("Local storage not supporting");
  }
}

function getLocalStorage(key, isObject) {
  if (!localStorage.hasOwnProperty(key)) {
    //throw Error(`${key} not present in local storage`);
    console.warn(`${key} not present in local storage`);
  }
  let localStorageValue = localStorage.getItem(key);
  return isObject ? JSON.parse(localStorageValue) : localStorageValue;
}

function capitalizeFirstLetter(string) {
  if (string === "" || string === undefined) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function calculatePercentageOfTwoNumber(mainValue, againstValue) {
  return (parseFloat(againstValue) / parseFloat(mainValue)) * 100;
}

function getRangeBasedArray(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}
function isUrl(str) {
  let regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(str);
}

function emptyStringWhenNull(input) {
  return input ? input : "";
}
function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function arrayChunk(input, size) {
  for (var x, i = 0, c = -1, l = input.length, n = []; i < l; i++) {
    (x = i % size) ? (n[c][x] = input[i]) : (n[++c] = [input[i]]);
  }
  return n;
}
function createUUID() {
  // http://www.ietf.org/rfc/rfc4122.txt
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

function extractContent(value) {
  var span = document.createElement("span");
  span.innerHTML = value;
  return span.textContent || span.innerText;
}

function randomNumberWithRange(minimum, maximum) {
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
}

export default {
  isEmptyObject,
  showWebScreen,
  screenWidth,
  screenHeight,
  screenResolution,
  setLocalStorage,
  getLocalStorage,
  capitalizeFirstLetter,
  calculatePercentageOfTwoNumber,
  getRangeBasedArray,
  isUrl,
  emptyStringWhenNull,
  getRandomInteger,
  arrayChunk,
  createUUID,
  extractContent,
  randomNumberWithRange,
};
