import React, { lazy, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { GET_FEED_DETAILS_START } from "../actions/FeedAction";
import utils from "../utils/commonUtils";
import MyConnectionsComponent from "../components/ConnectionComponents/MyConnectionsComponent";
import { GET_ALL_CONNECTIONS_START } from "../actions/ConnectionsAction";
import { ThreeColumnGridView } from "../components/common/GridViews";
import { useParams } from "react-router-dom";
const ConnectionSuggestion = lazy(() =>
  import("../components/ConnectionComponents/ConnectionSuggestion")
);
const Groups = lazy(() =>
  import("../components/HomeComponents/Groups")
);
const ProfileDashboard = lazy(() =>
  import("../components/HomeComponents/ProfileDashboard")
);
const LeftNavbar = lazy(() => import("../components/HomeComponents/LeftNavbar"));
const FeedFooter = lazy(() =>
  import("../components/HomeComponents/FeedFooter")
);
const FeedHeader = lazy(() =>
  import("../components/HomeComponents/FeedHeader")
);
const FeedContent = lazy(() =>
  import("../components/HomeComponents/FeedContent")
);
const MaxWidthComponent = lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = lazy(() => import("../components/common/Body"));

const useStyle = makeStyles((theme) => ({
  mainBodyRoot: {
    padding: "0px auto",
  },
  gridContainer: {
    maxWidth: "1200px",
    margin: "15px auto",
    [theme.breakpoints.down(1200)]: {
      padding: "0 2%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 10px",
      marginTop: "80px",
    },
    "&>div": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "&:nth-child(2)": {
        marginBottom: "15px",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          marginBottom: "70px",
        },
      },
      "&:nth-child(3)": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  },
  mainFeedDiv: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.common.white,
    borderRadius: "10px",
    marginBottom: "15px",
    padding: "20px",
    border: `1px solid ${theme.palette.border.borderColor}`,
  },
}));

let getFeedDetailFlag = false;

export const FeedDetailPageContainer = (props) => {
  const {
    getFeedDetailsAPI,
    getFeedDetailsData,
    userData,
    getAllConnections,
    getAllConnectionsData,
  } = props;
  const classes = useStyle();
  const feedRootDivRef = useRef(null);
  const { feed_id } = useParams();
  const [feed, setFeed] = useState(null);

  useEffect(() => {
    if (getFeedDetailsData?.status === "1") {
      setFeed(getFeedDetailsData?.data);
      if (getFeedDetailFlag) {
        if (feedRootDivRef.current) {
          feedRootDivRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          getFeedDetailFlag = false;
        }
      }
    }
  }, [getFeedDetailsData]);
  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
    }
  }, [userData]);
  useEffect(() => {
    getFeedDetailsAPI({ feed_id: utils.toNormalString(feed_id) });
    getFeedDetailFlag = true;
  }, [feed_id]);
  return (
    <Body>
      <MaxWidthComponent className={classes.gridContainer}>
        <ThreeColumnGridView
          leftSide={
            <>
              <ProfileDashboard
                myProfile={1}
                userData={userData}
                getAllConnectionsData={getAllConnectionsData}
              />
              <LeftNavbar userData={userData} />
            </>
          }
          center={
            <>
              {feed ? (
                <div ref={feedRootDivRef} className={classes.mainFeedDiv}>
                  <FeedHeader feedData={feed} />
                  <FeedContent feedData={feed} />
                  <FeedFooter feedData={feed} location={"feed-detail"} />
                </div>
              ) : (
                <div className="simpleZeroStateMsg">
                  Feed data not available. May be feed was deleted.
                </div>
              )}
            </>
          }
          rightSide={
            <>
              <Groups />
              <ConnectionSuggestion />
              <MyConnectionsComponent
                myProfile={1}
                userData={userData}
                getAllConnectionsData={getAllConnectionsData}
              />
            </>
          }
        />
      </MaxWidthComponent>
    </Body>
  );
};

const mapStateToProps = (state) => ({
  getFeedDetailsData: state.FeedData.getFeedDetailsData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
  userData: state.UserData.getUserData,
});

const mapDispatchToProps = (dispatch) => ({
  getFeedDetailsAPI: (data) =>
    dispatch({
      type: GET_FEED_DETAILS_START,
      data,
    }),
  getAllConnections: (user_id) =>
    dispatch({
      type: GET_ALL_CONNECTIONS_START,
      user_id,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedDetailPageContainer);
