import React, { lazy , useEffect , useState } from "react";
import "../scss/Blog.scss";
import { connect } from "react-redux";
import { TwoColumnGridView } from "../components/common/GridViews";
import { useParams } from "react-router-dom";
import utils from "../utils/commonUtils";
import { GET_PROFILE_USER_DATA_START } from "../actions/UserAction";
const PostListing = lazy(() => import("../components/PostListing/PostListing"));
const LeftNavbar = lazy(() =>
  import("../components/HomeComponents/LeftNavbar")
);
const ProfileDashboard = lazy(() =>
  import("../components/HomeComponents/ProfileDashboard")
);
const MaxWidthComponent = lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = lazy(() => import("../components/common/Body"));

function PostListContainer(props) {
  const { userData, profileUserData , getProfileUser } = props;
  const { user_slug } = useParams();
  const [myProfile, setMyProfile] = useState(1);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => { 
    if (userData?.userInfo?.status === "1") {
      if (user_slug && user_slug !== userData?.userInfo?.data?.slug) {
        setMyProfile(0);
        getProfileUser({
          slug : user_slug,
          user_id: utils.toBase64(userData?.userInfo?.data?.id),
        });
      } else {
        setMyProfile(1);
        setCurrentUser(userData);
      }
    }
  }, [userData, user_slug]);

  useEffect(() => {
    if (profileUserData?.userInfo?.status === "1") {
      if (user_slug && user_slug === profileUserData?.userInfo?.data?.slug) {
        setMyProfile(0)
        getProfileUser({
          user_id: utils.toBase64(profileUserData?.userInfo?.data?.id),
        });
        setCurrentUser(profileUserData);
      }
    }
  }, [profileUserData]);

  return (
    <>
      <Body>
        <MaxWidthComponent className="PostGridContainer">
          <TwoColumnGridView
            type={"singleLeftColumn"}
            leftSide={
              <>
                <ProfileDashboard
                myProfile={myProfile}
                userData={currentUser}
                  loading={userData?.loading || profileUserData?.loading}
                />
                <LeftNavbar myProfile={myProfile}userData={currentUser} />
              </>
            }
            rightSide={<PostListing myProfile={myProfile}   userData={currentUser} />}
          />
        </MaxWidthComponent>
      </Body>
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  profileUserData: state.UserData.getProfileUserData,
});

const mapDispatchToProps = (dispatch) => ({
  getProfileUser: (data) =>
  dispatch({
    type: GET_PROFILE_USER_DATA_START,
    data,
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostListContainer);
