import React, { lazy } from "react";
import { makeStyles, Box, Grid, FormHelperText } from "@material-ui/core";
import moment from "moment";
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));
const PlainTextfield = lazy(() => import("../Textfields/PlainTextfield"));
const DatePicker = lazy(() => import("../DateTimePickers/DatePicker"));
const SimpleButton = lazy(() => import("../Buttons/SimpleButton"));

const useStyles = makeStyles((theme) => ({
  gridRow: {
    width: "100%",
    justifyContent: "space-between",
    "&>div": {
      width: "49%",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      flexDirection: "column",
      "&>div": {
        width: "100%",
        "&:nth-child(1)": {
          marginBottom: 20,
        },
      },
    },
  },
}));

export default function EduWorkForm({
  type,
  errors,
  data,
  handleChange,
  isUpdate = false,
  handleUpdate,
  handleSubmit,
  handleClose,
  loading = false,
  ewhDetails,
  educationValidation,
  workValidation,
}) {
  const classes = useStyles();
  return (
    <Box>
      <Box className="manageMB20">
        <LabelTypography required has_error={errors?.heading}>
          {type === "education" ? "University Name" : "Organisation Name"}
        </LabelTypography>
        <PlainTextfield
          value={data?.heading}
          onChange={(e) => handleChange(e, "heading", e.target.value)}
          has_error={errors?.heading}
          helperText={errors?.heading ? "Please enter university name." : ""}
        />
      </Box>
      <Box className="manageMB20">
        <LabelTypography
          required
          has_error={errors?.from_date || errors?.to_date}
        >
          {type === "education" ? "Passing Year" : "Experiance"}
        </LabelTypography>
        <Grid container className={classes.gridRow}>
          <Box>
            <DatePicker
              value={data?.from_date ? moment(data?.from_date, "MM/yyyy").toDate() : null}
              format="MM/yyyy"
              onChange={(date) =>
                handleChange(null, "from_date", moment(date).format("MM/yyyy"))
              }
              className={classes.datePicker}
              placeholder="From"
              required={true}
              views={["month", "year"]}
              has_error={errors?.from_date}
              helperText={
                errors?.from_date ? "Passing years is required field." : ""
              }
            />
          </Box>
          <Box>
            <DatePicker
              value={data?.to_date ? moment(data?.to_date, "MM/yyyy").toDate() : null}
              format="MM/yyyy"
              onChange={(date) =>
                handleChange(null, "to_date", moment(date).format("MM/yyyy"))
              }
              minDate={moment(data?.from_date, "yyyy").format("MM/yyyy")}
              className={classes.datePicker}
              placeholder="To"
              required={true}
              views={["month", "year"]}
              has_error={errors?.to_date}
              helperText={
                errors?.to_date
                  ? !data?.from_date
                    ? "Passing years is required field."
                    : "Education end date always greater than start date."
                  : ""
              }
            />
          </Box>
        </Grid>
      </Box>
      <Box className="manageMB20">
        <LabelTypography children="Achievement" />
        <PlainTextfield
          multiline={true}
          rows={4}
          value={data?.message}
          onChange={(e) => handleChange(e, "message", e.target.value)}
          placeholder="Achievement"
        />
      </Box>
      <Box className="profileFormActionButtonsDiv">
        {isUpdate ? (
          <SimpleButton
            children="Update"
            secondaryColorBtn
            onClick={handleUpdate}
            loading={loading}
          />
        ) : (
          <SimpleButton
            children="Add"
            secondaryColorBtn
            onClick={handleSubmit}
            loading={loading}
          />
        )}
        {ewhDetails?.length !== 0 && (
          <SimpleButton
            children="Close"
            secondaryColorBtn
            onClick={handleClose}
          />
        )}
      </Box>
      {type === "education"
        ? educationValidation && (
          <FormHelperText error>
            Minimum one education detail is required for the next page.
            Otherwise you can skip this page.
          </FormHelperText>
        )
        : workValidation && (
          <FormHelperText error>
            Minimum one work detail is required for the next page. Otherwise
            you can skip this page.
          </FormHelperText>
        )}
    </Box>
  );
}
