import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useClasses = makeStyles((theme) => ({
  parasRoot: {
    fontSize: 12,
    color: theme.palette.common.black,
    lineHeight: "18px",
  },
}));

export default function ParasTypography({
  className = "",
  children,
  light,
  medium,
  bold,
  variant,
  ellipsis,
  small,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useClasses();
  return (
    <Typography
      variant={variant}
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      className={classNames(
        classes.parasRoot,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        small && "fwLighter",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className
      )}
      {...props}
    >
      {children}
    </Typography>
  );
}
