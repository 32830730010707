import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import UserAvatar from "../common/UserAvatar";
import { GET_CONNECTION_SUGGESTIONS_START } from "../../actions/ConnectionsAction";
import { useHistory } from "react-router-dom";
import UserRoles from "../common/UserRoles";
import H6 from "../Headings/H6";
import "../../scss/ConnectionComponents.scss";
import LeftRightComponent from "../common/LeftRightComponent";
import ConnAndMsgComponents from "./ConnAndMsgComponent";
import { UserSkeleton } from "../common/Skeletons";
import DisplayName from "../common/DisplayName";

export const ConnectionSuggestion = (props) => {
  const { getConnectionSuggestions, getConnectionSuggestionsData, userData } =
    props;
  const history = useHistory();
  const [connectionSuggestions, setConnectionSuggestions] = useState([]);

  useEffect(() => {
    if (getConnectionSuggestionsData?.data?.status === "1") {
      setConnectionSuggestions(getConnectionSuggestionsData?.data?.data);
    }
  }, [getConnectionSuggestionsData]);

  useEffect(() => {
    getConnectionSuggestions();
  }, []);
  return getConnectionSuggestionsData?.loading ||
    connectionSuggestions?.length !== 0 ? (
    <LeftRightComponent
      heading={"People You may know"}
      showViewMore={connectionSuggestions?.length > 5 ? true : false}
      handleViewMoreClick={() =>
        history.push(`/user-profile/${userData?.userInfo?.data?.slug}/3`)
      }
    >
      <Box className="manageMT15">
        {getConnectionSuggestionsData?.loading ? (
          <UserSkeleton num={5} />
        ) : (
          connectionSuggestions?.slice(0, 5).map((item, key) => {
            return (
              <Box className="ldrabRoot" key={key}>
                <Box>
                  <UserAvatar
                    src={item?.profile_picture}
                    name={item?.display_name}
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />
                  <Box>
                    <H6
                      medium
                      ellipsis
                      onClick={() =>
                        history.push("/user-profile/" + item?.slug + "/0")
                      }
                      title={item?.display_name}
                    >
                      <DisplayName user={item} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                    </H6>
                    <UserRoles
                      align="left"
                      roles={item?.user_performing_roles}
                    />
                  </Box>
                </Box>
                <Box>
                  <ConnAndMsgComponents
                    type={"connectionSuggestion"}
                    currentUser={{
                      id: item?.id,
                      display_name: item?.display_name,
                      status: item?.connection ? "withdraw" : "connection",
                      con_id: item?.connection?.id,
                    }}
                  />
                </Box>
              </Box>
            );
          })
        )}
      </Box>
    </LeftRightComponent>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({
  getConnectionSuggestionsData:
    state.ConnectionsData.getConnectionSuggestionsData,
  userData: state.UserData.getUserData,
});

const mapDispatchToProps = (dispatch) => ({
  getConnectionSuggestions: () =>
    dispatch({
      type: GET_CONNECTION_SUGGESTIONS_START,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectionSuggestion);
