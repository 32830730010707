import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import utils from "../../utils/commonUtils";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@material-ui/core";
import CarouselComponent from "./CarouselComponent";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  feedFileVideoDiv: {
    width: "100%",
    height: "calc(100% - 65px)",
    "&>div": {
      height: "100%",
      "&>div": {
        "&:nth-child(1)": {
          height: "100%",
          "&>div": {
            height: "100%",
            "&>video": {
              borderRadius: "0px !important",
              maxHeight: "unset !important",
              height: "100%",
            },
          },
        },
      },
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImageVideoLightbox({
  type,
  data,
  open,
  handleClose,
  activeIndex = 0,
}) {
  const classes = useStyle();
  let images = [];
  let videos = [];
  const activeFileType = utils.imageExtensions.includes(
    data.at(activeIndex).split(".").pop().toLowerCase()
  )
    ? "image"
    : "video";
  if (type === "db") {
    images = data.filter((item) =>
      utils.imageExtensions.includes(item.split(".").pop().toLowerCase())
    );
    videos = data.filter(
      (item) =>
        !utils.imageExtensions.includes(item.split(".").pop().toLowerCase())
    );
  }
  const [index, setIndex] = useState(
    activeFileType === "image"
      ? images?.indexOf(data?.at(activeIndex))
      : videos?.indexOf(data?.at(activeIndex))
  );

  return activeFileType === "image" ? (
    <Lightbox
      mainSrc={images[index]}
      nextSrc={images[(index + 1) % images.length]}
      prevSrc={images[(index + images.length - 1) % images.length]}
      onCloseRequest={handleClose}
      onMovePrevRequest={() => {
        setIndex((index + images.length - 1) % images.length);
      }}
      onMoveNextRequest={() => {
        setIndex((index + 1) % images.length);
      }}
    />
  ) : (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative", background: "#023047" }}>
        <Toolbar className="flex-hre">
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            title={"Close"}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className={classNames(classes.feedFileVideoDiv)}>
        <CarouselComponent
          index={index}
          children={videos.map((item, key) => {
            return (
              <video
                key={key}
                src={item}
                autoPlay={true}
                controls={true}
              ></video>
            );
          })}
          navButtonsAlwaysVisible={true}
          indicators={false}
          cycleNavigation={false}
          autoPlay={false}
        />
      </div>
    </Dialog>
  );
}
