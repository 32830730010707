import React from "react";
import { makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import BreadcrumbComponent from "./BreadcrumbComponent";

const useClasses = makeStyles((theme) => ({
  breadcome: {
    marginBottom: "35px",
    marginLeft: "-73",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  groupListingBreadCrumb: {
    marginTop: "20px",
  },
}));

export function BlogViewBreadCrumb(props) {
  const classes = useClasses();
  const { blogDetail, userData } = props;

  const links = [
    { text: "Home", link: "/home" }
  ];
  if (window.location.pathname.split("/")[1] == "my-blogs") {
    links.push({ text: "My Blogs", link: "/my-blogs" })
  } else if (window.location.pathname.split("/")[1] == "blog-view") {
    links.push({ text: "Blogs", link: "/blogs" })
  } else if (window.location.pathname.split("/")[1] == "blogs") {
    links.push({ text: `Blogs`, link: `/blogs/${window.location.pathname.split("/")[2]}` })
  }
  links.push({ text: blogDetail.title, underline: "none" })

  return (
    <div className={classes.breadcome}>
      <BreadcrumbComponent links={links} />
    </div>
  );
}

export function GroupListingBreadCrumb() {
  const classes = useClasses();
  const links = [
    { text: "Home", link: "/home" },
    { text: "Groups", underline: "none" },
  ];

  return (
    <div className={classes.groupListingBreadCrumb}>
      <BreadcrumbComponent links={links} />
    </div>
  );
}

export function FeedHeaderBreadCrumb(props) {
  const { feedData } = props;
  return (
    <>
      <BreadcrumbComponent
        links={[
          {
            text: feedData?.user?.display_name,
            link: `/user-profile/${feedData?.user?.slug}/0`,
          },
          {
            text: feedData?.group?.name,
            link: `/group/${feedData?.group?.slug}`,
          },
        ]}
        lastElementIsLink={true}
        separator={<NavigateNextIcon />}
      />
    </>
  );
}

export function PostListingBreadCrumb({ userData, myProfile }) {
  const links = [
    { text: "Home", link: "/home" },
  ];

  if (myProfile == 0) {
    links.push({ text: `Blogs`, underline: "none" })
  } else {
    links.push({ text: "My Blogs", underline: "none" })
  }

  return (
    <>
      <BreadcrumbComponent links={links} />
    </>
  );
}

export function UserListingBreadCrumb() {
  const links = [
    { text: "Home", link: "/home" },
    { text: "Blogs", underline: "none" },
  ];

  return (
    <>
      <BreadcrumbComponent links={links} />
    </>
  );
}
