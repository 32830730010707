import React , { useEffect , useState } from "react";
import { connect } from "react-redux";
import { TwoColumnGridView } from "../components/common/GridViews";
import GroupListingWeb from "../components/GroupListing/GroupListing";
import LeftNavbar from "../components/HomeComponents/LeftNavbar";
import ProfileDashboard from "../components/HomeComponents/ProfileDashboard";
import MaxWidthComponent from "../components/common/MaxWidthComponent";
import Body from "../components/common/Body";
import { useParams } from "react-router-dom";
import utils from "../utils/commonUtils";
import { GET_PROFILE_USER_DATA_START } from "../actions/UserAction";

function GroupListingContainer(props) {
  const { 
    userData, 
    profileUserData,
    getProfileUser,
  } = props;
  const { user_slug, step } = useParams();
  const [myProfile, setMyProfile] = useState(1);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => { 
    if (userData?.userInfo?.status === "1") {
      if (user_slug !== userData?.userInfo?.data?.slug) {
        setMyProfile(0);
        getProfileUser({
          slug : user_slug,
          user_id: utils.toBase64(userData?.userInfo?.data?.id),
        });
      } else {
        setMyProfile(1);
        setCurrentUser(userData);
      }
    }
  }, [userData, user_slug]);

  useEffect(() => {
    if (profileUserData?.userInfo?.status === "1") {
      setCurrentUser(profileUserData);
    }
  }, [profileUserData]);

  return (
    <Body>
      <MaxWidthComponent className={"manageMT15"}>
        <TwoColumnGridView
          type={"singleLeftColumn"}
          leftSide={
            <>
              <ProfileDashboard
                myProfile={myProfile}
                userData={currentUser}
                loading={userData?.loading || profileUserData?.loading}
              />
              <LeftNavbar myProfile={myProfile} userData={currentUser} />
            </>
          }
          rightSide={<GroupListingWeb />}
        />
      </MaxWidthComponent>
    </Body>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  profileUserData: state.UserData.getProfileUserData,
});

const mapDispatchToProps = (dispatch) => ({
  getProfileUser: (data) =>
  dispatch({
    type: GET_PROFILE_USER_DATA_START,
    data,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupListingContainer);
