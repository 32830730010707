import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import classNames from "classnames";
import H3 from "../Headings/H3";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { UPDATE_USER_PROFILE_PICTURE_START } from "../../actions/UserAction";
import UserAvatar from "../common/UserAvatar";
import ProfileBg from "../../assets/img/ProfileBg.png";
import EditMediaIcon from "../common/EditMediaIcon";
import UserRoles from "../common/UserRoles";
import { Body1 } from "../Typography/Typographys";
import { ProfileDashboardSkeleton } from "../common/Skeletons";
import ConnAndMsgComponent from "../ConnectionComponents/ConnAndMsgComponent";
import DisplayName from "../common/DisplayName";

function ProfileDashboard({
  userData,
  updateUserProfilePicture,
  getAllConnectionsData,
  myProfile,
  loading,
  updateUserProfilePictureData,
}) {
  const history = useHistory();
  const user = userData?.userInfo?.data;
  const connectionLength = getAllConnectionsData?.data?.data?.length;

  const handleProfilePictureUpload = (e) => {
    if (e.target.files) {
      const formData = new FormData();
      formData.append("profile_picture", e.target.files[0]);
      updateUserProfilePicture(formData);
    }
  };

  const getConnectionsLength = () => {
    if (connectionLength === 0) {
      return "0";
    } else if (connectionLength > 0 && connectionLength < 101) {
      return connectionLength;
    } else if (connectionLength > 100 && connectionLength < 501) {
      return "100+";
    } else if (connectionLength > 500 && connectionLength < 1001) {
      return "500+";
    } else {
      return "1000+";
    }
  };

  return (
    <Box className={classNames("lrComponent")}>
      {loading ? (
        <ProfileDashboardSkeleton />
      ) : (
        <>
          <Box className="backgroundImageDiv">
            <img src={ProfileBg} alt="Profile Background" />
            <Box className="userIcons">
              {updateUserProfilePictureData?.loading ?
                <div style={{ backgroundColor: "#FFF", width: 84, height: 84, borderRadius: 10, display: "flex", border: "2px solid #FB8500" }}>
                  <CircularProgress style={{ margin: "auto" }} />
                </div>
                :
                <UserAvatar
                  className="userProfilePicture"
                  name={user?.is_company_account ? user?.company_profile?.name : user?.display_name}
                  src={user?.profile_picture}
                  onClick={() =>
                    history.push("/user-profile/" + user?.slug + "/0")
                  }
                />
              }

              {myProfile === 1 && (
                <EditMediaIcon
                  accept="image/*"
                  inputId="upload-profile-picture"
                  onChange={handleProfilePictureUpload}
                />
              )}
            </Box>
          </Box>
          <Box className="userDetails">
            <H3
              children={
                <>
                  <DisplayName user={user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                </>
              }
              className="username"
              onClick={() => history.push(`/user-profile/${user?.slug}/0`)}
              medium
            />
            <UserRoles roles={user?.user_performing_roles} multiple />
            <Box style={{ display: "flex", justifyContent: "center" }}>
              {connectionLength > 0 ? (
                <Body1
                  onClick={() => history.push(`/user-profile/${user?.slug}/2`)}
                  className="profileConnectionParas"
                >
                  <span>
                    <span>{getConnectionsLength()}</span>
                    {` Connection${connectionLength === 1 ? "" : "s"}`}
                  </span>
                </Body1>
              ) : null}
              <Body1
                onClick={() => history.push(`/user-profile/${user?.slug}/3`)}
                className="profileConnectionParas"
              >
                {myProfile === 1 && connectionLength > 0 ? (
                  <>
                    <span>&nbsp;|</span>
                    <span>Grow your Network</span>
                  </>
                ) : null}
              </Body1>
            </Box>
            {myProfile === 0 && (
              <ConnAndMsgComponent
                type="profileDashboard"
                currentUser={{
                  status: userData?.userInfo?.data?.status,
                  con_id: userData?.userInfo?.data?.connectionReqData?.id,
                  inviter: userData?.userInfo?.data?.connectionReqData?.inviter,
                  invitee: userData?.userInfo?.data?.connectionReqData?.invitee,
                  id: userData?.userInfo?.data?.id,
                  display_name: userData?.userInfo?.data?.display_name,
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  updateUserProfilePictureData: state.UserData.updateUserProfilePictureData
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfilePicture: (userData) =>
    dispatch({
      type: UPDATE_USER_PROFILE_PICTURE_START,
      userData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDashboard);
