import React from "react";
import {
  DialogComponent,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";
import SimpleButton from "../Buttons/SimpleButton";

export default function AlertDialog({
  dialogTitle,
  dialogContent,
  open,
  handleClose,
  cancelBtnText = "Cancel",
  successBtnText = "Yes",
  className = "",
  ...props
}) {
  return (
    <DialogComponent
      className={className}
      open={open}
      handleClose={handleClose}
      {...props}
    >
      <DialogHeader handleClose={handleClose}>{dialogTitle}</DialogHeader>
      <DialogBody>{dialogContent}</DialogBody>
      <DialogFooter>
        <SimpleButton psTransparentBorderBtn onClick={() => handleClose(false)}>
          {cancelBtnText}
        </SimpleButton>
        <SimpleButton primaryColorBtn onClick={() => handleClose(true)}>
          {successBtnText}
        </SimpleButton>
      </DialogFooter>
    </DialogComponent>
  );
}
