import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import { ReactComponent as CommentIcon } from "../../assets/CommentIcon.svg";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { connect } from "react-redux";
import {
  FEED_COMMENT_START,
  FEED_LIKE_START,
  GET_FEED_LIKES_START,
  GET_FEED_COMMENTS_START,
} from "../../actions/FeedAction";
import utils from "../../utils/commonUtils";
import UserAvatar from "../common/UserAvatar";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import EndIcon from "../Textfields/EndIcon";
import { ReactComponent as SendIcon } from "../../assets/sendIcon.svg";
// import { ReactComponent as ShareIcon } from "../../assets/ShareIcon.svg";
import LikesDialog from "../Dialogs/LikesDialog";
import FeedCommentAndReplies from "../FeedComponents/FeedCommentAndReplies";
import { Body1 } from "../Typography/Typographys";
import FeedSharePopup from "../common/FeedSharePopup";
import CircularProgress from "@material-ui/core/CircularProgress";

let scrollToBottomInCommentDivFlag = false;

function FeedFooter({
  feedData,
  userData,
  toggleFeedLikeDislike,
  addFeedComment,
  getFeedLikes,
  feedLikesData,
  getFeedComments,
  feedCommentsData,
  setScrollFeedID,
  location,
}) {
  const [openLikeDialog, setOpenLikeDialog] = useState(false);
  const [commentMessage, setCommentMessage] = useState("");
  const [showComments, setShowComments] = useState(false);
  const [sharePostFeedDialogOpen, setSharePostFeedDialogOpen] = useState(false);
  const commentDivRef = useRef(null);
  const history = useHistory();
  const commentSectionRef = useRef(null);
  const commentIconRef = useRef(null);
  const commentLengthRef = useRef(null);
  const isLiked =
    feedData?.feed_likes?.filter((item) => {
      return item?.user_id === userData?.userInfo?.data?.id;
    }).length !== 0;

  const handleOpenLikeDialog = () => {
    setOpenLikeDialog(true);
    getFeedLikes(utils.toBase64(feedData?.id));
  };

  const handleLikeDislike = (type) => {
    if (type === "like") {
      toggleFeedLikeDislike(utils.toBase64(feedData?.id), "like");
    } else {
      toggleFeedLikeDislike(utils.toBase64(feedData?.id), "dislike");
      if (location !== "feed-detail") {
        setScrollFeedID(feedData?.id);
      }
    }
  };

  const handleAddNewComment = (e) => {
    if (e.key === "Enter" && commentMessage !== "") {
      addFeedComment(utils.toBase64(feedData?.id), commentMessage);
      setCommentMessage("");
      scrollToBottomInCommentDivFlag = true;
    }
  };

  const handleCommentTextfieldSendIconClick = (e) => {
    if (commentMessage?.trim() !== "") {
      addFeedComment(utils.toBase64(feedData?.id), commentMessage);
      setCommentMessage("");
      scrollToBottomInCommentDivFlag = true;
    }
  };

  const handleCommentShow = async () => {
    if (showComments) {
      setShowComments(false);
    } else {
      setShowComments(true);
    }
  };

  const scrollToBottomInCommentDiv = () => {
    if (commentDivRef.current) {
      commentDivRef.current.scrollTop = commentDivRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (
      feedCommentsData?.data?.status === "1"
      &&
      scrollToBottomInCommentDivFlag &&
      commentDivRef.current.scrollHeight !== 0
    ) {
      scrollToBottomInCommentDiv();
      scrollToBottomInCommentDivFlag = false;
    }
  }, [feedCommentsData]);

  useEffect(() => {
    if (showComments) {
      getFeedComments(utils.toBase64(feedData?.id));
      scrollToBottomInCommentDivFlag = true;
    }
  }, [showComments]);

  // Close Comment Section when we click outside
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (
  //       commentSectionRef.current &&
  //       !commentSectionRef.current.contains(event.target) &&
  //       commentIconRef.current &&
  //       !commentIconRef.current.contains(event.target) &&
  //       commentLengthRef.current &&
  //       !commentLengthRef.current.contains(event.target) &&
  //       showComments
  //     ) {
  //       setShowComments(false);
  //     }
  //   }
  //   const div = document.getElementById("root");
  //   div.addEventListener("mousedown", handleClickOutside);
  // }, [commentSectionRef, showComments]);

  return (
    <Grid>
      <Grid
        container
        alignItems="center"
        justifyContent="flex-start"
        className={classNames(
          "feedFooterLikeAndCommentCount",
          feedData?.feed_likes?.length === 0 &&
          feedData?.feed_comments?.length === 0 &&
          "display-none"
        )}
      >
        {feedData?.feed_likes?.length > 0 && (
          <Grid onClick={() => handleOpenLikeDialog()}>
            <Body1>
              <span>{feedData?.feed_likes?.length}</span>
              {feedData?.feed_likes?.length > 1 ? "Likes" : "Like"}
            </Body1>
          </Grid>
        )}
        {feedData?.feed_likes?.length > 0 &&
          feedData?.feed_comments?.length > 0 && <span></span>}
        {feedData?.feed_comments?.length > 0 && (
          <Grid ref={commentLengthRef} onClick={() => handleCommentShow()}>
            <Body1>
              <span>{feedData?.feed_comments?.length}</span>
              {feedData?.feed_comments?.length > 1 ? "Comments" : "Comment"}
            </Body1>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        className="feedFooterIcons"
      >
        <Grid
          onClick={() => {
            if (isLiked === true) {
              handleLikeDislike("dislike");
            } else {
              handleLikeDislike("like");
            }
          }}
        >
          {isLiked === true ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
          <Body1>{isLiked === true ? "Liked" : "Like"}</Body1>
        </Grid>
        <Grid innerRef={commentIconRef} onClick={() => handleCommentShow()}>
          <CommentIcon />
          <Body1>Comment</Body1>
        </Grid>
        {/* <Grid onClick={() => setSharePostFeedDialogOpen(true)}>
          <ShareIcon />
          <Body1>Share</Body1>
        </Grid> */}
      </Grid>
      <Grid
        className={!showComments ? "display-none" : ""}
        ref={commentSectionRef}
        style={{ marginTop: "20px" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className="replyTextfieldRootDiv"
        >
          <UserAvatar
            name={userData?.userInfo?.data?.display_name}
            src={userData?.userInfo?.data?.profile_picture}
            onClick={() =>
              history.push(
                "/user-profile/" + userData?.userInfo?.data?.slug + "/0"
              )
            }
          />
          <EndIcon
            placeholder="Add Your Comment..."
            value={commentMessage}
            backgroundTextfield={true}
            onChange={(e) => setCommentMessage(e.target.value)}
            onKeyDown={handleAddNewComment}
            newDesign
            Icon={
              <SendIcon
                className="svg"
                onClick={handleCommentTextfieldSendIconClick}
              />
            }
          />
        </Grid>
        <Grid className="feedCommentsRootDiv" ref={commentDivRef}>
          {(feedData?.feed_comments && feedData?.feed_comments.length !== 0 && !(feedData?.feed_comments[0]?.user)) && feedCommentsData?.loadingID == feedData.id &&
            < CircularProgress
              color="primary"
              style={{ margin: "24px auto 2px" }}
              disableShrink
            />
          }

          {(feedData?.feed_comments && feedData?.feed_comments?.length !== 0) &&
            feedData?.feed_comments[0]?.user &&
            feedData?.feed_comments?.map((item, key) => {
              return <FeedCommentAndReplies comment={item} key={key} />;
            })}
        </Grid>
      </Grid>
      <FeedSharePopup
        open={sharePostFeedDialogOpen}
        handleClose={() => setSharePostFeedDialogOpen(false)}
      />
      <LikesDialog
        open={openLikeDialog}
        handleClose={() => setOpenLikeDialog(false)}
        type="feedLikes"
        users={feedLikesData?.data?.data}
        loading={feedLikesData?.loading}
      />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  feedLikesData: state.FeedData.feedLikesData,
  feedCommentsData: state.FeedData.getFeedCommentsData,
});

const mapDispatchToProps = (dispatch) => ({
  toggleFeedLikeDislike: (feed_id, like_dislike_type) =>
    dispatch({
      type: FEED_LIKE_START,
      feed_id,
      like_dislike_type,
    }),
  addFeedComment: (feed_id, message) =>
    dispatch({
      type: FEED_COMMENT_START,
      feed_id,
      message,
    }),
  getFeedLikes: (feed_id) =>
    dispatch({
      type: GET_FEED_LIKES_START,
      feed_id,
    }),
  getFeedComments: (feed_id) =>
    dispatch({
      type: GET_FEED_COMMENTS_START,
      feed_id,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedFooter);
