import { post, get } from "../Api";

export const CreateNewFeed = async (feedData) => {
  return await post(`/feed`, feedData);
};

export const GetAllFeed = async (offset) => {
  return await post(`/feed/get-all-feeds`, { offset });
};

export const GetLikedFeed = async (offset) => {
  return await post(`/feed/get-liked-feeds`, { offset });
};

export const GetFeedLikes = async (feed_id) => {
  return await get(`/feed/get-feed-likes/${feed_id}`);
};

export const GetFeedComments = async (feed_id) => {
  return await get(`/feed/get-feed-comments/${feed_id}`);
};

export const GetFeedCommentLikes = async (feed_id, comment_id) => {
  return await get(`/feed/get-feed-comment-likes/${comment_id}/${feed_id}`);
};

export const GetFeedCommentReplyLikes = async (
  feed_id,
  comment_id,
  reply_id
) => {
  return await get(
    `/feed/get-feed-comment-reply-likes/${reply_id}/${comment_id}/${feed_id}`
  );
};

export const GetAllProfileFeed = async (user_id, offset) => {
  return await post(`/feed/get-user-feeds/${user_id}`, { offset });
};

export const UpdateFeed = async (feed_id, feedData) => {
  return await post(`/feed/update/${feed_id}`, feedData);
};

export const FeedLike = async (feed_id, like_dislike_type) => {
  return await get(`/feed/${like_dislike_type}/${feed_id}`);
};

export const FeedCommentLike = async (
  feed_id,
  comment_id,
  like_dislike_type
) => {
  return await post(`/feed/comment-like`, {
    feed_id,
    comment_id,
    type: like_dislike_type,
  });
};

export const FeedCommentReplyLike = async (
  feed_id,
  comment_id,
  reply_id,
  like_dislike_type
) => {
  return await post(`/feed/comment-reply-like`, {
    feed_id,
    comment_id,
    reply_id,
    type: like_dislike_type,
  });
};

export const FeedComment = async (feed_id, message) => {
  return await post(`/feed/comment/${feed_id}`, { message });
};

export const PostFeedCommentReply = async (feed_id, comment_id, message) => {
  return await post(`/feed/comment/reply/${feed_id}/${comment_id}`, {
    message,
  });
};

export const DeleteFeed = async (feed_id) => {
  return await get(`/feed/delete/${feed_id}`);
};

export const SearchFeeds = async (query_string, offset) => {
  return await post(`/feed/search-feeds`, { query_string, offset });
};

export const GetGroupFeeds = async (slug, offset) => {
  return await post(`/feed/group-feeds/${slug}`, { offset });
};

export const UpdateCommentAndReply = async (data) => {
  return await post(`/feed/update-feed-comment-and-reply`, data);
};

export const DeleteCommentAndReply = async (data) => {
  return await post(`/feed/delete-feed-comment-and-reply`, data);
};

export const GetFeedDetails = async (data) => {
  return await post(`/feed/get-feed-details`, data);
};
