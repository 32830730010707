import { Box, Grid } from "@material-ui/core";
import { Body1 } from "../Typography/Typographys";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import H4 from "../Headings/H4";
import H5 from "../Headings/H5";
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Vector } from '../../assets/Vector.svg'
import { ReactComponent as Download } from '../../assets/Download.svg'
import { ReactComponent as View } from '../../assets/View.svg'




const useStyle = makeStyles((theme) => ({
    space: {
        margin: "30px 10px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            marginTop: "100px",
        },
    },
    suggestedspace: {
        margin: "30px 10px",
        display: "flex",
        justifyContent: "space-between",
    },
    button: {
        display: "flex",
        margin: "8px",
        flexWrap: "wrap",
    },
    h5: {
        fontSize: "12px",
        lineHeight: "18px",
        margin: "8px",
    },
    h4: {
        fontWeight: "500px",
        margin: "8px",
    },
    color: {
        color: "#FB8500",
        cursor: "pointer",
        marginTop: "8px"
    },
    icon: {
        PaddignUp: "2px",
        paddingTop: "2px",
        margin: "7px",
    },
    viewicon: {
        paddingTop: "5px",
        margin: "7px"
    },
    fullbutton: {
        margin: "0px 3px",
        marginTop: "-20px",
        display: "flex",
        justifyContent: "space-between",
    },
    box: {
        display: 'grid',
        gap: 10,
        marginBottom: "70px",
        gridTemplateColumns: 'repeat(3, 1fr)',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    },
    suggestedbox: {
        display: 'grid',
        gap: 10,
        marginBottom: "70px",
        marginTop: "-18px",
        gridTemplateColumns: 'repeat(3, 1fr)',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
        }
    }
}))

function CardDesign(props) {
    const classes = useStyle()
    return (
        <Box className={classes.box}>
            {props.data.map((task)=> (
            <Card style={{ borderRadius: "12px", boxShadow: "none" }}>
                <CardMedia
                    component="img"
                    height="175px"
                    image={task.img}
                    alt={task.title}
                />
                <CardContent>
                    <H4 className={classes.h4} children={task.title} bold />
                    <H5 className={classes.h5} children={task.describtion} light />
                </CardContent>
                <CardActions className={classes.fullbutton} >
                    <Grid className={classes.button}>
                        <Download className={classes.icon} />
                        <Body1 className={classes.color}>Download</Body1>
                    </Grid>
                    <Grid className={classes.button}>
                        <View className={classes.viewicon} />
                        <Body1 className={classes.color}>View</Body1>
                    </Grid>
                    <Grid className={classes.button}>
                        <Vector className={classes.icon} />
                        <Body1 className={classes.color}>Sandbox</Body1>
                    </Grid>
                </CardActions>
            </Card>
            ))}
        </Box>
    )
}

export default CardDesign;