import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from "@material-ui/core";
import { ReactComponent as Group } from "../../assets/Group.svg";
import { ReactComponent as Activity } from "../../assets/Activity.svg";
import { ReactComponent as Sendbox } from "../../assets/Sendbox.svg";
import { ReactComponent as News } from "../../assets/News.svg";
import { useHistory } from "react-router-dom";
import "../../scss/Feed.scss";
import classNames from "classnames";
import ViewMore from "./ViewMore";
import { useParams } from "react-router-dom";

const profileEditLinks = [
  {
    Icon: "",
    listName: "Personal Details",
  },
  {
    Icon: "",
    listName: "Education Details",
  },
  {
    Icon: "",
    listName: "Employeement Details",
  },
  {
    Icon: "",
    listName: "Change Password",
  },
]

const homePageLinks = [
  {
    Icon: <Activity />,
    listName: "Feeds",
  },
  {
    Icon: <Group />,
    listName: "Groups",
  },
  {
    Icon: <Group />,
    listName: "Connections",
  },
  {
    Icon: <News />,
    listName: "Blogs (Upcoming)",
    disabled: true
  },
  {
    Icon: <Sendbox />,
    listName: "Sandbox (Upcoming)",
    disabled: true
  },
  {
    Icon: <Sendbox />,
    listName: "Free Design Set (Upcoming)",
    disabled: true
  },
];

export default function LeftNavbar({
  userData,
  location = "",
  handleClick,
  activeIndex = 0,
  myProfile = 1,
  ChangeCallback = () => { },
}) {
  const [active, setActive] = useState(activeIndex);
  const { user_slug, slug } = useParams()
  const [links, setLinks] = useState(location == "editProfile" ? profileEditLinks : homePageLinks)

  const history = useHistory();


  useEffect(() => {
    if (location == "editProfile") {
      if (userData?.userInfo?.data?.is_company_account) {
        let copyOfProfileLInks = profileEditLinks
        copyOfProfileLInks.push({
          Icon: "",
          listName: "Company Details",
          value: "company_details",
        })
        setLinks(copyOfProfileLInks)
      }
    }
  }, [userData])


  const leftNavbarLiClick = (index) => {
    if (index === 0) {
      if (myProfile == 0 && userData?.userInfo?.data?.slug) {
        history.push(`/user-profile/${userData?.userInfo?.data?.slug}/0`);
      } else {
        history.push("/home");
      }
    } else if (index === 1) {
      history.push("/groups/" + userData?.userInfo?.data?.slug + "/0");
    } else if (index === 2) {
      history.push(`/user-profile/${userData?.userInfo?.data?.slug}/2`);
    } else if (index === 3) {
      if (myProfile == 0 && userData?.userInfo?.data?.slug) {
        history.push(`/blogs/${userData?.userInfo?.data?.slug}`);
      } else {
        history.push("/my-blogs");
      }
    } else if (index === 4) {
      history.push("/sandbox");
    } else if (index === 5) {
      history.push("/free-design-set");
    } else {
      history.push("/home");
    }
  };
  useEffect(() => {
    const locationArr = history.location.pathname.split("/");
    const location = locationArr[1];
    if (["home"].includes(location)) {
      setActive(0);
    } else if (["group", "groups"].includes(location)) {
      setActive(1);
    } else if (["user-profile"].includes(location)) {
      if (locationArr[3] === "2") {
        setActive(2);
      } else {
        // setActive(0);
      }
    } else if (["my-blogs", "blogs"].includes(location)) {
      // setActive(3);
    } else {
      setActive(null);
    }
  }, [history.location.pathname]);
  return (
    <Box className={"lrComponent"}>
      <List className="mainList">
        {links.map((item, key) => {
          return (
            <ListItem
              key={key}
              className={classNames(
                "leftListItems",
                active === key && "activeLink",
                ["editProfile"].includes(location) && key === links.length - 1
                  ? "withoutViewMoreStyle"
                  : "",
                item.disabled ? "disabledItem" : ""
              )}
              onClick={() => {
                if (!item.disabled) {
                  setActive(key);
                  if (active !== key) {
                    ChangeCallback()
                  }
                  if (["editProfile"].includes(location)) {
                    handleClick(key);
                  } else {
                    leftNavbarLiClick(key);
                  }
                }
              }}
            >
              <ListItemIcon>{item?.Icon}</ListItemIcon>
              <ListItemText primary={item?.listName} />
            </ListItem>
          );
        })}
      </List>
      {!["editProfile"].includes(location) && (
        <ViewMore
          children="Discover More"
          onClick={() => history.push("/blogs")}
        />
      )}
    </Box>
  );
}
