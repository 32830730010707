import React, { useState, lazy } from "react";
import { makeStyles, } from "@material-ui/core";
import gravatar from "gravatar";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import SelectComponent from "../input/SelectComponent"
import ImageSelectDialog from "../common/ImageSelectDialog";
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));
const PlainTextfield = lazy(() => import("../Textfields/PlainTextfield"));
const RadioButtons = lazy(() => import("../input/RadioButtons"));

const useStyles = makeStyles((theme) => ({
  profileDetailForm: {
    "&>div": {

    },
  },
  dobDiv: {
    display: "flex",
    flexDirection: "column",
    "&>div": {
      width: "50%",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  },
  locationDiv: {
    display: "flex",
    "&>div": {
      marginBottom: "14px",
      width: "49%",
      "&:nth-child(1)": {
        marginRight: "2%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      "&>div": {
        width: "100%",
        "&:nth-child(1)": {
          marginRight: 0,
        },
      },
    },
  },
  profileImageDiv: {
    display: "flex",
    "&>div": {
      "&:nth-child(1)": {
        width: "max-content",
        marginRight: 20,
      },
      "&:nth-child(2)": {
        "&>img,&>label>img": {
          width: 170,
          height: 170,
          cursor: "pointer",
        },
        "&>svg,&>label>svg": {
          width: 100,
          height: 100,
        },
      },
    },
  },
  addPhotoIcon: {
    cursor: "pointer",
  },
  pictureProfileErrorMsg: {
    fontSize: "12px",
    whiteSpace: "nowrap",
  },
  descriptionDiv: {
    marginBottom: 12,
  }
}));

let companySizeList = [
  { value: "1-10", label: "1-10" },
  { value: "11-50", label: "11-50" },
  { value: "51-200", label: "51-200" },
  { value: "201-500", label: "201-500" },
  { value: "501-1000", label: "501-1000" },
  { value: "1001-5000", label: "1001-5000" },
  { value: "5001-10000", label: "5001-10000" },
  { value: "10000+", label: "10000+" },
];

export default function CompanyDetail({
  description,
  setDesscription,
  companyCountry,
  setCompanyContry,
  companyCity,
  setCompanyCity,
  company_picture,
  setCompany_picture,
  errors,
  setErrors,
  userData,
  company_picture_radio,
  setCompany_picture_radio,
  companyName,
  setCompanyName,
  companySize,
  setCompanySize,
  hideImageUpload
}) {
  const classes = useStyles();

  const [isValidGravatarImage, setIsValidGravatarImage] = useState(true);
  const [selectBoxOpen, setSelectBoxOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleGravatarError = (e) => {
    setIsValidGravatarImage(false);
  };

  const handleProfileImageUpload = (e) => {
    if (e.target.files) {
      setCompany_picture(e.target.files[0]);
    }
  };

  const handleProfileUploadRadio = (e) => {
    let value = e.target.value;
    if (value === "gravatar") {
      setCompany_picture(
        gravatar.url(
          userData?.userInfo?.data?.email,
          { s: "100", r: "x", d: "retro" },
          true
        )
      );
    } else {
      setCompany_picture(null);
      if (value === "none") {
        setCompany_picture(value);
      }
    }
    setCompany_picture_radio(value);
  };

  return (
    <div>
      <div className={classes.profileDetailForm}>

        <div className={classes.locationDiv}>
          <div>
            <LabelTypography
              children="Name"
              required
              has_error={errors !== null && errors["companyName"] ? true : false}
            />
            <PlainTextfield
              value={companyName}
              style={{ marginBottom: "10px !important" }}
              onChange={(e) => {
                setCompanyName(e.target.value);
                setErrors({ ...errors, companyName: false });
              }}
              placeholder="Company name"
              helperText={
                errors !== null && errors["companyName"]
                  ? "Please enter your company name."
                  : ""
              }
              has_error={errors !== null && errors["companyName"] ? true : false}
            />
          </div>
          <div>
            <LabelTypography
              children="Company size"
              required
              has_error={errors !== null && errors["companySize"] ? true : false}
            />
            <SelectComponent
              value={companySize}
              onChange={(e) => {
                setCompanySize(e.target.value);
              }}
              open={selectBoxOpen}
              setOpen={setSelectBoxOpen}
              menuItems={companySizeList}
              style={{
                border: "1px solid #ddd",
                width: "100%",
                height: "40px",
              }}
            />
          </div>
        </div>

        <div className={classes.locationDiv}>
          <div>
            <LabelTypography
              children="Town"
              required
              has_error={errors !== null && errors["companyCity"] ? true : false}
            />
            <PlainTextfield
              value={companyCity}
              style={{ marginBottom: "10px !important" }}
              onChange={(e) => {
                setCompanyCity(e.target.value);
                setErrors({ ...errors, companyCity: false });
              }}
              placeholder="Town"
              helperText={
                errors !== null && errors["companyCity"]
                  ? "Please enter your town."
                  : ""
              }
              has_error={errors !== null && errors["companyCity"] ? true : false}
            />
          </div>
          <div>
            <LabelTypography
              children="Nation"
              required
              has_error={errors !== null && errors["companyCountry"] ? true : false}
            />
            <PlainTextfield
              value={companyCountry}
              onChange={(e) => {
                setCompanyContry(e.target.value);
                setErrors({ ...errors, companyCountry: false });
              }}
              placeholder="Nation"
              helperText={
                errors !== null && errors["companyCountry"]
                  ? "Please enter your nation."
                  : ""
              }
              has_error={errors !== null && errors["companyCountry"] ? true : false}
            />
          </div>
        </div>

        <div className={classes.descriptionDiv}>
          <LabelTypography
            children="Description"
          />
          <PlainTextfield
            multiline={true}
            rows={4}
            value={description}
            onChange={(e) => {
              setDesscription(e.target.value);
            }}
            placeholder="Description"
          />
        </div>

        {!hideImageUpload && <div className={classes.profileImageDiv}>
          <RadioButtons
            row={false}
            label={"Upload Your Company Logo"}
            value={company_picture_radio}
            onChange={handleProfileUploadRadio}
            radioBtnList={[
              { label: "Upload", value: "upload" },
              { label: "Gravatar", value: "gravatar" },
              { label: "None", value: "none" },
            ].filter((item, key) => {
              return key === 1 ? (isValidGravatarImage ? true : false) : true;
            })}
          />
          {company_picture_radio !== "none" &&
            <div className="center" style={{ flexDirection: "column" }}>
              {company_picture_radio === "upload" ?
                (
                  <label htmlFor="stepper-profile-picture" onClick={() => setOpen(true)}>
                    {typeof company_picture === "string" ? (
                      <img src={company_picture} alt="Your company logo" />
                    ) : company_picture !== null ? (
                      <img
                        src={URL.createObjectURL(company_picture)}
                        alt="Your company logo"
                      />
                    ) : (
                      <AddPhotoAlternateIcon className={classes.addPhotoIcon} />
                    )}
                  </label>
                )
                :
                company_picture !== null ?
                  (<img src={company_picture} alt="Your company logo" />)
                  : (
                    <AddPhotoAlternateIcon />
                  )
              }
              {company_picture_radio === "upload" && company_picture === null ?
                <LabelTypography
                  has_error={errors !== null && (errors["company_picture"] || errors["inValidProfilePictue"]) ? true : false}
                  className={classes.pictureProfileErrorMsg}
                >
                  {(errors !== null && errors["company_picture"])
                    ? "Company logo is compulsory whie upload is selected"
                    : ""
                  }
                </LabelTypography> : ""}
            </div>
          }
        </div>}
      </div>
      <ImageSelectDialog
        open={open}
        onClose={() => setOpen(false)}
        onCrop={(croppedImage) => handleProfileImageUpload({ target: { files: [croppedImage] } })}
      />
      <img
        src={gravatar.url(
          userData?.userInfo?.data?.email,
          { s: "100", r: "x", d: "404" },
          true
        )}
        onError={handleGravatarError}
        className="display-none"
        alt="Verify Gravatar Pic"
      />
    </div>
  );
}
