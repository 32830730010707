import {
  GET_ALL_CONNECTIONS_ERROR,
  GET_ALL_CONNECTIONS_START,
  GET_ALL_CONNECTIONS_SUCCESS,
  NEW_CONNECTION_REQUEST_ERROR,
  NEW_CONNECTION_REQUEST_START,
  NEW_CONNECTION_REQUEST_SUCCESS,
  GET_CONNECTION_SUGGESTIONS_ERROR,
  GET_CONNECTION_SUGGESTIONS_START,
  GET_CONNECTION_SUGGESTIONS_SUCCESS,
  GET_CONNECTION_REQUESTS_ERROR,
  GET_CONNECTION_REQUESTS_START,
  GET_CONNECTION_REQUESTS_SUCCESS,
  UPDATE_CONNECTION_REQUEST_ERROR,
  UPDATE_CONNECTION_REQUEST_START,
  UPDATE_CONNECTION_REQUEST_SUCCESS,
  CANCEL_CONNECTION_REQUEST_ERROR,
  CANCEL_CONNECTION_REQUEST_START,
  CANCEL_CONNECTION_REQUEST_SUCCESS,
  SOCKET_IO_NEW_CONNECTION_REQUEST,
  SOCKET_IO_CANCEL_CONNECTION_REQUEST,
  SOCKET_IO_ACCEPT_CONNECTION_REQUEST,
  SOCKET_IO_UPDATE_CONNECTION_REQUEST,
  REMOVE_CONNECTION_USER_ERROR,
  REMOVE_CONNECTION_USER_START,
  REMOVE_CONNECTION_USER_SUCCESS,
  SOCKET_IO_REMOVE_CONNECTION_USER,
} from "../actions/ConnectionsAction";

const initialState = {
  getAllConnectionsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  newConnectionRequestData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getConnectionSuggestionsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getConnectionRequestsData: {
    loading: false,
    data: {
      data: [],
      status: "",
      message: "",
      offset: 0,
      offsetStatus: false,
    },
    errorMsg: "",
  },
  updateConnectionRequestData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  cancelConnectionRequest: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  removeConnectionUserData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
};

const ConnectionsReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CONNECTIONS_START: {
      return {
        ...previousState,
        getAllConnectionsData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_ALL_CONNECTIONS_SUCCESS: {
      return {
        ...previousState,
        getAllConnectionsData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_ALL_CONNECTIONS_ERROR: {
      return {
        ...previousState,
        getAllConnectionsData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_CONNECTION_REQUESTS_START: {
      return {
        ...previousState,
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          data: {
            ...previousState.getConnectionRequestsData.data,
            offset: action?.data?.offset,
          },
          loading: true,
        },
      };
    }
    case GET_CONNECTION_REQUESTS_SUCCESS: {
      const { data, status, message, offset, offsetStatus } = action?.data;
      return {
        ...previousState,
        getConnectionRequestsData: {
          loading: false,
          data: {
            data:
              offset === 0
                ? data
                : [
                    ...previousState.getConnectionRequestsData?.data?.data,
                    ...data,
                  ],
            status,
            message,
            offset,
            offsetStatus,
          },
          errorMsg: "",
        },
      };
    }
    case GET_CONNECTION_REQUESTS_ERROR: {
      return {
        ...previousState,
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          loading: false,
          errorMsg: action.data,
        },
      };
    }
    case NEW_CONNECTION_REQUEST_START: {
      return {
        ...previousState,
        newConnectionRequestData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case NEW_CONNECTION_REQUEST_SUCCESS: {
      return {
        ...previousState,
        newConnectionRequestData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case NEW_CONNECTION_REQUEST_ERROR: {
      return {
        ...previousState,
        newConnectionRequestData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_CONNECTION_REQUEST_START: {
      return {
        ...previousState,
        updateConnectionRequestData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_CONNECTION_REQUEST_SUCCESS: {
      return {
        ...previousState,
        updateConnectionRequestData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_CONNECTION_REQUEST_ERROR: {
      return {
        ...previousState,
        updateConnectionRequestData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_CONNECTION_SUGGESTIONS_START: {
      return {
        ...previousState,
        getConnectionSuggestionsData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_CONNECTION_SUGGESTIONS_SUCCESS: {
      return {
        ...previousState,
        getConnectionSuggestionsData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_CONNECTION_SUGGESTIONS_ERROR: {
      return {
        ...previousState,
        getConnectionSuggestionsData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case CANCEL_CONNECTION_REQUEST_START: {
      return {
        ...previousState,
        cancelConnectionRequest: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CANCEL_CONNECTION_REQUEST_SUCCESS: {
      return {
        ...previousState,
        cancelConnectionRequest: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case CANCEL_CONNECTION_REQUEST_ERROR: {
      return {
        ...previousState,
        cancelConnectionRequest: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case REMOVE_CONNECTION_USER_START: {
      return {
        ...previousState,
        removeConnectionUserData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case REMOVE_CONNECTION_USER_SUCCESS: {
      return {
        ...previousState,
        removeConnectionUserData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case REMOVE_CONNECTION_USER_ERROR: {
      return {
        ...previousState,
        removeConnectionUserData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case SOCKET_IO_NEW_CONNECTION_REQUEST: {
      const actionData = action.data;
      const oldSuggesionsList = {
        ...previousState.getConnectionSuggestionsData.data,
      };
      if (actionData?.userId === actionData?.inviter) {
        oldSuggesionsList["data"] = oldSuggesionsList?.data?.map((item) => {
          return item?.id === actionData?.invitee
            ? { ...item, connection: actionData }
            : item;
        });
      } else {
        oldSuggesionsList["data"] = oldSuggesionsList?.data?.filter((item) => {
          return item?.id !== actionData?.inviter;
        });
      }
      const oldConnectionRequests = {
        ...previousState?.getConnectionRequestsData?.data,
      };
      if (oldConnectionRequests?.data?.length !== 0) {
        oldConnectionRequests["data"] = [
          actionData,
          ...oldConnectionRequests?.data,
        ];
      } else {
        oldConnectionRequests["data"] = [actionData];
      }
      return {
        ...previousState,
        getConnectionSuggestionsData: {
          ...previousState.getConnectionSuggestionsData,
          data: oldSuggesionsList,
        },
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          data: oldConnectionRequests,
        },
      };
    }
    case SOCKET_IO_CANCEL_CONNECTION_REQUEST: {
      const { invitee, inviter, id, userId } = action.data;
      const oldConnectionRequests = {
        ...previousState.getConnectionRequestsData.data,
      };
      oldConnectionRequests["data"] = oldConnectionRequests?.data?.filter(
        (item) => item?.id !== id
      );
      const oldConnectionSuggestions = {
        ...previousState.getConnectionSuggestionsData.data,
      };
      if (inviter === userId) {
        oldConnectionSuggestions["data"] = oldConnectionSuggestions?.data?.map(
          (item) => {
            if (item?.id === invitee) {
              const tempItem = item;
              delete tempItem["connection"];
              return tempItem;
            } else {
              return item;
            }
          }
        );
      }
      return {
        ...previousState,
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          data: oldConnectionRequests,
        },
        getConnectionSuggestionsData: {
          ...previousState.getConnectionSuggestionsData,
          data: oldConnectionSuggestions,
        },
      };
    }
    case SOCKET_IO_ACCEPT_CONNECTION_REQUEST: {
      const { id, invitee } = action.data;
      const oldConnectionRequests = {
        ...previousState.getConnectionRequestsData.data,
      };
      oldConnectionRequests["data"] = oldConnectionRequests?.data?.filter(
        (item) => item?.id !== id
      );
      const oldMyConnections = { ...previousState.getAllConnectionsData.data };
      if (oldMyConnections?.data !== undefined) {
        oldMyConnections["data"] = [action?.data, ...oldMyConnections?.data];
      } else {
        oldMyConnections["data"] = [action?.data];
      }
      const connectionSuggestions = {
        ...previousState.getConnectionSuggestionsData.data,
      };
      connectionSuggestions["data"] = connectionSuggestions?.data?.filter(
        (item) => item?.id !== invitee
      );
      return {
        ...previousState,
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          data: oldConnectionRequests,
        },
        getAllConnectionsData: {
          ...previousState.getAllConnectionsData,
          data: oldMyConnections,
        },
        getConnectionSuggestionsData: {
          ...previousState.getConnectionSuggestionsData,
          data: connectionSuggestions,
        },
      };
    }
    case SOCKET_IO_UPDATE_CONNECTION_REQUEST: {
      const { id, invitee } = action.data;
      const oldConnectionRequests = {
        ...previousState.getConnectionRequestsData.data,
      };
      oldConnectionRequests["data"] = oldConnectionRequests?.data?.filter(
        (item) => item?.id !== id
      );
      const oldConnectionSuggestions = {
        ...previousState.getConnectionSuggestionsData?.data,
      };
      oldConnectionSuggestions["data"] = oldConnectionSuggestions?.data?.map(
        (item) => {
          if (item?.id === invitee) {
            const tempItem = item;
            delete tempItem["connection"];
            return tempItem;
          } else {
            return item;
          }
        }
      );
      return {
        ...previousState,
        getConnectionRequestsData: {
          ...previousState.getConnectionRequestsData,
          data: oldConnectionRequests,
        },
        getConnectionSuggestionsData: {
          ...previousState.getConnectionSuggestionsData,
          data: oldConnectionSuggestions,
        },
      };
    }
    case SOCKET_IO_REMOVE_CONNECTION_USER: {
      const oldConnections = { ...previousState.getAllConnectionsData.data };
      const newConnections = oldConnections?.data?.filter((item) => {
        return item?.id !== action.data.con_id;
      });
      return {
        ...previousState,
        getAllConnectionsData: {
          ...previousState.getAllConnectionsData,
          data: {
            ...previousState.getAllConnectionsData.data,
            data: newConnections,
          },
        },
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default ConnectionsReducers;
