import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_ALL_CHAT_ROOM_ERROR,
  GET_ALL_CHAT_ROOM_START,
  GET_ALL_CHAT_ROOM_SUCCESS,
  ADD_NEW_CHAT_MESSAGE_ERROR,
  ADD_NEW_CHAT_MESSAGE_SUCCESS,
  ADD_NEW_CHAT_MESSAGE_START,
  GET_CHAT_ROOM_SUCCESS,
  GET_CHAT_ROOM_START,
  GET_CHAT_ROOM_ERROR,
  CREATE_NEW_CHAT_ROOM_ERROR,
  CREATE_NEW_CHAT_ROOM_SUCCESS,
  CREATE_NEW_CHAT_ROOM_START,
  UPDATE_CHAT_GROUP_START,
  UPDATE_CHAT_GROUP_SUCCESS,
  UPDATE_CHAT_GROUP_ERROR,
  ADD_UPDATE_DELETE_CHAT_GROUP_START,
  ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS,
  ADD_UPDATE_DELETE_CHAT_GROUP_ERROR,
  DELETE_GROUP_CHAT_START,
  DELETE_GROUP_CHAT_SUCCESS,
  DELETE_GROUP_CHAT_ERROR,
} from "../actions/ChatAction";
import {
  GetAllChatRoom,
  AddNewChatMessage,
  GetChatRoom,
  CreateNewChatRoom,
  UpdateChatGroup,
  AddUpdateDeleteChatGroup,
  DeleteChatGroup,
} from "../Apis/ChatApis";
import toast from "../components/common/Snackbars";

function* GetAllChatRoomWorker({ data }) {
  const response = yield call(GetAllChatRoom, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_CHAT_ROOM_SUCCESS, data: response });
    } else {
      yield put({ type: GET_ALL_CHAT_ROOM_ERROR, data: response });
      toast.error(response?.message || 'Unauthorized!');
    }
  } catch (err) {
    yield put({ type: GET_ALL_CHAT_ROOM_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAllChatRoomWatcher() {
  yield takeEvery(GET_ALL_CHAT_ROOM_START, GetAllChatRoomWorker);
}

function* GetChatRoomWorker({ room_id, data }) {
  const response = yield call(GetChatRoom, room_id, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_CHAT_ROOM_SUCCESS, data: response });
    } else {
      yield put({ type: GET_CHAT_ROOM_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_CHAT_ROOM_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetChatRoomWatcher() {
  yield takeEvery(GET_CHAT_ROOM_START, GetChatRoomWorker);
}

function* AddNewChatMessageWorker({ room_id, message }) {
  const response = yield call(AddNewChatMessage, room_id, message);
  try {
    if (response?.status === "1") {
      yield put({ type: ADD_NEW_CHAT_MESSAGE_SUCCESS, data: response });
    } else {
      yield put({ type: ADD_NEW_CHAT_MESSAGE_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: ADD_NEW_CHAT_MESSAGE_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* AddNewChatMessageWatcher() {
  yield takeEvery(ADD_NEW_CHAT_MESSAGE_START, AddNewChatMessageWorker);
}

function* CreateNewChatRoomWorker({ chatData }) {
  const response = yield call(CreateNewChatRoom, chatData);
  try {
    if (response?.status === "1") {
      yield put({ type: CREATE_NEW_CHAT_ROOM_SUCCESS, data: response });
    } else if (response?.status === "3") {
      yield put({ type: CREATE_NEW_CHAT_ROOM_ERROR, data: response });
    } else {
      yield put({ type: CREATE_NEW_CHAT_ROOM_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CREATE_NEW_CHAT_ROOM_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CreateNewChatRoomWatcher() {
  yield takeEvery(CREATE_NEW_CHAT_ROOM_START, CreateNewChatRoomWorker);
}

//Chat group

function* UpdateChatGroupWorker({ room_id, chatGroupData }) {
  const response = yield call(UpdateChatGroup, room_id, chatGroupData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_CHAT_GROUP_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_CHAT_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_CHAT_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateChatGroupWatcher() {
  yield takeEvery(UPDATE_CHAT_GROUP_START, UpdateChatGroupWorker);
}

//Add Update Delete Chat Group

function* AddUpdateDeleteChatGroupWorker({ room_id, chatParticipantIds }) {
  const response = yield call(
    AddUpdateDeleteChatGroup,
    room_id,
    chatParticipantIds
  );
  try {
    if (response?.status === "1") {
      yield put({ type: ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: ADD_UPDATE_DELETE_CHAT_GROUP_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: ADD_UPDATE_DELETE_CHAT_GROUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* AddUpdateDeleteChatGroupWatcher() {
  yield takeEvery(
    ADD_UPDATE_DELETE_CHAT_GROUP_START,
    AddUpdateDeleteChatGroupWorker
  );
}

//Delete Chat Group

function* DeleteChatGroupWorker({ room_id }) {
  const response = yield call(DeleteChatGroup, room_id);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_GROUP_CHAT_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_GROUP_CHAT_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_GROUP_CHAT_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteChatGroupWatcher() {
  yield takeEvery(DELETE_GROUP_CHAT_START, DeleteChatGroupWorker);
}
