import React, { useState, useEffect, lazy } from "react";
import {
  Divider,
  makeStyles,
  Grid,
  CircularProgress,
  Box,
} from "@material-ui/core";
import MobileStepper from "@mui/material/MobileStepper";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  EDUCATION_HISTORY_START,
  WORK_HISTORY_START,
  GET_USER_DATA_START,
  DELETE_EDUCATION_HISTORY_START,
  DELETE_WORK_HISTORY_START,
  UPDATE_EDUCATION_HISTORY_START,
  UPDATE_WORK_HISTORY_START,
  GET_COMPANY_PERFORMING_ROLES_START,
  GET_COMPANY_PROFILE_DETAILS_START,
} from "../actions/UserAction";
import { ReactComponent as BlackLogo } from "../assets/blackLogo.svg";
import useViewport from "../hooks/useViewport";
import utils from "../utils/commonUtils";
import toast from "../components/common/Snackbars";
import moment from "moment";
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import CompanyDetail from "../components/StepperComponents/CompanyDetail";
import H2 from "../components/Headings/H2"
import H4 from "../components/Headings/H4"
import H6 from "../components/Headings/H6"
import SimpleButton from "../components/Buttons/SimpleButton"
import LabelTypography from "../components/Typography/LabelTypography"
import ProfileDetail from "../components/StepperComponents/ProfileDetail"
import UserRoles from "../components/StepperComponents/UserRoles"
import VerticalStepper from "../components/StepperComponents/VerticalStepper"
import {
  RegisterCompanyAccount,
  UpdateUserProfileStep,
  ProfileDetail as ProfileDetailApi,
  PerformingRoles,
  CompanyProfileDetails,
  UpdateCompanyProfileDetails,
  UpdateCompanyPerformingRoles,
  CompanyPerformingRoles,
} from "../Apis/UserApis";

const useStyles = makeStyles((theme) => ({
  userStepsRoot: {
    height: "100vh",
    display: "flex",
  },
  userStepRightSideDiv: {
    height: "100%",
    overflowY: "scroll",
    padding: "100px 0",
    background: theme.palette.common.white,
    "&>div": {
      width: "65%",
      margin: "0 auto",
      flexDirection: "column",
      "&>h2": {
        width: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "40px 0",
      "&>div": {
        width: "80%",
      },
    },
  },
  mobileStepperRoot: {
    width: "100%",
    marginBottom: "20px",
    "&>span": {
      borderRadius: "5px",
      width: "100%",
      background: theme.palette.text.gray,
      "&>span": {
        borderRadius: "5px",
        background: theme.palette.secondary.main,
      },
    },
  },
  userDetailHeading: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  stepperBtnsDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&>div": {
      "&>button": {
        "&:nth-child(1)": {
          marginRight: 10,
        },
      },
    },
  },
  stepperBtns: {
    minWidth: "100px",
    height: "30px",
    borderRadius: "15px !important",
    "&>span": {
      fontWeight: theme.palette.fontweight.light400,
      fontSize: "14px",
      lineHeight: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  mobileStepperText: {
    width: "100%",
    textAlign: "left",
    color: theme.palette.text.medium,
    lineHeight: "15px",
    fontSize: "10px",
  },
  wkleapLogoMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  divider: {
    color: theme.palette.text.bold,
    width: "100%",
    margin: "10px 0px",
  },
  ChooseProfileTypeContainer: {
    margin: "16px 0px"
  },
  ProfileType: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "1px solid #D3D3D3",
    borderRadius: 8,
    padding: 20,
    gap: 16,
    aspectRatio: 5 / 3,
    cursor: "pointer",
    textAlign: "center",
    "& svg": {
      width: 48,
      height: 48,
      color: "#023047",
    },
  }
}));

let updateCompanyPerformingRolesFlag = false
let mounted = true
let hasCompanyProfile = false

const StepperContainer = ({
  profileDetailData,
  performingRolesData,
  userData,
  educationHistory,
  workHistory,
  userEducationData,
  updateUserEducationData,
  userWorkData,
  updateUserWorkData,
  getUserData,
  removeEducationHistory,
  deleteUserWorkHistory,
  updateUserEducationHistory,
  updateUserWorkHistory,
  getCompanyPerformingRolesAction,
  getCompanyPerformingRolesData,
  getCompanyProfileAction,
  getCompanyProfileData,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1
    // userData?.userInfo?.data?.profile_step ||                    // commneted for development purpose only
    // parseInt(history.location.pathname.split("/").pop())
  );
  const [errors, setErrors] = useState(null);
  // Profile Detail States
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState("male");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [profile_picture, setProfile_picture] = useState(null);

  // Company Detail States
  const [description, setDesscription] = useState("");
  const [companyCountry, setCompanyContry] = useState("");
  const [companyCity, setCompanyCity] = useState("");
  const [company_picture, setCompany_picture] = useState(null);
  const [company_picture_radio, setCompany_picture_radio] = useState(
    company_picture === null && company_picture === ""
      ? "none"
      : typeof company_picture === "object"
        ? "upload"
        : company_picture?.includes("gravatar")
          ? "gravatar"
          : "none"
  );

  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("1-10");

  // User Performing Role States
  const [roles, setRoles] = useState([]);
  const { width } = useViewport();
  const [ewhData, setEwhData] = useState({
    heading: "",
    from_date: null,
    to_date: null,
    message: "",
  });
  const [ewhDetails, setEwhDetails] = useState([]);
  const [showEduWorkForm, setShowEduWorkForm] = useState(true);
  const [roleValidation, setRoleValidation] = useState(false);
  const [educationValidation, setEducationValidation] = useState(false);
  const [workValidation, setWorkValidation] = useState(false);
  const [isCompanyAccount, setIsCompanyAccount] = useState(null)
  const [loading, setLoading] = useState(false)
  const [backLoading, setBackLoading] = useState(false)
  const [skipLoading, setSkipLoading] = useState(false)

  useEffect(() => {
    if (!getCompanyProfileData.loading && getCompanyProfileData?.data?.id) {
      setDesscription(getCompanyProfileData?.data?.description || "")
      setCompanyCity(getCompanyProfileData?.data?.city || "")
      setCompanyContry(getCompanyProfileData?.data?.country || "")
      setCompanyName(getCompanyProfileData?.data?.name || "")
      setCompanySize(getCompanyProfileData?.data?.size || "1-10")
      hasCompanyProfile = true
    }
  }, [getCompanyProfileData])

  useEffect(() => {
    if (userData?.userInfo?.data) {
      if (mounted) {
        if (userData?.userInfo?.data?.is_company_account == true) {
          setIsCompanyAccount(true)
          getCompanyPerformingRolesAction()
          getCompanyProfileAction()
        } else if (userData?.userInfo?.data?.is_company_account == false) {
          setIsCompanyAccount(false)
        }
        if (userData?.userInfo?.data?.profile_step) {
          setActiveStep(userData?.userInfo?.data?.profile_step)
        }
        mounted = false
      }
    }
  }, [userData])

  const steps = [
    "Type",
    "Details",
    "Activites",
    // "Education Details",
    // "Work Details",
  ];

  const handleChange = (e, key, value) => {
    let obj = { ...ewhData };
    obj[key] = value;
    setEwhData(obj);
    if (obj[key]) {
      let errorObj = { ...errors };
      if (errorObj[key]) {
        errorObj[key] = false;
        setErrors(errorObj);
      }
    }
  };

  const verifyewData = (errorObj, dataObj) => {
    let isValid = false;
    let error = { ...errorObj };
    for (let item of Object.keys(errorObj)) {
      if (dataObj[item]) {
        error[item] = false;
        isValid = true;
        continue;
      } else if (item === "to_date") {
        error[item] = false;
        isValid = true;
        continue;
      } else {
        isValid = false;
        error[item] = true;
      }
    }
    setErrors(error);
    return isValid;
  };

  const handleewSubmit = () => {
    if (verifyewData(errors, ewhData)) {
      if (activeStep === 3) {
        educationHistory(
          ewhData?.heading,
          ewhData?.from_date,
          ewhData?.to_date,
          ewhData?.message
        );
      } else if (activeStep === 4) {
        workHistory(
          ewhData?.heading,
          ewhData?.from_date,
          ewhData?.to_date,
          ewhData?.message
        );
      }
    }
  };

  const handleewUpdate = () => {
    if (verifyewData(errors, ewhData)) {
      if (activeStep === 3) {
        updateUserEducationHistory(
          utils.toBase64(ewhData?.id),
          ewhData?.heading,
          ewhData?.from_date,
          ewhData?.to_date,
          ewhData?.message
        );
      } else if (activeStep === 4) {
        updateUserWorkHistory(
          utils.toBase64(ewhData?.id),
          ewhData?.heading,
          ewhData?.from_date,
          ewhData?.to_date,
          ewhData?.message
        );
      }
    }
  };

  const handleewhClose = () => {
    setEwhData({
      heading: "",
      from_date: null,
      to_date: null,
      message: "",
    });
    setErrors({ heading: false, from_date: false, to_date: false });
    if (ewhDetails?.length !== 0) {
      setShowEduWorkForm(false);
    }
  };

  const handleewhDelete = (id) => {
    if (activeStep === 3) {
      removeEducationHistory(utils.toBase64(id));
    } else if (activeStep === 4) {
      deleteUserWorkHistory(utils.toBase64(id));
    }
  };

  const handleewhEdit = (id) => {
    const selectedData = ewhDetails?.filter((item) => item?.id === id)[0];
    let obj = {};
    if (activeStep === 3) {
      obj["heading"] = selectedData?.university;
    } else if (activeStep === 4) {
      obj["heading"] = selectedData?.org_name;
    }
    obj["from_date"] = selectedData?.from_date;
    obj["to_date"] = selectedData?.to_date;
    obj["message"] = selectedData?.message;
    obj["id"] = selectedData?.id;
    setEwhData(obj);
    setShowEduWorkForm(true);
  };

  const handleewhNextBtnClick = async () => {
    if (ewhData?.heading && ewhData?.from_date && ewhData?.to_date) {
      if (ewhData?.id) {
        handleewUpdate();
      } else {
        handleewSubmit();
      }
      await UpdateUserProfileStep(activeStep + 1);
      setActiveStep(activeStep + 1)
    } else if (
      ewhData?.heading === "" &&
      ewhData?.from_date === null &&
      ewhData?.to_date === null
    ) {
      if (ewhDetails?.length !== 0) {
        await UpdateUserProfileStep(activeStep + 1);
      } else {
        activeStep === 3
          ? setEducationValidation(true)
          : setWorkValidation(true);
      }
    } else {
      verifyewData(errors, ewhData);
    }
  };

  const handleSkip = async () => {
    setSkipLoading(true)
    await UpdateUserProfileStep(activeStep + 1);
    if (activeStep < 3) {
      setActiveStep(activeStep + 1);
    }
    if ((activeStep + 1) == 4) {
      window.location.reload()
    }
    setSkipLoading(false)
  };

  const handleBack = async () => {
    setBackLoading(true)
    await UpdateUserProfileStep(activeStep - 1);
    setActiveStep(activeStep - 1);
    setBackLoading(false)
  };

  const handleNext = async () => {
    setLoading(true)
    if (activeStep === 1) {
      if (isCompanyAccount == null) {

      } else {
        await RegisterCompanyAccount(isCompanyAccount)
        await UpdateUserProfileStep(activeStep + 1);
        setActiveStep(activeStep + 1);
      }

    } else if (activeStep === 2) {
      if (isCompanyAccount == true) {
        let status = 0
        let error = {};

        if (companyCity == "") {
          status = 1
          error["companyCity"] = true
        }

        if (companyCountry == "") {
          status = 1
          error["companyCountry"] = true
        }

        // if (company_picture_radio === "upload" && company_picture === null) {
        //   status = 1
        //   error["company_picture"] = true
        // }

        if (companyName == "") {
          status = 1
          error["companyName"] = true
        }

        setErrors(error)
        if (status == 0) {
          const formData = new FormData();
          formData.append("description", description);
          formData.append("country", companyCountry);
          formData.append("city", companyCity);
          formData.append("name", companyName)
          formData.append("size", companySize)
          if (company_picture !== null) {
            if (typeof company_picture === "string") {
              formData.append("profile_picture_url", company_picture);
            } else {
              formData.append("profile_picture", company_picture);
            }
          }
          if (hasCompanyProfile) {
            await UpdateCompanyProfileDetails(formData).then(res => (res.status == "0") && toast.error(res.message))
          } else {
            await CompanyProfileDetails(formData).then(res => (res.status == "0") && toast.error(res.message))
          }
          await UpdateUserProfileStep(activeStep + 1);
          setActiveStep(activeStep + 1);
        }

      } else {
        if (dob && gender && country && city && profile_picture) {
          let error = {};
          let status = 0;
          if (!moment(dob).isValid()) {
            status = 1;
            error["inValidDob"] = true;
          }
          if (moment(dob).isAfter(moment().subtract(1, "days"))) {
            status = 1;
            error["inValidDob"] = true;
          }
          setErrors(error);

          if (status == 0) {
            const formData = new FormData();
            formData.append("dob", dob);
            formData.append("gender", gender);
            formData.append("country", country);
            formData.append("city", city);
            if (profile_picture !== null) {
              if (typeof profile_picture === "string") {
                formData.append("profile_picture_url", profile_picture);
              } else {
                formData.append("profile_picture", profile_picture);
              }
            }
            await ProfileDetailApi(formData).then(res => (res.status == "0") && toast.error(res.message))
            await UpdateUserProfileStep(activeStep + 1).then(res => (res.status == "0") && toast.error(res.message))
            setActiveStep(activeStep + 1)
          }
        } else {
          let error = {};
          if (!dob) {
            error["dob"] = true;
          }
          if (!gender) {
            error["gender"] = true;
          }
          if (!country) {
            error["country"] = true;
          }
          if (!city) {
            error["city"] = true;
          }
          if (!profile_picture) {
            error["profile_picture"] = true;
          }
          setErrors(error);
        }
      }
    } else if (activeStep === 3) {
      if (roles.length > 0) {
        // handleewhNextBtnClick();
        if (isCompanyAccount == true) {
          if (updateCompanyPerformingRolesFlag) {
            await UpdateCompanyPerformingRoles(roles)
          } else {
            await CompanyPerformingRoles(roles)
          }
        } else {
          await PerformingRoles(roles);
        }
        await UpdateUserProfileStep(activeStep + 1);
        window.location.href = `${window.location.origin}/home`
      } else {
        setRoleValidation(true);
      }
    }
    // else if (activeStep === 3) {
    //   handleewhNextBtnClick();
    // } else if (activeStep === 4) {
    //   handleewhNextBtnClick();
    // } 
    else {

    }
    setLoading(false)
  };

  const isDisabledNextBtn = () => {
    if (activeStep === 1) {
      if (profileDetailData?.loading) {
        return true;
      } else {
        return false;
      }
    } else if (activeStep === 2) {
      if (performingRolesData?.loading) {
        return true;
      } else {
        return false;
      }
    } else if (activeStep === 3) {
      if (userEducationData?.loading || updateUserEducationData?.loading) {
        return true;
      } else {
        return false;
      }
    } else if (activeStep === 4) {
      if (userWorkData?.loading || updateUserWorkData?.loading) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const getNextBtnChildren = () => {
    if (activeStep === 1) {
      if (profileDetailData?.loading) {
        return <CircularProgress size={20} color="#fff" />;
      } else {
        return "Next";
      }
    } else if (activeStep === 2) {
      if (performingRolesData?.loading) {
        return <CircularProgress size={20} color="#fff" />;
      } else {
        return "Next";
      }
    } else if (activeStep === 3) {
      return width < 600 ? "Submit" : "Submit & Go to Dashboard";
    } else if (activeStep === 4) {
      return width < 600 ? "Submit" : "Submit & Go to Dashboard";
    } else {
      return "Next";
    }
  };

  useEffect(() => {
    if (
      profileDetailData?.errorMsg?.status === "3" ||
      performingRolesData?.errorMsg?.status === "3" ||
      userEducationData?.errorMsg?.status === "3" ||
      userWorkData?.errorMsg?.status === "3"
    ) {
      getUserData();
    }
  }, [
    profileDetailData,
    performingRolesData,
    history,
    userEducationData,
    userWorkData,
    getUserData,
  ]);

  useEffect(() => {
    if (getCompanyPerformingRolesData?.data?.length > 0 && isCompanyAccount) {
      setRoles(getCompanyPerformingRolesData?.data?.map((item) => item?.role));
      updateCompanyPerformingRolesFlag = true
    }
  }, [getCompanyPerformingRolesData])

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      const {
        profile_step,
        user_performing_roles,
        user_profile,
        profile_picture,
        user_education_histories,
        user_work_histories,
      } = userData?.userInfo?.data;
      if (user_performing_roles?.length > 0) {
        setRoles(user_performing_roles?.map((item) => item?.role));
      }
      if (user_profile) {
        const { dob, gender, city, country } = user_profile;
        setDob(dob);
        setGender(gender);
        setCity(city);
        setCountry(country);
        setProfile_picture(profile_picture);
      }
      if (userData?.userInfo?.data?.profile_step === 3) {
        setEwhDetails(user_education_histories);
        if (user_education_histories?.length === 0) {
          setShowEduWorkForm(true);
        } else {
          setShowEduWorkForm(false);
        }
        handleewhClose();
      }
      if (userData?.userInfo?.data?.profile_step === 4) {
        setEwhDetails(user_work_histories);
        if (user_work_histories?.length === 0) {
          setShowEduWorkForm(true);
        } else {
          setShowEduWorkForm(false);
        }
        handleewhClose();
      }
    }
  }, [userData]);

  function getStepContent(step) {
    if (step == 1) {
      return <Grid container spacing={2} className={classes.ChooseProfileTypeContainer}>
        <Grid item md={12} sm={12} >
          <H4>Choose profile type</H4>
        </Grid>
        <Grid item md={6} >
          <div
            className={classes.ProfileType}
            onClick={() => setIsCompanyAccount(false)}
            style={{
              borderColor: isCompanyAccount == false ? "#FB8500" : "#D3D3D3"
            }}
          >
            <PersonIcon />
            <H6> Unlock knowledge, insights, and opportunities with an Individual account.</H6>
            <SimpleButton>
              Join as Individual
            </SimpleButton>
          </div>
        </Grid>
        <Grid item md={6}  >
          <div
            className={classes.ProfileType}
            onClick={() => setIsCompanyAccount(true)}
            style={{
              borderColor: isCompanyAccount == true ? "#FB8500" : "#D3D3D3"
            }}
          >
            <BusinessIcon />
            <H6>Boost brand awareness and engage with professionals with a Company account.</H6>
            <SimpleButton>
              Join as Company
            </SimpleButton>
          </div>
        </Grid>

      </Grid>
    } else if (step === 2) {
      return (
        isCompanyAccount ?
          <CompanyDetail
            description={description}
            setDesscription={setDesscription}
            companyCountry={companyCountry}
            setCompanyContry={setCompanyContry}
            companyCity={companyCity}
            setCompanyCity={setCompanyCity}
            company_picture={company_picture}
            setCompany_picture={setCompany_picture}
            errors={errors}
            setErrors={setErrors}
            userData={userData}
            company_picture_radio={company_picture_radio}
            setCompany_picture_radio={setCompany_picture_radio}
            companyName={companyName}
            setCompanyName={setCompanyName}
            companySize={companySize}
            setCompanySize={setCompanySize}
          />
          :
          <ProfileDetail
            dob={dob}
            gender={gender}
            country={country}
            city={city}
            setDob={setDob}
            setGender={setGender}
            setCountry={setCountry}
            setCity={setCity}
            profile_picture={profile_picture}
            setProfile_picture={setProfile_picture}
            userData={userData}
            errors={errors}
            setErrors={setErrors}
          />
      );
    } else if (step === 3) {
      return (
        <UserRoles
          roles={roles}
          setRoles={setRoles}
          roleValidation={roleValidation}
          isCompanyAccount={isCompanyAccount}
        />
      );
    }
    // else if ([3, 4].includes(step)) {
    //   return (
    //     <EduAndWorkDetail
    //       activeStep={activeStep}
    //       errors={errors}
    //       ewhData={ewhData}
    //       handleChange={handleChange}
    //       ewhDetails={ewhDetails}
    //       handleSubmit={handleewSubmit}
    //       handleUpdate={handleewUpdate}
    //       handleClose={handleewhClose}
    //       onDelete={handleewhDelete}
    //       onEdit={handleewhEdit}
    //       setShowEduWorkForm={setShowEduWorkForm}
    //       showEduWorkForm={showEduWorkForm}
    //       educationValidation={educationValidation}
    //       workValidation={workValidation}
    //     />
    //   );
    // } 
    else {
      return "Unknown Step";
    }
  }

  useEffect(() => {
    if ([3, 4].includes(activeStep)) {
      setErrors({ heading: false, from_date: false, to_date: false });
    }
  }, []);

  return (
    <div className={classes.userStepsRoot}>
      <VerticalStepper steps={steps} activeStep={activeStep} />
      <Grid container className={classes.userStepRightSideDiv}>
        <div className="center">
          <div className={classes.wkleapLogoMobile}>
            <BlackLogo />
          </div>
          <H2
            children={`Step ${activeStep}:`}
            className={classes.userDetailHeading}
            medium
          />
          <LabelTypography
            children={`${activeStep} of ${steps.length} Completed`}
            className={classes.mobileStepperText}
            medium
          />
          <MobileStepper
            variant="progress"
            position="static"
            steps={steps.length + 1}
            activeStep={activeStep}
            className={classes.mobileStepperRoot}
          />
          <H2 children={steps[activeStep - 1]} medium />
          <Divider className={classes.divider} />
          <Box sx={{ width: "100%", minHeight: 200 }}>{getStepContent(activeStep)}</Box>
          <Divider className={classes.divider} />
          <div className={classes.stepperBtnsDiv}>
            <SimpleButton
              children="Back"
              onClick={handleBack}
              className={classes.stepperBtns}
              primaryColorBtn
              disabled={activeStep === 1}
              loading={backLoading}
            />
            <div>
              {!isCompanyAccount && <>
                {activeStep !== 1 && <SimpleButton
                  children="Skip"
                  onClick={handleSkip}
                  className={classes.stepperBtns}
                  psTransparentBorderBtn
                  loading={skipLoading}
                />}
              </>}
              <SimpleButton
                children={getNextBtnChildren()}
                type="submit"
                onClick={handleNext}
                className={classes.stepperBtns}
                primaryColorBtn={true}
                disabled={isDisabledNextBtn()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profileDetailData: state.UserData.profileDetailData,
  performingRolesData: state.UserData.performingRolesData,
  userData: state.UserData.getUserData,
  userWorkData: state.UserData.userWorkData,
  updateWorkData: state.UserData.updateUserWorkData,
  userEducationData: state.UserData.userEducationData,
  updateUserEducationData: state.UserData.updateUserEducationData,
  getCompanyPerformingRolesData: state.UserData.getCompanyPerformingRolesData,
  getCompanyProfileData: state.UserData.getCompanyProfileData
});

const mapDispatchToProps = (dispatch) => ({
  getUserData: () =>
    dispatch({
      type: GET_USER_DATA_START,
    }),
  educationHistory: (university, from_date, to_date, message) =>
    dispatch({
      type: EDUCATION_HISTORY_START,
      university,
      from_date,
      to_date,
      message,
    }),
  workHistory: (org_name, from_date, to_date, message) =>
    dispatch({
      type: WORK_HISTORY_START,
      org_name,
      from_date,
      to_date,
      message,
    }),
  removeEducationHistory: (id) =>
    dispatch({
      type: DELETE_EDUCATION_HISTORY_START,
      id,
    }),
  deleteUserWorkHistory: (id) =>
    dispatch({
      type: DELETE_WORK_HISTORY_START,
      id,
    }),
  updateUserEducationHistory: (id, university, from_date, to_date, message) =>
    dispatch({
      type: UPDATE_EDUCATION_HISTORY_START,
      id,
      university,
      from_date,
      to_date,
      message,
    }),
  updateUserWorkHistory: (id, org_name, from_date, to_date, message) =>
    dispatch({
      type: UPDATE_WORK_HISTORY_START,
      id,
      org_name,
      from_date,
      to_date,
      message,
    }),
  getCompanyPerformingRolesAction: () =>
    dispatch({
      type: GET_COMPANY_PERFORMING_ROLES_START,
    }),
  getCompanyProfileAction: () =>
    dispatch({
      type: GET_COMPANY_PROFILE_DETAILS_START,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepperContainer);
