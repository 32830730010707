import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  GET_ALL_CONNECTIONS_ERROR,
  GET_ALL_CONNECTIONS_START,
  GET_ALL_CONNECTIONS_SUCCESS,
  NEW_CONNECTION_REQUEST_ERROR,
  NEW_CONNECTION_REQUEST_SUCCESS,
  NEW_CONNECTION_REQUEST_START,
  GET_CONNECTION_SUGGESTIONS_START,
  GET_CONNECTION_SUGGESTIONS_SUCCESS,
  GET_CONNECTION_SUGGESTIONS_ERROR,
  GET_CONNECTION_REQUESTS_SUCCESS,
  GET_CONNECTION_REQUESTS_START,
  GET_CONNECTION_REQUESTS_ERROR,
  UPDATE_CONNECTION_REQUEST_SUCCESS,
  UPDATE_CONNECTION_REQUEST_START,
  UPDATE_CONNECTION_REQUEST_ERROR,
  CANCEL_CONNECTION_REQUEST_SUCCESS,
  CANCEL_CONNECTION_REQUEST_START,
  CANCEL_CONNECTION_REQUEST_ERROR,
  REMOVE_CONNECTION_USER_SUCCESS,
  REMOVE_CONNECTION_USER_ERROR,
  REMOVE_CONNECTION_USER_START,
} from "../actions/ConnectionsAction";
import {
  GetAllConnections,
  NewConnectionRequest,
  GetConnectionSuggestions,
  GetConnectionRequests,
  UpdateConnectionRequest,
  CancelConnectionRequest,
  RemoveConnectionUser,
} from "../Apis/ConnectionsApis";
import toast from "../components/common/Snackbars";

function* GetConnectionsWorker({ user_id }) {
  const response = yield call(GetAllConnections, user_id);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_CONNECTIONS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_ALL_CONNECTIONS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_ALL_CONNECTIONS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetConnectionsWatcher() {
  yield takeEvery(GET_ALL_CONNECTIONS_START, GetConnectionsWorker);
}

function* GetConnectionRequestsWorker({ data }) {
  const response = yield call(GetConnectionRequests, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_CONNECTION_REQUESTS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_CONNECTION_REQUESTS_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_CONNECTION_REQUESTS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetConnectionRequestsWatcher() {
  yield takeEvery(GET_CONNECTION_REQUESTS_START, GetConnectionRequestsWorker);
}

function* GetConnectionSuggestionsWorker() {
  const response = yield call(GetConnectionSuggestions);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_CONNECTION_SUGGESTIONS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_CONNECTION_SUGGESTIONS_ERROR, data: response });
      toast.error(response?.message || 'Unauthorized!');
    }
  } catch (err) {
    yield put({ type: GET_CONNECTION_SUGGESTIONS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetConnectionSuggestionsWatcher() {
  yield takeEvery(
    GET_CONNECTION_SUGGESTIONS_START,
    GetConnectionSuggestionsWorker
  );
}

function* NewConnectionRequestWorker({ invitee_id, status }) {
  const response = yield call(NewConnectionRequest, invitee_id, status);
  try {
    if (response?.status === "1") {
      yield put({ type: NEW_CONNECTION_REQUEST_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: NEW_CONNECTION_REQUEST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: NEW_CONNECTION_REQUEST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* NewConnectionRequestWatcher() {
  yield takeEvery(NEW_CONNECTION_REQUEST_START, NewConnectionRequestWorker);
}

function* UpdateConnectionRequestWorker({ con_id, inviter_id, status }) {
  const response = yield call(
    UpdateConnectionRequest,
    con_id,
    inviter_id,
    status
  );
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_CONNECTION_REQUEST_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_CONNECTION_REQUEST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_CONNECTION_REQUEST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateConnectionRequestWatcher() {
  yield takeEvery(
    UPDATE_CONNECTION_REQUEST_START,
    UpdateConnectionRequestWorker
  );
}

function* CancelConnectionRequestWorker({ con_id }) {
  const response = yield call(CancelConnectionRequest, con_id);
  try {
    if (response?.status === "1") {
      yield put({ type: CANCEL_CONNECTION_REQUEST_SUCCESS, data: response });
    } else {
      yield put({ type: CANCEL_CONNECTION_REQUEST_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CANCEL_CONNECTION_REQUEST_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CancelConnectionRequestWatcher() {
  yield takeEvery(
    CANCEL_CONNECTION_REQUEST_START,
    CancelConnectionRequestWorker
  );
}

function* RemoveConnectionUserWorker({ con_id }) {
  const response = yield call(RemoveConnectionUser, con_id);
  try {
    if (response?.status === "1") {
      yield put({ type: REMOVE_CONNECTION_USER_SUCCESS, data: response });
    } else {
      yield put({ type: REMOVE_CONNECTION_USER_ERROR, data: response });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: REMOVE_CONNECTION_USER_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* RemoveConnectionUserWatcher() {
  yield takeEvery(REMOVE_CONNECTION_USER_START, RemoveConnectionUserWorker);
}
