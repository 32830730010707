import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UPDATE_COMMENT_AND_REPLY_START } from "../../actions/FeedAction";
import SimpleButton from "../Buttons/SimpleButton";
import "../../scss/Dialogs.scss";
import PlainTextfield from "../Textfields/PlainTextfield";
import {
  DialogBody,
  DialogComponent,
  DialogFooter,
  DialogHeader,
} from "./DialogComponents";

export const EditFeedCommentAndReplyDialog = ({
  open,
  handleClose,
  selectedCommentOrReplyData,
  updateCommentAndReplyAPI,
  updateCommentAndReplyData,
}) => {
  const [message, setMessage] = useState("");

  const handleUpdate = () => {
    let data = {
      type: selectedCommentOrReplyData?.type,
      message: message,
    };
    if (selectedCommentOrReplyData?.type === "comment") {
      data = { ...data, comment_id: selectedCommentOrReplyData?.data?.id };
    } else {
      data = { ...data, reply_id: selectedCommentOrReplyData?.data?.id };
    }
    updateCommentAndReplyAPI(data);
  };

  useEffect(() => {
    if (updateCommentAndReplyData?.status === "1") {
      handleClose();
      setMessage("");
    }
  }, [updateCommentAndReplyData]);

  useEffect(() => {
    if (open) {
      setMessage(selectedCommentOrReplyData?.data?.message);
    }
  }, [open]);
  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>
        {selectedCommentOrReplyData?.type === "comment"
          ? "Update Comment"
          : "Update Reply"}
      </DialogHeader>
      <DialogBody>
        <PlainTextfield
          placeholder={
            selectedCommentOrReplyData?.type === "comment"
              ? "Add your comment..."
              : "Add your reply..."
          }
          multiline
          minRows={4}
          maxRows={"Infinity"}
          type={"text"}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </DialogBody>
      <DialogFooter className="dialogFooter">
        <SimpleButton
          loading={updateCommentAndReplyData?.loading}
          primaryColorBtn
          onClick={handleUpdate}
        >
          Update
        </SimpleButton>
      </DialogFooter>
    </DialogComponent>
  );
};

const mapStateToProps = (state) => ({
  updateCommentAndReplyData: state.FeedData.updateCommentAndReplyData,
});

const mapDispatchToProps = (dispatch) => ({
  updateCommentAndReplyAPI: (data) =>
    dispatch({
      type: UPDATE_COMMENT_AND_REPLY_START,
      data,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFeedCommentAndReplyDialog);
