import React from "react";
import { makeStyles } from "@material-ui/core";
import CF from "../../utils/CommonFunction";
import CloseIcon from "@mui/icons-material/Close";
const ParasTypography = React.lazy(() =>
  import("../Typography/ParasTypography")
);
const AutocompleteTextfield = React.lazy(() =>
  import("../Textfields/AutocompleteTextfield")
);

const useStyle = makeStyles((theme) => ({
  autocompleteTextfield: {
    "&>div": {
      borderRadius: "10px",
      padding: "0px !important",
      "&>input": {
        padding: "10px !important",
        background: theme.palette.common.aliceBlue,
        border: `1px solid ${theme.palette.border.bordertopcolor}`,
        borderRadius: "10px",
        "&:hover": {
          border: `1px solid ${theme.palette.border.bordertopcolor}`,
          borderRadius: "10px",
        },
      },
      "&>div": {},
      "&>fieldset": {
        border: "none",
      },
    },
  },
  autocompleteTextfieldMob: {
    "&>div": {
      borderRadius: "0px",
      padding: "0px !important",
      "&>input": {
        padding: "10px !important",
      },
      "&>div": {
        marginRight: "10px",
        top : "unset"
      },
      "&>fieldset": {
        border: "none",
        borderBottom: `1px solid ${theme.palette.text.gray}`,
      },
    },
  },
}));

export function ChatCommon(props) {
  const { chatSelectedUsers, setChatSelectedUsers } = props;
  return (
    <>
      {chatSelectedUsers?.map((item, key) => {
        return (
          <div key={key}>
            <ParasTypography
              children={CF.capitalizeFirstLetter(item?.label)}
              medium
            />
            <CloseIcon
              className="CloseIcon"
              onClick={() => {
                setChatSelectedUsers((pre) =>
                  pre?.filter((preItem) => preItem?.id !== item?.id)
                );
              }}
            />
          </div>
        );
      })}
    </>
  );
}

export function AutocompleteTextfieldMessage(props) {
  const classes = useStyle();
  const {
    chatSelectedUsers,
    handleAutoCompleteChange,
    connectionsData,
    setActiveUser,
    activeUser,
  } = props;
  return (
    <>
      <AutocompleteTextfield
        onChange={handleAutoCompleteChange}
        className={classes.autocompleteTextfield}
        placeholder="Type a name or multiple names"
        options={connectionsData
          ?.filter((item) => {
            return !chatSelectedUsers
              ?.map((user) => user?.id)
              .includes(item?.id);
          })
          ?.map((item) => {
            return {
              label: CF.capitalizeFirstLetter(item?.display_name),
              id: item?.id,
            };
          })}
        textfieldOnChange={(e) => setActiveUser(e.target.value)}
        inputValue={activeUser}
        value={null}
      />
    </>
  );
}

export function AutocompleteTextfieldBottomMessage(props) {
  const classes = useStyle();
  const {
    chatSelectedUsers,
    handleAutoCompleteChange,
    connectionsData,
    setActiveUser,
    activeUser,
  } = props;
  return (
    <>
      <AutocompleteTextfield
        onChange={handleAutoCompleteChange}
        className={classes.autocompleteTextfieldMob}
        placeholder="Type a name or multiple names"
        options={connectionsData
          ?.filter((item) => {
            return !chatSelectedUsers
              ?.map((user) => user?.id)
              .includes(item?.id);
          })
          ?.map((item) => {
            return {
              label: CF.capitalizeFirstLetter(item?.display_name),
              id: item?.id,
            };
          })}
        textfieldOnChange={(e) => setActiveUser(e.target.value)}
        inputValue={activeUser}
        value={null}
      />
    </>
  );
}
