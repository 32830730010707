import { combineReducers } from "redux";
import AuthReducers from "./AuthReducers";
import UserReducers from "./UserReducers";
import FeedReducers from "./FeedReducers";
import ChatReducers from "./ChatReducers";
import PostReducers from "./PostReducers";
import CommonReducers from "./CommonReducers";
import ConnectionsReducers from "./ConnectionsReducers";
import GroupReducers from "./GroupReducers";

export default combineReducers({
  AuthData: AuthReducers,
  UserData: UserReducers,
  FeedData: FeedReducers,
  ChatData: ChatReducers,
  PostData: PostReducers,
  CommonData: CommonReducers,
  ConnectionsData: ConnectionsReducers,
  GroupData: GroupReducers,
});
