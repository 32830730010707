import { post } from "../Api";

export const GetAllChatRoom = async (data) => {
  return await post(`/chat/get-all-rooms`, data);
};

export const GetChatRoom = async (room_id, data) => {
  return await post(`/chat/get-chat-room/${room_id}`, data);
};

export const AddNewChatMessage = async (room_id, message) => {
  return await post(`/chat/add-chat-message/${room_id}`, { message });
};

export const CreateNewChatRoom = async (chatData) => {
  return await post(`/chat`, chatData);
};

//Update Chat Group

export const UpdateChatGroup = async (room_id, chatGroupData) => {
  return await post(`/chat/update/${room_id}`, chatGroupData);
};

//Socket Update Chat Group

export const AddUpdateDeleteChatGroup = async (room_id, chatParticipantIds) => {
  return await post(`/chat/update-chat-participants`, {
    room_id: room_id,
    chatParticipantIds: chatParticipantIds,
  });
};

export const DeleteChatGroup = async (room_id) => {
  return await post(`/chat/delete/${room_id}`);
};
