import { makeStyles } from "@material-ui/core";
import { Box } from "@mui/system";
import React from "react";
import LinkPreview from "../../assets/img/LinkPreview.webp";
import H3 from "../Headings/H3";
import ParasTypography from "../Typography/ParasTypography";

const useStyle = makeStyles((theme) => ({
  dlpRoot: {
    "&>div": {
      border: "1px solid rgb(204, 204, 204)",
      borderRadius: "10px",
      cursor: "pointer",
      "&>div": {
        width: "calc(100% - 20px)",
        padding: "10px",
        "&:nth-child(1)": {
          height: "200px",
          background: "#e5e5e5",
          borderRadius: "10px 10px 0px 0px",
          "&>img": {
            width: "40%",
            height: "180px",
            borderRadius: "10px 10px 0px 0px",
          },
        },
        "&:nth-child(2)": {
          "&>p": {
            padding: "10px 0px",
          },
          "&>p,&>h3": {
            textDecoration: "underline",
            textDecorationColor: "#fff",
          },
        },
      },
    },
  },
}));

export default function DefaultLinkPreview({ link, text }) {
  const classes = useStyle();
  return (
    <Box
      component={"a"}
      href={link}
      target="_blank"
      className={classes.dlpRoot}
    >
      <Box>
        <Box className="center">
          <img src={LinkPreview} alt="Default Link Preview Icon" />
        </Box>
        <Box>
          <H3>{text?.split("/")[2]?.split(".")[0]}</H3>
          <ParasTypography>{text}</ParasTypography>
        </Box>
      </Box>
    </Box>
  );
}
