import React from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
const FullMessagePageComponent = React.lazy(() =>
  import("../components/FullMessagePageComponent")
);
const MobileMessagePage = React.lazy(() =>
  import("../components/MessageMobile/Message")
);

export default function FullPageMessageContainer(props) {
  const theme = useTheme();
  const breakpoint = useMediaQuery(theme.breakpoints.up(960));
  return breakpoint ? <FullMessagePageComponent /> : <MobileMessagePage />;
}
