import React from "react";
import { Tooltip } from "@mui/material";
import classNames from "classnames";

export default function TooltipComponent({
  title,
  children,
  arrow,
  className,
  id,
  open,
  onOpen,
  onClose,
  placement,
  TransitionComponent,
  TransitionProps,
  ...props
}) {
  return (
    <Tooltip
      open={open}
      id={id}
      title={title}
      arrow={arrow}
      className={classNames(className)}
      onOpen={onOpen}
      onClose={onClose}
      placement={placement}
      TransitionComponent={TransitionComponent}
      TransitionProps={TransitionProps}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
