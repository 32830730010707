//Get All connections Actions
export const GET_ALL_CONNECTIONS_START = "GET_ALL_CONNECTIONS_START";
export const GET_ALL_CONNECTIONS_SUCCESS = "GET_ALL_CONNECTIONS_SUCCESS";
export const GET_ALL_CONNECTIONS_ERROR = "GET_ALL_CONNECTIONS_ERROR";

//New Connection Request Actions
export const NEW_CONNECTION_REQUEST_START = "NEW_CONNECTION_REQUEST_START";
export const NEW_CONNECTION_REQUEST_SUCCESS = "NEW_CONNECTION_REQUEST_SUCCESS";
export const NEW_CONNECTION_REQUEST_ERROR = "NEW_CONNECTION_REQUEST_ERROR";

//Get Connection Suggesstions Actions
export const GET_CONNECTION_SUGGESTIONS_START =
  "GET_CONNECTION_SUGGESTIONS_START";
export const GET_CONNECTION_SUGGESTIONS_SUCCESS =
  "GET_CONNECTION_SUGGESTIONS_SUCCESS";
export const GET_CONNECTION_SUGGESTIONS_ERROR =
  "GET_CONNECTION_SUGGESTIONS_ERROR";

//Get Connection Request Actions
export const GET_CONNECTION_REQUESTS_START = "GET_CONNECTION_REQUESTS_START";
export const GET_CONNECTION_REQUESTS_SUCCESS =
  "GET_CONNECTION_REQUESTS_SUCCESS";
export const GET_CONNECTION_REQUESTS_ERROR = "GET_CONNECTION_REQUESTS_ERROR";

//Update Connection Request Actions
export const UPDATE_CONNECTION_REQUEST_START =
  "UPDATE_CONNECTION_REQUEST_START";
export const UPDATE_CONNECTION_REQUEST_SUCCESS =
  "UPDATE_CONNECTION_REQUEST_SUCCESS";
export const UPDATE_CONNECTION_REQUEST_ERROR =
  "UPDATE_CONNECTION_REQUEST_ERROR";

//Get Cancel Connection Request Actions
export const CANCEL_CONNECTION_REQUEST_START =
  "CANCEL_CONNECTION_REQUEST_START";
export const CANCEL_CONNECTION_REQUEST_SUCCESS =
  "CANCEL_CONNECTION_REQUEST_SUCCESS";
export const CANCEL_CONNECTION_REQUEST_ERROR =
  "CANCEL_CONNECTION_REQUEST_ERROR";

//Socket Io New Connection Request Actions
export const SOCKET_IO_NEW_CONNECTION_REQUEST =
  "SOCKET_IO_NEW_CONNECTION_REQUEST";

//Socket Io New Connection Request Actions
export const SOCKET_IO_CANCEL_CONNECTION_REQUEST =
  "SOCKET_IO_CANCEL_CONNECTION_REQUEST";

//Socket Io Accept Connection Request Actions
export const SOCKET_IO_ACCEPT_CONNECTION_REQUEST =
  "SOCKET_IO_ACCEPT_CONNECTION_REQUEST";

//Socket Io Update Connection Request Actions
export const SOCKET_IO_UPDATE_CONNECTION_REQUEST =
  "SOCKET_IO_UPDATE_CONNECTION_REQUEST";

//Remove Connection User Actions
export const REMOVE_CONNECTION_USER_START = "REMOVE_CONNECTION_USER_START";
export const REMOVE_CONNECTION_USER_SUCCESS = "REMOVE_CONNECTION_USER_SUCCESS";
export const REMOVE_CONNECTION_USER_ERROR = "REMOVE_CONNECTION_USER_ERROR";

//Socket Io Remove Connection User Actions
export const SOCKET_IO_REMOVE_CONNECTION_USER =
  "SOCKET_IO_REMOVE_CONNECTION_USER";
