import React from "react";
import { Grid } from "@material-ui/core";
import "../scss/Blog.scss";
const BlogView = React.lazy(() => import("../components/BlogView/BlogView"));
const MaxWidthComponent = React.lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = React.lazy(() => import("../components/common/Body"));

export default function BlogViewContainer() {
  return (
    <>
      <Body>
        <MaxWidthComponent className="blogViewContainer">
          <Grid item md={10} sm={11}>
            <BlogView />
          </Grid>
        </MaxWidthComponent>
      </Body>
    </>
  );
}
