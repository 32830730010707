import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classname from "classnames";

const useStyles = makeStyles((theme) => ({
  wpSocietyH5Root: {
    color: theme.palette.text.bold,
    fontSize: "14px",
    lineHeight: "21px",
    textTransform: "capitalize",
  },
}));

export default function H5({
  className = "",
  children,
  bold,
  medium,
  light,
  ellipsis,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useStyles();
  return (
    <Typography
      variant="h5"
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      className={classname(
        classes.wpSocietyH5Root,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className
      )}
      {...props}
    >
      {children}
    </Typography>
  );
}
