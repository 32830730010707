// Profile Actions

// Get
export const GET_PROFILE_DETAILS_START = "GET_PROFILE_DETAILS_START";
export const GET_PROFILE_DETAILS_SUCCESS = "GET_PROFILE_DETAILS_SUCCESS";
export const GET_PROFILE_DETAILS_ERROR = "GET_PROFILE_DETAILS_ERROR";

// Get Company Profile
export const GET_COMPANY_PROFILE_DETAILS_START = "GET_COMPANY_PROFILE_DETAILS_START";
export const GET_COMPANY_PROFILE_DETAILS_SUCCESS = "GET_COMPANY_PROFILE_DETAILS_SUCCESS";
export const GET_COMPANY_PROFILE_DETAILS_ERROR = "GET_COMPANY_PROFILE_DETAILS_ERROR";

// Update
export const UPDATE_PROFILE_DETAILS_START = "UPDATE_PROFILE_DETAILS_START";
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS";
export const UPDATE_PROFILE_DETAILS_ERROR = "UPDATE_PROFILE_DETAILS_ERROR";

// GET Company Performing Roles Actions
export const GET_COMPANY_PERFORMING_ROLES_START = "GET_COMPANY_PERFORMING_ROLES_START";
export const GET_COMPANY_PERFORMING_ROLES_SUCCESS = "GET_COMPANY_PERFORMING_ROLES_SUCCESS";
export const GET_COMPANY_PERFORMING_ROLES_ERROR = "GET_COMPANY_PERFORMING_ROLES_ERROR";

// Delete Company Performing Roles Actions
export const DELETE_COMPANY_PERFORMING_ROLES_START = "DELETE_COMPANY_PERFORMING_ROLES_START";
export const DELETE_COMPANY_PERFORMING_ROLES_SUCCESS = "DELETE_COMPANY_PERFORMING_ROLES_SUCCESS";
export const DELETE_COMPANY_PERFORMING_ROLES_ERROR = "DELETE_COMPANY_PERFORMING_ROLES_ERROR";

//Get Performing Roles Actions
export const GET_PERFORMING_ROLES_START = "GET_PERFORMING_ROLES_START";
export const GET_PERFORMING_ROLES_SUCCESS = "GET_PERFORMING_ROLES_SUCCESS";
export const GET_PERFORMING_ROLES_ERROR = "GET_PERFORMING_ROLES_ERROR";

// User Education History Actions
export const EDUCATION_HISTORY_START = "EDUCATION_HISTORY_START";
export const EDUCATION_HISTORY_SUCCESS = "EDUCATION_HISTORY_SUCCESS";
export const EDUCATION_HISTORY_ERROR = "EDUCATION_HISTORY_ERROR";

// Get Education History Actions
export const GET_EDUCATION_HISTORY_START = "GET_EDUCATION_HISTORY_START";
export const GET_EDUCATION_HISTORY_SUCCESS = "GET_EDUCATION_HISTORY_SUCCESS";
export const GET_EDUCATION_HISTORY_ERROR = "GET_EDUCATION_HISTORY_ERROR";

//Update Education History Actions
export const UPDATE_EDUCATION_HISTORY_START = "UPDATE_EDUCATION_HISTORY_START";
export const UPDATE_EDUCATION_HISTORY_SUCCESS =
  "UPDATE_EDUCATION_HISTORY_SUCCESS";
export const UPDATE_EDUCATION_HISTORY_ERROR = "UPDATE_EDUCATION_HISTORY_ERROR";

//Delete Education History Actions
export const DELETE_EDUCATION_HISTORY_START = "DELETE_EDUCATION_HISTORY_START";
export const DELETE_EDUCATION_HISTORY_SUCCESS =
  "DELETE_EDUCATION_HISTORY_SUCCESS";
export const DELETE_EDUCATION_HISTORY_ERROR = "DELETE_EDUCATION_HISTORY_ERROR";

// User Work History Actions
export const WORK_HISTORY_START = "WORK_HISTORY_START";
export const WORK_HISTORY_SUCCESS = "WORK_HISTORY_SUCCESS";
export const WORK_HISTORY_ERROR = "WORK_HISTORY_ERROR";

//Get Work History Actions
export const GET_WORK_HISTORY_START = "GET_WORK_HISTORY_START";
export const GET_WORK_HISTORY_SUCCESS = "GET_WORK_HISTORY_SUCCESS";
export const GET_WORK_HISTORY_ERROR = "GET_WORK_HISTORY_ERROR";

//Update Work History Actions
export const UPDATE_WORK_HISTORY_START = "UPDATE_WORK_HISTORY_START";
export const UPDATE_WORK_HISTORY_SUCCESS = "UPDATE_WORK_HISTORY_SUCCESS";
export const UPDATE_WORK_HISTORY_ERROR = "UPDATE_WORK_HISTORY_ERROR";

//Delete Work History Actions
export const DELETE_WORK_HISTORY_START = "DELETE_WORK_HISTORY_START";
export const DELETE_WORK_HISTORY_SUCCESS = "DELETE_WORK_HISTORY_SUCCESS";
export const DELETE_WORK_HISTORY_ERROR = "DELETE_WORK_HISTORY_ERROR";

//User Data Actions
export const GET_USER_DATA_START = "GET_USER_DATA_START";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERROR = "GET_USER_DATA_ERROR";

// Profile User Data Actions
export const GET_PROFILE_USER_DATA_START = "GET_PROFILE_USER_DATA_START";
export const GET_PROFILE_USER_DATA_SUCCESS = "GET_PROFILE_USER_DATA_SUCCESS";
export const GET_PROFILE_USER_DATA_ERROR = "GET_PROFILE_USER_DATA_ERROR";

//Update User Data Actions
export const UPDATE_USER_DATA_START = "UPDATE_USER_DATA_START";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_ERROR = "UPDATE_USER_DATA_ERROR";

//Update User Profile Picture Actions
export const UPDATE_USER_PROFILE_PICTURE_START =
  "UPDATE_USER_PROFILE_PICTURE_START";
export const UPDATE_USER_PROFILE_PICTURE_SUCCESS =
  "UPDATE_USER_PROFILE_PICTURE_SUCCESS";
export const UPDATE_USER_PROFILE_PICTURE_ERROR =
  "UPDATE_USER_PROFILE_PICTURE_ERROR";

//Get All Users Actions
export const GET_ALL_USERS_START = "GET_ALL_USERS_START";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_ERROR = "GET_ALL_USERS_ERROR";

//Get Search Data Actions
export const GET_SEARCH_DATA_START = "GET_SEARCH_DATA_START";
export const GET_SEARCH_DATA_SUCCESS = "GET_SEARCH_DATA_SUCCESS";
export const GET_SEARCH_DATA_ERROR = "GET_SEARCH_DATA_ERROR";

//Socket IO Email verification Actions
export const SOCKET_IO_EMAIL_VERIFICATION_SUCCESS =
  "SOCKET_IO_EMAIL_VERIFICATION_SUCCESS";

//Update Connection Request Groups Actions
export const UPDATE_CONNECTION_REQUEST_NOTIFICATION =
  "UPDATE_CONNECTION_REQUEST_NOTIFICATION";

export const UPDATE_PROFILE_USER_CONNECTION_STATUS =
  "UPDATE_PROFILE_USER_CONNECTION_STATUS";

// Profile User Data Actions
export const EMPTY_PROFILE_USER_DATA = "EMPTY_PROFILE_USER_DATA";