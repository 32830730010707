import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as RoundedPlus } from "../../assets/RoundedPlus.svg";
import { ReactComponent as VerifiedTick } from "../../assets/VerifiedTick.svg";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { connect } from "react-redux";
import {
  GET_ALL_CHAT_ROOM_START,
  GET_CHAT_ROOM_START,
  UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
} from "../../actions/ChatAction";
import utils from "../../utils/commonUtils";
import { GET_ALL_CONNECTIONS_START } from "../../actions/ConnectionsAction";
import { CREATE_NEW_CHAT_ROOM_START } from "../../actions/ChatAction";
import { useIntersection } from "../../hooks/customHooks";
import "../../scss/ChatPage.scss";
import {
  ChatCommon,
  AutocompleteTextfieldBottomMessage,
} from "../common/ChatCommon";
import "../../scss/ChatPage.scss";
import { LoadingChatRooms } from "../common/Skeletons";
const LabelTypography = React.lazy(() =>
  import("../Typography/LabelTypography")
);
const UserAvatar = React.lazy(() => import("../common/UserAvatar"));
const ChatActionButtons = React.lazy(() =>
  import("../ChatComponents/ChatActionButton")
);
const MessageComponent = React.lazy(() =>
  import("../ChatComponents/MessageComponent")
);
const H3 = React.lazy(() => import("../Headings/H3"));
const H4 = React.lazy(() => import("../Headings/H4"));

let createChatRoomFlag = false;

function Message({
  getAllChatRoomsData,
  userData,
  updateChatMessageRootDiv,
  getChatRoom,
  getChatRoomData,
  getAllChatRooms,
  getAllConnections,
  getAllConnectionsData,
  createNewChatRoom,
  createNewChatRoomData,
  unreadChatCount,
}) {
  const [setTarget, entry] = useIntersection({
    threshold: 0.1,
  });

  const [chatListDiv, setChatListDiv] = useState(false);
  const [chatMessageDiv, setChatMessageDiv] = useState(true);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const [showNewChatDiv, setShowNewChatDiv] = useState(false);
  const [chatSelectedUsers, setChatSelectedUsers] = useState([]);
  const [connectionsData, setConnectionsData] = useState([]);
  const [allChats, setAllChats] = useState([]);
  const [activeUser, setActiveUser] = useState("");
  const messagesdiv = useRef();

  const handleAutoCompleteChange = (e, value) => {
    if (value) {
      setChatSelectedUsers((pre) => [...pre, value]);
    }
  };

  const handleCreateNewChatRoom = () => {
    const chatRoomType = chatSelectedUsers?.length === 1 ? "personal" : "group";
    let chatRoomName = "";
    if (chatRoomType === "group") {
      chatSelectedUsers.forEach((item, index) => {
        chatRoomName += item?.label;
        chatRoomName += index !== chatSelectedUsers.length - 1 ? "," : "";
      });
    }
    createNewChatRoom({
      type: chatRoomType,
      userIds: chatSelectedUsers?.map((item) => item?.id),
      name: chatRoomType === "group" ? chatRoomName : null,
    });
    createChatRoomFlag = true;
  };

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.1 && getAllChatRoomsData?.data?.offsetStatus) {
      let offset = getAllChatRoomsData?.data?.data?.length;
      getAllChatRooms({ offset });
    }
  }, [entry]);

  useEffect(() => {
    if (getChatRoomData?.status === "1") {
      setSelectedChatRoom(getChatRoomData?.data);
      setChatMessageDiv(true);
    }
  }, [getChatRoomData]);

  useEffect(() => {
    if (messagesdiv.current) {
      messagesdiv.current.scrollTop = messagesdiv.current.scrollHeight;
    }
  }, [selectedChatRoom]);

  useEffect(() => {
    if (createNewChatRoomData?.data?.status === "1" && createChatRoomFlag) {
      createChatRoomFlag = false;
      setShowNewChatDiv(false);
      setChatSelectedUsers([]);
      setConnectionsData([]);
      updateChatMessageRootDiv(true);
      getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
      getChatRoom(utils.toBase64(createNewChatRoomData?.data?.data?.id), {
        offset: 0,
      });
    } else if (
      createNewChatRoomData?.data?.status === "3" &&
      createChatRoomFlag
    ) {
      createChatRoomFlag = false;
      setShowNewChatDiv(false);
      setChatSelectedUsers([]);
      setConnectionsData([]);
      updateChatMessageRootDiv(true);
      getChatRoom(
        utils.toBase64(createNewChatRoomData?.data?.data?.chatRoomId),
        {
          offset: 0,
        }
      );
    }
  }, [createNewChatRoomData]);

  useEffect(() => {
    if (showNewChatDiv && userData?.userInfo?.status === "1") {
      getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
    }
  }, [showNewChatDiv, userData]);

  useEffect(() => {
    if (getAllConnectionsData?.data?.status === "1") {
      setConnectionsData(
        getAllConnectionsData?.data?.data?.map((item) => {
          return item?.inviter === userData?.userInfo?.data?.id
            ? item?.inviteeUser
            : item?.inviterUser;
        })
      );
    }
  }, [getAllConnectionsData]);

  useEffect(() => {
    if (getAllChatRoomsData?.data?.status === "1") {
      setAllChats(getAllChatRoomsData?.data?.data);
    }
  }, [getAllChatRoomsData]);

  useEffect(() => {
    getAllChatRooms({ offset: 0 });
  }, []);

  return (
    <div>
      <div className="MessagAasideChatRoot">
        <div className="MessageAsideHeader">
          <H4
            children="Messaging"
            onClick={() => {
              setChatListDiv(!chatListDiv);
            }}
            medium
          />
          <div>
            {unreadChatCount !== 0 && (
              <span className="center">{unreadChatCount}</span>
            )}

            <RoundedPlus
              onClick={() => {
                setShowNewChatDiv(true);
                setChatMessageDiv(true);
              }}
            />
            {chatListDiv ? (
              <KeyboardArrowDownRoundedIcon
                onClick={() => {
                  setChatListDiv(!chatListDiv);
                }}
              />
            ) : (
              <KeyboardArrowUpRoundedIcon
                onClick={() => {
                  setChatListDiv(!chatListDiv);
                }}
              />
            )}
          </div>
        </div>
        <div
          className={classNames(
            "MessageChatListRoot",
            chatListDiv && "MessageActiveChatList"
          )}
        >
          {getAllChatRoomsData?.loading && !getAllChatRoomsData?.data?.data
            ? [...Array(7)].map((item) => {
              return <LoadingChatRooms />;
            })
            : null}
          {allChats.length === 0 ? (
            <LabelTypography
              children="No connections available"
              className="MessageChatNotAvailableText"
            />
          ) : (
            allChats.map((item, key) => {
              return (
                <div
                  className="MessageChatListItem"
                  key={key}
                  onClick={() => {
                    updateChatMessageRootDiv(true);
                    getChatRoom(utils.toBase64(item?.id), { offset: 0 });
                    setChatMessageDiv(true);
                  }}
                >
                  <div className="MessageUserIcons">
                    {item?.type === "personal" ? (
                      item?.chat_participants[0]?.user_id ===
                        userData?.userInfo?.data?.id ? (
                        <UserAvatar
                          name={item?.chat_participants[1]?.user?.display_name}
                          src={
                            item?.chat_participants[1]?.user?.profile_picture
                          }
                          className="MessageChatRoomProfileImage"
                        />
                      ) : (
                        <UserAvatar
                          name={item?.chat_participants[0]?.user?.display_name}
                          src={
                            item?.chat_participants[0]?.user?.profile_picture
                          }
                          className="MessageChatRoomProfileImage"
                        />
                      )
                    ) : (
                      <UserAvatar
                        name={item?.name}
                        src={item?.profile_picture}
                        className="MessageChatRoomProfileImage"
                      />
                    )}

                    <div>
                      <H3
                        children={<>
                          {
                            item?.type === "personal"
                              ? item?.chat_participants[0]?.user_id ===
                                userData?.userInfo?.data?.id
                                ? item?.chat_participants[1]?.user?.display_name
                                : item?.chat_participants[0]?.user?.display_name
                              : item?.name
                          }
                          {
                            item?.type === "personal"
                              ? item?.chat_participants[0]?.user_id ===
                                userData?.userInfo?.data?.id
                                ? item?.chat_participants[1]?.user?.is_wkleap_verified && <VerifiedTick style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                                : item?.chat_participants[0]?.user?.is_wkleap_verified && <VerifiedTick style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                              : item?.name
                          }
                        </>}
                        className={classNames(
                          "MessageChatRoomHeading",
                          "textEllipsis"
                        )}
                        medium
                      />

                      {item?.chat_messages?.length !== 0
                        ? item?.chat_participants?.map((cp) => {
                          return cp?.user?.id ===
                            item?.chat_messages[0]?.user_id ? (
                            <LabelTypography
                              children={`${cp?.user?.id === userData?.userInfo?.data?.id
                                ? "You"
                                : cp?.user?.display_name
                                }: ${item?.chat_messages[0]?.message}`}
                              className={classNames(
                                "MessageChatRoomLastMessage ",
                                "text-light"
                              )}
                              light
                            />
                          ) : null;
                        })
                        : null}
                    </div>
                  </div>
                  <span
                    style={{
                      background:
                        item?.unread_message_count === 0
                          ? "inherit"
                          : "#023047",
                    }}
                  ></span>
                </div>
              );
            })
          )}
          {getAllChatRoomsData?.data?.offsetStatus && (
            <LoadingChatRooms setRef={setTarget} />
          )}
        </div>
      </div>
      <MessageComponent
        connectionsData={connectionsData}
        chatSelectedUsers={chatSelectedUsers}
      />
      <div
        className="MessageChatMessageRoot"
        style={{ display: showNewChatDiv ? "block" : "none" }}
      >
        <div
          className="MessageAsideHeader"
          onClick={() => setChatMessageDiv(!chatMessageDiv)}
        >
          <H4
            children="New message"
            className="MessageChatRoomHeading"
            medium
          />
          <CloseIcon
            className="CloseIcon"
            onClick={() => {
              setShowNewChatDiv(false);
              setChatSelectedUsers([]);
            }}
          />
        </div>
        <div
          className={classNames(
            chatMessageDiv && "MessageActiveChatMessageDiv",
            "MessageNewChatBody"
          )}
          style={{
            maxHeight: chatMessageDiv ? "400px" : "0px",
            minHeight: chatMessageDiv ? "300px" : "0px",
          }}
        >
          <div>
            {connectionsData !== null ? (
              <AutocompleteTextfieldBottomMessage
                chatSelectedUsers={chatSelectedUsers}
                handleAutoCompleteChange={handleAutoCompleteChange}
                connectionsData={connectionsData}
                setActiveUser={setActiveUser}
                activeUser={activeUser}
              />
            ) : (
              ""
            )}
            <div className="MessageSelectedUserForNewChatDiv">
              <ChatCommon
                chatSelectedUsers={chatSelectedUsers}
                setChatSelectedUsers={setChatSelectedUsers}
              />
            </div>
          </div>
          <div className="MessageNewChatFooter">
            <ChatActionButtons
              chatSelectedUsers={chatSelectedUsers}
              createNewChatRoom={createNewChatRoom}
              createChatRoomFlag={createChatRoomFlag}
              handleCreateNewChatRoom={handleCreateNewChatRoom}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  getAllChatRoomsData: state.ChatData.getAllChatRoomsData,
  userData: state.UserData.getUserData,
  chatMessageRootDiv: state.ChatData.chatMessageRootDiv,
  getChatRoomData: state.ChatData.getChatRoomData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
  createNewChatRoomData: state.ChatData.createNewChatRoomData,
  unreadChatCount: state.ChatData.unreadChatCount,
});

const mapDispatchToProps = (dispatch) => ({
  getChatRoom: (room_id, data) =>
    dispatch({
      type: GET_CHAT_ROOM_START,
      room_id,
      data,
    }),
  updateChatMessageRootDiv: (data) =>
    dispatch({
      type: UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
      data,
    }),
  getAllChatRooms: (data) =>
    dispatch({
      type: GET_ALL_CHAT_ROOM_START,
      data,
    }),
  getAllConnections: (user_id) =>
    dispatch({
      type: GET_ALL_CONNECTIONS_START,
      user_id,
    }),
  createNewChatRoom: (chatData) =>
    dispatch({
      type: CREATE_NEW_CHAT_ROOM_START,
      chatData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
