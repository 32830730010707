import React, { useEffect, useState } from "react";
import EndIcon from "../Textfields/EndIcon";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import utils from "../../utils/commonUtils";
import "../../scss/Search.scss";
import { connect } from "react-redux";
import { GET_SEARCH_DATA_START } from "../../actions/UserAction";
import SearchCommonComponent from "../common/SearchCommonComponent";
import { debounce } from "@material-ui/core"
function SearchPage(props) {
  const { getSearchDataAction } = props;
  const [queryString, setQueryString] = useState("");

  const handleSearch = (e, type) => {
    if (queryString?.trim() !== "") {
      if (type === "searchIcon" || (type === "onKeyDown" && e.keyCode === 13)) {
        getSearchDataAction(utils.toBase64(queryString));
      }
    }
  };

  const search = React.useMemo(
    () =>
      debounce((query) => {
        if (query?.trim() !== "") {
          getSearchDataAction(utils.toBase64(query));
        }
      }, 500),
    [],
  );

  useEffect(() => {
    search(queryString)
  }, [queryString])

  return (
    <div className="mobSearchRoot">
      <div>
        <EndIcon
          className="useChartBox"
          placeholder="Search Member"
          position="end"
          value={queryString}
          onKeyDown={(e) => handleSearch(e, "onKeyDown")}
          onChange={(e) => setQueryString(e.target.value)}
          Icon={
            <SearchOutlinedIcon
              onClick={(e) => handleSearch(e, "searchIcon")}
            />
          }
        />
      </div>
      <SearchCommonComponent />
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getSearchDataAction: (queryString) =>
    dispatch({
      type: GET_SEARCH_DATA_START,
      queryString,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
