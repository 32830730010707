//Get Chat Room Actions
export const GET_ALL_CHAT_ROOM_START = "GET_ALL_CHAT_ROOM_START";
export const GET_ALL_CHAT_ROOM_SUCCESS = "GET_ALL_CHAT_ROOM_SUCCESS";
export const GET_ALL_CHAT_ROOM_ERROR = "GET_ALL_CHAT_ROOM_ERROR";

//Add New Chat Meesage Actions
export const ADD_NEW_CHAT_MESSAGE_START = "ADD_NEW_CHAT_MESSAGE_START";
export const ADD_NEW_CHAT_MESSAGE_SUCCESS = "ADD_NEW_CHAT_MESSAGE_SUCCESS";
export const ADD_NEW_CHAT_MESSAGE_ERROR = "ADD_NEW_CHAT_MESSAGE_ERROR";

//Upate Chat Message Room Actions
export const UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY =
  "UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY";

//Get Chat Actions
export const GET_CHAT_ROOM_START = "GET_CHAT_ROOM_START";
export const GET_CHAT_ROOM_SUCCESS = "GET_CHAT_ROOM_SUCCESS";
export const GET_CHAT_ROOM_ERROR = "GET_CHAT_ROOM_ERROR";

//Socket Get Chat New Room Actions
export const SOCKET_IO_GET_NEW_MESSAGE = "SOCKET_IO_GET_NEW_MESSAGE";

//Create New Chat Room Actions
export const CREATE_NEW_CHAT_ROOM_START = "CREATE_NEW_CHAT_ROOM_START";
export const CREATE_NEW_CHAT_ROOM_SUCCESS = "CREATE_NEW_CHAT_ROOM_SUCCESS";
export const CREATE_NEW_CHAT_ROOM_ERROR = "CREATE_NEW_CHAT_ROOM_ERROR";

//Socket Get Chat Room Actions
export const SOCKET_IO_GET_NEW_CHAT_ROOM = "SOCKET_IO_GET_NEW_CHAT_ROOM";

//Socket To Mark As Reda CHat Actions
export const SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES =
  "SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES";

//Update Chat Group Actions
export const UPDATE_CHAT_GROUP_START = "UPDATE_CHAT_GROUP_START";
export const UPDATE_CHAT_GROUP_SUCCESS = "UPDATE_CHAT_GROUP_SUCCESS";
export const UPDATE_CHAT_GROUP_ERROR = "UPDATE_CHAT_GROUP_ERROR";

//Socket Update Chat Group

export const ADD_UPDATE_DELETE_CHAT_GROUP_START =
  "ADD_UPDATE_DELETE_CHAT_GROUP_START";
export const ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS =
  "ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS";
export const ADD_UPDATE_DELETE_CHAT_GROUP_ERROR =
  "ADD_UPDATE_DELETE_CHAT_GROUP_ERROR-";

//Socket io chat update group
export const SOCKET_IO_CHAT_UPDATE_GROUP = "SOCKET_IO_CHAT_UPDATE_GROUP";

//Group Member Delete ChatRoom Participants In Other User Profile
export const SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS =
  "SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS";

//Socket Io For Add New Participant Done in Our Website
export const SOCKET_IO_NEW_ADD_USER_PARTICIPANTS =
  "SOCKET_IO_NEW_ADD_USER_PARTICIPANTS";

//Socket Io Add Or Delete For Existing Users Or Admin
export const SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS =
  "SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS";

//Delete Chat Group
export const DELETE_GROUP_CHAT_START = "DELETE_GROUP_CHAT_START";
export const DELETE_GROUP_CHAT_SUCCESS = "DELETE_GROUP_CHAT_SUCCESS";
export const DELETE_GROUP_CHAT_ERROR = "DELETE_GROUP_CHAT_ERROR";

//Socket For Delete Chat Group

export const SOCKET_IO_DELETE_GROUP_CHAT = "SOCKET_IO_DELETE_GROUP_CHAT";
