import React from "react";
import { styled } from "@mui/material/styles";
import { makeStyles, Box, Paper, Card } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Editor } from "react-draft-wysiwyg";
import { DropzoneArea } from "material-ui-dropzone";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CF from "../../utils/CommonFunction";
import moment from "moment";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useHistory } from "react-router-dom";
import toast from "./Snackbars";
import { useParams } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import LabelTypography from "../Typography/LabelTypography";
import { CardContent, CardMedia } from "@mui/material";
const SimpleButton = React.lazy(() => import("../Buttons/SimpleButton"));
const AutocompleteTextfield = React.lazy(() =>
  import("../Textfields/AutocompleteTextfield")
);
const ParasTypography = React.lazy(() =>
  import("../Typography/ParasTypography")
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#fff",
    color: "#000",
    fontWeight: "400 !important",
    fontFamily: "Poppins",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "Poppins",
    color: "#000000",
    fontWeight: "400 !important",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStyles = makeStyles((theme) => ({
  selectedCategoriesRootDiv: {
    padding: "10px 0px",
    display: "flex",
    flexWrap: "wrap",
    "&>div": {
      background: theme.palette.text.medium,
      borderRadius: "25px",
      padding: "5px 10px",
      width: "max-content",
      display: "flex",
      alignItems: "center",
      margin: "0px 8px 8px 0px",
      "&>p": {
        marginRight: "5px",
        color: theme.palette.common.white,
        textTransform: "capitalize",
        fontFamily: theme.palette.font.boldText.fontFamily,
      },
      "&>svg": {
        cursor: "pointer",
        color: theme.palette.common.white,
      },
    },
  },
  autocompleteTextfield: {
    width: "100%",
  },
  dropZone: {
    backgroundColor: theme.palette.common.aliceBlue,
    minHeight: "185px",
    maxHeight: "100px",
    "&   .MuiDropzoneArea-text": {
      fontFamily: `${theme.palette.font.boldText.fontFamily} !important`,
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: theme.palette.fontweight.light400,
      lineHeight: "18px",
      letterSpacing: "0em",
      position: "relative",
      top: "103px",
      color: " rgba(2, 48, 71, 0.5)",
      marginTop: 0,
    },
    "& .MuiDropzoneArea-icon": {
      color: " rgba(2, 48, 71, 0.5)",
    },
    "& .MuiDropzonePreviewList-removeButton": {
      top: "10px",
      right: "10px",
      width: "40px",
      height: " 40px",
      position: "absolute",
      opacity: "1",
    },
    "& img.MuiDropzonePreviewList-image": {
      margin: "0",
      maxWidth: "100%",
      width: "100%",
      objectFit: "cover",
      height: "auto",
      maxHeight: "250px",
      minHeight: "250px",
      opacity: "1",
    },
    "&:hover .MuiDropzonePreviewList-image": {
      opacity: "1",
    },
  },
  dropzoneShowAttachFit: {
    "& .MuiDropzoneArea-textContainer": {
      display: "none",
    },
  },
  preview: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    flex: "0 0 100%",
  },
  dateColoumn: {
    width: "30%",
  },
  publishBtn: {
    height: "30px !important",
    width: "100px !important",
    marginTop: "-21px !important",
    borderRadius: "8px !important",
    float: "right",
    background: `${theme.palette.primary.main} !important`,
    "&:hover": {
      backgroundColor: `${theme.palette.common.primaryBtnHover} !important`,
    },
    "&>span": {
      fontWeight: `${theme.palette.fontweight.medium500} !important`,
      fontSize: "14px",
    },
  },
  WkUserBlogsNameWithAvatar: {
    display: "flex",
    "&>p": {
      margin: "12px 11px",
    }
  },

  wkUserBlogsData: {
    "&>div": {
      "&>p": {
        fontWeight: "bold",
      }
    }
  }

}));

export function CategoriesCommon(props) {
  const classes = useStyles();
  const {
    categories,
    handleAutocompleteChange,
    categoryValue,
    handleInputChange,
    createCategoryOnEnter,
    noOptionText,
    newFilter,
    selectedCategories,
    setNoOptionText,
    handleRemoveCategories,
  } = props;

  return (
    <>
      <AutocompleteTextfield
        placeholder="Search or Create Categories"
        className={classes.autocompleteTextfield}
        options={categories}
        onChange={handleAutocompleteChange}
        clearOnBlur={true}
        inputValue={categoryValue}
        onInputChange={handleInputChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            createCategoryOnEnter(e, e.target.value);
          }
        }}
        noOptionsText={noOptionText}
        filterOptions={(options, params) => {
          const filtered = newFilter(options, params);
          if (params.inputValue !== "") {
            let isAvailabel = 0;
            filtered.map((item) => {
              if (item?.label.trim() === params.inputValue.trim()) {
                isAvailabel = 1;
              }
              return item;
            });

            let optionsValueStatus = options.some(function (item) {
              return (
                item.label.toLowerCase() ===
                params.inputValue.trim().toLowerCase()
              );
            });
            let alreadySelected = selectedCategories.filter((item) => {
              return (
                item.label.toLowerCase() ===
                params.inputValue.trim().toLowerCase()
              );
            });
            if (alreadySelected.length > 0) {
              setNoOptionText(
                `${params.inputValue
                  .trim()
                  .toLowerCase()} category already selected.`
              );
            }
            if (
              isAvailabel === 0 &&
              !optionsValueStatus &&
              alreadySelected.length === 0
            ) {
              filtered.push({
                inputValue: params.inputValue,
                label: `Create Category "${params.inputValue}"`,
                id: null,
              });
            }
          }
          return filtered;
        }}
      />
      <Box className={classes.selectedCategoriesRootDiv}>
        {selectedCategories?.length > 0 &&
          selectedCategories?.map((item, key) => {
            return (
              <Box key={key}>
                <ParasTypography children={item?.label} />
                <CloseIcon onClick={() => handleRemoveCategories(item?.id)} />
              </Box>
            );
          })}
      </Box>
    </>
  );
}

export function AddPostCommon(props) {
  const { editorState, handleEditorChange } = props;
  return (
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        spellCheck
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
          image: {
            previewImage: true,
            uploadCallback: (file) => {
              return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve({
                    data: {
                      url: reader.result,
                    },
                  });
                };

                reader.onerror = (reason) => reject(reason);

                reader.readAsDataURL(file);
              });
            },
          },
        }}
      />
    </>
  );
}

export function ImageUploadCommon(props) {
  const classes = useStyles();
  const {
    imageBlog,
    handleOnchange,
    selectedFeaturedImage,
    onClickDeleteImage,
    dropZoneText = "Set Featured Image"
  } = props;
  return (
    <>
      <DropzoneArea
        dropzoneClass={`${classes.dropZone} ${!!imageBlog ? classes.dropzoneShowAttachFit : ""
          }`}
        filesLimit={1}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        onChange={handleOnchange}
        onDropRejected={(e) =>
          toast.error("Image size cannot be greater then 10 mb.")
        }
        previewGridProps={{
          container: { spacing: 0, direction: "row" },
        }}
        // maxFileSize={5000000}
        maxFileSize={11000000}
        dropzoneText={dropZoneText}
        showAlerts={false}
        showPreviewsInDropzone={
          imageBlog !== null && imageBlog !== undefined ? true : false
        }
        previewGridClasses={{
          container: classes.previewContainer,
          item: classes.preview,
        }}
        initialFiles={selectedFeaturedImage}
        onDelete={() => onClickDeleteImage()}
      />
    </>
  );
}

export function PostListingCommon(props) {
  const classes = useStyles();
  const history = useHistory();
  const { getPostListing, onRedirectView, setOpen, setSlug } = props;
  return (
    <>
      {getPostListing?.map((row, key) => (
        <Card style={{ display: 'flex', margin: "10px 0px" }} >
          <CardMedia
            component="img"
            sx={{
              width: 211,
              minWidth: 211,
              backgroundImage: `url(${row?.primary_image ? row?.primary_image : "No Image is Available"})`,
              backgroundColor: "#C4C4C4",
            }}
            onClick={(e) => onRedirectView(row?.slug)}
          // image={row?.primary_image ? row?.primary_image : ""}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }} className={classes.wkUserBlogsData}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <LabelTypography>
                {CF.capitalizeFirstLetter(row?.title)}
              </LabelTypography>
              <LabelTypography variant="subtitle1" color="text.secondary">
                {moment(row?.createdAt).format("DD-MM-yyyy")}
              </LabelTypography>
              <LabelTypography variant="h5">
                {row?.post_categories?.length <= 0 && "-"}
                {row?.post_categories?.map((item, index) => {
                  return CF.capitalizeFirstLetter(item.category.name).concat(
                    index + 1 === row.post_categories.length ? "" : ",  "
                  );
                })}
              </LabelTypography>
              <LabelTypography>
                <EditOutlinedIcon
                  onClick={() => {
                    history.push(`/edit-post/${row.slug}`);
                  }}
                />
                <DeleteOutlineIcon
                  onClick={() => {
                    setOpen(true);
                    setSlug(row?.slug);
                  }}
                />
              </LabelTypography>
            </CardContent>
          </Box>
        </Card>
      ))}
    </>
  );
}

export function UserPostListingCommon(props) {
  const classes = useStyles();
  const { getUserPostListing, onRedirectView } = props;
  return (
    <>
      {getUserPostListing?.map((row, key) => (
        <Card style={{ display: 'flex', margin: "10px 0px" }}>
          <CardMedia
            component="img"
            sx={{
              width: 211,
              minWidth: 211,
              backgroundImage: `url(${row?.primary_image ? row?.primary_image : "No Image is Available"})`,
              backgroundColor: "#C4C4C4",
            }}
            onClick={(e) => onRedirectView(row?.slug)}
          // image={row?.primary_image ? row?.primary_image : ""}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }} className={classes.wkUserBlogsData}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <LabelTypography>
                {CF.capitalizeFirstLetter(row?.title)}
              </LabelTypography>
              <LabelTypography variant="subtitle1" color="text.secondary">
                {moment(row?.createdAt).format("DD-MM-yyyy")}
              </LabelTypography>
              <LabelTypography variant="h5">
                {row?.post_categories?.length <= 0 && "-"}
                {row?.post_categories?.map((item, index) => {
                  return CF.capitalizeFirstLetter(item.category.name).concat(
                    index + 1 === row.post_categories.length ? "" : ",  "
                  );
                })}
              </LabelTypography>
            </CardContent>
          </Box>
        </Card>
      ))}
    </>
  );
}

export function PublishButton(props) {
  const classes = useStyles();
  const { handlePublishBlogPost, createPostData, type } = props;
  const { slug } = useParams();
  const history = useHistory();
  const onClickRedirectPostView = (slug) => {
    history.push(`/blog-view/${slug}`);
  };

  const getPublishButtons = (type) => {
    switch (type) {
      case "SaveDraftButton":
        return (
          <SimpleButton
            type="submit"
            className="PublicBlogBtns"
            onClick={(e) => handlePublishBlogPost(0)}
            primaryColorBtn={true}
          >
            Save Draft
          </SimpleButton>
        );
      case "PreviewButton":
        return (
          <SimpleButton
            type="submit"
            className="PublicBlogBtns"
            onClick={(e) => onClickRedirectPostView(slug)}
            primaryColorBtn={true}
          >
            Preview
          </SimpleButton>
        );
      case "PublishButton":
        return (
          <SimpleButton
            type="submit"
            onClick={(e) => handlePublishBlogPost(1)}
            className={classes.publishBtn}
            disabled={createPostData?.loading}
            loading={createPostData?.loading}
            primaryColorBtn={true}
          >
            Publish
          </SimpleButton>
        );
      default:
        return "";
    }
  };

  const getPublishComponent = (type) => {
    if (type === "SaveDraftButton") {
      return getPublishButtons("SaveDraftButton");
    } else if (type === "PreviewButton") {
      return getPublishButtons("PreviewButton");
    } else if (type === "PublishButton") {
      return getPublishButtons("PublishButton");
    }
  };

  return <>{getPublishComponent(type)}</>;
}
