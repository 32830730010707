import React, { useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import classNames from "classnames";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ImageSelectDialog from "./ImageSelectDialog"

const useStyle = makeStyles((theme) => ({
  editMediaIconLabel: {
    position: "absolute",
    right: "calc(-20px / 2)",
    bottom: "calc(-20px / 2)",
    display: "none",
  },
  editMediaIconRoot: {
    borderRadius: "50%",
    border: `1px solid ${theme.palette.text.light}`,
    background: theme.palette.common.aliceBlue,
    width: 25,
    height: 25,
    cursor: "pointer",
    "&>svg": {
      color: theme.palette.text.light,
      width: 15,
      height: 15,
    },
  },
}));

export default function EditMediaIcon({
  onClick,
  onChange,
  multiple = false,
  className = "",
  accept,
  inputId,
  ...props
}) {
  const classes = useStyle();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* <input
        id={inputId}
        className="display-none"
        onClick={onClick}
        onChange={onChange}
        multiple={multiple}
        type="file"
        accept={accept}
      />*/}
      <label
        className={classes.editMediaIconLabel}
      >
        <Box
          onClick={() => handleOpen()}
          className={classNames(classes.editMediaIconRoot, "center", className)}
        >
          <EditOutlinedIcon />
        </Box>
        <ImageSelectDialog
          open={open}
          onClose={handleClose}
          onCrop={(croppedImage) => onChange({ target: { files: [croppedImage] } })}
        />
      </label>
    </>
  );
}
