import React, { lazy } from "react";
import { connect } from "react-redux";
import { ThreeColumnGridView } from "../components/common/GridViews";
const ProfileDashboard = lazy(() =>
  import("../components/HomeComponents/ProfileDashboard")
);
const MyConnectionsComponent = lazy(() =>
  import("../components/ConnectionComponents/MyConnectionsComponent")
);
const NotificationCenterComponent = lazy(() =>
  import("../components/NotificationComponents/NotificationCenterComponent")
);
const LeftNavbar = lazy(() =>
  import("../components/HomeComponents/LeftNavbar")
);
const ConnectionSuggestion = lazy(() =>
  import("../components/ConnectionComponents/ConnectionSuggestion")
);
const MaxWidthComponent = lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = lazy(() => import("../components/common/Body"));

function NotificationsContainer({ userData, getAllConnectionsData }) {
  return (
    <Body>
      <MaxWidthComponent className={"manageMT15"}>
        <ThreeColumnGridView
          leftSide={
            <>
              <ProfileDashboard
                myProfile={1}
                userData={userData}
                getAllConnectionsData={getAllConnectionsData}
                loading={userData?.loading}
              />
              <LeftNavbar userData={userData} />
            </>
          }
          center={<NotificationCenterComponent />}
          rightSide={
            <>
              <ConnectionSuggestion />
              <MyConnectionsComponent
                myProfile={1}
                userData={userData}
                getAllConnectionsData={getAllConnectionsData}
              />
            </>
          }
        />
      </MaxWidthComponent>
    </Body>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);
