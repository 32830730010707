import React, { lazy, useEffect } from "react";
import "../scss/Blog.scss";
import { connect } from "react-redux";
import { TwoColumnGridView } from "../components/common/GridViews";
import { EMPTY_PROFILE_USER_DATA } from "../actions/UserAction";
const UserPostListing = lazy(() =>
  import("../components/UserPostListing/UserPostListing")
);
const LeftNavbar = lazy(() =>
  import("../components/HomeComponents/LeftNavbar")
);
const ProfileDashboard = lazy(() =>
  import("../components/HomeComponents/ProfileDashboard")
);
const MaxWidthComponent = lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const Body = lazy(() => import("../components/common/Body"));

function UserPostListContainer(props) {
  const { userData, profileUserData , emptyProfileUserData} = props;

  useEffect(() => {
    emptyProfileUserData()
  },[])

  return (
    <>
      <Body>
        <MaxWidthComponent className="PostGridContainer">
          <TwoColumnGridView
            type={"singleLeftColumn"}
            leftSide={
              <>
                <ProfileDashboard
                  myProfile={1}
                  userData={userData}
                  loading={userData?.loading || profileUserData?.loading}
                />
                <LeftNavbar userData={userData} />
              </>
            }
            rightSide={<UserPostListing />}
          />
        </MaxWidthComponent>
      </Body>
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  profileUserData: state.UserData.getProfileUserData,
});

const mapDispatchToProps = (dispatch) => ({
  emptyProfileUserData: (data) =>
  dispatch({
    type: EMPTY_PROFILE_USER_DATA,
    data,
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPostListContainer);
