import React from "react";
import { ReactComponent as SuccessSnackbarIcon } from "../../assets/SuccessSnackbarIcon.svg";
import { ReactComponent as ErrorSnackbarIcon } from "../../assets/ErrorSnackbarIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/CloseIcon.svg";
import { Grid, Typography, Box } from "@mui/material";
import { useSnackbar, SnackbarProvider } from "notistack";

export function SnackbarProviderComponent({ children }) {
  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      autoHideDuration={5000}
      maxSnack={"infinite"}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
}

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

const getSnackbar = (variant, msg) => {

  let styleObj = { background: "", logoColor: "", icon: "" };
  if (variant === "success") {
    styleObj["background"] = "rgb(235 255 225)";
    styleObj["logoColor"] = "#3DBC60";
    styleObj["icon"] = <SuccessSnackbarIcon />;
  } else if (variant === "error") {
    styleObj["background"] = "rgb(252 234 218)";
    styleObj["logoColor"] = "#EB4E2C";
    styleObj["icon"] = <ErrorSnackbarIcon />;
  } else if (variant === "warning") {
    styleObj["background"] = "rgb(255 249 238)";
    styleObj["logoColor"] = "#EF9400";
    styleObj["icon"] = <ErrorSnackbarIcon />;
  } else {
    styleObj["background"] = "";
    styleObj["logoColor"] = "";
    styleObj["icon"] = "";
  }
  return (
    <Box
      sx={{
        borderRadius: "10px",
        // backdropFilter: "blur(15px)",
        border: "1px solid" + styleObj?.logoColor,
        background: styleObj?.background,
        padding: "10px",
        boxShadow: "0px 0px 5px -2px rgba(0, 0, 0, 0.25)",
        cursor: "pointer",
      }}
    >
      <Grid container alignItems="center">
        <Box
          sx={{
            width: 30,
            height: 30,
            borderRadius: "5px",
            background: styleObj?.logoColor,
            marginRight: "10px",
          }}
          className="center"
        >
          {styleObj?.icon}
        </Box>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            maxWidth: 300,
            minWidth: 250,
          }}
        >
          {msg}
        </Typography>
        <Box
          className={"center"}
          sx={{
            padding: "10px",
            "&>svg": {
              width: 15,
              height: 15,
              fill: "#000",
            },
          }}
        >
          <CloseIcon />
        </Box>
      </Grid>
    </Box>
  );
};

const toast = {
  success(msg, options) {
    this.toast(msg, "success", options);
  },
  warning(msg, options) {
    this.toast(msg, "warning", options);
  },
  info(msg, options) {
    this.toast(msg, "info", options);
  },
  error(msg, options) {
    this.toast(msg, "error", options);
  },
  toast(msg, variant = "default", options = {}) {
    let key = useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      content: getSnackbar(variant, msg),
      onClick: () => {
        useSnackbarRef.closeSnackbar(key);
      },
      ...options,
    });
  },
};

export default toast;
