import "date-fns";
import React from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as Calender } from "../../assets/calender.svg";
import DateFnsUtils from "@date-io/date-fns";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  dataPickerRoot: {
    width: "100%",
    background: theme.palette.common.white,
    "&>div": {
      border: `1px solid ${theme.palette.border.borderColor}`,
      borderRadius: "8px",
      padding: "5px 10px",
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    },
  },
  has_error: {
    "&>div": {
      border: `1px solid ${theme.palette.common.red}`,
    },
    "&>p": {
      color: theme.palette.common.red,
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.common.red}`,
      },
    },
  },
}));

const defaultFunction = () => {};

export default function DatePicker({
  value = null,
  id,
  rootClassName,
  className,
  onChange = defaultFunction,
  format,
  components,
  disableCloseOnSelect,
  disabled = false,
  disableUnderline = true,
  keyboardIcon = <Calender />,
  required = true,
  placeholder,
  maxDate,
  minDate,
  InputProps = {
    disableUnderline: disableUnderline,
  },
  helperText,
  has_error = false,
  ...other
}) {
  const classes = useStyle();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} className={rootClassName}>
      <KeyboardDatePicker
        id={id}
        format={format}
        value={value}
        required={required}
        onChange={onChange}
        components={components}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        className={classNames(
          classes.dataPickerRoot,
          className,
          has_error && classes.has_error
        )}
        InputProps={InputProps}
        keyboardIcon={keyboardIcon}
        placeholder={placeholder}
        maxDate={maxDate}
        minDate={minDate}
        helperText={helperText}
        {...other}
      />
    </MuiPickersUtilsProvider>
  );
}
