import React from "react";
import utils from "../utils/commonUtils";
import CF from "../utils/CommonFunction";
import useViewport from "../hooks/useViewport";
const BottomTab = React.lazy(() =>
  import("../components/MobileBottomTab/BottomTab")
);

function BottomTabContainer(props) {
  const { path } = props;
  const { width } = useViewport();
  const showMobileFooter =
    !utils.getAuthRouts().includes(path) &&
    !utils.getNotShowHeaderFooterRouts().includes(path) &&
    !CF.showWebScreen(width)
      ? path === "messages"
        ? false
        : true
      : false;

  return showMobileFooter ? <BottomTab {...props} /> : "";
}
export default BottomTabContainer;
