import {
  EDUCATION_HISTORY_START,
  EDUCATION_HISTORY_SUCCESS,
  EDUCATION_HISTORY_ERROR,
  GET_EDUCATION_HISTORY_START,
  GET_EDUCATION_HISTORY_SUCCESS,
  GET_EDUCATION_HISTORY_ERROR,
  UPDATE_EDUCATION_HISTORY_START,
  UPDATE_EDUCATION_HISTORY_SUCCESS,
  UPDATE_EDUCATION_HISTORY_ERROR,
  DELETE_EDUCATION_HISTORY_START,
  DELETE_EDUCATION_HISTORY_SUCCESS,
  DELETE_EDUCATION_HISTORY_ERROR,
  WORK_HISTORY_START,
  WORK_HISTORY_SUCCESS,
  WORK_HISTORY_ERROR,
  GET_WORK_HISTORY_START,
  GET_WORK_HISTORY_SUCCESS,
  GET_WORK_HISTORY_ERROR,
  UPDATE_WORK_HISTORY_START,
  UPDATE_WORK_HISTORY_SUCCESS,
  UPDATE_WORK_HISTORY_ERROR,
  DELETE_WORK_HISTORY_START,
  DELETE_WORK_HISTORY_SUCCESS,
  DELETE_WORK_HISTORY_ERROR,
  GET_USER_DATA_ERROR,
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  GET_PROFILE_USER_DATA_ERROR,
  GET_PROFILE_USER_DATA_START,
  GET_PROFILE_USER_DATA_SUCCESS,
  GET_PROFILE_DETAILS_ERROR,
  GET_PROFILE_DETAILS_START,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PERFORMING_ROLES_START,
  GET_PERFORMING_ROLES_ERROR,
  GET_PERFORMING_ROLES_SUCCESS,
  UPDATE_PROFILE_DETAILS_ERROR,
  UPDATE_PROFILE_DETAILS_START,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_USER_DATA_ERROR,
  UPDATE_USER_DATA_START,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_PROFILE_PICTURE_ERROR,
  UPDATE_USER_PROFILE_PICTURE_START,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  GET_ALL_USERS_ERROR,
  GET_ALL_USERS_START,
  GET_ALL_USERS_SUCCESS,
  GET_SEARCH_DATA_ERROR,
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_SUCCESS,
  SOCKET_IO_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_CONNECTION_REQUEST_NOTIFICATION,
  UPDATE_PROFILE_USER_CONNECTION_STATUS,
  EMPTY_PROFILE_USER_DATA,
  GET_COMPANY_PERFORMING_ROLES_START,
  GET_COMPANY_PERFORMING_ROLES_SUCCESS,
  GET_COMPANY_PERFORMING_ROLES_ERROR,
  GET_COMPANY_PROFILE_DETAILS_START,
  GET_COMPANY_PROFILE_DETAILS_SUCCESS,
  GET_COMPANY_PROFILE_DETAILS_ERROR,
} from "../actions/UserAction";

const initialState = {
  getUserData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  updateUserData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  getProfileDetails: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  getPerformingRoles: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  profileDetailData: {
    loading: false,
    profileInfo: {},
    errorMsg: "",
  },
  updateProfileDetail: {
    loading: false,
    profileInfo: {},
    errorMsg: "",
  },
  performingRolesData: {
    loading: false,
    performingRolesInfo: {},
    errorMsg: "",
  },
  userEducationData: {
    loading: false,
    userEducationInfo: {},
    errorMsg: "",
  },
  getUserEducationData: {
    loading: false,
    userEducationInfo: {},
    errorMsg: "",
  },
  updateUserEducationData: {
    loading: false,
    userEducationInfo: {},
    errorMsg: "",
  },
  deleteUserEducationData: {
    loading: false,
    userEducationInfo: {},
    errorMsg: "",
  },
  userWorkData: {
    loading: false,
    userWorkInfo: {},
    errorMsg: "",
  },
  getUserWorkData: {
    loading: false,
    userWorkInfo: {},
    errorMsg: "",
  },
  updateUserWorkData: {
    loading: false,
    userWorkInfo: {},
    errorMsg: "",
  },
  deleteUserWorkData: {
    loading: false,
    userWorkInfo: {},
    errorMsg: "",
  },
  updateUserProfilePictureData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getAllUsersData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getSearchData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  updateUserProfileStepData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getProfileUserData: {
    loading: false,
    userInfo: {},
    errorMsg: "",
  },
  getCompanyPerformingRolesData: {
    loading: false,
    data: {}
  },
  getCompanyProfileData: {
    loading: false,
    data: {}
  }
};

const UserReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA_START: {
      return {
        ...previousState,
        getUserData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_USER_DATA_SUCCESS: {
      return {
        ...previousState,
        getUserData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_USER_DATA_ERROR: {
      return {
        ...previousState,
        getUserData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case EMPTY_PROFILE_USER_DATA: {
      return {
        ...previousState,
        getProfileUserData: {
          loading: false,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_USER_DATA_START: {
      return {
        ...previousState,
        getProfileUserData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_USER_DATA_SUCCESS: {
      return {
        ...previousState,
        getProfileUserData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_USER_DATA_ERROR: {
      return {
        ...previousState,
        getProfileUserData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_USER_PROFILE_PICTURE_START: {
      return {
        ...previousState,
        updateUserProfilePictureData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_USER_PROFILE_PICTURE_SUCCESS: {
      return {
        ...previousState,
        updateUserProfilePictureData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_USER_PROFILE_PICTURE_ERROR: {
      return {
        ...previousState,
        updateUserProfilePictureData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_USER_DATA_START: {
      return {
        ...previousState,
        updateUserData: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_USER_DATA_SUCCESS: {
      return {
        ...previousState,
        updateUserData: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: action?.data?.data,
          },
        },
      };
    }
    case UPDATE_USER_DATA_ERROR: {
      return {
        ...previousState,
        updateUserData: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_PROFILE_DETAILS_START: {
      return {
        ...previousState,
        getProfileDetails: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_DETAILS_SUCCESS: {
      return {
        ...previousState,
        getProfileDetails: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_DETAILS_ERROR: {
      return {
        ...previousState,
        getProfileDetails: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_PROFILE_DETAILS_START: {
      return {
        ...previousState,
        updateProfileDetail: {
          loading: true,
          profileInfo: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_PROFILE_DETAILS_SUCCESS: {
      return {
        ...previousState,
        updateProfileDetail: {
          loading: false,
          profileInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_profile: action.data.data,
            },
          },
        },
      };
    }
    case UPDATE_PROFILE_DETAILS_ERROR: {
      return {
        ...previousState,
        updateProfileDetail: {
          loading: false,
          profileInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_PERFORMING_ROLES_START: {
      return {
        ...previousState,
        getPerformingRoles: {
          loading: true,
          userInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_PERFORMING_ROLES_SUCCESS: {
      return {
        ...previousState,
        getPerformingRoles: {
          loading: false,
          userInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_PERFORMING_ROLES_ERROR: {
      return {
        ...previousState,
        getPerformingRoles: {
          loading: false,
          userInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case EDUCATION_HISTORY_START: {
      return {
        ...previousState,
        userEducationData: {
          loading: true,
          userEducationInfo: {},
          errorMsg: "",
        },
      };
    }
    case EDUCATION_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      return {
        ...previousState,
        userEducationData: {
          loading: false,
          userEducationInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_education_histories:
                oldUserData?.data?.user_education_histories === null
                  ? [action?.data?.data]
                  : [
                    ...oldUserData?.data?.user_education_histories,
                    action.data.data,
                  ],
            },
          },
        },
      };
    }
    case EDUCATION_HISTORY_ERROR: {
      return {
        ...previousState,
        userEducationData: {
          loading: false,
          userEducationInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_EDUCATION_HISTORY_START: {
      return {
        ...previousState,
        getUserEducationData: {
          loading: true,
          userEducationInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_EDUCATION_HISTORY_SUCCESS: {
      return {
        ...previousState,
        getUserEducationData: {
          loading: false,
          userEducationInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_EDUCATION_HISTORY_ERROR: {
      return {
        ...previousState,
        getUserEducationData: {
          loading: false,
          userEducationInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_EDUCATION_HISTORY_START: {
      return {
        ...previousState,
        updateUserEducationData: {
          loading: true,
          userEducationInfo: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_EDUCATION_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      const newUserData = oldUserData?.data?.user_education_histories.map(
        (item) => {
          return item?.id === action?.data?.data?.id
            ? action?.data?.data
            : item;
        }
      );
      return {
        ...previousState,
        updateUserEducationData: {
          loading: false,
          userEducationInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_education_histories: newUserData,
            },
          },
        },
      };
    }
    case UPDATE_EDUCATION_HISTORY_ERROR: {
      return {
        ...previousState,
        updateUserEducationData: {
          loading: false,
          userEducationInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case DELETE_EDUCATION_HISTORY_START: {
      return {
        ...previousState,
        deleteUserEducationData: {
          loading: true,
          userEducationInfo: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_EDUCATION_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      const newUserData = oldUserData?.data?.user_education_histories?.filter(
        (item) => {
          return item?.id !== action?.data?.data;
        }
      );
      return {
        ...previousState,
        deleteUserEducationData: {
          loading: false,
          userEducationInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_education_histories: newUserData,
            },
          },
        },
      };
    }
    case DELETE_EDUCATION_HISTORY_ERROR: {
      return {
        ...previousState,
        deleteUserEducationData: {
          loading: false,
          userEducationInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case WORK_HISTORY_START: {
      return {
        ...previousState,
        userWorkData: {
          loading: true,
          userWorkInfo: {},
          errorMsg: "",
        },
      };
    }
    case WORK_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      return {
        ...previousState,
        userWorkData: {
          loading: false,
          userWorkInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_work_histories:
                oldUserData?.data?.user_work_histories === null
                  ? [action?.data?.data]
                  : [
                    ...oldUserData?.data?.user_work_histories,
                    action.data.data,
                  ],
            },
          },
        },
      };
    }
    case WORK_HISTORY_ERROR: {
      return {
        ...previousState,
        userWorkData: {
          loading: false,
          userWorkInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_WORK_HISTORY_START: {
      return {
        ...previousState,
        getUserWorkData: {
          loading: true,
          userWorkInfo: {},
          errorMsg: "",
        },
      };
    }
    case GET_WORK_HISTORY_SUCCESS: {
      return {
        ...previousState,
        getUserWorkData: {
          loading: false,
          userWorkInfo: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_WORK_HISTORY_ERROR: {
      return {
        ...previousState,
        getUserWorkData: {
          loading: false,
          userWorkInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_WORK_HISTORY_START: {
      return {
        ...previousState,
        updateUserWorkData: {
          loading: true,
          userWorkInfo: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_WORK_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      const newUserData = oldUserData?.data?.user_work_histories?.map(
        (item) => {
          return item?.id === action?.data?.data?.id
            ? action?.data?.data
            : item;
        }
      );
      return {
        ...previousState,
        updateUserWorkData: {
          loading: false,
          userWorkInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_work_histories: newUserData,
            },
          },
        },
      };
    }
    case UPDATE_WORK_HISTORY_ERROR: {
      return {
        ...previousState,
        updateUserWorkData: {
          loading: false,
          userWorkInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case DELETE_WORK_HISTORY_START: {
      return {
        ...previousState,
        deleteUserWorkData: {
          loading: true,
          userWorkInfo: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_WORK_HISTORY_SUCCESS: {
      const oldUserData = { ...previousState?.getUserData?.userInfo };
      const newUserData = oldUserData?.data?.user_work_histories?.filter(
        (item) => {
          return item?.id !== action?.data?.data;
        }
      );
      return {
        ...previousState,
        deleteUserWorkData: {
          loading: false,
          userWorkInfo: action.data,
          errorMsg: "",
        },
        getUserData: {
          ...previousState?.getUserData,
          userInfo: {
            ...previousState?.getUserData?.userInfo,
            data: {
              ...previousState?.getUserData?.userInfo?.data,
              user_work_histories: newUserData,
            },
          },
        },
      };
    }
    case DELETE_WORK_HISTORY_ERROR: {
      return {
        ...previousState,
        deleteUserWorkData: {
          loading: false,
          userWorkInfo: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_ALL_USERS_START: {
      return {
        ...previousState,
        getAllUsersData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_ALL_USERS_SUCCESS: {
      return {
        ...previousState,
        getAllUsersData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_ALL_USERS_ERROR: {
      return {
        ...previousState,
        getAllUsersData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_SEARCH_DATA_START: {
      return {
        ...previousState,
        getSearchData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_SEARCH_DATA_SUCCESS: {
      return {
        ...previousState,
        getSearchData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_SEARCH_DATA_ERROR: {
      return {
        ...previousState,
        getSearchData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case SOCKET_IO_EMAIL_VERIFICATION_SUCCESS: {
      return {
        ...previousState,
        getUserData: {
          ...previousState.getUserData,
          userInfo: {
            ...previousState.getUserData.userInfo,
            data: {
              ...previousState.getUserData.userInfo?.data,
              is_verified: action.data.is_verified,
            },
          },
        },
      };
    }
    case UPDATE_CONNECTION_REQUEST_NOTIFICATION: {
      let userData = { ...previousState.getUserData?.userInfo };
      userData["data"]["connection_request_notification"] = action.data;
      return {
        ...previousState,
        getUserData: {
          ...previousState.getUserData,
          userInfo: userData,
        },
      };
    }
    case UPDATE_PROFILE_USER_CONNECTION_STATUS: {
      const profileUser = { ...previousState.getProfileUserData.userInfo };
      const { userId, inviter, invitee, id, type, status } = action?.data;
      if (type === "new") {
        if (userId === inviter) {
          if (profileUser?.data?.id === invitee) {
            profileUser["data"]["status"] = "withdraw";
            profileUser["data"]["connectionReqData"] = { id, invitee, inviter };
          }
        } else {
          if (profileUser?.data?.id === inviter) {
            profileUser["data"]["status"] = "pending";
            profileUser["data"]["connectionReqData"] = { id, invitee, inviter };
          }
        }
      } else if (type === "cancel") {
        if (inviter === userId && invitee === profileUser?.data?.id) {
          profileUser["data"]["connectionReqData"] = null;
          profileUser["data"]["status"] = "connection";
        }
      } else if (type === "accepted") {
        if (inviter === profileUser?.data?.id) {
          profileUser["data"]["connectionReqData"] = null;
          profileUser["data"]["status"] = "accepted";
        }
      } else if (status === "rejected") {
        if (inviter === profileUser?.data?.id) {
          profileUser["data"]["connectionReqData"] = null;
          profileUser["data"]["status"] = "connection";
        }
      } else {
        if (inviter === profileUser?.data?.id) {
          profileUser["data"]["connectionReqData"] = null;
          profileUser["data"]["status"] = "connection";
        }
      }
      return {
        ...previousState,
        getProfileUserData: {
          ...previousState.getProfileUserData,
          userInfo: profileUser,
        },
      };
    }
    case GET_COMPANY_PERFORMING_ROLES_START: {
      return {
        ...previousState,
        getCompanyPerformingRolesData: {
          loading: true,
          data: {}
        }
      };
    }
    case GET_COMPANY_PERFORMING_ROLES_SUCCESS: {
      return {
        ...previousState,
        getCompanyPerformingRolesData: {
          loading: false,
          data: action.data.data
        }
      };
    }
    case GET_COMPANY_PERFORMING_ROLES_ERROR: {
      return {
        ...previousState,
        getCompanyPerformingRolesData: {
          loading: false,
          data: {}
        }
      }
    }
    case GET_COMPANY_PROFILE_DETAILS_START: {
      return {
        ...previousState,
        getCompanyProfileData: {
          loading: true,
          data: {}
        }
      };
    }
    case GET_COMPANY_PROFILE_DETAILS_SUCCESS: {
      return {
        ...previousState,
        getCompanyProfileData: {
          loading: false,
          data: action.data.data
        }
      };
    }
    case GET_COMPANY_PROFILE_DETAILS_ERROR: {
      return {
        ...previousState,
        getCompanyProfileData: {
          loading: false,
          data: {}
        }
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default UserReducers;