import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import classNames from "classnames";
import H5 from "../Headings/H5";
import ViewMore from "../HomeComponents/ViewMore";

const useStyle = makeStyles((theme) => ({
  lrRoot: {
    padding: 20,
    "&>h5": {
      paddingBottom: 15,
      borderBottom: `1px solid ${theme.palette.line.lightgray}`,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      color: theme.palette.common.black,
      "&>svg": {
        marginLeft: 5,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
      "&>h5": {
        paddingBottom: 10,
      },
    },
  },
  lrZeroBottomPadding: {
    paddingBottom: 0,
  },
}));

export default function LeftRightComponent({
  heading,
  children,
  viewMoreText = "Show More",
  handleViewMoreClick,
  showViewMore = false,
  className = "",
  zeroBottomPadding = false,
}) {
  const classes = useStyle();
  return (
    <Box className={classNames("lrComponent", className)}>
      <Box
        className={classNames(
          classes.lrRoot,
          zeroBottomPadding && classes.lrZeroBottomPadding
        )}
      >
        <H5 light>{heading}</H5>
        {children}
      </Box>
      {showViewMore && (
        <ViewMore onClick={handleViewMoreClick}>{viewMoreText}</ViewMore>
      )}
    </Box>
  );
}
