import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { connect } from "react-redux";
import moment from "moment";
import utils from "../../utils/commonUtils";
import { useHistory } from "react-router-dom";
import { DELETE_FEED_START } from "../../actions/FeedAction";
import LockIcon from "@mui/icons-material/Lock";
import { ReactComponent as EarthIcon } from "../../assets/EarthIcon.svg";
import { Body2 } from "../Typography/Typographys";
import "../../scss/Feed.scss";
import UserAvatar from "../common/UserAvatar";
import EditFeedDialog from "../Dialogs/EditFeedDialog";
import Tooltip from "../common/Tooltip";
import H6 from "../Headings/H6";
import AlertDialog from "../Dialogs/AlertDialog";
import Dropdown from "../common/Dropdown";
import ConnAndMsgComponent from "../ConnectionComponents/ConnAndMsgComponent";
import { FeedHeaderBreadCrumb } from "../common/BreadCrumbCommonComponent";
import DisplayName from "../common/DisplayName";


function FeedHeader({
  feedData,
  userData,
  deleteFeed,
  getAllConnectionsData,
  getConnectionRequestsData,
}) {
  const [menuCardDiv, setMenuCardDiv] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openDeleteFeedDialog, setOpenDeleteFeedDialog] = useState(false);
  const history = useHistory();
  const [connectionsData, setConnectionsData] = useState([]);
  const [connectionRequest, setConnectionRequest] = useState([]);

  const handleDeleteFeedDialogClose = (value) => {
    if (value) {
      deleteFeed(utils.toBase64(feedData?.id));
    }
    setOpenDeleteFeedDialog(false);
  };

  const handleClose = () => {
    setMenuCardDiv(null);
  };

  const getFeedPrivacyIconDetails = () => {
    if (feedData?.group_id) {
      if (feedData?.group?.is_private) {
        return {
          is_private: true,
          title: "Private group",
        };
      } else {
        return {
          is_private: false,
          title: "Public group",
        };
      }
    } else if (feedData?.blog_id) {
      return {
        is_private: false,
        title: "Public blog",
      };
    } else if (feedData?.feed_privacy === 2) {
      return {
        is_private: true,
        title: "Private",
      };
    } else {
      return {
        is_private: false,
        title: "Public",
      };
    }
  };

  const handleClick = (i, type) => {
    handleClose();
    if (type === "edit") {
      setOpen(true);
    } else if (type === "delete") {
      setOpenDeleteFeedDialog(true);
    }
  };


  useEffect(() => {
    if (getAllConnectionsData?.data?.status === "1") {
      setConnectionsData(
        getAllConnectionsData?.data?.data?.map((item) => {
          return item?.inviter === userData?.userInfo?.data?.id
            ? item?.invitee
            : item?.inviter;
        })
      );
    }
  }, [getAllConnectionsData]);

  useEffect(() => {
    if (getConnectionRequestsData?.data?.status === "1") {
      setConnectionRequest(
        getConnectionRequestsData?.data?.data?.map((item) => {
          return item?.inviter === userData?.userInfo?.data?.id
            ? item?.invitee
            : item?.inviter;
        })
      );
    }
  }, [getConnectionRequestsData]);

  let menuItemList = [];
  if (feedData?.user_id === userData?.userInfo?.data?.id) {
    if (feedData?.feed_type_id !== 6) {
      menuItemList.push({ label: "Edit", type: "edit" });
    }
    menuItemList.push({ label: "Delete", type: "delete" });
  } else {
    menuItemList.push({
      label: (
        <ConnAndMsgComponent
          type={"simpleMessageText"}
          currentUser={{ id: feedData?.user_id }}
        />
      ),
      type: "message",
    });
    menuItemList.push({ label: "Report", type: "report" });
  }

  return (
    <Box className="ldrabRoot feedHeaderRoot">
      <Box>
        <UserAvatar
          src={feedData?.user?.profile_picture}
          name={feedData?.user?.display_name}
          onClick={() =>
            history.push("/user-profile/" + feedData?.user?.slug + "/0")
          }
        />
        <Box>
          {feedData?.feed_type_id === 7 ? (
            <FeedHeaderBreadCrumb feedData={feedData} />
          ) : (
            <Box className="subHeader">
              <H6
                className="userName"
                medium
                ellipsis
                onClick={() =>
                  history.push("/user-profile/" + feedData?.user?.slug + "/0")
                }
                title={feedData?.user?.display_name}
              >
                <DisplayName user={feedData?.user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
              </H6>
              {!connectionsData.includes(feedData?.user_id) &&
                !connectionRequest.includes(feedData?.user_id) &&
                feedData?.user_id !== userData?.userInfo?.data?.id && (
                  <ConnAndMsgComponent
                    type={"connectionSuggestion"}
                    currentUser={{
                      id: feedData?.user_id,
                      display_name: feedData?.user?.display_name,
                      status: feedData?.connection ? "withdraw" : "connection",
                      con_id: feedData?.connection?.id,
                    }}
                  />
                )}
            </Box>
          )}
          <Body2>
            {moment(feedData?.createdAt).fromNow()}
            <Tooltip hover title={getFeedPrivacyIconDetails()?.title}>
              {getFeedPrivacyIconDetails()?.is_private ? (
                <LockIcon />
              ) : (
                <EarthIcon />
              )}
            </Tooltip>
          </Body2>
        </Box>
      </Box>
      <Box className="moreIcon">
        <MoreHorizIcon
          onClick={(e) => {
            setMenuCardDiv(e.currentTarget);
          }}
          fill="red"
        />
      </Box>
      <EditFeedDialog open={open} setOpen={setOpen} feedData={feedData} />
      <Dropdown
        className="feedsMenu"
        anchorEl={menuCardDiv}
        handleClose={handleClose}
        handleClick={handleClick}
        menuItemList={menuItemList}
      />
      <AlertDialog
        open={openDeleteFeedDialog}
        handleClose={handleDeleteFeedDialogClose}
        dialogTitle="Permanentlty Delete Feed."
        dialogContent="Are you sure you want to permanently delete this feed?"
        successBtnText="Delete Feed"
      />
    </Box>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
  getConnectionRequestsData: state.ConnectionsData.getConnectionRequestsData,
});

const mapDispatchToProps = (dispatch) => ({
  deleteFeed: (feed_id) =>
    dispatch({
      type: DELETE_FEED_START,
      feed_id,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedHeader);
