import { post, get, deleteM } from "../Api";

export const GetAllGroups = async (data) => {
  return await post(`/group/get-all`, data);
};

export const GetGroupMembers = async (slug) => {
  return await get(`/group/get-members/${slug}`);
};

export const CreateGroup = async (groupData) => {
  return await post(`/group/create-new`, groupData);
};

export const UploadGroupIcon = async (slug, groupIconData) => {
  return await post(`/group/upload-group-icon/${slug}`, groupIconData);
};

export const GetGroupSuggestions = async (offset) => {
  return await post(`/group/get-group-suggestions`, { offset });
};

export const JoinNewGroup = async (group_id) => {
  return await post(`/group/join-new-group`, { group_id });
};

export const UpdateGroup = async (id, groupData) => {
  return await post(`/group/update/${id}`, groupData);
};

export const GetGroupJoiningRequests = async (data) => {
  return await post(`/group/get-join-requests`, data);
};

export const CancelGroupJoinRequest = async (group_id, admin_id) => {
  return await post(`/group/cancle-join-request`, { group_id, admin_id });
};

export const UpdateGroupJoinRequest = async (id, user_id, group_id, status) => {
  return await post(`/group/update-join-request`, {
    id,
    user_id,
    group_id,
    status,
  });
};

export const GetGroupDetails = async (slug) => {
  return await get(`/group/get-group-details/${slug}`);
};

export const LeaveGroup = async (data) => {
  return await post(`/group/leave-group/${data.slug}`);
};

export const DeleteGroupMembers = async (data) => {
  return await post(`/group/delete-group-users/${data.slug}`, {
    user_ids: data?.user_ids,
  });
};

export const DeleteGroup = async (data) => {
  return await deleteM(`/group/delete-group/${data.slug}`);
};
