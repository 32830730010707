import React, { useEffect, useState } from "react";
import { Grid, useTheme, useMediaQuery, makeStyles, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { GET_PROFILE_FEED_START } from "../actions/FeedAction";
import { useHistory, useParams } from "react-router-dom";
import utils from "../utils/commonUtils";
import {
  GET_ALL_CONNECTIONS_START,
  GET_CONNECTION_REQUESTS_START,
} from "../actions/ConnectionsAction";
import { GET_PROFILE_USER_DATA_START } from "../actions/UserAction";
import { UPDATE_CONNECTION_REQUEST_NOTIFICATION } from "../actions/UserAction";
import { useIntersection } from "../hooks/customHooks";
import { socket } from "../utils/socketioSetup";
import { ThreeColumnGridView } from "../components/common/GridViews";
import "../scss/UserProfile.scss";
import "../scss/ZeroStateComponents.scss";
import {
  MyConncetionSkeleton,
  SkeletonComponent,
} from "../components/common/Skeletons";
import {
  ZeroStateMyConnection,
  ZeroStateUserBackgroundTab,
} from "../components/common/ZeroStateComponents";
import {
  ACTIVE_EDIT_PROFILE_FORM_INDEX,
  EDIT_PROFILE_STATUS,
} from "../actions/CommonAction";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import H3 from "../components/Headings/H3";
import ProfileDashboard from "../components/HomeComponents/ProfileDashboard";
import Feeds from "../components/HomeComponents/Feeds";
import PersonalInfo from "../components/ProfileComponents/PersonalInfo";
import ProfileDetails from "../components/ProfileComponents/ProfileDetails";
import About from "../components/ProfileComponents/About";
import EducationDetails from "../components/ProfileComponents/EducationDetails";
import WorkDetails from "../components/ProfileComponents/WorkDetails";
import MyConnectionsComponent from "../components/ConnectionComponents/MyConnectionsComponent";
import SimpleButton from "../components/Buttons/SimpleButton";
import LeftNavbar from "../components/HomeComponents/LeftNavbar";
import ConnectionSuggestion from "../components/ConnectionComponents/ConnectionSuggestion";
import ChangePassword from "../components/ProfileComponents/ChangePassword";
import UserDetails from "../components/ProfileComponents/UserDetails";
import MaxWidthComponent from "../components/common/MaxWidthComponent";
import Body from "../components/common/Body";
import TabsComponent from "../components/common/TabsComponent";
import EduOrWorkDetailForm from "../components/ProfileComponents/EduOrWorkDetailForm";
import ConnectionCard from "../components/ConnectionComponents/ConnectionCard";
import PeopleMayYouKnow from "../components/ProfileComponents/PeopleMayYouKnow";
import "../scss/Profile.scss";
import EditCompanyDetails from "./EditCompanyDetails";

let getProfileUserFlag = false;

const useStyles = makeStyles((theme) => ({
  wkMyConnectionContainer: {
    "&>div": {
      padding: "8px",
      boxSizing: "border-box",
    },
  },
  wkMyConnections: {
    "@media (min-width: 960px)": {
      maxWidth: "16.666667%",
      flexBasis: "16.666667%",
    },
    wkMyConnectionsRequest: {
      "&>div": {
        padding: "8px",
      },
    },
  },
  editCompanuDetailsConatiner: {

  },
}));

function ProfileContainer(props) {
  const {
    getProfileFeed,
    feedData,
    getProfileUser,
    userData,
    profileUserData,
    getAllConnections,
    getAllConnectionsData,
    getConnectionRequests,
    getConnectionRequestsData,
    updateConnectionRequestNotification,
    updateEditProfileStatus,
    updateActiveEditProfileFormIndex,
    activeEditProfileForm,
    isEditProfile,
  } = props;
  const { slug, step } = useParams();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const match = useMediaQuery(theme.breakpoints.down("sm"));
  const [myProfile, setMyProfile] = useState(1);
  const [activeStep, setActiveStep] = useState(
    [0, 1, 2, 3].includes(parseInt(step)) ? parseInt(step) : 0
  );
  const [updateId, setUpdateId] = useState(null);
  const [workUpdateId, setWorkUpdateId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1,
  });
  const [setConnReqTarget, connReqEntry] = useIntersection({
    threshold: 0.1,
  });
  const [connectionRequests, setConnectionRequests] = useState([]);

  useEffect(() => {
    if (getConnectionRequestsData?.data?.status === "1") {
      setConnectionRequests(getConnectionRequestsData?.data?.data);
    }
  }, [getConnectionRequestsData]);

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.1 && feedData?.data?.offsetStatus) {
      getProfileFeed(
        utils.toBase64(currentUser?.userInfo?.data?.id),
        feedData?.data?.data?.length
      );
    }
  }, [entry]);

  useEffect(() => {
    const { intersectionRatio } = connReqEntry;
    if (
      intersectionRatio >= 0.1 &&
      getConnectionRequestsData?.data?.offsetStatus
    ) {
      getConnectionRequests({
        offset: getConnectionRequestsData?.data?.data?.length,
      });
    }
  }, [connReqEntry]);

  useEffect(() => {
    history.push(`/user-profile/${slug}/${activeStep}`);
    window.scrollTo(0, 0);
    if (
      activeStep === 2 &&
      userData?.userInfo?.status === "1" &&
      userData?.userInfo?.data?.connection_request_notification
    ) {
      socket.emit("MarkAsReadConnectionRequestNotification", { status: false });
      updateConnectionRequestNotification(false);
    }
  }, [activeStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isEditProfile]);

  useEffect(() => {
    const intStep = parseInt(step);
    if (intStep !== activeStep) {
      setActiveStep(intStep);
    }
  }, [step]);

  useEffect(() => {
    if (profileUserData?.userInfo?.status === "1" && getProfileUserFlag) {
      getProfileFeed(utils.toBase64(profileUserData?.userInfo?.data?.id), 0);
      getAllConnections(utils.toBase64(profileUserData?.userInfo?.data?.id));
      getProfileUserFlag = false;
    }
    if (profileUserData?.userInfo?.status === "1") {
      setCurrentUser(profileUserData);
    }
  }, [profileUserData]);

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      if (slug !== userData?.userInfo?.data?.slug) {
        setMyProfile(0);
        getProfileUser({
          slug,
          user_id: utils.toBase64(userData?.userInfo?.data?.id),
        });
        getProfileUserFlag = true;
      } else {
        setMyProfile(1);
        setCurrentUser(userData);
        getConnectionRequests({ offset: 0 });
        getProfileFeed(utils.toBase64(userData?.userInfo?.data?.id), 0);
        getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
      }
    }
  }, [userData, slug]);

  const ChangeCallback = () => {
    setUpdateId(null)
    setWorkUpdateId(null)
  }

  const getActiveEditProfileForm = (index) => {
    switch (index) {
      case 0:
        return <PersonalInfo {...props} />;
      case 1:
        return (
          <EduOrWorkDetailForm
            updateId={updateId}
            setUpdateId={setUpdateId}
            type="education"
            {...props}
          />
        );
      case 2:
        return (
          <EduOrWorkDetailForm
            updateId={workUpdateId}
            setUpdateId={setWorkUpdateId}
            type="work"
            {...props}
          />
        );
      case 3:
        return <ChangePassword {...props} />;
      case 4:
        return <EditCompanyDetails userData={userData} updateEditProfileStatus={updateEditProfileStatus} />
      default:
        <PersonalInfo {...props} />;
    }
  };

  return (
    <Body>
      <MaxWidthComponent className="udBody">
        <UserDetails
          type="userProfile"
          loading={userData?.loading || profileUserData?.loading}
          data={{
            image: currentUser?.userInfo?.data?.profile_picture,
            name: currentUser?.userInfo?.data?.display_name,
            is_wkleap_verified: currentUser?.userInfo?.data?.is_wkleap_verified,
            roles: currentUser?.userInfo?.data?.user_performing_roles,
            description: currentUser?.userInfo?.data?.user_profile?.description,
            is_company_account: currentUser?.userInfo?.data?.is_company_account,
            company_profile: currentUser?.userInfo?.data?.company_profile?.name,
          }}
          actionBtns={
            slug === userData?.userInfo?.data?.slug ? (
              !isEditProfile ? (
                <SimpleButton
                  endIcon={<EditOutlinedIcon fontSize="small" />}
                  onClick={() => {
                    setActiveStep(1);
                    updateEditProfileStatus(true);
                  }}
                  transparentBorderBtn
                >
                  Edit Profile
                </SimpleButton>
              ) : null
            ) : null
          }
        />
      </MaxWidthComponent>
      <MaxWidthComponent className="upTabs">
        <TabsComponent
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          tabsList={[
            { label: "Activity" },
            { label: "Background" },
            { label: "Connections" },
            { label: "People You May Know" },
          ]}
          onClick={() => {
            if (isEditProfile) {
              updateEditProfileStatus(false);
            }
          }}
        />
      </MaxWidthComponent>
      <MaxWidthComponent className={"manageMT15"}>
        {activeStep === 0 && (
          <ThreeColumnGridView
            leftSide={
              <>
                <ProfileDashboard
                  myProfile={myProfile}
                  userData={currentUser}
                  getAllConnectionsData={getAllConnectionsData}
                  loading={userData?.loading || profileUserData?.loading}
                />
                <LeftNavbar myProfile={myProfile} userData={currentUser} activeIndex={activeEditProfileForm} ChangeCallback={ChangeCallback} />
              </>
            }
            center={<Feeds feedData={feedData} setTarget={setTarget} />}
            rightSide={<ConnectionSuggestion />}
          />
        )}
        {activeStep === 1 && !isEditProfile && (
          <ThreeColumnGridView
            leftSide={
              <ProfileDetails
                currentUser={currentUser}
                myProfile={myProfile}
                loading={userData?.loading || profileUserData?.loading}
              />
            }
            center={
              <>
                {match ? (
                  <ProfileDetails
                    currentUser={currentUser}
                    myProfile={myProfile}
                    loading={userData?.loading || profileUserData?.loading}
                  />
                ) : null}
                {myProfile === 0 &&
                  !currentUser?.userInfo?.data?.user_profile?.description &&
                  currentUser?.userInfo?.data?.user_education_histories
                    ?.length === 0 &&
                  currentUser?.userInfo?.data?.user_work_histories?.length ===
                  0 ? (
                  <ZeroStateUserBackgroundTab />
                ) : null}
                <About
                  currentUser={currentUser}
                  myProfile={myProfile}
                  loading={userData?.loading || profileUserData?.loading}
                  {...props}
                />
                <EducationDetails
                  setUpdateId={setUpdateId}
                  myProfile={myProfile}
                  currentUser={currentUser}
                  userData={userData}
                  loading={userData?.loading || profileUserData?.loading}
                  {...props}
                />
                <WorkDetails
                  setWorkUpdateId={setWorkUpdateId}
                  myProfile={myProfile}
                  userData={userData}
                  currentUser={currentUser}
                  loading={userData?.loading || profileUserData?.loading}
                  {...props}
                />
              </>
            }
            rightSide={
              <>
                <ConnectionSuggestion />
                <MyConnectionsComponent
                  myProfile={myProfile}
                  userData={currentUser}
                  getAllConnectionsData={getAllConnectionsData}
                />
              </>
            }
            centerClasses={"managegvccBottomMargin"}
          />
        )}
        {activeStep === 1 && isEditProfile && (
          <ThreeColumnGridView
            leftSide={
              <LeftNavbar
                myProfile={myProfile}
                userData={currentUser}
                location="editProfile"
                activeIndex={activeEditProfileForm}
                handleClick={(index) => updateActiveEditProfileFormIndex(index)}
                ChangeCallback={ChangeCallback}
              />
            }
            center={
              <>
                {getActiveEditProfileForm(activeEditProfileForm)}
                {activeEditProfileForm == 1 && currentUser?.userInfo?.data?.user_education_histories?.length > 0 &&
                  <div className="eduWorkWrapper">
                    <EducationDetails
                      setUpdateId={setUpdateId}
                      myProfile={myProfile}
                      currentUser={currentUser}
                      userData={userData}
                      loading={userData?.loading || profileUserData?.loading}
                      {...props}
                    />
                  </div>}
                {activeEditProfileForm == 2 && currentUser?.userInfo?.data?.user_work_histories?.length > 0 &&
                  <div className="eduWorkWrapper">
                    <WorkDetails
                      setWorkUpdateId={setWorkUpdateId}
                      myProfile={myProfile}
                      userData={userData}
                      currentUser={currentUser}
                      loading={userData?.loading || profileUserData?.loading}
                      {...props}
                    />
                  </div>}
              </>
            }
            rightSide={
              <>
                <ConnectionSuggestion />
                <MyConnectionsComponent
                  myProfile={myProfile}
                  userData={currentUser}
                  getAllConnectionsData={getAllConnectionsData}
                />
              </>
            }
          />
        )}
      </MaxWidthComponent>
      {activeStep === 2 && (
        <MaxWidthComponent>
          {userData?.loading || profileUserData?.loading ? (
            <SkeletonComponent
              width={"30%"}
              height={20}
              style={{ margin: "20px 0px" }}
            />
          ) : (
            <H3
              children={
                myProfile === 1
                  ? "My Connections"
                  : currentUser?.userInfo?.data?.first_name + "'s Connections"
              }
              className="connectionHeadings"
            />
          )}
          {
            <Grid
              container
              className={classes.wkMyConnectionContainer}
              spacing={2}
            >
              {userData?.loading ||
                profileUserData?.loading ||
                getAllConnectionsData?.loading ? (
                <MyConncetionSkeleton />
              ) : getAllConnectionsData?.data?.data?.length === 0 ? (
                <ZeroStateMyConnection>
                  {myProfile === 1
                    ? "You don't have any connections yet."
                    : currentUser?.userInfo?.data?.first_name +
                    " don't have any connections yet."}
                </ZeroStateMyConnection>
              ) : (
                getAllConnectionsData?.data?.data?.map((item, key) => {
                  const connUserData =
                    item?.inviter !== currentUser?.userInfo?.data?.id
                      ? item?.inviterUser
                      : item?.inviteeUser;
                  return (
                    <>
                      <Grid
                        item
                        md={2}
                        sm={3}
                        xs={6}
                        className={classes.wkMyConnections}
                      >
                        <ConnectionCard
                          user={connUserData}
                          myProfile={myProfile}
                          location="myConnection"
                          data={item}
                          key={key}
                        />
                      </Grid>
                    </>
                  );
                })
              )}
            </Grid>
          }
          {getConnectionRequestsData?.loading &&
            getConnectionRequestsData?.data?.offset === 0 ? (
            <SkeletonComponent
              width={"30%"}
              height={20}
              style={{ margin: "20px 0px" }}
            />
          ) : connectionRequests.length !== 0 && myProfile ? (
            <H3
              children={`My Connection Requests(${connectionRequests?.length})`}
              className="connectionHeadings"
            />
          ) : null}
          {getConnectionRequestsData?.loading &&
            getConnectionRequestsData?.data?.offset === 0 ? (
            <Grid container>
              <MyConncetionSkeleton />
            </Grid>
          ) : connectionRequests?.length !== 0 && myProfile ? (
            <>
              <Grid
                container
                spacing={2}
                className={classes.wkMyConnectionsRequest}
              >
                {connectionRequests?.map((item, key) => {
                  const requestUserData =
                    item?.inviter === currentUser?.userInfo?.data?.id
                      ? item?.inviteeUser
                      : item?.inviterUser;
                  return (
                    <>
                      <Grid
                        item
                        md={2}
                        sm={3}
                        xs={6}
                        className={classes.wkMyConnections}
                      >
                        <ConnectionCard
                          user={requestUserData}
                          myProfile={myProfile}
                          location="myConnectionRequests"
                          data={item}
                          key={key}
                        />
                      </Grid>
                    </>
                  );
                })}
              </Grid>
              {getConnectionRequestsData?.data?.offsetStatus ? (
                <Grid innerRef={setConnReqTarget} container>
                  <MyConncetionSkeleton />
                </Grid>
              ) : null}
            </>
          ) : null}
        </MaxWidthComponent>
      )}
      {activeStep === 3 && <PeopleMayYouKnow />}
    </Body>
  );
}

const mapStateToProps = (state) => ({
  feedData: state.FeedData.feedData,
  userData: state.UserData.getUserData,
  profileUserData: state.UserData.getProfileUserData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
  getConnectionRequestsData: state.ConnectionsData.getConnectionRequestsData,
  isEditProfile: state.CommonData.editProfileStatus,
  activeEditProfileForm: state.CommonData.activeEditProfileFormIndex,
});

const mapDispatchToProps = (dispatch) => ({
  getProfileUser: (data) =>
    dispatch({
      type: GET_PROFILE_USER_DATA_START,
      data,
    }),
  getProfileFeed: (user_id, offset) =>
    dispatch({
      type: GET_PROFILE_FEED_START,
      user_id,
      offset,
    }),
  getAllConnections: (user_id) =>
    dispatch({
      type: GET_ALL_CONNECTIONS_START,
      user_id,
    }),
  getConnectionRequests: (data) =>
    dispatch({
      type: GET_CONNECTION_REQUESTS_START,
      data,
    }),
  updateConnectionRequestNotification: (data) =>
    dispatch({
      type: UPDATE_CONNECTION_REQUEST_NOTIFICATION,
      data,
    }),
  updateEditProfileStatus: (data) =>
    dispatch({
      type: EDIT_PROFILE_STATUS,
      data,
    }),
  updateActiveEditProfileFormIndex: (data) =>
    dispatch({
      type: ACTIVE_EDIT_PROFILE_FORM_INDEX,
      data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
