import React from "react";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as VerifiedTick } from "../../assets/VerifiedTick.svg";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({

}));

export default function DisplayName({ user, style }) {
  const classes = useStyle();
  return (
    <>
      {user?.is_company_account ? user?.company_profile?.name : user?.display_name || user?.name}
      {user?.is_wkleap_verified && <VerifiedTick style={style} />}
    </>
  );
}