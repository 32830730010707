import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { connect } from "react-redux";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import { Body1 } from "../Typography/Typographys";
import {
  DELETE_COMMENT_AND_REPLY_START,
  GET_FEED_COMMENT_LIKES_START,
  GET_FEED_COMMENT_REPLY_LIKES_START,
  POST_FEED_COMMENT_LIKE_START,
  POST_FEED_COMMENT_REPLY_LIKE_START,
  POST_FEED_COMMENT_REPLY_START,
} from "../../actions/FeedAction";
import utils from "../../utils/commonUtils";
import { ReactComponent as SendIcon } from "../../assets/sendIcon.svg";
import UserAvatar from "../common/UserAvatar";
import H5 from "../Headings/H5";
import HtmlMessage from "../common/HtmlMessage";
import Dropdown from "../common/Dropdown";
import AlertDialog from "../Dialogs/AlertDialog";
import EditFeedCommentAndReplyDialog from "../Dialogs/EditFeedCommentAndReplyDialog";
import LikesDialog from "../Dialogs/LikesDialog";
import EndIcon from "../Textfields/EndIcon";
import { Body2 } from "../Typography/Typographys";
import moment from "moment";
import DisplayName from "../common/DisplayName";

let deleteCommentAndReplyFlag = false;

function FeedCommentAndReplies({
  comment,
  userData,
  deleteCommentAndReplyAPI,
  deleteCommentAndReplyData,
  postFeedCommentLike,
  postFeedCommentReplyLike,
  getFeedCommentLikes,
  getFeedCommentReplyLikes,
  feedCommentLikesData,
  feedCommentReplyLikesData,
  postFeedCommentReply,
  feedCommentReplyData,
}) {
  const history = useHistory();
  const replyTextfieldRef = useRef(null);
  const [menuCardDiv, setMenuCardDiv] = useState(null);
  const [selectedCommentOrReplyData, setSelectedCommentOrReplyData] = useState({
    type: "",
    data: null,
  });
  const [dropdownList, setDropdownList] = useState([]);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [alertDialogTitle, setAlertDialogTitle] = useState("");
  const [alertDialogContent, setAlertDialogContent] = useState("");
  const [alertDialogSuccessBtnText, setAlertDialogSuccessBtnText] =
    useState("");
  const [
    openEditFeedCommentAndReplyDialog,
    setOpenEditFeedCommentAndReplyDialog,
  ] = useState(false);
  const [openLikeDialog, setOpenLikeDialog] = useState(false);
  const [likeDialogType, setLikeDialogType] = useState("");
  const [showReplyTextfield, setShowReplyTextfield] = useState(false);
  const [showReplyTextfieldType, setShowReplyTextfieldType] = useState("");
  const [showReplyTextfieldId, setShowReplyTextfieldId] = useState(0);
  const [replyMessage, setReplyMessage] = useState("");

  const handleOpenLikeDialog = (type, comment_id, reply_id) => {
    setLikeDialogType(type);
    setOpenLikeDialog(true);
    if (type === "commentLikes") {
      getFeedCommentLikes(
        utils.toBase64(comment?.feed_id),
        utils.toBase64(comment_id)
      );
    } else if (type === "commentReplyLikes") {
      getFeedCommentReplyLikes(
        utils.toBase64(comment?.feed_id),
        utils.toBase64(comment_id),
        utils.toBase64(reply_id)
      );
    }
  };

  const handleLikeDialogClose = () => {
    setOpenLikeDialog(false);
    setTimeout(() => {
      setLikeDialogType("");
    }, 1000);
  };

  const handleMoreHorizIconClick = (e, type, data) => {
    setMenuCardDiv(e.currentTarget);
    setSelectedCommentOrReplyData({
      type,
      data,
    });
    if (data?.user_id === userData?.userInfo?.data?.id) {
      setDropdownList([
        { label: "Edit", type: "edit" },
        { label: "Delete", type: "delete" },
      ]);
    } else {
      setDropdownList([{ label: "Report", type: "report" }]);
    }
  };

  const handleDropdownClose = () => {
    setMenuCardDiv(null);
    setDropdownList([]);
  };

  const handleDropdownLiClick = (i, type) => {
    handleDropdownClose();
    if (type === "edit") {
      setOpenEditFeedCommentAndReplyDialog(true);
    } else if (type === "delete") {
      if (selectedCommentOrReplyData?.type === "comment") {
        setAlertDialogTitle("Delete Comment");
        setAlertDialogContent("Are you sure you want to delete this comment?");
        setAlertDialogSuccessBtnText("Delete Comment");
      } else if (selectedCommentOrReplyData?.type === "reply") {
        setAlertDialogTitle("Delete Comment Reply");
        setAlertDialogContent(
          "Are you sure you want to delete this comment reply?"
        );
        setAlertDialogSuccessBtnText("Delete Reply");
      }
      setOpenAlertDialog(true);
    }
  };

  const handleAlertDialogClose = (value) => {
    if (value) {
      if (selectedCommentOrReplyData?.type === "comment") {
        deleteCommentAndReplyAPI({
          type: selectedCommentOrReplyData?.type,
          comment_id: selectedCommentOrReplyData?.data?.id,
          feed_id: selectedCommentOrReplyData?.data?.feed_id,
        });
        deleteCommentAndReplyFlag = true;
      } else if (selectedCommentOrReplyData?.type === "reply") {
        deleteCommentAndReplyAPI({
          type: selectedCommentOrReplyData?.type,
          comment_id: selectedCommentOrReplyData?.data?.comment_id,
          feed_id: selectedCommentOrReplyData?.data?.feed_id,
          reply_id: selectedCommentOrReplyData?.data?.id,
        });
        deleteCommentAndReplyFlag = true;
      }
    }
    setOpenAlertDialog(false);
    setAlertDialogTitle("");
    setAlertDialogContent("");
    setAlertDialogSuccessBtnText("");
  };

  const handleAddNewCommentReply = (e, type) => {
    if (replyMessage?.trim() !== "") {
      if (
        (type === "onKeyDown" && e.key === "Enter") ||
        type === "sendIconClick"
      ) {
        postFeedCommentReply(
          utils.toBase64(comment?.feed_id),
          utils.toBase64(comment?.id),
          replyMessage
        );
      }
    }
  };

  const handleReplyTextfieldClose = () => {
    setReplyMessage("");
    setShowReplyTextfield(false);
    setShowReplyTextfieldType("");
    setShowReplyTextfieldId(0);
  };

  const handleReplyTextfieldShowLinkClick = (type, reply_id) => {
    setShowReplyTextfield(true);
    setShowReplyTextfieldType(type);
    if (reply_id) {
      setShowReplyTextfieldId(reply_id);
    }
  };

  useEffect(() => {
    if (feedCommentReplyData?.data?.status === "1") {
      handleReplyTextfieldClose();
    }
  }, [feedCommentReplyData]);

  useEffect(() => {
    if (
      deleteCommentAndReplyData?.status === "1" &&
      deleteCommentAndReplyFlag
    ) {
      deleteCommentAndReplyFlag = false;
      setSelectedCommentOrReplyData({ type: "", data: null });
    }
  }, [deleteCommentAndReplyData]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        replyTextfieldRef.current &&
        !replyTextfieldRef.current.contains(event.target)
      ) {
        handleReplyTextfieldClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
  }, [replyTextfieldRef]);

  const getReplyTextfield = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        innerRef={replyTextfieldRef}
        className="replyTextfieldRootDiv"
      >
        <UserAvatar
          name={userData?.userInfo?.data?.display_name}
          src={userData?.userInfo?.data?.profile_picture}
          onClick={() =>
            history.push(
              "/user-profile/" + userData?.userInfo?.data?.slug + "/0"
            )
          }
        />
        <EndIcon
          placeholder="Add Comment Reply..."
          value={replyMessage}
          onChange={(e) => setReplyMessage(e.target.value)}
          onKeyDown={(e) => handleAddNewCommentReply(e, "onKeyDown")}
          backgroundTextfield
          newDesign
          Icon={
            <SendIcon
              className="svg"
              onClick={(e) => handleAddNewCommentReply(e, "sendIconClick")}
            />
          }
        />
      </Grid>
    );
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="flex-start">
        <UserAvatar
          name={comment?.user?.display_name}
          src={comment?.user?.profile_picture}
          onClick={() =>
            history.push("/user-profile/" + comment?.user?.slug + "/0")
          }
        />
        <Grid className="ffcarRightSideDiv">
          <Grid className="ffcarContentDiv">
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <H5
                onClick={() =>
                  history.push("/user-profile/" + comment?.user?.slug + "/0")
                }
                medium
                ellipsis
              >
                <DisplayName user={comment?.user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
              </H5>
              <Grid className="ffcarCommentTimeDiv">
                <Body2>{moment(comment?.createdAt).fromNow()}</Body2>
                <MoreHorizIcon
                  onClick={(e) => {
                    handleMoreHorizIconClick(e, "comment", comment);
                  }}
                  fontSize="small"
                />
              </Grid>
            </Grid>
            <HtmlMessage text={comment?.message} />
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-start"
            className="ffcarActionDiv"
          >
            {comment?.feed_comment_likes?.filter((item) => {
              return item?.user_id === userData?.userInfo?.data?.id;
            }).length > 0 ? (
              <ThumbUpIcon
                onClick={() =>
                  postFeedCommentLike(comment?.feed_id, comment?.id, "dislike")
                }
                fontSize="small"
              />
            ) : (
              <ThumbUpOutlinedIcon
                onClick={() =>
                  postFeedCommentLike(comment?.feed_id, comment?.id, "like")
                }
                fontSize="small"
              />
            )}
            {comment?.feed_comment_likes?.length > 0 && (
              <Body1
                onClick={() =>
                  handleOpenLikeDialog("commentLikes", comment?.id)
                }
                className="link"
              >
                {`Like ${comment?.feed_comment_likes?.length > 1
                  ? `(${comment?.feed_comment_likes?.length} Likes)`
                  : `(${comment?.feed_comment_likes?.length} Like)`
                  }`}
              </Body1>
            )}
            <span></span>
            <Body1
              onClick={() => handleReplyTextfieldShowLinkClick("comment")}
              className="link"
            >
              {`Reply ${comment?.feed_comment_replys?.length > 0
                ? comment?.feed_comment_replys?.length > 1
                  ? `(${comment?.feed_comment_replys?.length} Replies)`
                  : `(${comment?.feed_comment_replys?.length} Reply)`
                : ""
                }`}
            </Body1>
          </Grid>
        </Grid>
      </Grid>
      {showReplyTextfield &&
        showReplyTextfieldType === "comment" &&
        getReplyTextfield()}
      {comment?.feed_comment_replys?.map((reply, key) => {
        return (
          <>
            <Grid
              container
              justifyContent="space-between"
              alignItems="flex-start"
              className="commentReplyRootDiv"
              key={key}
            >
              <UserAvatar
                name={reply?.user?.display_name}
                src={reply?.user?.profile_picture}
                onClick={() =>
                  history.push("/user-profile/" + reply?.user?.slug + "/0")
                }
              />
              <Grid className="ffcarRightSideDiv">
                <Grid className="ffcarContentDiv">
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <H5
                      onClick={() =>
                        history.push(
                          "/user-profile/" + reply?.user?.slug + "/0"
                        )
                      }
                      medium
                      ellipsis
                    >
                      <DisplayName user={reply?.user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                    </H5>
                    <Grid className="ffcarCommentTimeDiv">
                      <Body2>{moment(reply?.createdAt).fromNow()}</Body2>
                      <MoreHorizIcon
                        onClick={(e) => {
                          handleMoreHorizIconClick(e, "reply", reply);
                        }}
                        fontSize="small"
                      />
                    </Grid>
                  </Grid>
                  <HtmlMessage text={reply?.message} />
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-start"
                  className="ffcarActionDiv"
                >
                  {reply?.feed_comment_reply_likes?.filter((item) => {
                    return item?.user_id === userData?.userInfo?.data?.id;
                  }).length > 0 ? (
                    <ThumbUpIcon
                      onClick={() =>
                        postFeedCommentReplyLike(
                          reply?.feed_id,
                          reply?.comment_id,
                          reply?.id,
                          "dislike"
                        )
                      }
                      fontSize="small"
                    />
                  ) : (
                    <ThumbUpOutlinedIcon
                      onClick={() =>
                        postFeedCommentReplyLike(
                          reply?.feed_id,
                          reply?.comment_id,
                          reply?.id,
                          "like"
                        )
                      }
                      fontSize="small"
                    />
                  )}
                  {reply?.feed_comment_reply_likes?.length > 0 && (
                    <Body1
                      onClick={() =>
                        handleOpenLikeDialog(
                          "commentReplyLikes",
                          reply?.comment_id,
                          reply?.id
                        )
                      }
                      className="link"
                    >
                      {`Like ${reply?.feed_comment_reply_likes?.length > 1
                        ? `(${reply?.feed_comment_reply_likes?.length} Likes)`
                        : `(${reply?.feed_comment_reply_likes?.length} Like)`
                        }`}
                    </Body1>
                  )}
                  {/* <span></span> */}
                  {/* <Body1
                    onClick={() =>
                      handleReplyTextfieldShowLinkClick("reply", reply?.id)
                    }
                    className="link"
                  >
                  </Body1> */}
                </Grid>
              </Grid>
            </Grid>
            {showReplyTextfield &&
              showReplyTextfieldType === "reply" &&
              showReplyTextfieldId === reply?.id &&
              getReplyTextfield()}
          </>
        );
      })}
      <Dropdown
        anchorEl={menuCardDiv}
        handleClose={handleDropdownClose}
        handleClick={handleDropdownLiClick}
        menuItemList={dropdownList}
      />
      <AlertDialog
        open={openAlertDialog}
        handleClose={handleAlertDialogClose}
        dialogTitle={alertDialogTitle}
        dialogContent={alertDialogContent}
        successBtnText={alertDialogSuccessBtnText}
      />
      <EditFeedCommentAndReplyDialog
        open={openEditFeedCommentAndReplyDialog}
        handleClose={() => setOpenEditFeedCommentAndReplyDialog(false)}
        selectedCommentOrReplyData={selectedCommentOrReplyData}
      />
      <LikesDialog
        open={openLikeDialog}
        handleClose={handleLikeDialogClose}
        type={likeDialogType}
        users={
          likeDialogType === "commentLikes"
            ? feedCommentLikesData?.data?.data
            : likeDialogType === "commentReplyLikes"
              ? feedCommentReplyLikesData?.data?.data
              : []
        }
        loading={
          likeDialogType === "commentLikes"
            ? feedCommentLikesData?.loading
            : likeDialogType === "commentReplyLikes"
              ? feedCommentReplyLikesData?.loading
              : false
        }
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  feedCommentLikesData: state.FeedData.getFeedCommentLikesData,
  feedCommentReplyLikesData: state.FeedData.getFeedCommentReplyLikes,
  feedCommentReplyData: state.FeedData.postFeedCommentReply,
  deleteCommentAndReplyData: state.FeedData.deleteCommentAndReplyData,
});

const mapDispatchToProps = (dispatch) => ({
  deleteCommentAndReplyAPI: (data) =>
    dispatch({
      type: DELETE_COMMENT_AND_REPLY_START,
      data,
    }),
  getFeedCommentLikes: (feed_id, comment_id) =>
    dispatch({
      type: GET_FEED_COMMENT_LIKES_START,
      feed_id,
      comment_id,
    }),
  postFeedCommentReply: (feed_id, comment_id, message) =>
    dispatch({
      type: POST_FEED_COMMENT_REPLY_START,
      feed_id,
      comment_id,
      message,
    }),
  getFeedCommentReplyLikes: (feed_id, comment_id, reply_id) =>
    dispatch({
      type: GET_FEED_COMMENT_REPLY_LIKES_START,
      feed_id,
      comment_id,
      reply_id,
    }),
  postFeedCommentLike: (feed_id, comment_id, like_dislike_type) =>
    dispatch({
      type: POST_FEED_COMMENT_LIKE_START,
      feed_id,
      comment_id,
      like_dislike_type,
    }),
  postFeedCommentReplyLike: (
    feed_id,
    comment_id,
    reply_id,
    like_dislike_type
  ) =>
    dispatch({
      type: POST_FEED_COMMENT_REPLY_LIKE_START,
      feed_id,
      comment_id,
      reply_id,
      like_dislike_type,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeedCommentAndReplies);
