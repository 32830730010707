import {
  NOTIFICATION_MESSAGE_SUCCESS,
  GET_ALL_NOTIFICATIONS_ERROR,
  GET_ALL_NOTIFICATIONS_START,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  SOCKET_IO_GET_NEW_NOTIFICATION,
  UPDATE_LOCATION_HISTORY,
  READ_NOTIFICATIONS_ERROR,
  READ_NOTIFICATIONS_START,
  READ_NOTIFICATIONS_SUCCESS,
  UPDATE_LINK_PREVIEW,
  ACTIVE_EDIT_PROFILE_FORM_INDEX,
  EDIT_PROFILE_STATUS,
  GET_UNREAD_COUNTS_START,
  GET_UNREAD_COUNTS_SUCCESS,
  GET_UNREAD_COUNTS_ERROR,
} from "../actions/CommonAction";

const initialState = {
  notificationMessage: false,
  unreadNotificationCount: 0,
  linkPreview: null,
  getAllNotificationsData: {
    loading: false,
    data: [],
    offset: 0,
    offsetStatus: false,
    status: "",
    message: "",
  },
  locationHistory: [],
  readNotificationsData: {
    loading: false,
    data: [],
    status: "",
    message: "",
  },
  editProfileStatus: false,
  activeEditProfileFormIndex: 0,
  unReadData: {
    loading: false,
    data: [],
    status: "",
    message: "",
  },
};

const CommonReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE_STATUS: {
      return {
        ...previousState,
        editProfileStatus: action?.data,
      };
    }
    case ACTIVE_EDIT_PROFILE_FORM_INDEX: {
      return {
        ...previousState,
        activeEditProfileFormIndex: action?.data,
      };
    }
    case NOTIFICATION_MESSAGE_SUCCESS: {
      return {
        ...previousState,
        notificationMessage: action.data,
      };
    }
    case GET_ALL_NOTIFICATIONS_START: {
      return {
        ...previousState,
        getAllNotificationsData: {
          ...previousState.getAllNotificationsData,
          loading: true,
        },
      };
    }
    case GET_ALL_NOTIFICATIONS_SUCCESS: {
      const { status, message, data, offset, offsetStatus } = action?.data;
      const unreadNotiCount = data?.filter((item) => !item?.is_read).length;
      let newData =
        offset === 0
          ? data
          : [...previousState?.getAllNotificationsData?.data, ...data];
      return {
        ...previousState,
        getAllNotificationsData: {
          ...previousState.getAllNotificationsData,
          loading: false,
          data: newData,
          status,
          message,
          offset,
          offsetStatus,
        },
        unreadNotificationCount: unreadNotiCount,
      };
    }
    case GET_ALL_NOTIFICATIONS_ERROR: {
      const { message, status } = action?.data;
      return {
        ...previousState,
        getAllNotificationsData: {
          ...previousState?.getAllNotificationsData,
          loading: false,
          message,
          status,
        },
      };
    }
    case SOCKET_IO_GET_NEW_NOTIFICATION: {
      return {
        ...previousState,
        getAllNotificationsData: {
          ...previousState?.getAllNotificationsData,
          data: [action?.data, ...previousState?.getAllNotificationsData?.data],
        },
        unreadNotificationCount: previousState.unreadNotificationCount + 1,
      };
    }
    case UPDATE_LOCATION_HISTORY: {
      const path = action.data;
      let locationHistory = [...previousState.locationHistory];
      const lastHistory = locationHistory[locationHistory?.length - 1];
      if (locationHistory.length === 0 || lastHistory !== path) {
        locationHistory.push(path);
      }
      return {
        ...previousState,
        locationHistory: locationHistory,
      };
    }
    case READ_NOTIFICATIONS_START: {
      return {
        ...previousState,
        readNotificationsData: {
          ...previousState.readNotificationsData,
          loading: true,
        },
      };
    }
    case READ_NOTIFICATIONS_SUCCESS: {
      const { data, status, message } = action?.data;
      let allNotifications = [...previousState?.getAllNotificationsData?.data];
      allNotifications = allNotifications?.map((item) => {
        return data?.includes(item?.id) ? { ...item, is_read: 1 } : item;
      });
      return {
        ...previousState,
        readNotificationsData: {
          loading: false,
          data,
          status,
          message,
        },
        getAllNotificationsData: {
          ...previousState?.getAllNotificationsData,
          data: allNotifications,
        },
        unreadNotificationCount:
          previousState.unreadNotificationCount - data?.length,
      };
    }
    case READ_NOTIFICATIONS_ERROR: {
      const { status, message } = action?.data;
      return {
        ...previousState,
        readNotificationsData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case GET_UNREAD_COUNTS_START: {
      return {
        ...previousState,
        unReadData: {
          ...previousState.unReadData,
          loading: true,
        },
      };
    }
    case GET_UNREAD_COUNTS_SUCCESS: {
      const { data, status, message } = action;
      return {
        ...previousState,
        unReadData: {
          loading: false,
          data,
          status,
          message,
        }
      }
    }
    case GET_UNREAD_COUNTS_ERROR: {
      const { status, message } = action;
      return {
        ...previousState,
        unReadData: {
          loading: false,
          status,
          message,
        },
      };
    }
    case UPDATE_LINK_PREVIEW: {
      return {
        ...previousState,
        linkPreview: action.data,
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default CommonReducers;
