import socketIOClient from "socket.io-client";
import utils from "../utils/commonUtils";
const { token } = utils.getLocalStorageItems();
export const socket = socketIOClient(
  `${process.env.REACT_APP_API_BASE_URL_SOCKET}?token=${token}`,
  {
    withCredentials: true,
    extraHeaders: {
      "Content-Type": "application/json",
    },
  }
);
