import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Divider, Box } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/BackIcon.svg";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as SendIcon } from "../../assets/sendIcon.svg";
import { connect } from "react-redux";
import {
  ADD_NEW_CHAT_MESSAGE_START,
  GET_ALL_CHAT_ROOM_START,
  GET_CHAT_ROOM_START,
} from "../../actions/ChatAction";
import utils from "../../utils/commonUtils";
import moment from "moment";
import { socket } from "../../utils/socketioSetup";
import useViewport from "../../hooks/useViewport";
import { useIntersection } from "../../hooks/customHooks";
import classNames from "classnames";
import UserListDialog from "../common/UserListDialog";
import { GET_ALL_CONNECTIONS_START } from "../../actions/ConnectionsAction";
import ParasTypography from "../Typography/ParasTypography";
import ConnAndMsgComponent from "../ConnectionComponents/ConnAndMsgComponent";
import { Divider as DividerNew } from "@mui/material";
import { ReactComponent as VerifiedTick } from "../../assets/VerifiedTick.svg";
import DisplayName from "../common/DisplayName";
const H6 = React.lazy(() => import("../Headings/H6"));
const UserSkeleton = React.lazy(() => import("../common/UserSkeleton"));
const HtmlMessage = React.lazy(() => import("../common/HtmlMessage"));
const CircularProgressComponent = React.lazy(() =>
  import("../common/CircularProgressComponent")
);
const H5 = React.lazy(() => import("../Headings/H5"));
const Body1 = React.lazy(() => import("../Typography/Body1"));
const LabelTypography = React.lazy(() =>
  import("../Typography/LabelTypography")
);
const UserAvatar = React.lazy(() => import("../common/UserAvatar"));
const PlainTextfield = React.lazy(() => import("../Textfields/PlainTextfield"));

const useStyles = makeStyles((theme) => ({
  chatRoot: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    height: "100%",
    position: "absolute",
  },
  chatListItem: {
    display: "flex",
    alignItems: "center",
    padding: "0px 20px",
    cursor: "pointer",
    height: "69px",
    minHeight: "69px",
    borderBottom: `1px solid ${theme.palette.border.bordertopcolor}`,
    "&>svg": {
      "&:nth-child(1)": {
        width: "21px",
        height: "21px",
      },
    },
    "&>div:nth-child(4)": {
      display: "contents",
    },
    "&>div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  userIcon: {
    margin: "0 10px",
    width: "37px !important",
    height: "37px !important",
    cursor: "pointer",
    borderRadius: "10px !important",
    objectFit: "cover",
    display: "flex !important",
  },
  userNameText: {
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "21px",
    letterSpacing: "0em",
    marginRight: "23px",
  },
  detailText: {
    fontFamily: theme.palette.font.boldText.fontFamily,
    fontSize: "11px",
    fontStyle: "normal",
    lineHeight: "15px",
    letterSpacing: "0em",
    color: theme.palette.text.light,
    fontWeight: theme.palette.fontweight.light400,
  },
  dividerLine: {
    width: 100,
    height: 1,
    background: theme.palette.common.lightgray,
    color: theme.palette.primary.main,
    [theme.breakpoints.down("xs")]: {
      width: 50,
    },
  },
  dateText: {
    color: theme.palette.text.medium,
  },
  dividerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "20px",
    marginTop: "4px",
    "&>p": {
      lineHeight: "21px",
      fontSize: "14px",
      margin: "0 6px",
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
      },
    },
  },
  chatLeft: {
    marginBottom: "10px",
    height: "auto",
    width: "max-content",
    maxWidth: "60%",
    overflow: "hidden",
    padding: "10px",
    borderRadius: "0px 10px 10px 10px",
    marginTop: "4px",
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.border.bordertopcolor}`,
    whiteSpace: "pre-wrap",
    "&>div": {
      "&>div": {
        color: theme.palette.common.black,
      },
    },
  },
  chatRight: {
    height: "auto",
    maxWidth: "60%",
    overflow: "hidden",
    padding: "10px",
    borderRadius: "10px 10px 0px 10px",
    background: theme.palette.common.white,
    marginBottom: "10px",
    marginLeft: "auto",
    border: `1px solid ${theme.palette.border.bordertopcolor}`,
    width: "max-content",
    whiteSpace: "pre-wrap",
    "&>div": {
      "&>div": {
        color: theme.palette.common.black,
      },
    },
  },
  timeText: {
    textAlign: "right",
    display: "inline-block",
    fontSize: "8px",
    fontStyle: "normal",
    lineHeight: "1.4",
    float: "right",
    color: theme.palette.text.medium,
  },
  addIcon: {
    color: theme.palette.primary.main,
    marginRight: "10px",
  },
  textMessage: {
    width: "100%",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: "8px",
    padding: "12px 0",
    "&>input": {
      lineHeight: "21px",
      fontSize: "14px",
      color: theme.palette.text.bold,
      fontWeight: theme.palette.fontweight.light400,
      fontFamily: theme.palette.font.boldText.robotoFontFamily,
    },
  },
  inputFile: {
    display: "none",
  },
  chatMessageRootDiv: {
    width: "auto",
    padding: "10px 15px",
    // height: "calc(100% - 138px)",
    marginTop: "auto",
    overflow: "auto",
    "& .MuiDivider-wrapper": {
      position: "relative"
    }
  },
  sendBtn: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  chatFooter: {
    display: "flex",
    alignItems: "center",
    background: theme.palette.common.white,
    padding: "0 20px",
    height: "69px",
    minHeight: "56px",
    border: `1px solid ${theme.palette.border.borderColor}`,
    "& textarea": {
      fontSize: "16px !important"
    }
  },
  borderNone: {
    "&>div": {
      border: "none !important",
    },
  },
  useraIconStyle: {
    "&>div": {
      "&:nth-child(1)": {
        "&>img": {
          objectPosition: "top",
        },
      },
    },
  },
  skeltonDiv: {
    padding: "0px 20px",
  },
}));

const dateFormatDMY = (date) => {
  return moment(date).format("DD MMMM yyyy");
};

let newChatMessageFlag = false;

function Message({
  getChatRoom,
  userData,
  getChatRoomData,
  addNewChatMessage,
  getAllChatRooms,
  addNewChatMessageData,
  getAllConnectionsData,
  getAllConnections,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { room_id } = useParams();
  const [message, setMessage] = useState("");
  const messagesRootDivRef = useRef(null);
  const [chatRoom, setChatRoom] = useState(null);
  const firstNewMessageDivRef = useRef(null);
  const [connectionsData, setConnectionsData] = useState([]);
  const [chatSelectedUsers, setChatSelectedUsers] = useState([]);
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [setTarget, entry] = useIntersection({ threshold: 0.1 });
  const textfieldRef = useRef(null);
  const [chatRoomBasicDetails, setChatRoomBasicDetails] = useState({
    profile_picture: null,
    name: "",
    slug: "",
    type: "",
  });
  const groupchat = getChatRoomData?.data;

  const { width } = useViewport();

  let chatDate = "";
  let todayDate = moment().format("DD MMMM yyyy");
  let yesterdayDate = moment().add(-1, "days").format("DD MMMM yyyy");
  var messageDate = "";
  const getChatMessageDate = (date) => {
    if (chatDate === "") {
      chatDate = dateFormatDMY(date);
      if (todayDate === dateFormatDMY(date)) {
        messageDate = "Today";
        return messageDate;
      } else if (yesterdayDate === dateFormatDMY(date)) {
        messageDate = "Yesterday";
        return "Yesterday";
      } else {
        messageDate = chatDate;
        return chatDate;
      }
    } else {
      if (chatDate !== dateFormatDMY(date)) {
        chatDate = dateFormatDMY(date);
        if (todayDate === dateFormatDMY(date)) {
          messageDate = "Today";
          return "Today";
        } else if (yesterdayDate === dateFormatDMY(date)) {
          messageDate = "Yesterday";
          return "Yesterday";
        } else {
          messageDate = chatDate;
          return chatDate;
        }
      } else {
        messageDate = "";
        return null;
      }
    }
  };

  const handleOnKeyDownClick = (e) => {
    if (e.key === "Enter" && !e.shiftKey && message.trim() !== "") {
      e.preventDefault();
      addNewChatMessage(room_id, message.trim(), userData?.userInfo?.data?.id);
      setMessage("")
      newChatMessageFlag = true;
    }
    if (message.trim() === "") {
      if (e.key === "Enter" || (e.key === "Enter" && e.shiftKey)) {
        e.preventDefault();
      }
    }
  };

  const handleMessageSend = (e) => {
    if (message !== "") {
      addNewChatMessage(room_id, message.trim(), userData?.userInfo?.data?.id);
      setMessage("")
      newChatMessageFlag = true;
    }
  };
  if (width > 960) {
    history.push("/messages");
  }

  useEffect(() => {
    if (addNewChatMessageData?.data?.status === "1" && newChatMessageFlag) {
      newChatMessageFlag = false;
      // setMessage("");
      if (textfieldRef.current) {
        textfieldRef.current.focus();
      }
    }
  }, [addNewChatMessageData]);

  useEffect(() => {
    if (chatRoom) {
      if (firstNewMessageDivRef.current) {
        firstNewMessageDivRef.current.scrollIntoView();
      }
      if (!getChatRoomData?.offsetStatus) {
      }
    }
    if (getChatRoomData?.offset === 0 && chatRoom) {
      if (messagesRootDivRef.current) {
        messagesRootDivRef.current.scrollTop =
          messagesRootDivRef.current.scrollHeight;
      }
    }
  }, [chatRoom]);

  useEffect(() => {
    const { intersectionRatio } = entry;
    console.log("getChatRoomData", getChatRoomData)
    if (intersectionRatio >= 0.1 && getChatRoomData?.offsetStatus) {
      let offset = getChatRoomData?.data?.chat_messages?.length ?? 0;
      getChatRoom(utils.toBase64(chatRoom?.id), { offset });
      getAllChatRooms({ offset });
    }
  }, [entry]);

  useEffect(() => {
    getChatRoom(room_id, { offset: 0 });
    getAllChatRooms({ offset: 0 });
    socket.emit("joinChatRoom", parseInt(utils.toNormalString(room_id)));
  }, []);

  useEffect(() => {
    if (getAllConnectionsData?.data?.status === "1") {
      setConnectionsData(
        getAllConnectionsData?.data?.data?.map((item) => {
          return item?.inviter === userData?.userInfo?.data?.id
            ? item?.inviteeUser
            : item?.inviterUser;
        })
      );
    }
  }, [getAllConnectionsData]);

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
    }
  }, [userData]);

  useEffect(() => {
    if (getChatRoomData?.status === "1") {
      setChatRoom(getChatRoomData?.data);
      const {
        id,
        chat_messages,
        message_unread_data,
        type,
        profile_picture,
        name,
        chat_participants,
        is_wkleap_verified,
      } = getChatRoomData?.data;
      let uData = chat_participants
        ?.filter((user) => user?.user_id !== userData?.userInfo?.data?.id)
        ?.map((item) => item?.user)[0];
      setChatRoomBasicDetails({
        type,
        profile_picture:
          type === "group" ? profile_picture : uData?.profile_picture,
        name: type === "group" ? name : uData?.display_name,
        slug: type === "group" ? "" : uData?.slug,
        is_wkleap_verified: type === "group" ? is_wkleap_verified : uData?.is_wkleap_verified,
      });
      if (chat_messages.length > 0) {
        let tempMUArray = [];
        try {
          tempMUArray = JSON.parse(message_unread_data);
        } catch (error) { }
        tempMUArray = tempMUArray?.filter(
          (item) => item?.user_id === userData?.userInfo?.data?.id
        );
        if (tempMUArray[0]?.last_seen_message_id !== chat_messages.at(-1)?.id) {
          socket.emit("MarkAsReadChatMessages", {
            room_id: id,
            message_id: chat_messages.at(-1)?.id,
          });
        }
      }
    }
  }, [getChatRoomData]);

  return (
    <>
      <div className={classes.chatRoot}>
        <div className={classes.chatListItem}>
          <BackIcon onClick={() => history.push("/messages")} />
          <div className={classes.useraIconStyle}>
            {chatRoomBasicDetails?.profile_picture ? (
              chatRoom?.chat_participants[0]?.user_id ===
                userData?.userInfo?.data?.id ? (
                <UserAvatar
                  name={chatRoomBasicDetails?.name}
                  src={chatRoomBasicDetails?.profile_picture}
                  className={classes.userIcon}
                  onClick={() =>
                    history.push(
                      "/user-profile/" +
                      chatRoomBasicDetails?.profile_picture.slug +
                      "/0"
                    )
                  }
                />
              ) : (
                <UserAvatar
                  name={chatRoomBasicDetails?.name}
                  src={chatRoomBasicDetails?.profile_picture}
                  className={classes.userIcon}
                  onClick={() =>
                    history.push(
                      "/user-profile/" + chatRoomBasicDetails?.slug + "/0"
                    )
                  }
                />
              )
            ) : (
              <UserAvatar
                name={chatRoomBasicDetails?.name}
                src={chatRoomBasicDetails?.profile_picture}
                className={classes.userIcon}
                onClick={() =>
                  history.push(
                    "/user-profile/" + chatRoomBasicDetails?.slug + "/0"
                  )
                }
              />
            )}
          </div>
          <div>
            <H5
              children={
                <>
                  {chatRoom?.type === "personal"
                    ? chatRoom?.chat_participants[0]?.user_id ===
                      userData?.userInfo?.data?.id
                      ? chatRoom?.chat_participants[1]?.user?.display_name
                      : chatRoom?.chat_participants[0]?.user?.display_name
                    : chatRoom?.name}
                  {chatRoom?.type === "personal"
                    ? chatRoom?.chat_participants[0]?.user_id ===
                      userData?.userInfo?.data?.id
                      ? chatRoom?.chat_participants[1]?.user?.is_wkleap_verified && <VerifiedTick style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                      : chatRoom?.chat_participants[0]?.user?.is_wkleap_verified && <VerifiedTick style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                    : chatRoom?.name}
                </>
              }
              onClick={() =>
                history.push(
                  "/user-profile/" +
                  chatRoom?.chat_participants[0].user.slug +
                  "/0"
                )
              }
              className={classes.userNameText}
              medium
            />
            {chatRoom?.type === "group" ? (
              <ParasTypography
                className="text-light"
                children={`${chatRoom?.chat_participants?.length} members`}
                onClick={() => setMembersDialogOpen(true)}
                medium
              />
            ) : null}
            {/* <Body1 children="Online" className={classes.detailText} /> */}
          </div>
          {userData?.userInfo?.data?.id === getChatRoomData?.data?.user_id &&
            chatRoom?.type === "group" && (
              <div>
                <ConnAndMsgComponent
                  type="chatGroupDetailUpdate"
                  chatGroupData={groupchat}
                  userData={userData}
                />
                <ConnAndMsgComponent
                  type="chatGroupDelete"
                  chatGroupData={groupchat}
                  userData={userData}
                />
              </div>
            )}
        </div>
        <div ref={messagesRootDivRef} className={classes.chatMessageRootDiv}>
          {chatRoom !== null && getChatRoomData?.offsetStatus && (
            <div ref={setTarget} className={classes.skeltonDiv}>
            </div>
          )}
          {getChatRoomData?.loading && !getChatRoomData?.data?.chat_messages
            ? [...Array(10)].map(() => {
              return (
                <div className={classes.skeltonDiv}>
                  <UserSkeleton />
                </div>
              );
            })
            :
            //  chatRoom?.chat_messages?.map((item, key) => {
            //     return (
            //       <>
            //         {getChatMessageDate(item?.createdAt) !== null ? (
            //           <div className={classes.dividerContainer}>
            //             <Divider className={classes.dividerLine} />
            //             <Body1
            //               children={messageDate}
            //               light
            //               className={classes.dateText}
            //             />
            //             <Divider className={classes.dividerLine} />
            //           </div>
            //         ) : null}
            //         <div
            //           className={
            //             item?.user_id === userData?.userInfo?.data?.id
            //               ? classes.chatRight
            //               : classes.chatLeft
            //           }
            //           ref={key === 9 ? firstNewMessageDivRef : null}
            //         >
            //           <HtmlMessage text={item?.message} expanded />
            //           <LabelTypography
            //             children={moment(item?.createdAt).format("LT")}
            //             className={classes.timeText}
            //             light
            //           />
            //         </div>
            //       </>
            //     );
            //   })
            chatRoom?.chat_messages?.map((item, key, arr) => {
              const date = utils.getChatMessageDate(item?.createdAt);
              return !utils.isContinuousMessage(item, arr, key, date) ? (
                <>
                  {date !== null && (
                    <Box sx={{ width: "100%", margin: "16px 0px" }}>
                      <DividerNew>
                        <ParasTypography className="MessageDate" children={date} medium />
                      </DividerNew>
                    </Box>
                  )}
                  <div
                    className="MessageRootDiv"
                    ref={
                      key ===
                        chatRoom?.chat_messages?.length -
                        getChatRoomData?.offset
                        ? firstNewMessageDivRef
                        : null
                    }
                  >
                    {chatRoom?.chat_participants?.map((user, key) => {
                      return user?.user_id === item?.user_id ? (
                        <>
                          <UserAvatar
                            name={user?.user?.display_name}
                            src={user?.user?.profile_picture}
                            className="MessageUserProfile"
                            variant="square"
                            onClick={() =>
                              history.push(
                                "/user-profile/" + user?.user?.slug + "/0"
                              )
                            }
                          />
                          <div className="MessageRightDiv">
                            <div>
                              <H6
                                children={
                                  <>
                                    <DisplayName user={user?.user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                                  </>
                                }
                                onClick={() =>
                                  history.push(
                                    "/user-profile/" +
                                    user?.user?.slug +
                                    "/0"
                                  )
                                }
                                title={user?.user?.display_name}
                                link
                                medium
                              />
                              <ParasTypography
                                children={moment(item?.createdAt).format(
                                  "LT"
                                )}
                                light
                              />
                            </div>
                            <HtmlMessage text={item?.message} expanded />
                          </div>
                        </>
                      ) : null;
                    })}
                  </div>
                </>
              ) : (
                <div
                  className="MessageContinuousMessage"
                  ref={
                    key ===
                      chatRoom?.chat_messages?.length -
                      getChatRoomData?.offset
                      ? firstNewMessageDivRef
                      : null
                  }
                >
                  <ParasTypography
                    children={moment(item?.createdAt).format("hh:mm")}
                    light
                  />
                  <HtmlMessage text={item?.message} expanded />
                </div>
              );
            })
          }
        </div>
        <div className={classes.chatFooter}>
          {/* <div>
        <input
          accept="image/*,video/*"
          className={classes.inputFile}
          id="chat-upload-image/video"
          multiple
          type="file"
        />
        <label
          htmlFor="chat-upload-image/video"
          className={classes.uploadFeedBottomIcons}
        >
          <AddIcon className={classes.addIcon} />
        </label>
      </div> */}
          <PlainTextfield
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Write a message..."
            onKeyDown={handleOnKeyDownClick}
            className={classNames(classes.textMessage, classes.borderNone)}
            multiline={true}
            maxRows={1}
            minRows={1}
            autoFocus={false}
            setInputRef={textfieldRef}
            disabled={addNewChatMessageData?.loading}
          />
          {addNewChatMessageData?.loading ? (
            <CircularProgressComponent />
          ) : (
            <IconButton onClick={handleMessageSend} className={classes.sendBtn}>
              <SendIcon />
            </IconButton>
          )}
        </div>
      </div>
      <UserListDialog
        open={membersDialogOpen}
        setOpen={setMembersDialogOpen}
        dialogTitle={`Group members`}
        getChatRoomData={getChatRoomData}
        activeChat={chatRoom}
        userData={userData}
        connectionsData={connectionsData}
        chatSelectedUsers={chatSelectedUsers}
        list={chatRoom?.chat_participants?.map((item) => item?.user)}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  getChatRoomData: state.ChatData.getChatRoomData,
  userData: state.UserData.getUserData,
  addNewChatMessageData: state.ChatData.addNewChatMessageData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
});

const mapDispatchToProps = (dispatch) => ({
  getChatRoom: (room_id, data) =>
    dispatch({
      type: GET_CHAT_ROOM_START,
      room_id,
      data,
    }),
  addNewChatMessage: (room_id, message, user_id) =>
    dispatch({
      type: ADD_NEW_CHAT_MESSAGE_START,
      room_id,
      message,
      user_id,
    }),
  getAllChatRooms: (data) =>
    dispatch({
      type: GET_ALL_CHAT_ROOM_START,
      data,
    }),
  getAllConnections: (user_id) =>
    dispatch({
      type: GET_ALL_CONNECTIONS_START,
      user_id,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
