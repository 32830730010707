import {
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  Box,
} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import LabelTypography from "../Typography/LabelTypography";

const useStyle = makeStyles((theme) => ({
  redioBtn: {
    "&>span": {
      "&:nth-child(1)": {
        "&>span": {
          "&:nth-child(1)": {
            "&>div": {
              "&>svg": {
                fill: theme.palette.text.gray,
              },
            },
          },
        },
      },
      "&:nth-child(2)": {
        fontWeight: theme.palette.fontweight.light400,
        color: theme.palette.common.black,
        fontSize: 14,
        lineHeight: "21px",
        fontFamily: theme.palette.font.boldText.fontFamily,
      },
    },
  },
  activeRedioBtn: {
    "&>span": {
      "&:nth-child(1)": {
        "&>span": {
          "&:nth-child(1)": {
            "&>div": {
              "&>svg": {
                fill: theme.palette.secondary.main,
              },
            },
          },
        },
      },
    },
  },
}));

export default function RadioButtons({
  label,
  required,
  value,
  onChange,
  row = true,
  radioBtnList = [],
  className,
  ...props
}) {
  const classes = useStyle();
  return (
    <Box className={className}>
      {label && <LabelTypography children={label} required={required} />}
      <RadioGroup
        row={row}
        onChange={onChange}
        value={value}
        required
        {...props}
      >
        {radioBtnList?.map((item, key) => {
          return (
            <FormControlLabel
              className={classNames(
                classes.redioBtn,
                value === item?.value && classes.activeRedioBtn
              )}
              key={key}
              value={item?.value}
              control={<Radio />}
              label={item?.label}
            />
          );
        })}
      </RadioGroup>
    </Box>
  );
}
