import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import "../../scss/Search.scss";
import UserAvatar from "../common/UserAvatar";
import SimpleButton from "../Buttons/SimpleButton";
import H4 from "../Headings/H4";
import UserRoles from "../common/UserRoles";
import { useHistory } from "react-router-dom";
import TooltipComponent from "../common/Tooltip";
import AlertDialog from "../Dialogs/AlertDialog";
import { MyConncetionSkeleton } from "../common/Skeletons";
import {
  GET_CONNECTION_SUGGESTIONS_START,
  CANCEL_CONNECTION_REQUEST_START,
  UPDATE_CONNECTION_REQUEST_START,
} from "../../actions/ConnectionsAction";
import utils from "../../utils/commonUtils";
import { NEW_CONNECTION_REQUEST_START } from "../../actions/ConnectionsAction";
import DisplayName from "../common/DisplayName";

export const PeopleMayYouKnow = (props) => {
  const {
    getConnectionSuggestions,
    getConnectionSuggestionsData,
    sendNewConnectionRequest,
    newConnectionRequestData,
    getConnectionRequestsData,
    userData,
    cancelConnectionRequest,
    updateConnectionRequest,
    updateConnectionRequestData,
  } = props;
  const history = useHistory();
  const [connectionSuggestions, setConnectionSuggestions] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const [connectionRequestData, setConnectionRequestData] = useState([]);
  const [dialogTitle, setDialogTitle] = useState("");
  const [type, setType] = useState(null);
  const [con_id, setCon_id] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [dialogSuccessBtnText, setDialogSuccessBtnText] = useState("");
  const [dialogCloseBtnText, setDialogCloseBtnText] = useState("");
  const [user_id, setUser_id] = useState(null);

  const handleRequestedBtnClick = (con_id) => {
    setDialogTitle("Are you sure want to withdraw this connection request?");
    setType("cancel");
    setCon_id(con_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Yes");
    setDialogCloseBtnText("No");
  };

  const handleSendNewConnectionRequest = (con_id) => {
    setDialogTitle("Are you sure you want to add this connection user?");
    setType("pending");
    setCon_id(con_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Connect");
    setDialogCloseBtnText("No");
  };

  const handleDeclineBtnClick = (con_id, user_id) => {
    setDialogTitle("Are you sure want to decline this connection request?");
    setType("Decline");
    setCon_id(con_id);
    setUser_id(user_id);
    setConfirmDialogOpen(true);
    setDialogSuccessBtnText("Decline");
    setDialogCloseBtnText("Cancel");
  };

  const handleDialogClose = (value) => {
    if (value && type === "cancel") {
      cancelConnectionRequest(utils.toBase64(con_id));
    }
    if (value && type === "Decline") {
      updateConnectionRequest(utils.toBase64(con_id), user_id, "rejected");
    }
    if (value && type === "pending") {
      sendNewConnectionRequest(con_id, "pending");
      setLoadingId(con_id);
    }
    setType(null);
    setUser_id(null);
    setCon_id(null);
    setConfirmDialogOpen(false);
    setDialogTitle("");
  };

  useEffect(() => {
    if (getConnectionSuggestionsData?.data?.status === "1") {
      setConnectionSuggestions(getConnectionSuggestionsData?.data?.data);
    }
    if (getConnectionRequestsData?.data?.status === "1") {
      setConnectionRequestData(getConnectionRequestsData?.data?.data);
    }
  }, [getConnectionSuggestionsData, getConnectionRequestsData]);

  useEffect(() => {
    getConnectionSuggestions();
  }, []);

  const getActionBtn = (id) => {
    if (connectionRequestData?.length > 0) {
      for (const item of connectionRequestData) {
        if (item?.inviterUser?.id === userData?.userInfo?.data?.id) {
          if (id === item?.inviteeUser?.id) {
            return (
              <SimpleButton
                children="Withdraw"
                psTransparentBorderBtn={true}
                onClick={() => handleRequestedBtnClick(item?.id)}
              />
            );
          }
        } else {
          if (id === item?.inviterUser?.id) {
            return (
              <div>
                <SimpleButton
                  children="Accept"
                  primaryColorBtn
                  onClick={() => {
                    updateConnectionRequest(
                      utils.toBase64(item?.id),
                      item?.inviterUser?.id,
                      "accepted"
                    );
                    setLoadingId(item?.id);
                  }}
                  loading={
                    updateConnectionRequestData?.loading &&
                    loadingId === item?.id
                  }
                  disabled={
                    updateConnectionRequestData?.loading &&
                    loadingId === item?.id
                  }
                />
                <SimpleButton
                  children="Decline"
                  psTransparentBorderBtn={true}
                  onClick={() =>
                    handleDeclineBtnClick(item?.id, item?.inviterUser?.id)
                  }
                />
              </div>
            );
          }
        }
      }
    }
    return (
      <SimpleButton
        children="Connect"
        psTransparentBorderBtn
        onClick={() => handleSendNewConnectionRequest(id)}
        loading={newConnectionRequestData?.loading && loadingId === id}
        disabled={newConnectionRequestData?.loading && loadingId === id}
        circularProgressColor="#023047"
      />
    );
  };

  return (
    <div className="root">
      <Grid container spacing={2} className="userListDiv">
        {getConnectionSuggestionsData?.loading ? (
          <MyConncetionSkeleton />
        ) : (
          connectionSuggestions?.map((item, key) => {
            return (
              <Grid item md={2} sm={3} xs={6} className="userBox" key={key}>
                <div></div>
                <div className="imageUserIcon">
                  <UserAvatar
                    src={item?.profile_picture}
                    name={item?.display_name}
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />
                  <TooltipComponent
                    children={
                      <span>
                        <H4
                          className="textEllipsis userName"
                          medium
                          onClick={() =>
                            history.push("/user-profile/" + item?.slug + "/0")
                          }
                        >
                          <DisplayName user={item} style={{ width: 14, height: 14, margin: "0px 0px -2px -1px" }} />
                        </H4>
                      </span>
                    }
                  />
                  {item?.user_performing_roles && item?.user_performing_roles?.length !== 0 && <TooltipComponent
                    children={
                      <span>
                        <UserRoles
                          align="center"
                          roles={item?.user_performing_roles}
                        />
                      </span>
                    }
                    title={item?.user_performing_roles[0].role}
                    arrow
                    placement="top"
                    className="tooltipComponent"
                    onClick={() =>
                      history.push("/user-profile/" + item?.slug + "/0")
                    }
                  />}
                  {getActionBtn(item?.id)}
                </div>
              </Grid>
            );
          })
        )}
      </Grid>
      <AlertDialog
        handleClose={handleDialogClose}
        dialogTitle={dialogTitle}
        open={confirmDialogOpen}
        dialogSuccessBtnText={dialogSuccessBtnText}
        dialogCloseBtnText={dialogCloseBtnText}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  getConnectionSuggestionsData:
    state.ConnectionsData.getConnectionSuggestionsData,
  userData: state.UserData.getUserData,
  newConnectionRequestData: state.ConnectionsData.newConnectionRequestData,
  getConnectionRequestsData: state.ConnectionsData.getConnectionRequestsData,
  updateConnectionRequestData:
    state.ConnectionsData.updateConnectionRequestData,
});

const mapDispatchToProps = (dispatch) => ({
  getConnectionSuggestions: () =>
    dispatch({
      type: GET_CONNECTION_SUGGESTIONS_START,
    }),
  sendNewConnectionRequest: (invitee_id, status) =>
    dispatch({
      type: NEW_CONNECTION_REQUEST_START,
      invitee_id,
      status,
    }),
  cancelConnectionRequest: (con_id) =>
    dispatch({
      type: CANCEL_CONNECTION_REQUEST_START,
      con_id,
    }),
  updateConnectionRequest: (con_id, inviter_id, status) =>
    dispatch({
      type: UPDATE_CONNECTION_REQUEST_START,
      con_id,
      inviter_id,
      status,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeopleMayYouKnow);
