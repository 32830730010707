import React from "react";
import Message from "../components/common/Message";
import CF from "../utils/CommonFunction";
import useViewport from "../hooks/useViewport";
import utils from "../utils/commonUtils";

export default function MessageContainer({ path }) {
  const { width } = useViewport();
  const showMessageComponent =
    !utils.getAuthRouts().concat(["messages"]).includes(path) &&
    !utils.getNotShowHeaderFooterRouts().includes(path) &&
    CF.showWebScreen(width);
  return showMessageComponent ? <Message /> : "";
}
