import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import UserAvatar from "../common/UserAvatar";
import { useHistory } from "react-router-dom";
import LeftRightComponent from "../common/LeftRightComponent";
import { Body1 } from "../Typography/Typographys";
import classNames from "classnames";
import "../../scss/ConnectionComponents.scss";
import { SkeletonComponent } from "../common/Skeletons";

export const MyConnectionsComponent = ({
  getAllConnectionsData,
  userData,
  myProfile,
}) => {
  const history = useHistory();
  const [connectionsList, setConnectionsList] = useState([]);

  const showExtraConnectionCount = () => {
    return connectionsList?.length !== 0 && connectionsList?.length > 14;
  };

  useEffect(() => {
    if (getAllConnectionsData?.data?.status === "1") {
      setConnectionsList(getAllConnectionsData?.data?.data);
    }
  }, [getAllConnectionsData]);

  return getAllConnectionsData?.loading || connectionsList?.length !== 0 ? (
    <LeftRightComponent
      heading={
        myProfile === 1
          ? "My Connections"
          : userData?.userInfo?.data?.first_name + "'s Connections"
      }
    >
      <Box className="myConnRoot">
        {getAllConnectionsData?.loading
          ? [...Array(7)].map(() => {
              return (
                <Box>
                  <SkeletonComponent width={44} height={44} />
                </Box>
              );
            })
          : connectionsList?.slice(0, 7).map((item, key) => {
              const connection =
                item?.inviterUser?.id === userData?.userInfo?.data?.id
                  ? item?.inviteeUser
                  : item?.inviterUser;
              return (
                <UserAvatar
                  src={connection.profile_picture}
                  key={key}
                  name={connection.display_name}
                  onClick={() =>
                    history.push("/user-profile/" + connection.slug + "/0")
                  }
                />
              );
            })}
        {showExtraConnectionCount() && (
          <Box
            className={classNames("myOtherConnCountDiv", "center")}
            onClick={() =>
              history.push(`/user-profile/${userData?.userInfo?.data?.slug}/2`)
            }
          >
            <Body1>{`${connectionsList?.length - 7}+`}</Body1>
          </Box>
        )}
      </Box>
    </LeftRightComponent>
  ) : (
    ""
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyConnectionsComponent);
