import {
  CREATE_FEED_ERROR,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_START,
  GET_FEED_SUCCESS,
  GET_FEED_START,
  GET_FEED_ERROR,
  GET_PROFILE_FEED_SUCCESS,
  GET_PROFILE_FEED_ERROR,
  GET_PROFILE_FEED_START,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_START,
  UPDATE_FEED_ERROR,
  FEED_LIKE_SUCCESS,
  FEED_LIKE_START,
  FEED_LIKE_ERROR,
  FEED_COMMENT_SUCCESS,
  FEED_COMMENT_START,
  FEED_COMMENT_ERROR,
  DELETE_FEED_SUCCESS,
  DELETE_FEED_START,
  DELETE_FEED_ERROR,
  GET_LIKED_FEED_SUCCESS,
  GET_LIKED_FEED_START,
  GET_LIKED_FEED_ERROR,
  GET_FEED_LIKES_SUCCESS,
  GET_FEED_LIKES_START,
  GET_FEED_LIKES_ERROR,
  GET_FEED_COMMENTS_SUCCESS,
  GET_FEED_COMMENTS_START,
  GET_FEED_COMMENTS_ERROR,
  GET_FEED_COMMENT_LIKES_SUCCESS,
  GET_FEED_COMMENT_LIKES_START,
  GET_FEED_COMMENT_LIKES_ERROR,
  POST_FEED_COMMENT_REPLY_SUCCESS,
  POST_FEED_COMMENT_REPLY_START,
  POST_FEED_COMMENT_REPLY_ERROR,
  GET_FEED_COMMENT_REPLY_LIKES_ERROR,
  GET_FEED_COMMENT_REPLY_LIKES_START,
  GET_FEED_COMMENT_REPLY_LIKES_SUCCESS,
  POST_FEED_COMMENT_LIKE_SUCCESS,
  POST_FEED_COMMENT_LIKE_START,
  POST_FEED_COMMENT_LIKE_ERROR,
  POST_FEED_COMMENT_REPLY_LIKE_SUCCESS,
  POST_FEED_COMMENT_REPLY_LIKE_START,
  POST_FEED_COMMENT_REPLY_LIKE_ERROR,
  GET_SEARCH_FEEDS_ERROR,
  GET_SEARCH_FEEDS_SUCCESS,
  GET_SEARCH_FEEDS_START,
  GET_GROUP_FEEDS_ERROR,
  GET_GROUP_FEEDS_SUCCESS,
  GET_GROUP_FEEDS_START,
  UPDATE_COMMENT_AND_REPLY_ERROR,
  UPDATE_COMMENT_AND_REPLY_START,
  UPDATE_COMMENT_AND_REPLY_SUCCESS,
  DELETE_COMMENT_AND_REPLY_ERROR,
  DELETE_COMMENT_AND_REPLY_START,
  DELETE_COMMENT_AND_REPLY_SUCCESS,
  GET_FEED_DETAILS_ERROR,
  GET_FEED_DETAILS_START,
  GET_FEED_DETAILS_SUCCESS,
} from "../actions/FeedAction";
import {
  CreateNewFeed,
  GetAllFeed,
  GetAllProfileFeed,
  UpdateFeed,
  FeedLike,
  FeedComment,
  DeleteFeed,
  GetLikedFeed,
  GetFeedLikes,
  GetFeedComments,
  GetFeedCommentLikes,
  PostFeedCommentReply,
  GetFeedCommentReplyLikes,
  FeedCommentLike,
  FeedCommentReplyLike,
  SearchFeeds,
  GetGroupFeeds,
  UpdateCommentAndReply,
  DeleteCommentAndReply,
  GetFeedDetails,
} from "../Apis/FeedApis";
import { call, put, takeEvery } from "@redux-saga/core/effects";
import toast from "../components/common/Snackbars";

// Feeds Related Saga Functions
// Create
function* CreateNewFeedWorker({ feedData }) {
  const response = yield call(CreateNewFeed, feedData);
  try {
    if (response?.status === "1") {
      yield put({ type: CREATE_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: CREATE_FEED_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: CREATE_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* CreateNewFeedWatcher() {
  yield takeEvery(CREATE_FEED_START, CreateNewFeedWorker);
}

// Get
function* GetFeedWorker({ offset }) {
  const response = yield call(GetAllFeed, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: GET_FEED_ERROR, data: response?.message });
    }
  } catch (err) {
    yield put({ type: GET_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedWatcher() {
  yield takeEvery(GET_FEED_START, GetFeedWorker);
}

function* GetFeedLikesWorker({ feed_id }) {
  const response = yield call(GetFeedLikes, feed_id);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_FEED_LIKES_SUCCESS, data: response });
    } else {
      yield put({ type: GET_FEED_LIKES_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_FEED_LIKES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedLikesWatcher() {
  yield takeEvery(GET_FEED_LIKES_START, GetFeedLikesWorker);
}

function* PostFeedCommentLikeWorker({
  feed_id,
  comment_id,
  like_dislike_type,
}) {
  const response = yield call(
    FeedCommentLike,
    feed_id,
    comment_id,
    like_dislike_type
  );
  try {
    if (response?.status === "1") {
      yield put({ type: POST_FEED_COMMENT_LIKE_SUCCESS, data: response });
    } else {
      yield put({
        type: POST_FEED_COMMENT_LIKE_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: POST_FEED_COMMENT_LIKE_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* PostFeedCommentLikeWatcher() {
  yield takeEvery(POST_FEED_COMMENT_LIKE_START, PostFeedCommentLikeWorker);
}

function* PostFeedCommentReplyLikeWorker({
  feed_id,
  comment_id,
  reply_id,
  like_dislike_type,
}) {
  const response = yield call(
    FeedCommentReplyLike,
    feed_id,
    comment_id,
    reply_id,
    like_dislike_type
  );
  try {
    if (response?.status === "1") {
      yield put({ type: POST_FEED_COMMENT_REPLY_LIKE_SUCCESS, data: response });
    } else {
      yield put({
        type: POST_FEED_COMMENT_REPLY_LIKE_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: POST_FEED_COMMENT_REPLY_LIKE_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* PostFeedCommentReplyLikeWatcher() {
  yield takeEvery(
    POST_FEED_COMMENT_REPLY_LIKE_START,
    PostFeedCommentReplyLikeWorker
  );
}

function* GetFeedCommentLikesWorker({ feed_id, comment_id }) {
  const response = yield call(GetFeedCommentLikes, feed_id, comment_id);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_FEED_COMMENT_LIKES_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_FEED_COMMENT_LIKES_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_FEED_COMMENT_LIKES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedCommentLikesWatcher() {
  yield takeEvery(GET_FEED_COMMENT_LIKES_START, GetFeedCommentLikesWorker);
}

function* GetFeedCommentReplyLikesWorker({ feed_id, comment_id, reply_id }) {
  const response = yield call(
    GetFeedCommentReplyLikes,
    feed_id,
    comment_id,
    reply_id
  );
  try {
    if (response?.status === "1") {
      yield put({ type: GET_FEED_COMMENT_REPLY_LIKES_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_FEED_COMMENT_REPLY_LIKES_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_FEED_COMMENT_REPLY_LIKES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedCommentReplyLikesWatcher() {
  yield takeEvery(
    GET_FEED_COMMENT_REPLY_LIKES_START,
    GetFeedCommentReplyLikesWorker
  );
}

function* PostFeedCommentReplyWorker({ feed_id, comment_id, message }) {
  const response = yield call(
    PostFeedCommentReply,
    feed_id,
    comment_id,
    message
  );
  try {
    if (response?.status === "1") {
      yield put({ type: POST_FEED_COMMENT_REPLY_SUCCESS, data: response });
    } else {
      yield put({
        type: POST_FEED_COMMENT_REPLY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: POST_FEED_COMMENT_REPLY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* PostFeedCommentReplyWatcher() {
  yield takeEvery(POST_FEED_COMMENT_REPLY_START, PostFeedCommentReplyWorker);
}

function* GetFeedCommentsWorker({ feed_id }) {
  const response = yield call(GetFeedComments, feed_id);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_FEED_COMMENTS_SUCCESS, data: response, feed_id });
    } else {
      yield put({ type: GET_FEED_COMMENTS_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_FEED_COMMENTS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedCommentsWatcher() {
  yield takeEvery(GET_FEED_COMMENTS_START, GetFeedCommentsWorker);
}

function* GetLikedFeedWorker({ offset }) {
  const response = yield call(GetLikedFeed, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_LIKED_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: GET_LIKED_FEED_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_LIKED_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetLikedFeedWatcher() {
  yield takeEvery(GET_LIKED_FEED_START, GetLikedFeedWorker);
}

function* GetProfileFeedWorker({ user_id, offset }) {
  const response = yield call(GetAllProfileFeed, user_id, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_PROFILE_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: GET_PROFILE_FEED_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_PROFILE_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetProfileFeedWatcher() {
  yield takeEvery(GET_PROFILE_FEED_START, GetProfileFeedWorker);
}

function* UpdateFeedWorker({ feed_id, feedData }) {
  const response = yield call(UpdateFeed, feed_id, feedData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_FEED_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateFeedWatcher() {
  yield takeEvery(UPDATE_FEED_START, UpdateFeedWorker);
}

function* FeedLikeWorker({ feed_id, like_dislike_type }) {
  const response = yield call(FeedLike, feed_id, like_dislike_type);
  try {
    if (response?.status === "1") {
      yield put({ type: FEED_LIKE_SUCCESS, data: response });
    } else {
      yield put({ type: FEED_LIKE_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: FEED_LIKE_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* FeedLikeWatcher() {
  yield takeEvery(FEED_LIKE_START, FeedLikeWorker);
}

function* FeedCommentWorker({ feed_id, message }) {
  const response = yield call(FeedComment, feed_id, message);
  try {
    if (response?.status === "1") {
      yield put({ type: FEED_COMMENT_SUCCESS, data: response });
    } else {
      yield put({ type: FEED_COMMENT_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: FEED_COMMENT_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* FeedCommentWatcher() {
  yield takeEvery(FEED_COMMENT_START, FeedCommentWorker);
}

function* DeleteFeedWorker({ feed_id }) {
  const response = yield call(DeleteFeed, feed_id);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_FEED_SUCCESS, data: response });
    } else {
      yield put({ type: DELETE_FEED_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_FEED_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteFeedWatcher() {
  yield takeEvery(DELETE_FEED_START, DeleteFeedWorker);
}

function* SearchFeedsWorker({ query_string, offset }) {
  const response = yield call(SearchFeeds, query_string, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_SEARCH_FEEDS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_SEARCH_FEEDS_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_SEARCH_FEEDS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* SearchFeedsWatcher() {
  yield takeEvery(GET_SEARCH_FEEDS_START, SearchFeedsWorker);
}

function* GetGroupFeedsWorker({ slug, offset }) {
  const response = yield call(GetGroupFeeds, slug, offset);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_GROUP_FEEDS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_GROUP_FEEDS_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_GROUP_FEEDS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetGroupFeedsWatcher() {
  yield takeEvery(GET_GROUP_FEEDS_START, GetGroupFeedsWorker);
}

function* UpdateCommentAndReplyWorker({ data }) {
  const response = yield call(UpdateCommentAndReply, data);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_COMMENT_AND_REPLY_SUCCESS, data: response });
    } else {
      yield put({
        type: UPDATE_COMMENT_AND_REPLY_ERROR,
        data: response,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({
      type: UPDATE_COMMENT_AND_REPLY_ERROR,
      data: { message: err?.message, status: "0" },
    });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateCommentAndReplyWatcher() {
  yield takeEvery(UPDATE_COMMENT_AND_REPLY_START, UpdateCommentAndReplyWorker);
}

function* DeleteCommentAndReplyWorker({ data }) {
  const response = yield call(DeleteCommentAndReply, data);
  try {
    if (response?.status === "1") {
      yield put({
        type: DELETE_COMMENT_AND_REPLY_SUCCESS,
        data: { ...response, ...data },
      });
    } else {
      yield put({
        type: DELETE_COMMENT_AND_REPLY_ERROR,
        data: response,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({
      type: DELETE_COMMENT_AND_REPLY_ERROR,
      data: { message: err?.message, status: "0" },
    });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteCommentAndReplyWatcher() {
  yield takeEvery(DELETE_COMMENT_AND_REPLY_START, DeleteCommentAndReplyWorker);
}

function* GetFeedDetailsWorker({ data }) {
  const response = yield call(GetFeedDetails, data);
  try {
    if (response?.status === "1") {
      yield put({
        type: GET_FEED_DETAILS_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: GET_FEED_DETAILS_ERROR,
        data: response,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({
      type: GET_FEED_DETAILS_ERROR,
      data: { message: err?.message, status: "0" },
    });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetFeedDetailsWatcher() {
  yield takeEvery(GET_FEED_DETAILS_START, GetFeedDetailsWorker);
}
