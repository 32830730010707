import { get, post, deleteM } from "../Api";

//Get Post
export const GetPost = async (offset) => {
  return await post(`/post/get`, { offset });
};
//Create Post
export const CreatePost = async (postData) => {
  return await post(`/post`, postData);
};

//Edit Post
export const UpdateBlogPost = async (id, blogData) => {
  return await post(`/post/update/${id}`, blogData);
};

//Get Blog Details
export const GetBlogDetails = async (id) => {
  return await get(`/post/get-blog-detail/${id}`);
};

//get categories list
export const GetAllCategories = async () => {
  return await get(`/post/get-categories`);
};

//Get Autofill Categories
export const GetAutofillCategories = async (query, newCategory) => {
  return await post(`/post/autofill-categories`, {
    queryString: query,
    new_category: newCategory,
  });
};

//Remove Category API
export const deleteCategory = async (id) => {
  return await deleteM(`/post/delete-categories/${id}`);
};

//Create Category
export const CreateCategory = async (name) => {
  return await post(`/post/categories`, { name });
};

//get categories list
export const GetPostCategories = async () => {
  return await get(`/post/get-categories`);
};

//Delete Blog post
export const deleteBlogPost = async (slug) => {
  return await deleteM(`/post/delete/${slug}`);
};

//Third Party get post details
export const GetAllBlogsPost = async (offset) => {
  return await post(`/post/get-all-blogs`, { offset });
};

//Get User Post
export const GetUserPost = async (user_slug , offset) => {
  return await get(`/post/get-blogs-by-user/${user_slug}?offset=${offset}`);
};
