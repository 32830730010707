import React, { lazy } from "react";
import { makeStyles, FormHelperText } from "@material-ui/core";
import classNames from "classnames";
const ParasTypography = lazy(() => import("../Typography/ParasTypography"));
const LabelTypography = lazy(() => import("../Typography/LabelTypography"));

const useStyles = makeStyles((theme) => ({
  rolesUL: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    marginTop: 10,
  },
  roleLI: {
    border: `1px solid ${theme.palette.text.bold}`,
    padding: "5px 10px",
    borderRadius: "5px",
    margin: "0px 10px 10px 0px",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
      "&>p": {
        color: theme.palette.secondary.main,
      },
    },
  },
  selectedRole: {
    border: `1px solid ${theme.palette.secondary.main}`,
    "&>p": {
      color: theme.palette.secondary.main,
    },
  },
}));

export default function UserRoles({ roles, setRoles, roleValidation, isCompanyAccount }) {
  const classes = useStyles();

  const handleChange = (role) => {
    let tempRoles = [...roles];
    if (tempRoles.includes(role.toLowerCase())) {
      tempRoles = tempRoles.filter((item) => item !== role.toLowerCase());
    } else {
      tempRoles.push(role.toLowerCase());
    }
    setRoles(tempRoles);
  };

  const allRoles = [
    "Developer",
    "Designer",
    "Blogger",
    "Consultant",
    "Support person",
    "Content strategist",
    "Marketer",
    "Sys Admin",
    "Publisher",
    "SEO Consultant",
    "Project Manager",
    "Content Writer",
    "Quality Assurance",
    "Other"
  ];

  const companyRoles = [
    "Website Development",
    "Theme Development",
    "Plugin Development",
    "Website Optimization",
    "Maintenance and Support",
    "Training and Consultation",
    "Website Migration",
    "Website Security",
  ]

  return (
    <div>
      <LabelTypography children="Select Your Activites" />
      <div className={classes.rolesUL}>
        {isCompanyAccount ?
          <>
            {companyRoles.map((item, key) => {
              return (
                <div
                  className={classNames(
                    classes.roleLI,
                    roles.includes(item.toLowerCase()) && classes.selectedRole
                  )}
                  key={key}
                  onClick={() => handleChange(item)}
                >
                  <ParasTypography children={item} />
                </div>
              );
            })}
          </>
          :
          <>
            {allRoles.map((item, key) => {
              return (
                <div
                  className={classNames(
                    classes.roleLI,
                    roles.includes(item.toLowerCase()) && classes.selectedRole
                  )}
                  key={key}
                  onClick={() => handleChange(item)}
                >
                  <ParasTypography children={item} />
                </div>
              );
            })}
          </>
        }
      </div>
      {roleValidation && (
        <FormHelperText error>Minimum one role is required.</FormHelperText>
      )}
    </div>
  );
}
