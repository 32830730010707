import { post, get, deleteM } from "../Api";

// User Profile Detail Related APIs
// Create
export const ProfileDetail = async (formData) => {
  return await post(`/user/user-profile`, formData);
};

// Update
export const UpdateProfileDetail = async (profileData) => {
  return await post(`/user/update/user-profile`, profileData);
};

// Get
export const GetProfileDetail = async () => {
  return await get(`/user/get/user-profile-data`);
};

// User Performing Roles Related APIs
export const PerformingRoles = async (user_performing_roles) => {
  return await post(`/user/user-performing-roles`, { user_performing_roles });
};

export const GetPerformingRoles = async () => {
  return await get(`/user/get/user-performing-roles`);
};

// User Education History Related APIs
// Create
export const UserEducationHistory = async (
  university,
  from_date,
  to_date,
  message
) => {
  return await post(`/user/education-history`, {
    university,
    from_date,
    to_date,
    message,
  });
};

// Get
export const GetEducationHistory = async () => {
  return await get(`/user/get/education-history`);
};

// Update
export const UpdateEducationHistory = async (
  id,
  university,
  from_date,
  to_date,
  message
) => {
  return await post(`/user/update/education-history/${id}`, {
    university,
    from_date,
    to_date,
    message,
  });
};

// Delete
export const DeleteEducationHistory = async (id) => {
  return await deleteM(`/user/delete/education-history/${id}`);
};

// User Work History Related APIs
// Create
export const UserWorkHistory = async (
  org_name,
  from_date,
  to_date,
  message
) => {
  return await post(`/user/work-history`, {
    org_name,
    from_date,
    to_date,
    message,
  });
};

// Get
export const GetUserWorkHistory = async () => {
  return await get(`/user/get/work-history`);
};

// Update
export const UpdateUserWorkHistory = async (
  id,
  org_name,
  from_date,
  to_date,
  message
) => {
  return await post(`/user/update/work-history/${id}`, {
    org_name,
    from_date,
    to_date,
    message,
  });
};

// Delete
export const DeleteUserWorkHistory = async (id) => {
  return await deleteM(`/user/delete/work-history/${id}`);
};

// Update User Data
export const UpdateUserData = async (userData) => {
  return await post(`/user`, userData);
};

// Get User Data
export const GetUserData = async () => {
  return await get(`/user/get-user`);
};

// Get Profile User Data
export const GetProfileUserData = async (data) => {
  return await get(`/user/get-user/${data?.slug}/${data?.user_id}`);
};

export const UpdateUserProfilePicture = async (userData) => {
  return await post(`/user/update-profile-picture`, userData);
};

export const GetAllUsers = async () => {
  return await get(`/user/get-all-users`);
};

export const GetSearchData = async (queryString) => {
  return await get(`/user/get-search-users/${queryString}`);
};

export const UpdateUserProfileStep = async (profile_step) => {
  return await post(`/user/update-user-profile-step`, { profile_step });
};

export const RegisterCompanyAccount = async (is_company_account) => {
  return await post(`/user/company-regstration`, { is_company_account });
};


export const CompanyProfileDetails = async (data) => {
  return await post(`/user/company-profile`, data);
};


export const UpdateCompanyProfileDetails = async (data) => {
  return await post(`/user/update/company-profile`, data);
};

export const CompanyPerformingRoles = async (user_performing_roles) => {
  return await post(`/user/company-performing-roles`, { user_performing_roles });
};

export const UpdateCompanyPerformingRoles = async (user_performing_roles) => {
  return await post(`/user/company-performing-roles/update`, { user_performing_roles });
};


export const GetCompanyPerformingRoles = async () => {
  return await get(`/user/get/company-performing-roles`);
};


export const DeleteCompanyPerformingRoles = async () => {
  return await deleteM(`/user/delete/company-performing-roles`);
};


export const GetCompanyProfile = async () => {
  return await get(`/user/get/company-profile-data`);
};

