//Get All Groups Actions
export const GET_ALL_GROUPS_START = "GET_ALL_GROUPS_START";
export const GET_ALL_GROUPS_SUCCESS = "GET_ALL_GROUPS_SUCCESS";
export const GET_ALL_GROUPS_ERROR = "GET_ALL_GROUPS_ERROR";

//Get All Groups Actions
export const GET_GROUP_MEMBERS_START = "GET_GROUP_MEMBERS_START";
export const GET_GROUP_MEMBERS_SUCCESS = "GET_GROUP_MEMBERS_SUCCESS";
export const GET_GROUP_MEMBERS_ERROR = "GET_GROUP_MEMBERS_ERROR";

//Create Group Actions
export const CREATE_GROUP_START = "CREATE_GROUP_START";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR";

//Update Group Icons Actions
export const UPLOAD_GROUP_ICON_START = "UPLOAD_GROUP_ICON_START";
export const UPLOAD_GROUP_ICON_SUCCESS = "UPLOAD_GROUP_ICON_SUCCESS";
export const UPLOAD_GROUP_ICON_ERROR = "UPLOAD_GROUP_ICON_ERROR";

//Get Group Suggestions Actions
export const GET_GROUP_SUGGESTIONS_START = "GET_GROUP_SUGGESTIONS_START";
export const GET_GROUP_SUGGESTIONS_SUCCESS = "GET_GROUP_SUGGESTIONS_SUCCESS";
export const GET_GROUP_SUGGESTIONS_ERROR = "GET_GROUP_SUGGESTIONS_ERROR";

//Join New Group Actions
export const JOIN_NEW_GROUP_START = "JOIN_NEW_GROUP_START";
export const JOIN_NEW_GROUP_SUCCESS = "JOIN_NEW_GROUP_SUCCESS";
export const JOIN_NEW_GROUP_ERROR = "JOIN_NEW_GROUP_ERROR";

//Update Group Actions
export const UPDATE_GROUP_START = "UPDATE_GROUP_START";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR";

//Get Groups Joining Requests Actions
export const GET_GROUP_JOINING_REQUESTS_START =
  "GET_GROUP_JOINING_REQUESTS_START";
export const GET_GROUP_JOINING_REQUESTS_SUCCESS =
  "GET_GROUP_JOINING_REQUESTS_SUCCESS";
export const GET_GROUP_JOINING_REQUESTS_ERROR =
  "GET_GROUP_JOINING_REQUESTS_ERROR";

//Canel Group Join Request Actions
export const CANCEL_GROUP_JOIN_REQUEST_START =
  "CANCEL_GROUP_JOIN_REQUEST_START";
export const CANCEL_GROUP_JOIN_REQUEST_SUCCESS =
  "CANCEL_GROUP_JOIN_REQUEST_SUCCESS";
export const CANCEL_GROUP_JOIN_REQUEST_ERROR =
  "CANCEL_GROUP_JOIN_REQUEST_ERROR";

//Update Group Join Request Actions
export const UPDATE_GROUP_JOIN_REQUEST_START =
  "UPDATE_GROUP_JOIN_REQUEST_START";
export const UPDATE_GROUP_JOIN_REQUEST_SUCCESS =
  "UPDATE_GROUP_JOIN_REQUEST_SUCCESS";
export const UPDATE_GROUP_JOIN_REQUEST_ERROR =
  "UPDATE_GROUP_JOIN_REQUEST_ERROR";

//Socket Io New Group joining Actions
export const SOCKET_IO_NEW_GROUP_JOINING_REQUEST =
  "SOCKET_IO_NEW_GROUP_JOINING_REQUEST";

//Get All Groups Actions
export const SOCKET_IO_ADD_NEW_GROUP_MEMBER = "SOCKET_IO_ADD_NEW_GROUP_MEMBER";
export const SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST =
  "SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST";
export const SOCKET_IO_JOIN_NEW_GROUP = "SOCKET_IO_JOIN_NEW_GROUP";
export const SOCKET_IO_UPDATE_GROUP = "SOCKET_IO_UPDATE_GROUP";

//Get Group Details Actions
export const GET_GROUP_DETAILS_START = "GET_GROUP_DETAILS_START";
export const GET_GROUP_DETAILS_SUCCESS = "GET_GROUP_DETAILS_SUCCESS";
export const GET_GROUP_DETAILS_ERROR = "GET_GROUP_DETAILS_ERROR";

//Leave Group Actions
export const LEAVE_GROUP_START = "LEAVE_GROUP_START";
export const LEAVE_GROUP_SUCCESS = "LEAVE_GROUP_SUCCESS";
export const LEAVE_GROUP_ERROR = "LEAVE_GROUP_ERROR";

//Delete Group Members Actions
export const DELETE_GROUP_MEMBERS_START = "DELETE_GROUP_MEMBERS_START";
export const DELETE_GROUP_MEMBERS_SUCCESS = "DELETE_GROUP_MEMBERS_SUCCESS";
export const DELETE_GROUP_MEMBERS_ERROR = "DELETE_GROUP_MEMBERS_ERROR";

//Delete Group Actions
export const DELETE_GROUP_START = "DELETE_GROUP_START";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR";

//Socket Io Delete Group & Group Members Actions
export const SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS =
  "SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS";
