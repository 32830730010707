import { Avatar } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  userAvatarRoot: {
    cursor: "pointer",
    "&>img": {
      borderRadius: "10px",
      background: "#fff",
      objectPosition: "top",
    },
  },
  squareVariant: {
    borderRadius: "10px !important",
  },
  noSrc: {
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: "0px",
    background: "#bdbdbd !important",
  },
  src: {
    background: theme.palette.secondary.main,
    padding: "2px",
  },
}));

export default function UserAvatar({
  src,
  name,
  setRef,
  className,
  variant = "square",
  ...props
}) {
  const classes = useStyle();
  return (
    <Avatar
      src={src}
      title={name?.replace(/\b\w/g, (l) => l?.toUpperCase())}
      ref={setRef}
      className={classNames(
        classes.userAvatarRoot,
        variant === "square" && classes.squareVariant,
        src ? (variant === "square" ? classes.src : "") : classes.noSrc,
        className
      )}
      variant={variant}
      {...props}
    >
      {name?.split(" ")[0][0]?.toUpperCase()}
      {name?.split(" ").length > 1 && name?.split(" ")[1][0]?.toUpperCase()}
    </Avatar>
  );
}
