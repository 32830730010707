import React from "react";
import {
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import "../../scss/FormComponents.scss";

const useStyle = makeStyles((theme) => ({
  selectedPaperBox: {
    borderRadius: "19px",
    marginTop: "55px",
    marginLeft: "-21px",
  }
}))

export default function SelectComponent({
  className = "",
  onChange,
  value,
  disableUnderline = true,
  menuItemClasses = "",
  menuItems = [],
  open,
  setOpen,
  feedPrivacySelectBox = false,
  openCallback = () => { },
  closeCallback = () => { },
  ...others
}) {
  const classes = useStyle();
  return (
    <Select
      className={classNames(
        "selectBoxRoot",
        feedPrivacySelectBox && "feedPrivacySelectBox",
        className
      )}
      MenuProps={{
        classes: { paper: classes.selectedPaperBox },
        anchorOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
      }}

      onChange={onChange}
      onClose={() => { setOpen(false); closeCallback() }}
      onOpen={() => { setOpen(true); openCallback() }}
      open={open}
      value={value}
      disableUnderline={disableUnderline}
      {...others}
    >
      {menuItems?.map((item, key) => {
        return (
          <MenuItem
            value={item?.value}
            className={classNames(
              "selectBoxMenuItemRoot",
              value === item?.value && "activeSelectBoxMenuItem",
              menuItemClasses
            )}
            key={key}
          >
            {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
            <ListItemText primary={item?.label} />
          </MenuItem>
        );
      })}
    </Select>
  );
}
