import React, { useState, useEffect } from "react";
import { Container, CssBaseline, Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as BlackLogo } from "../assets/blackLogo.svg";
import { RESET_PASSWORD_START , RESET_TOEKN_VARIFY_START} from "../actions/AuthAction";
import utils from "../utils/commonUtils";
import CF from "../utils/CommonFunction";
import "../scss/Auth.scss";
const SimpleButton = React.lazy(() =>
  import("../components/Buttons/SimpleButton")
);
const PlainTextfield = React.lazy(() => import("./Textfields/PlainTextfield"));
const H2 = React.lazy(() => import("./Headings/H2"));

function ResetPassword(props) {
  const { resetPasswordData, dispatchResetPassword , resetTokenVarifyAction , resetTokenVarifyData } = props;
  const history = useHistory();

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const { token } = useParams();
  //State for random image
  const [randomImage, setRanddomImage] = useState({
    imageSrc: "",
    imageText: "",
  });

  useEffect(() => {
    resetTokenVarifyAction(token)
  },[])

  //Handle click reset password
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatchResetPassword(token, password, confirm_password);
  };

  //UseEffect for setstate value in random image
  useEffect(() => {
    const { imageSrc, imageText } =
      utils.authDynamicImage[CF.randomNumberWithRange(0, 2)];
    setRanddomImage({
      imageSrc: imageSrc,
      imageText: imageText,
    });
  }, []);

  useEffect(() => {
    if (resetPasswordData?.userInfo?.status === "1") {
      setPassword("");
      setConfirm_password("");
      history.push("/signin");
    }
  }, [resetPasswordData]);

  useEffect(() => {
    if (resetTokenVarifyData?.data?.status === "0") {
      history.push("/signin");
    }
  }, [resetTokenVarifyData]);


  function createMarkup(text) {
    return { __html: text };
  }

  const formComponent = () => {
    return (
      <form onSubmit={handleSubmit}>
        <PlainTextfield
          placeholder="New Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          value={password}
          type="password"
          required={true}
        />
        <PlainTextfield
          placeholder="Confirm Password"
          onChange={(e) => {
            setConfirm_password(e.target.value);
          }}
          value={confirm_password}
          type="password"
          required={true}
        />
        <SimpleButton
          children="Submit"
          disabled={resetPasswordData?.loading}
          loading={resetPasswordData?.loading}
          type="submit"
          primaryColorBtn
          authBtn
          className="SubmitBtns"
        />
      </form>
    );
  };

  return (
    <>
      <Container component="main" maxWidth="xs" className="Container">
        <CssBaseline />
        <div className="MainContainer">
          <div className="DivContainer">
            <Grid container xs={12} sm={12} md={12}  className="MainGridContainer">
              <Grid item xs={12} sm={12} md={5}>
                <div className="AuthLeftSideDiv">
                  <div className="WkleapLogo">
                    <BlackLogo />
                  </div>
                  <div className="AuthMainDiv">
                    <div className="AuthFormRoot">
                      <div>
                        <H2 children="Reset password" className="HTag" medium />
                      </div>
                      {formComponent()}
                      <SimpleButton
                        children="Cancel"
                        psTransparentBorderBtn
                        className="AuthBtns"
                        onClick={() => history.push("/signin")}
                        authBtn
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div className="RightSideImageContainer">
                  <div>
                    <img
                      src={randomImage.imageSrc}
                      alt=""
                      className="AuthImage"
                    />
                    <div
                      dangerouslySetInnerHTML={createMarkup(
                        randomImage.imageText
                      )}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  resetPasswordData: state.AuthData.resetPasswordData,
  resetTokenVarifyData : state.AuthData.resetTokenVarifyData
});

const mapDispatchToProps = (dispatch) => ({
  dispatchResetPassword: (token, password, confirm_password) =>
    dispatch({
      type: RESET_PASSWORD_START,
      token,
      password,
      confirm_password,
    }),
  resetTokenVarifyAction: (token) =>
    dispatch({
      type: RESET_TOEKN_VARIFY_START,
      token,
    }),


});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
