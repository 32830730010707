import { post , get} from "../Api";

export const GetAllNotifications = async (data) => {
  return await post(`/get-all-notifications`, data);
};

export const ReadNotifications = async (data) => {
  return await post(`/read-notifications`, data);
};

export const GetUnReadCounts = async (data) => {
  return await get(`/get-all-counts`);
};
