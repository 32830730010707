import React, { useEffect, useState } from "react";
import { Box, FormHelperText } from "@material-ui/core";
import { connect } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { CHANGE_PASSWORD_START } from "../../actions/AuthAction";
import SimpleButton from "../Buttons/SimpleButton";
import LabelTypography from "../Typography/LabelTypography";
import EndIcon from "../Textfields/EndIcon";
import LeftRightComponent from "../common/LeftRightComponent";

let changePasswordFlag = false;

const ChangePassword = (props) => {
  const { changePasswordAPI, changePasswordData, updateEditProfileStatus } =
    props;
  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewConfirmPassword, setShowNewConfirmPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    changePasswordAPI({ old_password, new_password, confirm_password });
    changePasswordFlag = true;
  };

  useEffect(() => {
    if (changePasswordData.status === "1" && changePasswordFlag) {
      changePasswordFlag = false;
      setOld_password("");
      setNew_password("");
      setConfirm_password("");
      updateEditProfileStatus(false);
    } else if (changePasswordData.status === "0" && changePasswordFlag) {
      setPasswordValidation(changePasswordData.message);
    }
  }, [changePasswordData]);

  return (
    <LeftRightComponent heading={"Change Password"}>
      <Box className={"manageMT15"}>
        <form onSubmit={handleSubmit}>
          <Box className="manageMB20">
            <LabelTypography children="Old Password" required />
            <EndIcon
              type={showOldPassword ? "text" : "password"}
              value={old_password}
              onChange={(e) => setOld_password(e.target.value)}
              required
              className="passwordTextfield"
              Icon={
                showOldPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setShowOldPassword(false)}
                  />
                ) : (
                  <VisibilityIcon onClick={() => setShowOldPassword(true)} />
                )
              }
            />
            {passwordValidation == "Invalid old password." && (
              <FormHelperText error>{passwordValidation}</FormHelperText>
            )}
          </Box>
          <Box className="manageMB20">
            <LabelTypography children="New Password" required />
            <EndIcon
              type={showNewPassword ? "text" : "password"}
              value={new_password}
              onChange={(e) => setNew_password(e.target.value)}
              required
              className="passwordTextfield"
              Icon={
                showNewPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setShowNewPassword(false)}
                  />
                ) : (
                  <VisibilityIcon onClick={() => setShowNewPassword(true)} />
                )
              }
            />
          </Box>
          <Box className="manageMB20">
            <LabelTypography children="Confirm Password" required />
            <EndIcon
              type={showNewConfirmPassword ? "text" : "password"}
              value={confirm_password}
              onChange={(e) => setConfirm_password(e.target.value)}
              required
              className="passwordTextfield"
              Icon={
                showNewConfirmPassword ? (
                  <VisibilityOffIcon
                    onClick={() => setShowNewConfirmPassword(false)}
                  />
                ) : (
                  <VisibilityIcon
                    onClick={() => setShowNewConfirmPassword(true)}
                  />
                )
              }
            />
            {passwordValidation != "Invalid old password." && (
              <FormHelperText error>{passwordValidation}</FormHelperText>
            )}
          </Box>
          <Box className="profileFormActionButtonsDiv">
            <SimpleButton
              children={"Update"}
              loading={changePasswordData?.loading}
              secondaryColorBtn
              type="submit"
            />
            <SimpleButton
              children="Close"
              onClick={() => {
                updateEditProfileStatus(false);
              }}
              secondaryColorBtn
            />
          </Box>
        </form>
      </Box>
    </LeftRightComponent>
  );
};

const mapStateToProps = (state) => ({
  changePasswordData: state.AuthData.changePasswordData,
});

const mapDispatchToProps = (dispatch) => ({
  changePasswordAPI: (data) =>
    dispatch({
      type: CHANGE_PASSWORD_START,
      data,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
