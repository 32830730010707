import {
  GET_ALL_CHAT_ROOM_ERROR,
  GET_ALL_CHAT_ROOM_START,
  GET_ALL_CHAT_ROOM_SUCCESS,
  ADD_NEW_CHAT_MESSAGE_ERROR,
  ADD_NEW_CHAT_MESSAGE_START,
  ADD_NEW_CHAT_MESSAGE_SUCCESS,
  UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY,
  GET_CHAT_ROOM_ERROR,
  GET_CHAT_ROOM_START,
  GET_CHAT_ROOM_SUCCESS,
  SOCKET_IO_GET_NEW_MESSAGE,
  CREATE_NEW_CHAT_ROOM_ERROR,
  CREATE_NEW_CHAT_ROOM_START,
  CREATE_NEW_CHAT_ROOM_SUCCESS,
  SOCKET_IO_GET_NEW_CHAT_ROOM,
  SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES,
  UPDATE_CHAT_GROUP_START,
  UPDATE_CHAT_GROUP_SUCCESS,
  UPDATE_CHAT_GROUP_ERROR,
  ADD_UPDATE_DELETE_CHAT_GROUP_START,
  ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS,
  ADD_UPDATE_DELETE_CHAT_GROUP_ERROR,
  SOCKET_IO_CHAT_UPDATE_GROUP,
  SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS,
  SOCKET_IO_NEW_ADD_USER_PARTICIPANTS,
  SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS,
  DELETE_GROUP_CHAT_START,
  DELETE_GROUP_CHAT_SUCCESS,
  DELETE_GROUP_CHAT_ERROR,
  SOCKET_IO_DELETE_GROUP_CHAT,
} from "../actions/ChatAction";
import utils from "../utils/commonUtils";
import { socket } from "../utils/socketioSetup";

const initialState = {
  getAllChatRoomsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  addNewChatMessageData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  chatMessageRootDiv: false,
  unreadChatCount: 0,
  getChatRoomData: {
    loading: false,
    data: {},
    status: "",
    message: "",
    offset: 0,
    offsetStatus: false,
    isSocketMessage: false,
  },
  createNewChatRoomData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  updateChatGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  addUpdateDeleteChatGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  deleteChatGroupData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
};

const ChatReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CHAT_ROOM_START: {
      return {
        ...previousState,
        getAllChatRoomsData: {
          loading: true,
          data: {
            ...previousState?.getAllChatRoomsData?.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_ALL_CHAT_ROOM_SUCCESS: {
      const chats = action?.data?.data;
      let unreadChatCount = 0;
      for (let item of chats) {
        if (item?.unread_message_count !== 0) {
          unreadChatCount += 1;
        }
      }
      const offset = action?.data?.offset;
      if (unreadChatCount !== 0) {
        utils.updateFavicon("unread");
      }
      return {
        ...previousState,
        getAllChatRoomsData: {
          loading: false,
          data: {
            data:
              offset === 0
                ? action?.data?.data
                : [
                  ...previousState?.getAllChatRoomsData?.data?.data,
                  ...action?.data?.data,
                ],
            status: action?.data?.status,
            offset: action?.data?.offset,
            offsetStatus: action?.data?.offsetStatus,
            message: action?.data?.message,
          },
          errorMsg: "",
        },
        unreadChatCount: unreadChatCount,
      };
    }
    case GET_ALL_CHAT_ROOM_ERROR: {
      return {
        ...previousState,
        getAllChatRoomsData: {
          loading: false,
          data: {
            ...previousState?.getAllChatRoomsData?.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case GET_CHAT_ROOM_START: {
      const { offset } = action?.data;
      return {
        ...previousState,
        getChatRoomData: {
          ...previousState.getChatRoomData,
          loading: true,
          status: "",
          data: (offset === 0) ? undefined : previousState?.getChatRoomData?.data
        },
      };
    }
    case GET_CHAT_ROOM_SUCCESS: {
      const { status, message, offset, offsetStatus, data } = action?.data;
      let chatRoom = { ...previousState?.getChatRoomData?.data };
      if (offset === 0) {
        data["chat_messages"] = data?.chat_messages?.reverse();
        chatRoom = data;
      } else {
        chatRoom["chat_messages"] = [
          ...data?.chat_messages?.reverse(),
          ...chatRoom?.chat_messages,
        ];
      }
      return {
        ...previousState,
        getChatRoomData: {
          ...previousState.getChatRoomData,
          loading: false,
          data: chatRoom,
          status,
          message,
          offset,
          offsetStatus,
          isSocketMessage: false,
        },
      };
    }
    case GET_CHAT_ROOM_ERROR: {
      const { status, message } = action?.data;
      return {
        ...previousState,
        getChatRoomData: {
          ...previousState.getChatRoomData,
          loading: false,
          status,
          message,
        },
      };
    }
    case ADD_NEW_CHAT_MESSAGE_START: {
      const msg = action.message;
      const room_id = action.room_id;
      const user_id = action.user_id;
      let allChat = {
        ...previousState?.getAllChatRoomsData?.data,
      };
      let activeChat = { ...previousState.getChatRoomData?.data };
      let newMessage = {
        id: activeChat?.chat_messages.length + 1,
        user_id,
        message: msg,
        room_id,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString()
      }
      activeChat["chat_messages"] = [...activeChat?.chat_messages, newMessage];
      let messageChatRoomExist = false;
      allChat["data"] = allChat?.data?.map((item) => {
        if (item?.id === parseInt(utils.toNormalString(room_id))) {
          messageChatRoomExist = true;
          return { ...item, chat_messages: [newMessage] };
        } else {
          return item;
        }
      });
      let unreadChatCount = previousState.unreadChatCount;
      return {
        ...previousState,
        addNewChatMessageData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
        ...previousState,
        getChatRoomData: {
          ...previousState?.getChatRoomData,
          data: activeChat,
          isSocketMessage: true,
        },
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: allChat,
        },
        unreadChatCount: unreadChatCount,
      };
    }
    case ADD_NEW_CHAT_MESSAGE_SUCCESS: {
      return {
        ...previousState,
        addNewChatMessageData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case ADD_NEW_CHAT_MESSAGE_ERROR: {
      return {
        ...previousState,
        addNewChatMessageData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case CREATE_NEW_CHAT_ROOM_START: {
      return {
        ...previousState,
        createNewChatRoomData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case CREATE_NEW_CHAT_ROOM_SUCCESS: {
      let oldAllChatRoomData = { ...previousState.getAllChatRoomsData.data };
      if (
        oldAllChatRoomData?.data === null ||
        oldAllChatRoomData?.data === undefined
      ) {
        oldAllChatRoomData.data = [action.data.data];
      } else {
        oldAllChatRoomData?.data.unshift(action.data.data);
      }
      return {
        ...previousState,
        createNewChatRoomData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: oldAllChatRoomData,
        },
      };
    }
    case CREATE_NEW_CHAT_ROOM_ERROR: {
      return {
        ...previousState,
        createNewChatRoomData: {
          loading: false,
          data: action.data,
          errorMsg: action.data.message,
        },
      };
    }
    case UPDATE_CHAT_MESSAGE_ROOM_DIV_DISPLAY: {
      return {
        ...previousState,
        chatMessageRootDiv: action.data,
      };
    }
    case SOCKET_IO_GET_NEW_MESSAGE: {
      const msg = action.data;
      const user_id = action.user_id;
      const path = action.path;
      const width = action?.width;
      let allChat = {
        ...previousState?.getAllChatRoomsData?.data,
      };
      let activeChat = { ...previousState.getChatRoomData?.data };
      let messageChatRoomExist = false;
      allChat["data"] = allChat?.data?.map((item) => {
        if (item?.id === parseInt(msg?.room_id)) {
          messageChatRoomExist = true;
          return { ...item, chat_messages: [msg] };
        } else {
          return item;
        }
      });
      if (activeChat?.id === msg?.room_id) {
        if (
          !activeChat?.chat_messages?.map((item) => item?.id).includes(msg?.id)
        ) {
          activeChat["chat_messages"] = [...activeChat?.chat_messages, msg];
        }
      }
      const msgDialogOpen = previousState.chatMessageRootDiv;
      let unreadChatCount = previousState.unreadChatCount;
      if (msg?.user_id !== user_id && messageChatRoomExist) {
        if (activeChat?.id !== msg?.room_id) {
          allChat["data"] = allChat?.data?.map((item) => {
            if (item?.id === msg?.room_id) {
              if (item?.unread_message_count === 0) {
                unreadChatCount += 1;
              }
              return {
                ...item,
                unread_message_count: item?.unread_message_count + 1,
              };
            } else {
              return item;
            }
          });
          utils.updateFavicon("unread");
        } else {
          if (
            msgDialogOpen ||
            (path === "messages" && width > 960) ||
            path === "user-chat"
          ) {
            activeChat["message_unread_data"] =
              utils.updateChatUnreadMessageData(
                activeChat?.message_unread_data,
                user_id,
                msg?.id
              );
            allChat["data"] = allChat?.data?.map((item) => {
              return item?.id === msg?.room_id
                ? {
                  ...item,
                  message_unread_data: utils.updateChatUnreadMessageData(
                    item?.message_unread_data,
                    user_id,
                    msg?.id
                  ),
                }
                : item;
            });
            const verifyTabIsActiveOrNot = () => {
              if (document.hidden) {
                utils.updateFavicon("unread");
              } else {
                utils.updateFavicon("read");
                document.removeEventListener(
                  "visibilitychange",
                  verifyTabIsActiveOrNot
                );
              }
            };
            if (document.hidden) {
              utils.updateFavicon("unread");
              document.addEventListener(
                "visibilitychange",
                verifyTabIsActiveOrNot
              );
            }
          } else {
            allChat["data"] = allChat?.data?.map((item) => {
              if (item?.id === msg?.room_id) {
                if (item?.unread_message_count === 0) {
                  unreadChatCount += 1;
                }
                return {
                  ...item,
                  unread_message_count: item?.unread_message_count + 1,
                };
              } else {
                return item;
              }
            });
            utils.updateFavicon("unread");
          }
        }
      }
      if (!messageChatRoomExist) {
        socket.emit("GetNewChatRoom", { room_id: msg?.room_id });
      }
      return {
        ...previousState,
        getChatRoomData: {
          ...previousState?.getChatRoomData,
          data: activeChat,
          isSocketMessage: true,
        },
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: allChat,
        },
        unreadChatCount: unreadChatCount,
      };
    }
    case SOCKET_IO_GET_NEW_CHAT_ROOM: {
      const allChats = {
        ...previousState?.getAllChatRoomsData?.data,
      };
      let unreadChatCount = previousState.unreadChatCount;
      let isAvailable = false;
      for (let item of allChats?.data) {
        if (item?.id === action?.data?.id) {
          isAvailable = true;
          break;
        }
      }
      if (!isAvailable) {
        allChats["data"] = [action?.data, ...allChats?.data];
        unreadChatCount += 1;
        utils.updateFavicon("unread");
      }
      return {
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: allChats,
        },
        unreadChatCount: unreadChatCount,
      };
    }
    case SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES: {
      const unreadChatCount = previousState.unreadChatCount;
      let allChats = { ...previousState.getAllChatRoomsData?.data };
      const { room_id, user_id, message_id } = action?.data;
      allChats["data"] = allChats?.data?.map((item) => {
        if (item?.id === room_id) {
          let tempMUArray = JSON.parse(item?.message_unread_data);
          tempMUArray = tempMUArray?.map((user) => {
            return user?.user_id === user_id
              ? { ...user, last_seen_message_id: message_id }
              : user;
          });
          return {
            ...item,
            unread_message_count: 0,
            message_unread_data: JSON.stringify(tempMUArray),
          };
        } else {
          return item;
        }
      });
      let activeChatRoom = { ...previousState.getChatRoomData?.data };
      if (activeChatRoom?.id === room_id) {
        let tempMUArray = JSON.parse(activeChatRoom?.message_unread_data);
        tempMUArray = tempMUArray?.map((user) => {
          return user?.user_id === user_id
            ? { ...user, last_seen_message_id: message_id }
            : user;
        });
        activeChatRoom["message_unread_data"] = JSON.stringify(tempMUArray);
      }
      if (unreadChatCount === 0 || unreadChatCount - 1 === 0) {
        utils.updateFavicon("read");
      }
      return {
        ...previousState,
        unreadChatCount: unreadChatCount === 0 ? 0 : unreadChatCount - 1,
        getAllChatRoomsData: {
          ...previousState.getAllChatRoomsData,
          data: allChats,
        },
        getChatRoomData: {
          ...previousState.getChatRoomData,
          data: activeChatRoom,
        },
      };
    }

    //START: Chat Group Update
    case UPDATE_CHAT_GROUP_START: {
      return {
        ...previousState,
        updateChatGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_CHAT_GROUP_SUCCESS: {
      return {
        ...previousState,
        updateChatGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_CHAT_GROUP_ERROR: {
      return {
        ...previousState,
        updateChatGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }

    //END: Chat Group Update

    //START : Group Member Add or Delete
    case ADD_UPDATE_DELETE_CHAT_GROUP_START: {
      return {
        ...previousState,
        addUpdateDeleteChatGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case ADD_UPDATE_DELETE_CHAT_GROUP_SUCCESS: {
      return {
        ...previousState,
        addUpdateDeleteChatGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case ADD_UPDATE_DELETE_CHAT_GROUP_ERROR: {
      return {
        ...previousState,
        addUpdateDeleteChatGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    //END : Group Member Add or delete

    //START:Group Edit Socket & Update listing socket
    case SOCKET_IO_CHAT_UPDATE_GROUP: {
      const chatGroupDetail = { ...previousState.getChatRoomData.data };
      if (chatGroupDetail?.id === parseInt(action?.data?.room_id)) {
        const { profile_picture, name } = action?.data;
        chatGroupDetail["profile_picture"] = profile_picture;
        chatGroupDetail["name"] = name;
      }

      let chatAllDetails = { ...previousState.getAllChatRoomsData.data };
      let allGroups = chatAllDetails?.data;
      let finalGroups = allGroups?.map((item) => {
        if (item?.id === chatGroupDetail?.id) {
          const { profile_picture, name } = action?.data;
          return {
            ...item,
            profile_picture: profile_picture,
            name: name,
          };
        } else {
          return item;
        }
      });
      chatAllDetails.data = finalGroups;

      return {
        ...previousState,
        getChatRoomData: {
          ...previousState.getChatRoomData,
          data: chatGroupDetail,
        },
        getAllChatRoomsData: {
          ...previousState.getAllChatRoomsData,
          data: chatAllDetails,
        },
      };
    }
    //END:Group Edit Socket & Update listing socket

    //START : Socket Io Add for another user profile
    case SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS: {
      let newChatGroupData = { ...previousState.getChatRoomData.data };
      const { addNewChatParticipants } = action.data;
      let newChatUsers = newChatGroupData["chat_participants"].concat(
        addNewChatParticipants
      );
      newChatGroupData["chat_participants"] = newChatUsers;
    }
    //END : Socket Io Add for another user profile

    //START:Group Member Delete for another user profile
    case SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS: {
      let room_id = action?.data;
      let getChats = { ...previousState?.getAllChatRoomsData?.data };
      getChats["data"] = getChats?.data?.filter((chat, index) => {
        return chat?.id !== room_id;
      });
      return {
        ...previousState,
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: getChats,
        },
      };
    }
    //END:Group Member Delete for another user profile

    //START:Socket Io group member delete or update
    case SOCKET_IO_NEW_ADD_USER_PARTICIPANTS: {
      let groupInfo = { ...previousState.getChatRoomData.data };
      const { deleteChatParticipants, addChatParticipants } = action.data;
      groupInfo["chat_participants"] = groupInfo?.chat_participants?.filter(
        (item) => {
          return deleteChatParticipants.indexOf(item.user_id) === -1;
        }
      );
      let tempUsers =
        groupInfo["chat_participants"].concat(addChatParticipants);
      groupInfo["chat_participants"] = tempUsers;

      return {
        ...previousState,
        getChatRoomData: {
          ...previousState.getChatRoomData,
          data: groupInfo,
        },
      };
    }
    //END:Socket Io group member delete or update

    //START :Group Chat delete

    case DELETE_GROUP_CHAT_START: {
      return {
        ...previousState,
        deleteChatGroupData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_CHAT_SUCCESS: {
      return {
        ...previousState,
        deleteChatGroupData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_GROUP_CHAT_ERROR: {
      return {
        ...previousState,
        deleteChatGroupData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    //START:Socket for delete chat group
    case SOCKET_IO_DELETE_GROUP_CHAT: {
      let groupChatAllData = { ...previousState.getAllChatRoomsData.data };
      const { room_id } = action?.data;
      groupChatAllData["data"] = groupChatAllData?.data?.filter((item) => {
        return item?.id !== room_id;
      });

      let chatRoomData = {};
      if (groupChatAllData["data"].length === 0) {
        window.location.reload();
        return false;
      } else {
        chatRoomData = {
          ...previousState?.getChatRoomData,
          data: groupChatAllData["data"][0],
        };
      }
      return {
        ...previousState,
        getAllChatRoomsData: {
          ...previousState?.getAllChatRoomsData,
          data: groupChatAllData,
        },
        getChatRoomData: chatRoomData,
      };
    }
    //END:Socket for delete chat group
    //END: Group Chat delete
    default: {
      return previousState || initialState;
    }
  }
};

export default ChatReducers;
