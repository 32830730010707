import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classname from "classnames";

const useStyles = makeStyles((theme) => ({
  wpSocietyH6Root: {
    color: theme.palette.text.bold,
    fontSize: "12px",
    lineHeight: "18px",
    textTransform: "capitalize",
  },
}));

export default function H6({
  className = "",
  children,
  bold,
  medium,
  light,
  ellipsis,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useStyles();
  return (
    <Typography
      variant="h6"
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      className={classname(
        classes.wpSocietyH6Root,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className
      )}
      {...props}
    >
      {children}
    </Typography>
  );
}
