import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import CircularProgressComponent from "../common/CircularProgressComponent";

const useStyle = makeStyles((theme) => ({
  btnRoot: {
    cursor: "pointer",
    boxSizing: "border-box",
    border: `1px solid ${theme.palette.common.black}`,
    borderRadius: "10px",
    background: "transparent",
    "&>span": {
      fontFamily: theme.palette.font.boldText.fontFamily,
      fontSize: 12,
      lineHeight: "18px",
      fontWeight: theme.palette.fontweight.light400,
      textTransform: "capitalize",
      whiteSpace: "nowrap",
      color: theme.palette.common.black,
      "&>span": {
        margin: "0px 5px",
        "&>svg": {
          width: 18,
          height: 18,
          color: theme.palette.common.black,
        },
      },
    },
  },
  primaryColorBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.main,
    "&>span": {
      color: theme.palette.common.white,
      "&>span>svg": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      border: `1px solid ${theme.palette.common.primaryBtnHover}`,
      background: theme.palette.common.primaryBtnHover,
    },
  },
  secondaryColorBtn: {
    border: `1px solid ${theme.palette.secondary.main}`,
    background: theme.palette.secondary.main,
    "&>span": {
      color: theme.palette.common.white,
      "&>span>svg": {
        color: theme.palette.common.white,
      },
    },
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
      background: theme.palette.secondary.main,
    },
  },
  transparentBorderBtn: {
    border: `1px solid ${theme.palette.text.medium}`,
    background: "transparent",
    "&>span": {
      color: theme.palette.text.medium,
      "&>span>svg": {
        color: theme.palette.text.medium,
      },
    },
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
      background: "transparent",
      "&>span": {
        color: theme.palette.secondary.main,
        "&>span>svg": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  psTransparentBorderBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    background: "transparent",
    "&>span": {
      color: theme.palette.primary.main,
      "&>span>svg": {
        color: theme.palette.primary.main,
      },
    },
    "&:hover": {
      border: `1px solid ${theme.palette.secondary.main}`,
      background: "transparent",
      "&>span": {
        color: theme.palette.secondary.main,
        "&>span>svg": {
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  disabled: {
    cursor: "initial",
    border: `1px solid ${theme.palette.common.lightgrayone}`,
    background: theme.palette.common.lightgrayone,
    "&>span": {
      color: theme.palette.text.light,
      "&>span>svg": {
        color: theme.palette.text.light,
      },
      "&>div": {
        "&>svg": {
          color: theme.palette.text.light,
        },
      },
    },
  },
  authBtn: {
    width: "100%",
    borderRadius: "20px",
    height: 40,
  },
}));

const defaultFunction = () => { };

export default function SimpleButton({
  className,
  children,
  disabled = false,
  onClick = defaultFunction,
  type,
  color,
  endIcon,
  fullWidth = false,
  size,
  startIcon,
  href,
  variant,
  primaryColorBtn = false,
  secondaryColorBtn = false,
  transparentBorderBtn = false,
  psTransparentBorderBtn = false,
  loading = false,
  circularProgressColor,
  authBtn,
}) {
  const classes = useStyle();
  return (
    <Button
      className={classNames(classes.btnRoot, className, [
        primaryColorBtn && classes.primaryColorBtn,
        secondaryColorBtn && classes.secondaryColorBtn,
        transparentBorderBtn && classes.transparentBorderBtn,
        psTransparentBorderBtn && classes.psTransparentBorderBtn,
        authBtn && classes.authBtn,
        (disabled || loading) && classes.disabled,
      ])}
      onClick={() => onClick()}
      disabled={disabled}
      variant={variant}
      type={type}
      size={size}
      color={color}
      endIcon={loading ? null : endIcon}
      startIcon={loading ? null : startIcon}
      fullWidth={fullWidth}
      href={href}
    >
      {loading ? (
        <CircularProgressComponent customColor={circularProgressColor} />
      ) : (
        children
      )}
    </Button>
  );
}
