import { call, put, takeEvery, select } from "@redux-saga/core/effects";
import {
  EDUCATION_HISTORY_START,
  EDUCATION_HISTORY_SUCCESS,
  EDUCATION_HISTORY_ERROR,
  GET_EDUCATION_HISTORY_START,
  GET_EDUCATION_HISTORY_SUCCESS,
  GET_EDUCATION_HISTORY_ERROR,
  UPDATE_EDUCATION_HISTORY_START,
  UPDATE_EDUCATION_HISTORY_SUCCESS,
  UPDATE_EDUCATION_HISTORY_ERROR,
  DELETE_EDUCATION_HISTORY_START,
  DELETE_EDUCATION_HISTORY_SUCCESS,
  DELETE_EDUCATION_HISTORY_ERROR,
  WORK_HISTORY_START,
  WORK_HISTORY_SUCCESS,
  WORK_HISTORY_ERROR,
  GET_WORK_HISTORY_START,
  GET_WORK_HISTORY_SUCCESS,
  GET_WORK_HISTORY_ERROR,
  UPDATE_WORK_HISTORY_START,
  UPDATE_WORK_HISTORY_SUCCESS,
  UPDATE_WORK_HISTORY_ERROR,
  DELETE_WORK_HISTORY_START,
  DELETE_WORK_HISTORY_SUCCESS,
  DELETE_WORK_HISTORY_ERROR,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_START,
  GET_USER_DATA_ERROR,
  GET_PROFILE_USER_DATA_SUCCESS,
  GET_PROFILE_USER_DATA_START,
  GET_PROFILE_USER_DATA_ERROR,
  GET_PROFILE_DETAILS_SUCCESS,
  GET_PROFILE_DETAILS_START,
  GET_PROFILE_DETAILS_ERROR,
  GET_PERFORMING_ROLES_SUCCESS,
  GET_PERFORMING_ROLES_ERROR,
  GET_PERFORMING_ROLES_START,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_START,
  UPDATE_PROFILE_DETAILS_ERROR,
  UPDATE_USER_DATA_SUCCESS,
  UPDATE_USER_DATA_START,
  UPDATE_USER_DATA_ERROR,
  UPDATE_USER_PROFILE_PICTURE_ERROR,
  UPDATE_USER_PROFILE_PICTURE_START,
  UPDATE_USER_PROFILE_PICTURE_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_START,
  GET_ALL_USERS_ERROR,
  GET_SEARCH_DATA_SUCCESS,
  GET_SEARCH_DATA_START,
  GET_SEARCH_DATA_ERROR,
  GET_COMPANY_PERFORMING_ROLES_START,
  GET_COMPANY_PERFORMING_ROLES_SUCCESS,
  GET_COMPANY_PERFORMING_ROLES_ERROR,
  DELETE_COMPANY_PERFORMING_ROLES_START,
  DELETE_COMPANY_PERFORMING_ROLES_SUCCESS,
  DELETE_COMPANY_PERFORMING_ROLES_ERROR,
  GET_COMPANY_PROFILE_DETAILS_START,
  GET_COMPANY_PROFILE_DETAILS_SUCCESS,
  GET_COMPANY_PROFILE_DETAILS_ERROR,
} from "../actions/UserAction";
import {
  ProfileDetail,
  PerformingRoles,
  UserEducationHistory,
  UserWorkHistory,
  GetEducationHistory,
  UpdateEducationHistory,
  DeleteEducationHistory,
  GetUserWorkHistory,
  UpdateUserWorkHistory,
  DeleteUserWorkHistory,
  GetUserData,
  GetProfileUserData,
  GetProfileDetail,
  GetPerformingRoles,
  UpdateProfileDetail,
  UpdateUserData,
  UpdateUserProfilePicture,
  GetAllUsers,
  GetSearchData,
  UpdateUserProfileStep,
  RegisterCompanyAccount,
  CompanyProfileDetails,
  UpdateCompanyProfileDetails,
  CompanyPerformingRoles,
  UpdateCompanyPerformingRoles,
  GetCompanyPerformingRoles,
  DeleteCompanyPerformingRoles,
  GetCompanyProfile,
} from "../Apis/UserApis";
import toast from "../components/common/Snackbars";

// Get User Data
function* GetUserDataWorker() {
  const response = yield call(GetUserData);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_USER_DATA_SUCCESS, data: response });
    } else {
      yield put({ type: GET_USER_DATA_ERROR, data: response });
      toast.error(response?.message || 'Unauthorized!');
    }
  } catch (err) {
    yield put({ type: GET_USER_DATA_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetUserDataWatcher() {
  yield takeEvery(GET_USER_DATA_START, GetUserDataWorker);
}

// Get Profile User Data
function* GetProfileUserDataWorker({ data }) {
  const response = yield call(GetProfileUserData, data);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_PROFILE_USER_DATA_SUCCESS, data: response });
    } else {
      yield put({ type: GET_PROFILE_USER_DATA_ERROR, data: response });
    }
  } catch (err) {
    yield put({ type: GET_PROFILE_USER_DATA_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetProfileUserDataWatcher() {
  yield takeEvery(GET_PROFILE_USER_DATA_START, GetProfileUserDataWorker);
}

// Update User Data
function* UpdateUserDataWorker({ userData }) {
  const response = yield call(UpdateUserData, userData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_USER_DATA_SUCCESS, data: response });
    } else {
      yield put({ type: UPDATE_USER_DATA_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_USER_DATA_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateUserDataWatcher() {
  yield takeEvery(UPDATE_USER_DATA_START, UpdateUserDataWorker);
}

function* UpdateUserProfilePictureWorker({ userData }) {
  const response = yield call(UpdateUserProfilePicture, userData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_USER_PROFILE_PICTURE_SUCCESS, data: response });
      window.location.reload();
    } else {
      yield put({
        type: UPDATE_USER_PROFILE_PICTURE_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_USER_PROFILE_PICTURE_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateUserProfilePictureWatcher() {
  yield takeEvery(
    UPDATE_USER_PROFILE_PICTURE_START,
    UpdateUserProfilePictureWorker
  );
}

// User Profile Detail Related Saga Functions

// Update
function* UpdateProfileDetailWorker({ profileData }) {
  const response = yield call(UpdateProfileDetail, profileData);
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_PROFILE_DETAILS_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({
        type: UPDATE_PROFILE_DETAILS_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_PROFILE_DETAILS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateProfileDetailWatcher() {
  yield takeEvery(UPDATE_PROFILE_DETAILS_START, UpdateProfileDetailWorker);
}

// Get
function* GetProfileDetailWorker() {
  const response = yield call(GetProfileDetail);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_PROFILE_DETAILS_SUCCESS, data: response });
    } else {
      yield put({ type: GET_PROFILE_DETAILS_ERROR, data: response?.message });
    }
  } catch (err) {
    yield put({ type: GET_PROFILE_DETAILS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetProfileDetailWatcher() {
  yield takeEvery(GET_PROFILE_DETAILS_START, GetProfileDetailWorker);
}

function* GetPerformingRolesWorker() {
  const response = yield call(GetPerformingRoles);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_PERFORMING_ROLES_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_PERFORMING_ROLES_ERROR,
        data: response?.message,
      });
    }
  } catch (err) {
    yield put({ type: GET_PERFORMING_ROLES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetPerformingRolesWatcher() {
  yield takeEvery(GET_PERFORMING_ROLES_START, GetPerformingRolesWorker);
}

// User Education History Related Saga Functions
// Create
function* EducationHistoryWorker({ university, from_date, to_date, message }) {
  const response = yield call(
    UserEducationHistory,
    university,
    from_date,
    to_date,
    message
  );
  try {
    if (response?.status === "1") {
      yield put({ type: EDUCATION_HISTORY_SUCCESS, data: response });
    } else if (response?.status === "3") {
      yield put({ type: EDUCATION_HISTORY_ERROR, data: response });
      toast.error(response?.message);
    } else {
      yield put({
        type: EDUCATION_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: EDUCATION_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* EducationHistoryWatcher() {
  yield takeEvery(EDUCATION_HISTORY_START, EducationHistoryWorker);
}

// Update
function* UpdateEducationHistoryWorker({
  id,
  university,
  from_date,
  to_date,
  message,
}) {
  const response = yield call(
    UpdateEducationHistory,
    id,
    university,
    from_date,
    to_date,
    message
  );
  try {
    if (response?.status === "1") {
      yield put({
        type: UPDATE_EDUCATION_HISTORY_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: UPDATE_EDUCATION_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_EDUCATION_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateEducationHistoryWatcher() {
  yield takeEvery(UPDATE_EDUCATION_HISTORY_START, UpdateEducationHistoryWorker);
}

// Delete
function* DeleteEducationHistoryWorker({ id }) {
  const response = yield call(DeleteEducationHistory, id);
  try {
    if (response?.status === "1") {
      yield put({
        type: DELETE_EDUCATION_HISTORY_SUCCESS,
        data: response,
      });
    } else {
      yield put({
        type: DELETE_EDUCATION_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_EDUCATION_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteEducationHistoryWatcher() {
  yield takeEvery(DELETE_EDUCATION_HISTORY_START, DeleteEducationHistoryWorker);
}

// Get
function* GetEducationHistoryWorker() {
  const response = yield call(GetEducationHistory);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_EDUCATION_HISTORY_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_EDUCATION_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_EDUCATION_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetEducationHistoryWatcher() {
  yield takeEvery(GET_EDUCATION_HISTORY_START, GetEducationHistoryWorker);
}

// User Work History Related Saga Functions
// Create
function* WorkHistoryWorker({ org_name, from_date, to_date, message }) {
  const response = yield call(
    UserWorkHistory,
    org_name,
    from_date,
    to_date,
    message
  );
  try {
    if (response?.status === "1") {
      yield put({ type: WORK_HISTORY_SUCCESS, data: response });
    } else if (response?.status === "3") {
      yield put({ type: WORK_HISTORY_ERROR, data: response });
      toast.error(response?.message);
    } else {
      yield put({
        type: WORK_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: WORK_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* WorkHistoryWatcher() {
  yield takeEvery(WORK_HISTORY_START, WorkHistoryWorker);
}

// Update
function* UpdateWorkHistoryWorker({
  id,
  org_name,
  from_date,
  to_date,
  message,
}) {
  const response = yield call(
    UpdateUserWorkHistory,
    id,
    org_name,
    from_date,
    to_date,
    message
  );
  try {
    if (response?.status === "1") {
      yield put({ type: UPDATE_WORK_HISTORY_SUCCESS, data: response });
    } else {
      yield put({
        type: UPDATE_WORK_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: UPDATE_WORK_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* UpdateWorkHistoryWatcher() {
  yield takeEvery(UPDATE_WORK_HISTORY_START, UpdateWorkHistoryWorker);
}

// Delete
function* DeleteWorkHistoryWorker({ id }) {
  const response = yield call(DeleteUserWorkHistory, id);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_WORK_HISTORY_SUCCESS, data: response });
    } else {
      yield put({
        type: DELETE_WORK_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_WORK_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteWorkHistoryWatcher() {
  yield takeEvery(DELETE_WORK_HISTORY_START, DeleteWorkHistoryWorker);
}

// Get
function* GetWorkHistoryWorker() {
  const response = yield call(GetUserWorkHistory);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_WORK_HISTORY_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_WORK_HISTORY_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_WORK_HISTORY_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetWorkHistoryWatcher() {
  yield takeEvery(GET_WORK_HISTORY_START, GetWorkHistoryWorker);
}

function* GetAllUsersWorker() {
  const response = yield call(GetAllUsers);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_ALL_USERS_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_ALL_USERS_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_ALL_USERS_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetAllUsersWatcher() {
  yield takeEvery(GET_ALL_USERS_START, GetAllUsersWorker);
}

function* GetSearchDataWorker({ queryString }) {
  const response = yield call(GetSearchData, queryString);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_SEARCH_DATA_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_SEARCH_DATA_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_SEARCH_DATA_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetSearchDataWatcher() {
  yield takeEvery(GET_SEARCH_DATA_START, GetSearchDataWorker);
}

function* GetCompanyPerformingRolesWorker() {
  const response = yield call(GetCompanyPerformingRoles);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_COMPANY_PERFORMING_ROLES_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_COMPANY_PERFORMING_ROLES_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: GET_COMPANY_PERFORMING_ROLES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* GetCompanyPerformingRolesWatcher() {
  yield takeEvery(GET_COMPANY_PERFORMING_ROLES_START, GetCompanyPerformingRolesWorker);
}

function* DeleteCompanyPerformingRolesWorker() {
  const response = yield call(DeleteCompanyPerformingRoles);
  try {
    if (response?.status === "1") {
      yield put({ type: DELETE_COMPANY_PERFORMING_ROLES_SUCCESS, data: response });
    } else {
      yield put({
        type: DELETE_COMPANY_PERFORMING_ROLES_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (err) {
    yield put({ type: DELETE_COMPANY_PERFORMING_ROLES_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* DeleteCompanyPerformingRolesWatcher() {
  yield takeEvery(DELETE_COMPANY_PERFORMING_ROLES_START, DeleteCompanyPerformingRolesWorker);
}

function* GetCompanyProfileWorker() {
  const response = yield call(GetCompanyProfile);
  try {
    if (response?.status === "1") {
      yield put({ type: GET_COMPANY_PROFILE_DETAILS_SUCCESS, data: response });
    } else {
      yield put({
        type: GET_COMPANY_PROFILE_DETAILS_ERROR,
        data: response?.message,
      });
    }
  } catch (err) {
    yield put({ type: GET_COMPANY_PROFILE_DETAILS_ERROR, data: err?.message });
  }
}

export function* GetCompanyProfileWatcher() {
  yield takeEvery(GET_COMPANY_PROFILE_DETAILS_START, GetCompanyProfileWorker);
}