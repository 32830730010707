import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  body1Root: {
    fontSize: 12,
    lineHeight: "18px",
    color: theme.palette.text.medium,
  },
  body2Root: {
    fontSize: 10,
    lineHeight: "18px",
    color: theme.palette.text.light,
  },
}));

export function Body1({
  children,
  className = "",
  bold,
  medium,
  light = true,
  ellipsis,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useStyle();
  return (
    <Typography
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      variant="body1"
      className={classNames(
        classes.body1Root,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className
      )}
      variantMapping={{ body1: "p" }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export function Body2({
  children,
  className = "",
  bold,
  medium,
  light = true,
  ellipsis,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useStyle();
  return (
    <Typography
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      variant="body2"
      className={classNames(
        classes.body2Root,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className
      )}
      variantMapping={{ body2: "p" }}
      {...props}
    >
      {children}
    </Typography>
  );
}
