import React, { Suspense, useEffect } from "react";
import PageLoading from "./components/PageLoading";
import { connect } from "react-redux";
import { socket } from "./utils/socketioSetup";
import {
  Route,
  useHistory,
  Redirect,
  Switch,
  useLocation,
} from "react-router-dom";
import GroupListingContainer from "./containers/GroupListingContainer";
import { NotificationContainer } from "react-notifications";
import toast from "./components/common/Snackbars";
import "react-notifications/lib/notifications.css";
import {
  SOCKET_IO_DELETE_FEED,
  SOCKET_IO_FEED_DISLIKE,
  SOCKET_IO_FEED_LIKE,
  SOCKET_IO_GET_NEW_FEED,
  SOCKET_IO_UPDATE_FEED,
  SOCKET_IO_NEW_FEED_COMMENT,
  SOCKET_IO_NEW_FEED_COMMENT_REPLY,
  SOCKET_IO_NEW_FEED_COMMENT_LIKE,
  SOCKET_IO_DISLIKE_FEED_COMMENT,
  SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE,
  SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY,
  SOCKET_IO_DELETE_GROUP_FEED,
} from "./actions/FeedAction";
import SigninContainers from "./containers/SigninContainers";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import SignupContainers from "./containers/SignupContainers";
import SignupVerifactionContainer from "./containers/SignupVerifactionContainer";
import FeedDetailPageContainer from "./containers/FeedDetailPageContainer";
import StepperContainer from "./containers/StepperContainer";
import ForgotPasswordContainer from "./containers/ForgotPassword";
import ResetPasswordContainer from "./containers/ResetPassword";
import ProfileContainer from "./containers/ProfileContainer";
import {
  GET_USER_DATA_START,
  SOCKET_IO_EMAIL_VERIFICATION_SUCCESS,
  UPDATE_CONNECTION_REQUEST_NOTIFICATION,
  UPDATE_PROFILE_USER_CONNECTION_STATUS,
} from "./actions/UserAction";
import MessageContainer from "./containers/MessageContainer";
import HeaderContanier from "./containers/HeaderContainer";
import GroupDetailContainer from "./containers/GroupDetailContainer";
import SharePostMobileContainer from "./containers/SharePostMobileContainer";
import ChatPageMobile from "./components/MessageMobile/ChatpageMobile";
import AddPostContainer from "./containers/AddPostContainer";
import PostListContainer from "./containers/PostListContainer";
import BlogViewContainer from "./containers/BlogViewContainer";
import FreeDesignSet from "./containers/FreeDesignSet";
import SearchPage from "./components/MobileSearch/MobileSearch";
import NotificationsContainer from "./containers/NotificationsContainer";
import BottomTabContainer from "./containers/BottomTabContainer";
import utils from "./utils/commonUtils";
import {
  SOCKET_IO_GET_NEW_CHAT_ROOM,
  SOCKET_IO_GET_NEW_MESSAGE,
  SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES,
  SOCKET_IO_CHAT_UPDATE_GROUP,
  SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS,
  SOCKET_IO_NEW_ADD_USER_PARTICIPANTS,
  SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS,
  SOCKET_IO_DELETE_GROUP_CHAT,
} from "./actions/ChatAction";
import FullPageMessageContainer from "./containers/FullPageMessageContainer";
import {
  SOCKET_IO_GET_NEW_NOTIFICATION,
  UPDATE_LOCATION_HISTORY,
  GET_UNREAD_COUNTS_START,
} from "./actions/CommonAction";
import VerifyEmail from "./components/VerifyEmail";
import SearchComponent from "./components/SearchComponent";
import {
  SOCKET_IO_ACCEPT_CONNECTION_REQUEST,
  SOCKET_IO_CANCEL_CONNECTION_REQUEST,
  SOCKET_IO_NEW_CONNECTION_REQUEST,
  SOCKET_IO_UPDATE_CONNECTION_REQUEST,
  SOCKET_IO_REMOVE_CONNECTION_USER,
} from "./actions/ConnectionsAction";
import {
  SOCKET_IO_ADD_NEW_GROUP_MEMBER,
  SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS,
  SOCKET_IO_JOIN_NEW_GROUP,
  SOCKET_IO_NEW_GROUP_JOINING_REQUEST,
  SOCKET_IO_UPDATE_GROUP,
  SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST,
} from "./actions/GroupAction";
import useViewport from "./hooks/useViewport";
import UserPostListContainer from "./containers/UserPostiListContainer";
const HomeContainers = React.lazy(() => import("./containers/HomeContainers"));

let userSentMessageDontCallReadMarkFlag = false

function Main(props) {
  const history = useHistory();
  const location = useLocation();
  const {
    updateLocationHistory,
    socketIoGetNewFeed,
    getUserData,
    userData,
    socketIoUpdateFeed,
    socketIoDeleteFeed,
    socketIoFeedLike,
    socketIoFeedDislike,
    socketIoUpdateNewChatMessage,
    socketIoGetNewChatRoom,
    socketIoNewFeedComment,
    socketIoNewFeedCommentReply,
    socketIoNewFeedCommentLike,
    socketIoDislikeFeedComment,
    socketIoNewFeedCommentReplyLike,
    socketIoDislikeFeedCommentReply,
    socketIoGetNewNotification,
    socketIoNewConnectionRequest,
    socketIoCancelConnectionRequest,
    socketIoAcceptConnectionRequest,
    socketIoUpdateConnectionRequest,
    socketIoNewGroupJoiningRequest,
    socketIoAddNewMember,
    socketIoUpdateGroupJoiningRequest,
    socketIoJoinNewGroup,
    socketIoUpdateGroup,
    socketIoRemoveConnectionUser,
    socketIoDeleteGroupAndGroupMembers,
    socketIoEmailVerification,
    socketIoDeleteGroupFeeds,
    socketIoMarkAsReadChatMessages,
    updateConnectionRequestNotification,
    updateProfileUserConnectionStatus,
    socketIoChatGroupUpdate,
    socketIoDeleteGroupMember,
    socketIoGroupMemberNewAddDeleteUserParticipants,
    socketIoGroupMemberAddDeleteExtistingUser,
    socketIoDeleteGroupChat,
    getUnReadCounts,
  } = props;
  const path = location.pathname.split("/")[1];
  const { token } = utils.getLocalStorageItems();
  const { width } = useViewport();
  useEffect(() => {
    socket.on("isVerifiedEmail", (data) => {
      localStorage.setItem("x-access-token", data.token);
      socketIoEmailVerification(data);
    });
    socket.on("newFeed", (data) => {
      socketIoGetNewFeed(data);
    });
    socket.on("updateFeed", (data) => {
      socketIoUpdateFeed(data);
    });
    socket.on("deleteFeed", (data) => {
      socketIoDeleteFeed(data);
    });
    socket.on("feedLike", (data) => {
      socketIoFeedLike(data);
    });
    socket.on("feedDislike", (data) => {
      socketIoFeedDislike(data);
    });
    socket.on("NewChatRoom", (data) => {
      socketIoGetNewChatRoom(data);
      toast.success("You received new messages in Chat");
    });
    socket.on("feedComment", (data) => {
      socketIoNewFeedComment(data);
    });
    socket.on("feedCommentReply", (data) => {
      socketIoNewFeedCommentReply(data);
    });
    socket.on("NewCommentLike", (data) => {
      socketIoNewFeedCommentLike(data);
    });
    socket.on("dislikeComment", (data) => {
      socketIoDislikeFeedComment(data);
    });
    socket.on("NewCommentReplyLike", (data) => {
      socketIoNewFeedCommentReplyLike(data);
    });
    socket.on("dislikeCommentReply", (data) => {
      socketIoDislikeFeedCommentReply(data);
    });
    socket.on("notification", (data) => {
      if (data?.action_type == "messageOnly" && window.location.pathname.split("/")[1] == "messages") {

      } else {
        toast.success(data?.action);
        getUnReadCounts()
        if (data?.action_type !== "messageOnly") {
          socketIoGetNewNotification(data);
          updateConnectionRequestNotification(true);
        }
      }
    });
    socket.on("acceptConnectionRequest", (data) => {
      socketIoAcceptConnectionRequest(data);
      getUnReadCounts()
      updateProfileUserConnectionStatus({ ...data, type: "accepted" });
    });
    socket.on("updateConnectionRequest", (data) => {
      socketIoUpdateConnectionRequest(data);
      getUnReadCounts()
      updateProfileUserConnectionStatus(data);
    });
    socket.on("NewGroupJoiningRequest", (data) => {
      socketIoNewGroupJoiningRequest(data);
    });
    socket.on("AddNewGroupMember", (data) => {
      socketIoAddNewMember(data);
    });
    socket.on("UpdateGroupJoiningRequest", (data) => {
      socketIoUpdateGroupJoiningRequest(data);
    });
    socket.on("JoinNewGroup", (data) => {
      socketIoJoinNewGroup(data);
    });
    socket.on("UpdateGroup", (data) => {
      socketIoUpdateGroup(data);
    });
    socket.on("RemoveConnection", (data) => {
      socketIoRemoveConnectionUser(data);
    });
    socket.on("MarkAsReadChatMessages", (data) => {
      if (!userSentMessageDontCallReadMarkFlag) {
        socketIoMarkAsReadChatMessages(data);
        getUnReadCounts()
      }
    });
    socket.on("updateChatRoom", (data) => {
      socketIoChatGroupUpdate(data);
    });
    socket.on("deleteChatRoomParticipants", (data) => {
      socketIoDeleteGroupMember(data);
    });
    socket.on("addOrDeleteChatParticipants", (data) => {
      socketIoGroupMemberNewAddDeleteUserParticipants(data);
    });
    socket.on("addNewAndDeletedChatParticipants", (data) => {
      socketIoGroupMemberAddDeleteExtistingUser(data);
    });
    socket.on("deleteChatRoom", (data) => {
      socketIoDeleteGroupChat(data);
    });
  }, []);

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      socket.on("DeleteGroupAndGroupMembers", (data) => {
        socketIoDeleteGroupAndGroupMembers({
          ...data,
          userId: userData?.userInfo?.data?.id,
        });
        if (data?.type === "delete_group") {
          socketIoDeleteGroupFeeds(data);
        }
      });
      socket.on("newConnectionRequest", (data) => {
        socketIoNewConnectionRequest({
          ...data,
          userId: userData?.userInfo?.data?.id,
        });
        getUnReadCounts()
        updateProfileUserConnectionStatus({
          ...data,
          userId: userData?.userInfo?.data?.id,
          type: "new",
        });
      });
      socket.on("cancelConnectionRequest", (data) => {
        socketIoCancelConnectionRequest({
          ...data,
          userId: userData?.userInfo?.data?.id,
        });
        getUnReadCounts()
        updateProfileUserConnectionStatus({
          ...data,
          userId: userData?.userInfo?.data?.id,
          type: "cancel",
        });
      });
      socket.on("newChatMessage", (data) => {
        if (data?.user_id !== userData?.userInfo?.data?.id) {
          userSentMessageDontCallReadMarkFlag = false
          socketIoUpdateNewChatMessage(
            data,
            userData?.userInfo?.data?.id,
            path,
            width
          );
        } else if (data?.user_id == userData?.userInfo?.data?.id) {
          userSentMessageDontCallReadMarkFlag = true
        }
      });
    }
  }, [userData]);
  useEffect(() => {
    if (token && token !== "") {
      getUserData();
    } else if (
      ["reset-password", "forgot-password", "verify-email"].includes(path)
    ) {
    } else {
      if (["signin"].includes(path)) {
        history.push("signin");
      } else if (["signup"].includes(path)) {
        history.push("signup");
      } else {
        window.location.href = "/signin";
      }
    }
  }, []);
  useEffect(() => {
    if (token || token !== null) {
      if (userData?.loading === false) {
        if (userData?.errorMsg?.status === "2") {
          localStorage.clear();
          window.location.href = "/signin";
        } else if (
          userData?.userInfo?.data?.is_verified === false ||
          userData?.userInfo?.data?.is_verified === null
        ) {
          history.push("/signup-verification");
        } else if (userData?.userInfo?.data?.is_verified === true) {
          const profile_step = userData?.userInfo?.data?.profile_step;
          if (profile_step === 5) {
            let protectedRoute = [
              "signin",
              "signup",
              "reset-password",
              "forgot-password",
              "user-details",
              "signup-verification",
            ];
            if (protectedRoute.includes(path)) {
              window.location.href = "/home";
            }
          } else if (profile_step === 0) {
            window.location.href = "/signup";
          } else {
            if (profile_step > 3) {
              history.push(`/home`);
            } else {
              history.push(`/user-details/${profile_step}`);
            }
          }
        } else {
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    updateLocationHistory(path);
  }, [path, updateLocationHistory]);
  return (
    <Suspense fallback={<PageLoading />}>
      <NotificationContainer />
      <HeaderContanier userData={userData} path={path} {...props} />
      <Switch>
        <Redirect exact from="/" to="/home" />
        <Route exact path="/home" component={HomeContainers} />
        <Route
          exact
          path="/user-profile/:slug/:step"
          component={ProfileContainer}
        />
        <Route path="/signin" component={SigninContainers} />
        <Route path="/linkedin" component={LinkedInPopUp} />
        <Route path="/signup" component={SignupContainers} />
        <Route
          path="/signup-verification"
          component={SignupVerifactionContainer}
        />
        <Route path="/verify-email/:token" component={VerifyEmail} />
        <Route path="/user-details" component={StepperContainer} />
        <Route path="/forgot-password" component={ForgotPasswordContainer} />
        <Route path="/share-post" component={SharePostMobileContainer} />
        <Route path="/messages" component={FullPageMessageContainer} />
        <Route path="/user-chat/:room_id" component={ChatPageMobile} />
        <Route exact path="/search" component={SearchPage} />
        <Route exact path="/search/:queryString" component={SearchComponent} />
        <Route path="/notification" component={NotificationsContainer} />
        <Route
          path="/reset-password/:token"
          component={ResetPasswordContainer}
        />
        <Route path="/add-post" component={AddPostContainer} />
        <Route path="/edit-post/:slug" component={AddPostContainer} />
        {/* <Route exact path="/my-blogs" component={PostListContainer} />
        <Route path="/my-blogs/:slug" component={BlogViewContainer} /> */}
        {/* <Route path="/free-design-set" component={FreeDesignSet} /> */}
        <Route exact path="/blogs" component={UserPostListContainer} />
        <Route exact path="/blogs/:user_slug" component={PostListContainer} />
        <Route path="/blog-view/:slug" component={BlogViewContainer} />
        <Route exact path="/blogs/:user_slug/:slug" component={BlogViewContainer} />
        {/* Group Routes */}
        <Route
          path="/groups/:user_slug/:tab_index"
          component={GroupListingContainer}
        />
        <Route path="/group/:slug" component={GroupDetailContainer} />
        <Route path="/feed/:feed_id" component={FeedDetailPageContainer} />
      </Switch>
      <BottomTabContainer path={path} {...props} />
      <MessageContainer path={path} userData={userData} />
    </Suspense>
  );
}
const mapStateToProps = (state) => ({
  signinData: state.AuthData.signinData,
  signupData: state.AuthData.signupData,
  forgotPasswordData: state.AuthData.forgotPasswordData,
  resetPasswordData: state.AuthData.resetPasswordData,
  profileDetailData: state.UserData.profileDetailData,
  performingRolesData: state.UserData.performingRolesData,
  userEducationData: state.UserData.userEducationData,
  userWorkData: state.UserData.userWorkData,
  userData: state.UserData.getUserData,
  getChatRoomData: state.ChatData.getChatRoomData,
  locationHistory: state.CommonData.locationHistory,
});
const mapDispatchToProps = (dispatch) => ({
  updateLocationHistory: (data) =>
    dispatch({
      type: UPDATE_LOCATION_HISTORY,
      data,
    }),
  socketIoAddNewMember: (data) =>
    dispatch({
      type: SOCKET_IO_ADD_NEW_GROUP_MEMBER,
      data,
    }),
  socketIoNewGroupJoiningRequest: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_GROUP_JOINING_REQUEST,
      data,
    }),
  socketIoGetNewFeed: (data) =>
    dispatch({
      type: SOCKET_IO_GET_NEW_FEED,
      data,
    }),
  getUserData: () =>
    dispatch({
      type: GET_USER_DATA_START,
    }),
  socketIoUpdateFeed: (data) =>
    dispatch({
      type: SOCKET_IO_UPDATE_FEED,
      data,
    }),
  socketIoDeleteFeed: (data) =>
    dispatch({
      type: SOCKET_IO_DELETE_FEED,
      data,
    }),
  socketIoFeedLike: (data) =>
    dispatch({
      type: SOCKET_IO_FEED_LIKE,
      data,
    }),
  socketIoFeedDislike: (data) =>
    dispatch({
      type: SOCKET_IO_FEED_DISLIKE,
      data,
    }),
  socketIoUpdateNewChatMessage: (data, user_id, path, width) =>
    dispatch({
      type: SOCKET_IO_GET_NEW_MESSAGE,
      data,
      user_id,
      path,
      width,
    }),
  socketIoGetNewChatRoom: (data) =>
    dispatch({
      type: SOCKET_IO_GET_NEW_CHAT_ROOM,
      data,
    }),
  socketIoNewFeedComment: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_FEED_COMMENT,
      data,
    }),
  socketIoNewFeedCommentReply: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_FEED_COMMENT_REPLY,
      data,
    }),
  socketIoNewFeedCommentLike: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_FEED_COMMENT_LIKE,
      data,
    }),
  socketIoDislikeFeedComment: (data) =>
    dispatch({
      type: SOCKET_IO_DISLIKE_FEED_COMMENT,
      data,
    }),
  socketIoNewFeedCommentReplyLike: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE,
      data,
    }),
  socketIoDislikeFeedCommentReply: (data) =>
    dispatch({
      type: SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY,
      data,
    }),
  socketIoGetNewNotification: (data) =>
    dispatch({
      type: SOCKET_IO_GET_NEW_NOTIFICATION,
      data,
    }),
  socketIoNewConnectionRequest: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_CONNECTION_REQUEST,
      data,
    }),
  socketIoCancelConnectionRequest: (data) =>
    dispatch({
      type: SOCKET_IO_CANCEL_CONNECTION_REQUEST,
      data,
    }),
  socketIoAcceptConnectionRequest: (data) =>
    dispatch({
      type: SOCKET_IO_ACCEPT_CONNECTION_REQUEST,
      data,
    }),
  socketIoUpdateConnectionRequest: (data) =>
    dispatch({
      type: SOCKET_IO_UPDATE_CONNECTION_REQUEST,
      data,
    }),
  socketIoUpdateGroupJoiningRequest: (data) =>
    dispatch({
      type: SOCKET_IO_UPDATE_GROUP_JOIN_REQUEST,
      data,
    }),
  socketIoJoinNewGroup: (data) =>
    dispatch({
      type: SOCKET_IO_JOIN_NEW_GROUP,
      data,
    }),
  socketIoUpdateGroup: (data) =>
    dispatch({
      type: SOCKET_IO_UPDATE_GROUP,
      data,
    }),
  socketIoRemoveConnectionUser: (data) =>
    dispatch({
      type: SOCKET_IO_REMOVE_CONNECTION_USER,
      data,
    }),
  socketIoEmailVerification: (data) =>
    dispatch({
      type: SOCKET_IO_EMAIL_VERIFICATION_SUCCESS,
      data,
    }),
  socketIoDeleteGroupAndGroupMembers: (data) =>
    dispatch({
      type: SOCKET_IO_DELETE_GROUP_AND_GROUP_MEMBERS,
      data,
    }),
  socketIoDeleteGroupFeeds: (data) =>
    dispatch({
      type: SOCKET_IO_DELETE_GROUP_FEED,
      data,
    }),
  socketIoMarkAsReadChatMessages: (data) =>
    dispatch({
      type: SOCKET_IO_MARK_AS_READ_CHAT_MESSAGES,
      data,
    }),
  updateConnectionRequestNotification: (data) =>
    dispatch({
      type: UPDATE_CONNECTION_REQUEST_NOTIFICATION,
      data,
    }),
  updateProfileUserConnectionStatus: (data) =>
    dispatch({
      type: UPDATE_PROFILE_USER_CONNECTION_STATUS,
      data,
    }),
  socketIoChatGroupUpdate: (data) =>
    dispatch({
      type: SOCKET_IO_CHAT_UPDATE_GROUP,
      data,
    }),
  socketIoDeleteGroupMember: (data) =>
    dispatch({
      type: SOCKET_IO_DELETE_GROUP_MEMBER_PARTICIPANTS,
      data,
    }),
  socketIoGroupMemberNewAddDeleteUserParticipants: (data) =>
    dispatch({
      type: SOCKET_IO_NEW_ADD_USER_PARTICIPANTS,
      data,
    }),
  socketIoGroupMemberAddDeleteExtistingUser: (data) =>
    dispatch({
      type: SOCKET_IO_Add_OR_DELETE_EXTISTING_USERS,
      data,
    }),
  socketIoDeleteGroupChat: (data) =>
    dispatch({
      type: SOCKET_IO_DELETE_GROUP_CHAT,
      data,
    }),
  getUnReadCounts: (data) =>
    dispatch({
      type: GET_UNREAD_COUNTS_START,
      data,
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Main);
