import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useClasses = makeStyles((theme) => ({
  labelRoot: {
    fontSize: 14,
    lineHeight: "21px",
    color: theme.palette.text.bold,
    marginBottom: 5,
  },
  has_error: {
    color: theme.palette.common.red,
  },
}));

export default function LabelTypography({
  className = "",
  children,
  variant,
  has_error,
  required,
  bold,
  medium,
  light,
  ellipsis,
  multilineEllipsis,
  line = 2,
  style,
  ...props
}) {
  const classes = useClasses();
  return (
    <Typography
      variant={variant}
      style={{ WebkitLineClamp: multilineEllipsis ? line : "unset", ...style }}
      className={classNames(
        classes.labelRoot,
        bold && "fwBold",
        medium && "fwMedium",
        light && "fwLight",
        ellipsis && "textEllipsis",
        multilineEllipsis && "multilineTextEllipsis",
        className,
        has_error && classes.has_error
      )}
      {...props}
    >
      {children}
      {required ? <span style={{ color: "red" }}> *</span> : null}
    </Typography>
  );
}
