import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState, lazy } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useIntersection } from "../hooks/customHooks";
import { GET_GROUP_DETAILS_START } from "../actions/GroupAction";
import { GET_ALL_CONNECTIONS_START , GET_CONNECTION_REQUESTS_START} from "../actions/ConnectionsAction";
import utils from "../utils/commonUtils";
import { GET_GROUP_FEEDS_START } from "../actions/FeedAction";
import zeroStateGroupIcon from "../assets/img/zeroStateGroupIcon.png";
import { ThreeColumnGridView } from "../components/common/GridViews";
const GroupMembers = React.lazy(() =>
  import("../components/GroupListing/GroupMembers")
);
const ProfileDashboard = React.lazy(() =>
  import("../components/HomeComponents/ProfileDashboard")
);
const LeftNavbar = React.lazy(() =>
  import("../components/HomeComponents/LeftNavbar")
);
const Groups = React.lazy(() => import("../components/HomeComponents/Groups"));
const Feeds = React.lazy(() => import("../components/HomeComponents/Feeds"));
const UploadFeed = React.lazy(() =>
  import("../components/HomeComponents/UploadFeed")
);
const Body = lazy(() => import("../components/common/Body"));
const UserDetails = lazy(() =>
  import("../components/ProfileComponents/UserDetails")
);
const MaxWidthComponent = lazy(() =>
  import("../components/common/MaxWidthComponent")
);
const GroupActionButtons = lazy(() =>
  import("../components/GroupListing/GroupActionButtons")
);

const useStyle = makeStyles((theme) => ({
  udBody: {
    background: theme.palette.common.white,
  },
}));

export const GroupDetailContainer = (props) => {
  const classes = useStyle();
  const { slug } = useParams();
  const history = useHistory();
  const {
    userData,
    feedData,
    getAllConnections,
    getAllConnectionsData,
    getGroupDetails,
    groupDetailData,
    getGroupFeeds,
    getConnectionRequests,
  } = props;
  const [group, setGroup] = useState(null);
  const [groupMembersId, setGroupMembersId] = useState([]);
  const [setTarget, entry] = useIntersection({
    threshold: 0.1,
  });

  useEffect(() => {
    const { intersectionRatio } = entry;
    if (intersectionRatio >= 0.1 && feedData?.data?.offsetStatus) {
      let offset = feedData?.data?.data?.length;
      getGroupFeeds(slug, offset);
    }
  }, [entry]);

  useEffect(() => {
    if (groupDetailData?.data?.status === "1") {
      setGroup(groupDetailData?.data?.data);
      setGroupMembersId(
        groupDetailData?.data?.data?.group_users?.map((item) => item.user_id)
      );
    } else if (groupDetailData?.errorMsg?.status === "0") {
      history.push(`/groups/${userData?.userInfo?.data?.slug}/0`);
    }
  }, [groupDetailData]);

  useEffect(() => {
    if (userData?.userInfo?.status === "1") {
      getAllConnections(utils.toBase64(userData?.userInfo?.data?.id));
    }
  }, [userData, getAllConnections]);

  useEffect(() => {
    getGroupDetails(slug);
    getGroupFeeds(slug, 0);
    window.scrollTo(0, 0);
  }, [slug]);

  useEffect(() => {
    getConnectionRequests({ offset: 0 });
  },[])

  return (
    <Body>
      <MaxWidthComponent className={classes.udBody}>
        <UserDetails
          type="groupDetail"
          loading={groupDetailData?.loading}
          data={{
            image: group?.icon || zeroStateGroupIcon,
            name: group?.name,
            description: group?.description,
          }}
          actionBtns={
            <GroupActionButtons
              type={"groupDetailPageTop"}
              groupData={{
                ...group,
                req_id: groupDetailData?.data?.verifyMyJoinRequest?.id,
                status: groupDetailData?.data?.verifyMyJoinRequest?.status,
              }}
              {...props}
            />
          }
        />
      </MaxWidthComponent>
      <MaxWidthComponent className={"manageMT15"}>
        <ThreeColumnGridView
          leftSide={
            <>
              <ProfileDashboard
                myProfile={1}
                userData={userData}
                getAllConnectionsData={getAllConnectionsData}
                loading={userData?.loading}
              />
              <LeftNavbar userData={userData} />
            </>
          }
          center={
            <>
              {groupMembersId?.includes(userData?.userInfo?.data?.id) && (
                <UploadFeed uploadFeedLocation="group" group={group} />
              )}
              <Feeds feedData={feedData} setTarget={setTarget} />
            </>
          }
          rightSide={
            <>
              <GroupMembers
                group={group}
                loading={groupDetailData?.loading}
                {...props}
              />
              <Groups group={group} />
            </>
          }
        />
      </MaxWidthComponent>
    </Body>
  );
};

const mapStateToProps = (state) => ({
  userData: state.UserData.getUserData,
  feedData: state.FeedData.feedData,
  getAllConnectionsData: state.ConnectionsData.getAllConnectionsData,
  groupDetailData: state.GroupData.getGroupDetailsData,
});

const mapDispatchToProps = (dispatch) => ({
  getAllConnections: (user_id) =>
    dispatch({
      type: GET_ALL_CONNECTIONS_START,
      user_id,
    }),
  getGroupDetails: (slug) =>
    dispatch({
      type: GET_GROUP_DETAILS_START,
      slug,
    }),
  getGroupFeeds: (slug, offset) =>
    dispatch({
      type: GET_GROUP_FEEDS_START,
      slug,
      offset,
    }),
    getConnectionRequests: (data) =>
    dispatch({
      type: GET_CONNECTION_REQUESTS_START,
      data,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupDetailContainer);
