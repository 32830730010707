import React from "react";
import CF from "../utils/CommonFunction";
import useViewport from "../hooks/useViewport";
import utils from "../utils/commonUtils";
const HeaderWeb = React.lazy(() => import("../components/common/Navbar"));
const HeaderMobile = React.lazy(() => import("../components/MobileHeader/Header"));

function HeaderContanier(props) {
  const { userData, path } = props;
  const { token } = utils.getLocalStorageItems();
  const { width } = useViewport();
  const profile_step = userData?.userInfo?.data?.profile_step;
  const showNavbar =
    !utils.getAuthRouts().includes(path) &&
    !utils.getNotShowHeaderFooterRouts().includes(path) &&
    !utils.getNotShowHeaderTopRouts().includes(path) &&
    token &&
    profile_step > 2
      ? path === "messages"
        ? width > 960
          ? true
          : false
        : true
      : false;
  return showNavbar ? (
    CF.showWebScreen(width) ? (
      <HeaderWeb {...props} />
    ) : (
      <HeaderMobile {...props} />
    )
  ) : (
    ""
  );
}
export default HeaderContanier;
