import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  UPDATE_PROFILE_DETAILS_START,
  UPDATE_USER_DATA_START,
} from "../../actions/UserAction";
import moment from "moment";
import SimpleButton from "../Buttons/SimpleButton";
import PlainTextfield from "../Textfields/PlainTextfield";
import LabelTypography from "../Typography/LabelTypography";
import DatePicker from "../DateTimePickers/DatePicker";
import LeftRightComponent from "../common/LeftRightComponent";
import RadioButtons from "../input/RadioButtons";

let userProfileDetailMessage = false;

function PersonalInfo({
  userData,
  updateUserProfile,
  updateUser,
  updateUserData,
  updateEditProfileStatus,
}) {
  const user = userData?.userInfo?.data;
  const [first_name, setFirst_name] = useState(user?.first_name);
  const [last_name, setLast_name] = useState(user?.last_name);
  const [email, setEmail] = useState(user?.email);
  const [dob, setDob] = useState(user?.user_profile?.dob);
  const [contact_number, setContact_number] = useState(user?.contact_number);
  const [country, setCountry] = useState(user?.user_profile?.country);
  const [city, setCity] = useState(user?.user_profile?.city);
  const [description, setDescription] = useState(
    user?.user_profile?.description
  );

  const [gender, setGender] = useState(user?.user_profile?.gender || "male");
  const [validationArr, setValidationArr] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let temp = [];
    let status = 0;
    if (!first_name || first_name?.trim() == "") {
      temp.push("first_name");
      status = 1;
    }
    if (!last_name || last_name?.trim() == "") {
      temp.push("last_name");
      status = 1;
    }
    if (contact_number && contact_number.trim() !== "") {
      if (contact_number.replace(/-/g, "").length != 10) {
        temp.push("invalidContact");
        status = 1;
      }
    }
    if (!country || country?.trim() == "") {
      temp.push("country");
      status = 1;
    }
    if (!city || city.trim() == "") {
      temp.push("city");
      status = 1;
    }
    if (!dob) {
      temp.push("dob");
      status = 1; 
    } else {
      if (!moment(dob).isValid()) {
        status = 1;
        temp.push("inValidDob");
      }
      if (moment(dob).isAfter(moment().subtract(1, "days"))) {
        status = 1;
        temp.push("inValidDob");
      }
    }

    setValidationArr(temp);
    if (status == 0 && temp.length == 0) {
      var Dob = moment(dob).format("YYYY-MM-DD") + "T00:00:00.000Z";
      updateUserProfile({ description, country, city, dob: Dob, gender });
      updateUser({ first_name, last_name, contact_number });
      userProfileDetailMessage = true;
    }
  };

  useEffect(() => {
    if (updateUserData?.userInfo?.status === "1" && userProfileDetailMessage) {
      userProfileDetailMessage = false;
      updateEditProfileStatus(false);
    }
  }, [updateUserData]);

  return (
    <LeftRightComponent heading={"Personal Info"}>
      <Box className={"manageMT15"}>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="space-between" className="gridRow">
            <Grid>
              <LabelTypography children="First Name" required />
              <PlainTextfield
                type="text"
                required={false}
                value={first_name}
                onChange={(e) => setFirst_name(e.target.value)}
                onFocus={() =>
                  setValidationArr((prev) =>
                    prev.filter((p) => p != "first_name")
                  )
                }
                has_error={validationArr.includes("first_name")}
                helperText={
                  validationArr.includes("first_name")
                    ? "Please enter first name"
                    : null
                }
              />
            </Grid>
            <Grid>
              <LabelTypography children="Last Name" required />
              <PlainTextfield
                type="text"
                required={false}
                value={last_name}
                onChange={(e) => setLast_name(e.target.value)}
                onFocus={() =>
                  setValidationArr((prev) =>
                    prev.filter((p) => p != "last_name")
                  )
                }
                has_error={validationArr.includes("last_name")}
                helperText={
                  validationArr.includes("last_name")
                    ? "Please enter last name"
                    : null
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" className="gridRow">
            <Grid>
              <LabelTypography children="Email Address" required />
              <PlainTextfield
                type="email"
                required={false}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled
              />
            </Grid>
            <Grid>
              <LabelTypography children="Phone Number" />
              <PlainTextfield
                type={"text"}
                required={false}
                value={contact_number}
                onFocus={() =>
                  setValidationArr((prev) =>
                    prev.filter((p) => p != "invalidContact")
                  )
                }
                has_error={validationArr.includes("invalidContact")}
                helperText={
                  validationArr.includes("invalidContact")
                    ? "Please enter valid contact number"
                    : null
                }
                onChange={(e) => {
                  if (e.target.value.trim() != "") {
                    setContact_number(e.target.value.replace(/[^0-9\-]/g, ""));
                  } else {
                    setContact_number("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between" className="gridRow">
            <Grid>
              <LabelTypography children="Nation" required />
              <PlainTextfield
                type="text"
                required={false}
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                onFocus={() =>
                  setValidationArr((prev) => prev.filter((p) => p != "country"))
                }
                has_error={validationArr.includes("country")}
                helperText={
                  validationArr.includes("country")
                    ? "Please enter nation"
                    : null
                }
              />
            </Grid>
            <Grid>
              <LabelTypography children="Town" required />
              <PlainTextfield
                type="text"
                required={false}
                value={city}
                onChange={(e) => setCity(e.target.value)}
                onFocus={() =>
                  setValidationArr((prev) => prev.filter((p) => p != "city"))
                }
                has_error={validationArr.includes("city")}
                helperText={
                  validationArr.includes("city") ? "Please enter city" : null
                }
              />
            </Grid>
          </Grid>
          <Box className={"manageMB20"}>
            <LabelTypography children="Date of birth" required />
            <DatePicker
              value={dob}
              required={false}
              format="dd/MM/yyyy"
              onChange={(date) => {
                setDob(date);
              }}
              onFocus={() =>
                setValidationArr((prev) =>
                  prev.filter((p) =>( p != "dob" || p != "inValidDob" ))
                )
              }
              maxDate={moment().subtract(1, "days").toString()}
              helperText={
                validationArr.includes("dob")
                  ? "Please enter your date of birth."
                  : validationArr.includes("inValidDob")
                  ? "Date of birth should be always past date."
                  : ""
              }
              has_error={
                validationArr.includes("inValidDob") ||
                validationArr.includes("dob")
                  ? true
                  : false
              }
            />
          </Box>
          <Box className={"manageMB20"}>
            <RadioButtons
              label={"Gender"}
              required
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              radioBtnList={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" },
                { label: "Others", value: "others" },
              ]}
            />
          </Box>
          <Box className={"manageMB20"}>
            <LabelTypography children="About Description" />
            <PlainTextfield
              multiline={true}
              rows={5}
              type="text"
              value={description}
              required={false}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>
          <Box className="profileFormActionButtonsDiv">
            <SimpleButton
              children="Save"
              secondaryColorBtn
              type="submit"
              loading={updateUserData?.loading || userData?.loading}
            />
            <SimpleButton
              children="Close"
              onClick={() => {
                updateEditProfileStatus(false);
              }}
              secondaryColorBtn
            />
          </Box>
        </form>
      </Box>
    </LeftRightComponent>
  );
}

const mapStateToProps = (state) => ({
  updateUserData: state.UserData.updateUserData,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserProfile: (profileData) =>
    dispatch({
      type: UPDATE_PROFILE_DETAILS_START,
      profileData,
    }),
  updateUser: (userData) =>
    dispatch({
      type: UPDATE_USER_DATA_START,
      userData,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo);
