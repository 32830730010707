import MaxWidthComponent from "../components/common/MaxWidthComponent"
import SimpleButton from "../components/Buttons/SimpleButton";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import H3 from "../components/Headings/H3";
import { Body1 } from "../components/Typography/Typographys";
import { makeStyles } from '@material-ui/core/styles';
import GrayBG from '../assets/img/gray.png'
import CardDesign from "../components/Card/CardDesign";

// -----------------------------------CUSTOM CSS CLASSES FOR ELEMENT------------------------------
const useStyle = makeStyles((theme) => ({
    space: {
        margin: "30px 10px",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            marginTop: "100px",
        },
    },
    suggestedspace: {
        margin: "30px 10px",
        display: "flex",
        justifyContent: "space-between",
    },
}))

// -----------------------------------FUNCTION FOR DESIGNSET CARD------------------------------

function FreeDesignSet() {
    const classes = useStyle()
       // --------------------------------USER DESIGNSET DATA-------------------------
       const designset = [
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'Harvard Business Service Review Discussion Group',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        }
    ]
    // --------------------------------SUGGESTED DESIGNSET DATA-------------------------
    const suggested = [
        {
            img: GrayBG,
            title: 'suggested design',  //Harvard Business Service Review Discussion Group
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'suggested design',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
        {
            img: GrayBG,
            title: 'suggested design',
            describtion: 'Lorem ipsum dolor sit amet. Ut tenetur voluptatem ut deleniti expedita aut voluptatem'
        },
    ]

    return (
        <>
            <Body1>
                <MaxWidthComponent >
                    <div className={classes.space} >
                        <H3 children='Your Design Set' bold />
                        <SimpleButton
                            endIcon={<ControlPointIcon />}
                            children='create post'
                            primaryColorBtn
                        />
                    </div>
                    {/* -------------------USER DESIGN SET CARD------------------------ */}
                    <CardDesign data = {designset} />

                    {/* --------------------SUGGESTED DESIGN CARD FOR USER---------------------- */}
                    <H3 className={classes.suggestedspace} children='Suggested Group for you' bold />
                   <CardDesign data = {suggested} />
                </MaxWidthComponent>
            </Body1>
        </>
    )
}

export default (FreeDesignSet);