import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import SideStickyComponent from "./SideStickyComponent";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  threeColumnGridContainer: {
    "&>div": {
      "&:nth-child(1),&:nth-child(3)": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "&:nth-child(2)": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
  },
  slcGridView: {
    "&>div": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "&:nth-child(2)": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
    },
  },
  srcGridView: {
    "&>div": {
      "&:nth-child(1)": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
        },
      },
      "&:nth-child(2)": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
  },
}));

export function ThreeColumnGridView({
  leftSide,
  rightSide,
  center,
  className,
  centerClasses = "",
}) {
  const classes = useStyle();
  return (
    <Grid
      container
      className={classNames(classes.threeColumnGridContainer, className)}
    >
      <Grid item md={3}>
        <SideStickyComponent align="left" children={leftSide} />
      </Grid>
      <Grid item md={6} className={centerClasses}>
        {center}
      </Grid>
      <Grid item md={3}>
        <SideStickyComponent children={rightSide} />
      </Grid>
    </Grid>
  );
}

export function TwoColumnGridView({
  leftSide,
  rightSide,
  className = "",
  type,
}) {
  const classes = useStyle();
  return (
    <Grid
      container
      className={classNames(
        type === "singleLeftColumn" ? classes.slcGridView : classes.srcGridView,
        className
      )}
    >
      <Grid item md={type === "singleLeftColumn" ? 3 : 9}>
        {type === "singleLeftColumn" ? (
          <SideStickyComponent align="left" children={leftSide} />
        ) : (
          leftSide
        )}
      </Grid>
      <Grid item md={type === "singleLeftColumn" ? 9 : 3}>
        {type === "singleLeftColumn" ? (
          rightSide
        ) : (
          <SideStickyComponent children={rightSide} />
        )}
      </Grid>
    </Grid>
  );
}
