import { Box } from "@material-ui/core";
import React from "react";
import UserAvatar from "../common/UserAvatar";
import UserRoles from "../common/UserRoles";
import H6 from "../Headings/H6";
import { DialogComponent, DialogBody, DialogHeader } from "./DialogComponents";
import { useHistory } from "react-router-dom";
import { UserSkeleton } from "../common/Skeletons";
import DisplayName from "../common/DisplayName";

export default function LikesDialog({
  open,
  handleClose,
  type,
  users = [],
  loading,
}) {
  const history = useHistory();
  return (
    <DialogComponent open={open} handleClose={handleClose}>
      <DialogHeader handleClose={handleClose}>
        {type === "feedLikes"
          ? "Feed Likes"
          : type === "commentLikes"
            ? "Comment Likes"
            : type === "commentReplyLikes"
              ? "Comment Reply Likes"
              : ""}
      </DialogHeader>
      <DialogBody>
        {loading ? (
          <UserSkeleton num={8} />
        ) : (
          users?.sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt))).map((item, key) => {
            const user = item?.user;
            return (
              <Box className="ldrabRoot" key={key}>
                <Box>
                  <UserAvatar
                    src={user?.profile_picture}
                    name={user?.display_name}
                    onClick={() =>
                      history.push("/user-profile/" + user?.slug + "/0")
                    }
                  />
                  <Box>
                    <H6
                      medium
                      ellipsis
                      onClick={() =>
                        history.push("/user-profile/" + user?.slug + "/0")
                      }
                      title={user?.display_name}
                    >
                      <DisplayName user={user} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                    </H6>
                    <UserRoles
                      align="left"
                      roles={user?.user_performing_roles}
                    />
                  </Box>
                </Box>
              </Box>
            );
          })
        )}
      </DialogBody>
    </DialogComponent>
  );
}
