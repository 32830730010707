import { call, put, takeEvery } from "@redux-saga/core/effects";
import {
  SIGNIN_START,
  SIGNIN_SUCCESS,
  SIGNIN_ERROR,
  SIGNUP_START,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  LOGOUT_SUCCESS,
  LOGOUT_START,
  LOGOUT_ERROR,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_START,
  RESEND_VERIFICATION_EMAIL_ERROR,
  RESEND_VERIFICATION_EMAIL_START,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  RESET_TOEKN_VARIFY_START,
  RESET_TOEKN_VARIFY_SUCCESS,
  RESET_TOEKN_VARIFY_ERROR,
} from "../actions/AuthAction";
import {
  PostSignin,
  PostSignup,
  ForgotPassword,
  ResetPassword,
  Logout,
  VerifyEmail,
  ResendVerificationEmail,
  ChangePassword,
  ResetTokenVerify,
} from "../Apis/AuthApis";
import toast from "../components/common/Snackbars";

function* SigninWorker({ signinData }) {
  const response = yield call(PostSignin, signinData);
  try {
    if (response?.status === "1") {
      yield put({ type: SIGNIN_SUCCESS, data: response });
      localStorage.setItem("x-access-token", response?.token);
    } else {
      yield put({ type: SIGNIN_ERROR, data: response?.message });
      if (response?.token) {
        localStorage.setItem("x-access-token", response?.token);
      }
    }
  } catch (err) {
    yield put({ type: SIGNIN_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* SigninWatcher() {
  yield takeEvery(SIGNIN_START, SigninWorker);
}

function* SignupWorker({ signupData }) {
  const response = yield call(PostSignup, signupData);
  try {
    if (response?.status === "1") {
      yield put({ type: SIGNUP_SUCCESS, data: response });
      if (response?.token) {
        localStorage.setItem("x-access-token", response?.token);
      }
    } else {
      yield put({ type: SIGNUP_ERROR, data: response?.message });
    }
  } catch (err) {
    yield put({ type: SIGNUP_ERROR, data: err?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* SignupWatcher() {
  yield takeEvery(SIGNUP_START, SignupWorker);
}

function* ForgotPasswordWorker({ email }) {
  const response = yield call(ForgotPassword, email);
  try {
    if (response?.status === "1") {
      yield put({ type: FORGOT_PASSWORD_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: FORGOT_PASSWORD_ERROR, data: response?.message });
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ForgotPasswordWatcher() {
  yield takeEvery(FORGOT_PASSWORD_START, ForgotPasswordWorker);
}

function* ResetPasswordWorker({ token, password, confirm_password }) {
  const response = yield call(ResetPassword, token, password, confirm_password);
  try {
    if (response?.status === "1") {
      yield put({ type: RESET_PASSWORD_SUCCESS, data: response });
      toast.success(response?.message);
    } else {
      yield put({ type: RESET_PASSWORD_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ResetPasswordWatcher() {
  yield takeEvery(RESET_PASSWORD_START, ResetPasswordWorker);
}

function* ResetTokenVerifyWorker({ token }) {
  const response = yield call(ResetTokenVerify, token);
  try {
    if (response?.status === "1") {
      yield put({ type: RESET_TOEKN_VARIFY_SUCCESS, data: response });
    } else {
      yield put({
        type: RESET_TOEKN_VARIFY_ERROR,
        data: response,
        msg: response.message,
      });
    }
  } catch (error) {
    yield put({ type: RESET_TOEKN_VARIFY_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ResetTokenVarifyWatcher() {
  yield takeEvery(RESET_TOEKN_VARIFY_START, ResetTokenVerifyWorker);
}

function* LogoutWorker() {
  const response = yield call(Logout);
  try {
    if (response?.status === "1") {
      localStorage.clear();
      yield put({ type: LOGOUT_SUCCESS, data: response });
      window.location.href = "/signin";
      toast.success(response?.message);
    } else {
      yield put({ type: LOGOUT_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (error) {
    yield put({ type: LOGOUT_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* LogoutWatcher() {
  yield takeEvery(LOGOUT_START, LogoutWorker);
}

function* VerifyEmailWorker({ token }) {
  const response = yield call(VerifyEmail, token);
  try {
    if (response?.status === "1") {
      yield put({ type: VERIFY_EMAIL_SUCCESS, data: response });
      toast.success(response?.message);
      localStorage.setItem("x-access-token", response?.token);
    } else if (response?.status === "3") {
      yield put({ type: VERIFY_EMAIL_SUCCESS, data: response });
      toast.error(response?.message);
    } else {
      yield put({ type: VERIFY_EMAIL_ERROR, data: response?.message });
      toast.error(response?.message);
    }
  } catch (error) {
    yield put({ type: VERIFY_EMAIL_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* VerifyEmailWatcher() {
  yield takeEvery(VERIFY_EMAIL_START, VerifyEmailWorker);
}

function* ResendVerificationEmailWorker({ token }) {
  const response = yield call(ResendVerificationEmail, token);
  try {
    if (response?.status === "1") {
      yield put({ type: RESEND_VERIFICATION_EMAIL_SUCCESS, data: response });
      toast.success(response?.message);
      localStorage.setItem("x-access-token", response?.token);
    } else {
      yield put({
        type: RESEND_VERIFICATION_EMAIL_ERROR,
        data: response?.message,
      });
      toast.error(response?.message);
    }
  } catch (error) {
    yield put({ type: RESEND_VERIFICATION_EMAIL_ERROR, data: error?.message });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ResendVerificationEmailWatcher() {
  yield takeEvery(
    RESEND_VERIFICATION_EMAIL_START,
    ResendVerificationEmailWorker
  );
}

function* ChangePasswordWorker({ data }) {
  const response = yield call(ChangePassword, data);
  try {
    if (response?.status === "1") {
      yield put({ type: CHANGE_PASSWORD_SUCCESS, data: response });
    } else {
      yield put({
        type: CHANGE_PASSWORD_ERROR,
        data: response,
      });
      if (response?.status !== "2") {
      }
    }
  } catch (error) {
    yield put({
      type: CHANGE_PASSWORD_ERROR,
      data: { status: "0", message: error?.message },
    });
    toast.error("Something went wrong! Try after sometime.");
  }
}

export function* ChangePasswordWatcher() {
  yield takeEvery(CHANGE_PASSWORD_START, ChangePasswordWorker);
}
