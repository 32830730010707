import React, { lazy } from "react";
import { makeStyles, Step, StepLabel, Stepper } from "@material-ui/core";
import { ReactComponent as WhiteLogo } from "../../assets/whiteLogo.svg";
import classNames from "classnames";
const H2 = lazy(() => import("../Headings/H2"));

const useStyle = makeStyles((theme) => ({
  userStepLeftDiv: {
    width: "27%",
    height: "100%",
    background: theme.palette.primary.main,
    padding: 40,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  userStepLeftText: {
    width: "100%",
    height: "calc(100% - 46px)",
    flexDirection: "column",
    "&>h2": {
      width: "100%",
      color: theme.palette.common.white,
    },
  },
  stepperRoot: {
    marginTop: 37,
    width: "100%",
    background: "transparent",
    "&>div:nth-of-type(even)": {
      "&>span": {
        marginTop: "-35px",
        marginBottom: "-35px",
        height: "13vh",
        opacity: "0.5",
      },
    },
    "&>div:nth-of-type(odd)": {
      "&>span": {
        padding: "0",
      },
    },
  },
  stepperLabel: {
    "&>span": {
      "&:nth-child(1)": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&>svg": {
          "&>circle": {
            r: "8",
          },
          opacity: "0.5",
          color: theme.palette.common.white,
          zIndex: "2",
          "&>text": {
            display: "none",
          },
        },
      },
      "&:nth-child(2)": {
        "&>span": {
          marginLeft: "20px",
          color: theme.palette.common.white,
          lineHeight: "21px",
          fontSize: "14px",
          opacity: "0.5",
          fontWeight: theme.palette.fontweight.medium500,
          fontFamily: theme.palette.font.boldText.fontFamily,
        },
      },
    },
  },
  activeStepStyle: {
    "&>span": {
      "&>svg": {
        "&>circle": {
          r: "12 !important",
        },
        opacity: "1 !important",
      },
      "&:nth-child(2)": {
        "&>span": {
          lineHeight: "30px !important",
          fontSize: "20px !important",
          opacity: "1 !important",
        },
      },
    },
  },
}));

export default function VerticalStepper({ activeStep, steps }) {
  const classes = useStyle();
  return (
    <div className={classes.userStepLeftDiv}>
      <WhiteLogo />
      <div className={classNames(classes.userStepLeftText, "center")}>
        <H2 children={`Step ${activeStep}:`} medium />
        <Stepper className={classes.stepperRoot} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                className={classNames(
                  classes.stepperLabel,
                  activeStep === index + 1 ? classes.activeStepStyle : null
                )}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
}
