import React from "react";
import { Menu, MenuItem, ListItemIcon, ListItemText } from "@material-ui/core";
import classNames from "classnames";
import "../../scss/CommonComponent.scss";

export default function Dropdown({
  anchorOrigin = { vertical: "bottom", horizontal: "right" },
  transformOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  transitionDuration = 0,
  elevation = 0,
  getContentAnchorEl = null,
  menuItemList = [],
  menuItemClassName = "",
  className = "",
  handleClick = () => {},
  handleClose,
  anchorEl,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      elevation={elevation}
      getContentAnchorEl={getContentAnchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      transitionDuration={transitionDuration}
      className={classNames("dropdownRoot", className)}
    >
      {menuItemList?.map((item, key) => {
        return (
          <MenuItem
            onClick={() => handleClick(key, item?.type)}
            className={classNames("dropdownMenuItemRoot", menuItemClassName)}
            key={key}
          >
            {item?.icon && <ListItemIcon>{item?.icon}</ListItemIcon>}
            <ListItemText primary={item?.label} />
          </MenuItem>
        );
      })}
    </Menu>
  );
}
