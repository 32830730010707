import React, { useRef, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  rightSideDivStyle: {
    "&>div": {
      margin: "0px 0px 10px 10px",
    },
  },
  leftSideDivStyle: {
    "&>div": {
      margin: "0px 10px 10px 0px",
    },
  },
}));

export default function SideStickyComponent({ children, align = "right" }) {
  const classes = useStyle();
  const divRef = useRef(null);
  const [divTop, setDivTop] = useState(0);

  window.addEventListener("scroll", () => {
    if (divRef.current) {
      const windowHeight = window.innerHeight;
      const divHeight = divRef.current.offsetHeight;
      const bottomHeight = align === "left" ? 84 : 149;
      if (divHeight > windowHeight - bottomHeight) {
        const bottomMargin = align === "left" ? 15 : 65;
        setDivTop(windowHeight - divHeight - bottomMargin);
      } else {
        setDivTop(84);
      }
    }
  });

  return (
    <Box
      ref={divRef}
      style={{
        position: "sticky",
        top: `${divTop}px`,
      }}
      className={
        align === "left" ? classes.leftSideDivStyle : classes.rightSideDivStyle
      }
    >
      {children}
    </Box>
  );
}
