import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import H6 from "../Headings/H6";
import ParasTypography from "../Typography/ParasTypography";

const useStyle = makeStyles((theme) => ({
  udCardRoot: {
    boxSizing: "border-box",
    width: "100%",
    borderRadius: "15px",
    border: `1px solid ${theme.palette.border.borderColor}`,
    padding: 20,
    marginBottom: 10,
    position: "relative",
    "&>div": {
      "&:nth-child(1)": {
        display: "flex",
        flexDirection: "column",
      },
    },
    "&:hover": {
      "&>div": {
        "&:nth-child(2)": {
          display: "flex",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: 15,
    },
  },
  udcActionDiv: {
    display: "none",
    position: "absolute",
    top: 10,
    right: 10,
    flexDirection: "column",
    "&>div": {
      cursor: "pointer",
      borderRadius: "50%",
      padding: 5,
      border: `1px solid ${theme.palette.secondary.main}`,
      marginBottom: 5,
      "&>svg": {
        width: 20,
        height: 20,
        color: theme.palette.secondary.main,
      },
      "&:hover": {
        background: theme.palette.secondary.main,
        "&>svg": {
          color: theme.palette.common.white,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  udcLi: {
    margin: "5px 0px",
    display: "flex",
    boxSizing: "border-box",
    "&>h6": {
      width: "20%",
      [theme.breakpoints.down("sm")]: {
        color: theme.palette.common.black,
        width: "30% !important",
      },
    },
    "&>p": {
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "80% !important",
      },
      "&>span": {
        margin: "0px 5px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "&>h6,&>p": {
        width: "100%",
      },
    },
  },
}));

export default function UserDetailCard({
  data,
  type,
  onDelete,
  onEdit,
  userData,
  currentUser
}) {
  const classes = useStyle();
  return (
    <Box className={classes.udCardRoot}>
      <Box>
        <Box className={classes.udcLi}>
          <H6>
            {type === "education" ? "University Name" : "Organisation Name"}
          </H6>
          <ParasTypography>
            <ParasTypography variant="span">:</ParasTypography>
            {data?.heading}
          </ParasTypography>
        </Box>
        <Box className={classes.udcLi}>
          <H6>{type === "education" ? "Passing Year" : "Experiance"}</H6>
          <ParasTypography>
            <ParasTypography variant="span">:</ParasTypography>
            {data?.date}
          </ParasTypography>
        </Box>
        {data?.message && data?.message !== "" && <Box className={classes.udcLi}>
          <H6>Message</H6>
          <ParasTypography>
            <ParasTypography variant="span">:</ParasTypography>
            {data?.message}
          </ParasTypography>
        </Box>}
      </Box>
      {userData?.userInfo?.data?.id === currentUser?.userInfo?.data?.id && (
        <Box className={classes.udcActionDiv}>
          <Box className="center" onClick={onDelete}>
            <DeleteOutlineIcon />
          </Box>
          <Box className="center" onClick={onEdit}>
            <EditOutlinedIcon />
          </Box>
        </Box>
      )}
    </Box>
  );
}
