import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import classNames from "classnames";
import UserAvatar from "./UserAvatar";
import H5 from "../Headings/H5";
import UserRoles from "./UserRoles";
import { useHistory } from "react-router-dom";
import "../../scss/Dialogs.scss";
import CF from "../../utils/CommonFunction";
import AutocompleteTextfield from "../Textfields/AutocompleteTextfield";
import { ADD_UPDATE_DELETE_CHAT_GROUP_START } from "../../actions/ChatAction";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SimpleButton from "../Buttons/SimpleButton";
import DisplayName from "./DisplayName";

const useStyle = makeStyles((theme) => ({
  autocompleteTextfield: {
    "&>div": {
      borderRadius: "10px",
      padding: "0px !important",
      marginRight: "140px !important",
      position: "relative",
      left: "5px",
      "&>input": {
        padding: "10px !important",
        background: theme.palette.common.aliceBlue,
        border: `1px solid ${theme.palette.border.bordertopcolor}`,
        borderRadius: "10px",
        "&:hover": {
          border: `1px solid ${theme.palette.border.bordertopcolor}`,
          borderRadius: "10px",
        },
      },
      "&>div": {},
      "&>fieldset": {
        border: "none",
      },
    },
  },
}));

let addNewGroupMemberFlag = false;
export const UserListDialog = ({
  open,
  setOpen,
  dialogTitle,
  list,
  getChatRoomData,
  userData,
  activeChat,
  addUpdateDeleteChatGroup,
  connectionsData,
  chatSelectedUsers,
  addUpdateDeleteChatGroupData,
}) => {
  const classes = useStyle();
  const history = useHistory();
  const [finalList, setFinalList] = React.useState([]);
  const [userIds, setUserIds] = React.useState([]);
  const [newSelectedUser, setNewSelectedUser] = React.useState(null);
  const [autoCompleteData, setAutoCompleteDatautoCompleteData] = React.useState(
    []
  );

  const chatMemberDelete = (deleteUserId) => {
    let finalUserIds = userIds.filter(function (e) {
      return e !== deleteUserId;
    });
    addUpdateDeleteChatGroup(activeChat?.id, finalUserIds);
  };
  const handleAutoCompleteChange = (e, value) => {
    if (!value) {
      setNewSelectedUser(null);
    } else {
      setNewSelectedUser(value);
    }
  };

  const addNewMember = () => {
    let _userIds = userIds;
    _userIds.push(newSelectedUser?.id);
    addUpdateDeleteChatGroup(activeChat?.id, _userIds);
    addNewGroupMemberFlag = true;
  };

  useEffect(() => {
    if (
      addUpdateDeleteChatGroupData?.data?.status === "1" &&
      addNewGroupMemberFlag
    ) {
      addNewGroupMemberFlag = false;
      setNewSelectedUser(null);
    }
  }, [addUpdateDeleteChatGroupData]);

  useEffect(() => {
    let _userIds = [];
    if (list && list.length > 0) {
      let arrayWithoutCurrentUser = [];
      let arrayWithCurrentUser = [];
      for (var i = 0; i < list.length; i++) {
        if (userData?.userInfo?.data?.id === list[i]?.id) {
          arrayWithCurrentUser.push(list[i]);
        } else {
          arrayWithoutCurrentUser.push(list[i]);
        }
        _userIds.push(list[i]?.id);
      }
      const arrayFinal = arrayWithCurrentUser.concat(arrayWithoutCurrentUser);
      setFinalList(arrayFinal);
      setUserIds(_userIds);
    }

    //Autocomplete drop down Filter data
    let tempConnectionDate = connectionsData || [];
    tempConnectionDate = connectionsData?.filter((item) => {
      return _userIds.indexOf(item?.id) === -1;
    });
    setAutoCompleteDatautoCompleteData(tempConnectionDate);
  }, [list, connectionsData]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <div className={classNames("dialogTitle", "flex-hrsb")}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        {userData?.userInfo?.data?.id === getChatRoomData?.data?.user_id &&
          activeChat?.type === "group" && (
            <div className={classNames("dialogTitle", "flex-hrsb")}>
              <>
                <AutocompleteTextfield
                  placeholder="Select name"
                  onChange={handleAutoCompleteChange}
                  className={classes.autocompleteTextfield}
                  options={autoCompleteData
                    ?.filter((item) => {
                      return !chatSelectedUsers
                        ?.map((user) => user?.id)
                        .includes(item?.id);
                    })
                    ?.map((item) => {
                      return {
                        label: CF.capitalizeFirstLetter(item?.display_name),
                        id: item?.id,
                      };
                    })}
                  value={newSelectedUser}
                />
                <SimpleButton
                  children="Add Member"
                  primaryColorBtn
                  onClick={() => addNewMember()}
                  // disabled={newSelectedUser?.length === 0}
                  disabled={!newSelectedUser}
                />
              </>
            </div>
          )}
        <DialogContent className="dialogContent">
          {finalList?.map((item, key) => {
            return (
              <div key={key} className={classNames("userLi", "flex-hrs")}>
                <UserAvatar
                  name={item?.display_name}
                  src={item?.profile_picture}
                  onClick={() => history.push(`/user-profile/${item?.slug}/0`)}
                />
                <div
                  className={classNames(
                    item?.user_performing_roles?.length !== 0
                      ? "flex-hcs"
                      : "flex-hrs",
                    "userLiData"
                  )}
                >
                  <div className="nameIconStyle">
                    <H5
                      children={
                        <>
                          <DisplayName user={item} style={{ width: 14, height: 14, margin: "0px 0px -2px 4px" }} />
                        </>
                      }
                      title={item?.display_name}
                      onClick={() =>
                        history.push(`/user-profile/${item?.slug}/0`)
                      }
                      medium
                    />
                    {getChatRoomData?.data?.user_id !== item?.id &&
                      activeChat?.type === "group" &&
                      userData?.userInfo?.data?.id ===
                      getChatRoomData?.data?.user_id && (
                        <DeleteOutlineIcon
                          onClick={() => chatMemberDelete(item?.id)}
                        />
                      )}
                  </div>
                  {item?.user_performing_roles?.length > 0 ? (
                    <UserRoles
                      roles={item?.user_performing_roles}
                      align="left"
                    />
                  ) : null}
                </div>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => ({
  addUpdateDeleteChatGroupData: state.ChatData.addUpdateDeleteChatGroupData,
});

const mapDispatchToProps = (dispatch) => ({
  addUpdateDeleteChatGroup: (room_id, chatParticipantIds) =>
    dispatch({
      type: ADD_UPDATE_DELETE_CHAT_GROUP_START,
      room_id,
      chatParticipantIds,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserListDialog);
