import { Breadcrumbs, Link, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  breadcrumbsRoot: {},
  link: {
    color: theme.palette.common.black,
    cursor: "pointer",
    textTransform: "capitalize",
    fontSize: 14,
    lineHeight: "21px",
    fontFamily: theme.palette.font.boldText.fontFamily,
    fontWeight: theme.palette.fontweight.light400,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
}));

export default function BreadcrumbComponent({
  links,
  className,
  lastElementIsLink = false,
  separator = "/",
}) {
  const classes = useStyle();
  const history = useHistory();
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      className={classNames(classes.breadcrumbsRoot, className)}
      separator={separator}
    >
      {links.map((item, key) => {
        return (
          <Link
            underline={item.underline ? item.underline : "hover"}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            color="inherit"
            onClick={() => {
              if (item.link) {
                history.push(item.link);
              }
            }}
            key={key}
            className={classes.link}
            style={{
              cursor:
                links.length - 1 === key && !lastElementIsLink
                  ? "default"
                  : "pointer",
            }}
          >
            {item.icon ? item?.icon : ""}
            {item.text}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
}
