import {
  CREATE_FEED_START,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_ERROR,
  GET_FEED_ERROR,
  GET_FEED_START,
  GET_FEED_SUCCESS,
  SOCKET_IO_GET_NEW_FEED,
  GET_PROFILE_FEED_START,
  GET_PROFILE_FEED_ERROR,
  GET_PROFILE_FEED_SUCCESS,
  UPDATE_FEED_ERROR,
  UPDATE_FEED_START,
  UPDATE_FEED_SUCCESS,
  FEED_LIKE_ERROR,
  FEED_LIKE_START,
  FEED_LIKE_SUCCESS,
  FEED_COMMENT_ERROR,
  FEED_COMMENT_START,
  FEED_COMMENT_SUCCESS,
  DELETE_FEED_ERROR,
  DELETE_FEED_START,
  DELETE_FEED_SUCCESS,
  SOCKET_IO_UPDATE_FEED,
  SOCKET_IO_DELETE_FEED,
  SOCKET_IO_FEED_LIKE,
  SOCKET_IO_FEED_DISLIKE,
  SOCKET_IO_NEW_FEED_COMMENT,
  GET_LIKED_FEED_ERROR,
  GET_LIKED_FEED_START,
  GET_LIKED_FEED_SUCCESS,
  GET_FEED_LIKES_ERROR,
  GET_FEED_LIKES_START,
  GET_FEED_LIKES_SUCCESS,
  GET_FEED_COMMENTS_ERROR,
  GET_FEED_COMMENTS_START,
  GET_FEED_COMMENTS_SUCCESS,
  GET_FEED_COMMENT_LIKES_ERROR,
  GET_FEED_COMMENT_LIKES_START,
  GET_FEED_COMMENT_LIKES_SUCCESS,
  POST_FEED_COMMENT_REPLY_ERROR,
  POST_FEED_COMMENT_REPLY_START,
  POST_FEED_COMMENT_REPLY_SUCCESS,
  GET_FEED_COMMENT_REPLY_LIKES_ERROR,
  GET_FEED_COMMENT_REPLY_LIKES_START,
  GET_FEED_COMMENT_REPLY_LIKES_SUCCESS,
  SOCKET_IO_NEW_FEED_COMMENT_REPLY,
  SOCKET_IO_NEW_FEED_COMMENT_LIKE,
  SOCKET_IO_DISLIKE_FEED_COMMENT,
  POST_FEED_COMMENT_LIKE_ERROR,
  POST_FEED_COMMENT_LIKE_START,
  POST_FEED_COMMENT_LIKE_SUCCESS,
  POST_FEED_COMMENT_REPLY_LIKE_ERROR,
  POST_FEED_COMMENT_REPLY_LIKE_START,
  POST_FEED_COMMENT_REPLY_LIKE_SUCCESS,
  SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY,
  SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE,
  GET_SEARCH_FEEDS_ERROR,
  GET_SEARCH_FEEDS_START,
  GET_SEARCH_FEEDS_SUCCESS,
  SET_FEEDS_TYPE,
  GET_GROUP_FEEDS_ERROR,
  GET_GROUP_FEEDS_START,
  GET_GROUP_FEEDS_SUCCESS,
  SOCKET_IO_DELETE_GROUP_FEED,
  UPDATE_COMMENT_AND_REPLY_ERROR,
  UPDATE_COMMENT_AND_REPLY_START,
  UPDATE_COMMENT_AND_REPLY_SUCCESS,
  DELETE_COMMENT_AND_REPLY_ERROR,
  DELETE_COMMENT_AND_REPLY_START,
  DELETE_COMMENT_AND_REPLY_SUCCESS,
  GET_FEED_DETAILS_ERROR,
  GET_FEED_DETAILS_START,
  GET_FEED_DETAILS_SUCCESS,
} from "../actions/FeedAction";
import { GET_PROFILE_USER_DATA_START } from "../actions/UserAction";
import utils from "../utils/commonUtils"

const initialState = {
  newFeedData: {
    loading: false,
    feedData: {},
    errorMsg: "",
  },
  feedsType: "all",
  feedData: {
    loading: false,
    data: {
      data: [],
      offset: 0,
      offsetStatus: true,
      status: "",
      message: "",
    },
    errorMsg: "",
  },
  updateFeedData: {
    loading: false,
    feedData: {},
    errorMsg: "",
  },
  feedLikeData: {
    loading: false,
    feedData: {},
    errorMsg: "",
  },
  feedCommentData: {
    loading: false,
    feedData: {},
    errorMsg: "",
  },
  deleteFeedData: {
    loading: false,
    feedData: {},
    errorMsg: "",
  },
  feedLikesData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getFeedCommentsData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getFeedCommentLikesData: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  postFeedCommentReply: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  getFeedCommentReplyLikes: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  postFeedCommentLike: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  postFeedCommentReplyLike: {
    loading: false,
    data: {},
    errorMsg: "",
  },
  updateCommentAndReplyData: {
    loading: false,
    data: {},
    message: "",
    status: "",
  },
  deleteCommentAndReplyData: {
    loading: false,
    data: {},
    message: "",
    status: "",
  },
  getFeedDetailsData: {
    loading: false,
    data: {},
    message: "",
    status: "",
  },
};

const FeedReducers = (previousState = initialState, action) => {
  switch (action.type) {
    case SET_FEEDS_TYPE: {
      return {
        ...previousState,
        feedsType: action.data,
      };
    }
    case CREATE_FEED_START: {
      return {
        ...previousState,
        newFeedData: {
          loading: true,
          feedData: {},
          errorMsg: "",
        },
      };
    }
    case CREATE_FEED_SUCCESS: {
      return {
        ...previousState,
        newFeedData: {
          loading: false,
          feedData: action.data,
          errorMsg: "",
        },
      };
    }
    case CREATE_FEED_ERROR: {
      return {
        ...previousState,
        newFeedData: {
          loading: false,
          feedData: {},
          errorMsg: action.data,
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_START: {
      return {
        ...previousState,
        postFeedCommentReply: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_SUCCESS: {
      return {
        ...previousState,
        postFeedCommentReply: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_ERROR: {
      return {
        ...previousState,
        postFeedCommentReply: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_FEED_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_FEED_SUCCESS: {
      const oldData = { ...previousState.feedData.data };
      const offset = action.data.offset;
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_FEED_ERROR: {
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case GET_FEED_LIKES_START: {
      return {
        ...previousState,
        feedLikesData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_FEED_LIKES_SUCCESS: {
      return {
        ...previousState,
        feedLikesData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_FEED_LIKES_ERROR: {
      return {
        ...previousState,
        feedLikesData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_LIKE_START: {
      return {
        ...previousState,
        postFeedCommentReplyLike: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_LIKE_SUCCESS: {
      return {
        ...previousState,
        postFeedCommentReplyLike: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_REPLY_LIKE_ERROR: {
      return {
        ...previousState,
        postFeedCommentReplyLike: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_FEED_COMMENT_REPLY_LIKES_START: {
      return {
        ...previousState,
        getFeedCommentReplyLikes: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_FEED_COMMENT_REPLY_LIKES_SUCCESS: {
      return {
        ...previousState,
        getFeedCommentReplyLikes: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_FEED_COMMENT_REPLY_LIKES_ERROR: {
      return {
        ...previousState,
        getFeedCommentReplyLikes: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_FEED_COMMENT_LIKES_START: {
      return {
        ...previousState,
        getFeedCommentLikesData: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case GET_FEED_COMMENT_LIKES_SUCCESS: {
      return {
        ...previousState,
        getFeedCommentLikesData: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case GET_FEED_COMMENT_LIKES_ERROR: {
      return {
        ...previousState,
        getFeedCommentLikesData: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case POST_FEED_COMMENT_LIKE_START: {
      return {
        ...previousState,
        postFeedCommentLike: {
          loading: true,
          data: {},
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_LIKE_SUCCESS: {
      return {
        ...previousState,
        postFeedCommentLike: {
          loading: false,
          data: action.data,
          errorMsg: "",
        },
      };
    }
    case POST_FEED_COMMENT_LIKE_ERROR: {
      return {
        ...previousState,
        postFeedCommentLike: {
          loading: false,
          data: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_FEED_COMMENTS_START: {
      return {
        ...previousState,
        getFeedCommentsData: {
          loading: true,
          data: {},
          errorMsg: "",
          loadingID: utils.toNormalString(action.feed_id),
        },
      };
    }
    case GET_FEED_COMMENTS_SUCCESS: {
      let feedData = { ...previousState?.feedData?.data };
      let feedId = utils.toNormalString(action.feed_id)
      let feedCommentData = action?.data;
      let oldFeedData = feedData?.data;
      let updatedFeedData = oldFeedData.map(feedItem => {
        if (feedItem.id == feedId) {
          return {
            ...feedItem,
            feed_comments: feedCommentData.data
          }
        } else return feedItem
      })
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        getFeedCommentsData: {
          loading: false,
          data: action.data,
          errorMsg: "",
          loadingID: null,
        },
      };
    }
    case GET_FEED_COMMENTS_ERROR: {
      return {
        ...previousState,
        getFeedCommentsData: {
          loading: false,
          data: {},
          errorMsg: action.data,
          loadingID: null,
        },
      };
    }
    case SOCKET_IO_GET_NEW_FEED: {
      let feedData = { ...previousState?.feedData?.data };
      if (action?.data) {
        feedData["data"] = [action?.data, ...feedData?.data];
      }
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: feedData,
        },
      };
    }
    case SOCKET_IO_UPDATE_FEED: {
      let feedData = { ...previousState?.feedData?.data };
      let newData = action?.data;
      let oldData = feedData?.data;
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data:
              oldData !== undefined
                ? [
                  ...oldData?.map((item) => {
                    return item?.id !== newData?.id ? item : newData;
                  }),
                ]
                : [newData],
          },
        },
      };
    }
    case SOCKET_IO_DELETE_FEED: {
      let feedData = { ...previousState?.feedData?.data };
      let feed_id = action?.data;
      let oldData = feedData?.data;

      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data:
              oldData !== undefined
                ? oldData?.filter((item) => {
                  return item?.id !== feed_id;
                })
                : [],
          },
        },
      };
    }
    case SOCKET_IO_FEED_LIKE: {
      let feedData = { ...previousState?.feedData?.data };
      let feedLikeData = action.data?.data;
      let oldFeedData = feedData?.data;
      let selectedFeedIndex;
      if (oldFeedData !== undefined) {
        let selectedFeed = oldFeedData?.filter((item, i) => {
          if (item?.id === feedLikeData?.feed_id) {
            selectedFeedIndex = i;
          }
          return item?.id === feedLikeData?.feed_id;
        });
        selectedFeed[0]?.feed_likes?.push(feedLikeData);

        oldFeedData?.splice(selectedFeedIndex, 1, selectedFeed[0]);
      }
      let feedDetails = { ...previousState.getFeedDetailsData.data };
      if (feedDetails?.id === feedLikeData?.feed_id) {
        feedDetails["feed_likes"] = [...feedDetails?.feed_likes, feedLikeData];
      }
      let feedLikesData = { ...previousState.feedLikesData.data };
      if (
        feedLikesData?.data?.length > 0 &&
        feedLikesData?.data[0]?.feed_id === feedLikeData?.feed_id
      ) {
        feedLikesData["data"] = [
          ...feedLikesData?.data,
          action?.data?.likeData,
        ];
      }
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data: oldFeedData,
          },
        },
        getFeedDetailsData: {
          ...previousState.getFeedDetailsData,
          data: feedDetails,
        },
        feedLikesData: {
          ...previousState.feedLikesData,
          data: feedLikesData,
        },
      };
    }
    case SOCKET_IO_FEED_DISLIKE: {
      let feedData = { ...previousState?.feedData?.data };
      let feedDislikeData = action.data;
      let oldFeedData = feedData?.data;
      let selectedFeedIndex;
      let feedDislikeIndex;
      const feedsType = previousState.feedsType;
      let feedMessage = previousState?.feedData?.data?.message;
      if (feedsType === "liked") {
        if (oldFeedData !== undefined) {
          oldFeedData = oldFeedData?.filter((item) => {
            return item?.id !== feedDislikeData?.feed_id;
          });
          if (oldFeedData?.length === 0) {
            feedMessage =
              "Feeds not available. Because you have not liked any feeds,comments and comments replys.";
          }
        }
      } else {
        if (oldFeedData !== undefined) {
          let selectedFeed = oldFeedData.filter((item, i) => {
            if (item?.id === feedDislikeData?.feed_id) {
              selectedFeedIndex = i;
            }
            return item?.id === feedDislikeData?.feed_id;
          });
          if (selectedFeed.length !== 0) {
            selectedFeed[0].feed_likes.filter((item, i) => {
              if (item?.user_id === feedDislikeData?.user_id) {
                feedDislikeIndex = i;
              }
              return item?.user_id === feedDislikeData?.user_id;
            });
            selectedFeed[0].feed_likes.splice(feedDislikeIndex, 1);
            oldFeedData.splice(selectedFeedIndex, 1, selectedFeed[0]);
          }
        }
      }
      let feedDetails = { ...previousState.getFeedDetailsData.data };
      if (feedDetails?.id === feedDislikeData?.feed_id) {
        feedDetails["feed_likes"] = feedDetails?.feed_likes?.filter(
          (item) => item?.user_id !== feedDislikeData?.user_id
        );
      }
      let feedLikesData = { ...previousState.feedLikesData.data };
      if (
        feedLikesData?.data?.length > 0 &&
        feedLikesData?.data[0]?.feed_id === feedDislikeData?.feed_id
      ) {
        feedLikesData["data"] = feedLikesData?.data?.filter((item) => {
          return item?.user_id !== feedDislikeData?.user_id;
        });
      }
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data: oldFeedData,
            message: feedMessage,
          },
        },
        getFeedDetailsData: {
          ...previousState.getFeedDetailsData,
          data: feedDetails,
        },
        feedLikesData: {
          ...previousState.feedLikesData,
          data: feedLikesData,
        },
      };
    }
    case SOCKET_IO_NEW_FEED_COMMENT: {
      // let feedData = { ...previousState?.feedData?.data };
      // let oldComments = { ...previousState?.getFeedCommentsData?.data };
      // let feedCommentData = action?.data;
      // let oldFeedData = feedData?.data;
      // let oldCommentsData = oldComments?.data;
      // let selectedFeedIndex;
      // if (oldFeedData !== undefined) {
      //   let selectedFeed = oldFeedData?.filter((item, i) => {
      //     if (item?.id === feedCommentData?.feed_id) {
      //       selectedFeedIndex = i;
      //     }
      //     return item?.id === feedCommentData?.feed_id;
      //   });
      //   if (selectedFeed.length !== 0) {
      //     selectedFeed[0]?.feed_comments?.unshift(feedCommentData);

      //     oldFeedData?.splice(selectedFeedIndex, 1, selectedFeed[0]);
      //   }
      // }
      // if (oldCommentsData !== undefined) {
      //   oldCommentsData.unshift(action?.data);
      // } else {
      //   oldCommentsData = [action?.data];
      // }
      // let feedDetails = { ...previousState.getFeedDetailsData.data };
      // if (feedDetails?.id === feedCommentData?.feed_id) {
      //   feedDetails["feed_comments"] = [
      //     ...feedDetails?.feed_comments,
      //     feedCommentData,
      //   ];
      // }


      let updatedFeedData = previousState?.feedData?.data?.data?.map(feedItem => {
        if (feedItem.id == action?.data?.feed_id) {
          return {
            ...feedItem,
            feed_comments: [
              action?.data,
              ...feedItem?.feed_comments,
            ]
          }
        } else return feedItem
      })


      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data: updatedFeedData,
          },
        },
        // getFeedCommentsData: {
        //   ...previousState?.getFeedCommentsData,
        //   data: {
        //     ...previousState?.getFeedCommentsData?.data,
        //     data: oldCommentsData,
        //   },
        // },
        // getFeedDetailsData: {
        //   ...previousState.getFeedDetailsData,
        //   data: feedDetails,
        // },
      };
    }
    case SOCKET_IO_NEW_FEED_COMMENT_REPLY: {
      // let oldComments = { ...previousState?.getFeedCommentsData?.data };
      // let oldCommentsData = oldComments?.data;
      // let commentIndex;
      // if (oldCommentsData !== undefined) {
      //   let selectedComment = oldCommentsData?.filter((item, i) => {
      //     if (item?.id === action?.data?.comment_id) {
      //       commentIndex = i;
      //     }
      //     return item?.id === action?.data?.comment_id;
      //   });
      //   selectedComment[0]?.feed_comment_replys?.unshift(action?.data);
      //   oldCommentsData?.splice(commentIndex, 1, selectedComment[0]);
      // }
      let updatedFeedData = previousState?.feedData?.data?.data?.map(feedItem => {
        if (feedItem.id == action?.data?.feed_id) {
          return {
            ...feedItem,
            feed_comments: feedItem?.feed_comments.map(comm => {
              if(comm.id == action?.data?.comment_id){
                return {
                  ...comm,
                  feed_comment_replys : [action?.data , ...comm.feed_comment_replys]
                }
              } else return comm
            })
          }
        } else return feedItem
      })

      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            ...previousState?.feedData?.data,
            data: updatedFeedData,
          },
        },
        // getFeedCommentsData: {
        //   ...previousState?.getFeedCommentsData,
        //   data: {
        //     ...previousState?.getFeedCommentsData?.data,
        //     data: oldCommentsData,
        //   },
        // },
      };
    }
    case SOCKET_IO_NEW_FEED_COMMENT_LIKE: {
      let oldComments = { ...previousState?.getFeedCommentsData?.data };
      let oldCommentsData = oldComments?.data;
      let commentIndex;
      let feedCommentLikesData;
      if (oldCommentsData !== undefined) {
        let selectedComment = oldCommentsData?.filter((item, i) => {
          if (item?.id === action?.data?.comment_id) {
            commentIndex = i;
          }
          return item?.id === action?.data?.comment_id;
        });
        selectedComment[0]?.feed_comment_likes?.unshift(action?.data);
        oldCommentsData?.splice(commentIndex, 1, selectedComment[0]);

        let getFeedCommentLikes = {
          ...previousState?.getFeedCommentLikesData?.data,
        };
        feedCommentLikesData = getFeedCommentLikes?.data;
        if (feedCommentLikesData !== undefined) {
          feedCommentLikesData?.unshift(action?.data);
        }
      }

      let feedData = { ...previousState?.feedData?.data };
      let feedId = action?.data.feed_id;
      let comment_id = action?.data.comment_id
      let oldFeedData = feedData?.data;
      let updatedFeedData = oldFeedData.map(feedItem => {
        if (feedItem.id == feedId) {
          return {
            ...feedItem,
            feed_comments: feedItem.feed_comments.map(comm => {
              if (comm.id == comment_id) {
                return {
                  ...comm,
                  feed_comment_likes: [action?.data, ...comm.feed_comment_likes]
                }
              } else return comm
            })
          }
        } else return feedItem
      })
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        getFeedCommentsData: {
          ...previousState?.getFeedCommentsData,
          data: {
            ...previousState?.getFeedCommentsData?.data,
            data: oldCommentsData,
          },
        },
        getFeedCommentLikesData: {
          ...previousState?.getFeedCommentLikesData,
          data: {
            ...previousState?.getFeedCommentLikesData?.data,
            data: feedCommentLikesData,
          },
        },
      };
    }
    case SOCKET_IO_DISLIKE_FEED_COMMENT: {
      const { feed_id, comment_id, user_id } = action?.data;
      let comments = { ...previousState?.getFeedCommentsData?.data };
      comments["data"] = comments?.data?.map((comment) => {
        return comment?.id === comment_id
          ? {
            ...comment,
            feed_comment_likes: comment?.feed_comment_likes?.filter(
              (user) => user?.user_id !== user_id
            ),
          }
          : comment;
      });
      let commentLikes = {
        ...previousState?.getFeedCommentLikesData?.data,
      };
      if (commentLikes?.data && commentLikes?.data[0]?.feed_id === feed_id) {
        commentLikes["data"] = commentLikes?.data?.filter(
          (item) => item?.user_id !== user_id
        );
      }
      let feedData = { ...previousState?.feedData?.data };
      let feedId = action?.data.feed_id;
      let oldFeedData = feedData?.data;
      let updatedFeedData = oldFeedData.map(feedItem => {
        if (feedItem.id == feedId) {
          return {
            ...feedItem,
            feed_comments: feedItem.feed_comments.map(comm => {
              if (comm.id == comment_id) {
                return {
                  ...comm,
                  feed_comment_likes: comm.feed_comment_likes.filter(
                    (user) => user?.user_id !== user_id
                  )
                }
              } else return comm
            })
          }
        } else return feedItem
      })
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        getFeedCommentsData: {
          ...previousState?.getFeedCommentsData,
          data: comments,
        },
        getFeedCommentLikesData: {
          ...previousState?.getFeedCommentLikesData,
          data: commentLikes,
        },
      }
    }
    case SOCKET_IO_NEW_FEED_COMMENT_REPLY_LIKE: {
      // let oldComments = { ...previousState?.getFeedCommentsData?.data };
      // let oldCommentsData = oldComments?.data;
      // let commentIndex;
      // let feedCommentReplyLikesData;
      // if (oldCommentsData !== undefined) {
      //   let selectedComment = oldCommentsData?.filter((item, i) => {
      //     if (item?.id === action?.data?.comment_id) {
      //       commentIndex = i;
      //     }
      //     return item?.id === action?.data?.comment_id;
      //   });
      //   let replyIndex;
      //   selectedComment[0]?.feed_comment_replys?.filter((item, i) => {
      //     if (item?.id === action?.data?.reply_id) {
      //       replyIndex = i;
      //     }
      //     return item?.id === action?.data?.reply_id;
      //   });
      //   selectedComment[0]?.feed_comment_replys[
      //     replyIndex
      //   ]?.feed_comment_reply_likes?.push(action?.data);
      //   oldCommentsData?.splice(commentIndex, 1, selectedComment[0]);

      //   let getFeedCommentReplyLikes = {
      //     ...previousState?.getFeedCommentReplyLikes?.data,
      //   };
      //   feedCommentReplyLikesData = getFeedCommentReplyLikes?.data;
      //   if (feedCommentReplyLikesData !== undefined) {
      //     feedCommentReplyLikesData?.push(action?.data);
      //   }
      // }

      let updatedFeedData = previousState?.feedData?.data?.data?.map(feedItem => {
        if (feedItem.id == action?.data?.feed_id) {
          return {
            ...feedItem,
            feed_comments: feedItem?.feed_comments.map(comm => {
              if(comm.id == action?.data?.comment_id){
                return {
                  ...comm,
                  feed_comment_replys : comm.feed_comment_replys.map(reply => {
                    if(reply.id == action?.data?.reply_id){
                      return {
                        ...reply,
                        feed_comment_reply_likes : [action?.data  , ...reply.feed_comment_reply_likes]
                      }
                    } else return reply
                  })
                }
              } else return comm
            })
          }
        } else return feedItem
      })

      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        // getFeedCommentsData: {
        //   ...previousState?.getFeedCommentsData,
        //   data: {
        //     ...previousState?.getFeedCommentsData?.data,
        //     data: oldCommentsData,
        //   },
        // },
        // getFeedCommentReplyLikes: {
        //   ...previousState?.getFeedCommentReplyLikes,
        //   data: {
        //     ...previousState?.getFeedCommentReplyLikes?.data,
        //     data: feedCommentReplyLikesData,
        //   },
        // },
      };
    }
    case SOCKET_IO_DISLIKE_FEED_COMMENT_REPLY: {
      // const { feed_id, comment_id, reply_id, user_id } = action?.data;
      // let comments = { ...previousState?.getFeedCommentsData?.data };
      // comments["data"] = comments?.data?.map((comment) => {
      //   return comment?.id === comment_id
      //     ? {
      //       ...comment,
      //       feed_comment_replys: comment?.feed_comment_replys?.map(
      //         (reply) => {
      //           return reply?.id === reply_id
      //             ? {
      //               ...reply,
      //               feed_comment_reply_likes:
      //                 reply?.feed_comment_reply_likes?.filter(
      //                   (item) => item?.user_id !== user_id
      //                 ),
      //             }
      //             : reply;
      //         }
      //       ),
      //     }
      //     : comment;
      // });
      // let commentReplyLikes = {
      //   ...previousState?.getFeedCommentReplyLikes?.data,
      // };
      // if (
      //   commentReplyLikes?.data &&
      //   commentReplyLikes?.data[0]?.feed_id === feed_id &&
      //   commentReplyLikes?.data[0]?.comment_id === comment_id &&
      //   commentReplyLikes?.data[0]?.reply_id === reply_id
      // ) {
      //   commentReplyLikes["data"] = commentReplyLikes?.data?.filter(
      //     (item) => item?.user_id !== user_id
      //   );
      // }
      let updatedFeedData = previousState?.feedData?.data?.data?.map(feedItem => {
        if (feedItem.id == action?.data?.feed_id) {
          return {
            ...feedItem,
            feed_comments: feedItem?.feed_comments.map(comm => {
              if(comm.id == action?.data?.comment_id){
                return {
                  ...comm,
                  feed_comment_replys : comm.feed_comment_replys.map(reply => {
                    if(reply.id == action?.data?.reply_id){
                      return {
                        ...reply,
                        feed_comment_reply_likes : reply.feed_comment_reply_likes.filter((item) => item?.user_id !== action?.data?.user_id)
                      }
                    } else return reply
                  })
                }
              } else return comm
            })
          }
        } else return feedItem
      })

      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        // getFeedCommentsData: {
        //   ...previousState?.getFeedCommentsData,
        //   data: comments,
        // },
        // getFeedCommentReplyLikes: {
        //   ...previousState?.getFeedCommentReplyLikes,
        //   data: commentReplyLikes,
        // },
      };
    }
    case GET_PROFILE_FEED_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_FEED_SUCCESS: {
      const offset = action.data.offset;
      const oldData = { ...previousState.feedData.data };
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            offsetStatus: action.data.offsetStatus,
            status: action.data.status,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_PROFILE_FEED_ERROR: {
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case GET_LIKED_FEED_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_LIKED_FEED_SUCCESS: {
      const offset = action.data.offset;
      const oldData = { ...previousState.feedData.data };
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            offset: action.data.offset,
            status: action.data.status,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_LIKED_FEED_ERROR: {
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_FEED_START: {
      return {
        ...previousState,
        updateFeedData: {
          loading: true,
          feedData: {},
          errorMsg: "",
        },
      };
    }
    case UPDATE_FEED_SUCCESS: {
      return {
        ...previousState,
        updateFeedData: {
          loading: false,
          feedData: action.data,
          errorMsg: "",
        },
      };
    }
    case UPDATE_FEED_ERROR: {
      return {
        ...previousState,
        updateFeedData: {
          loading: false,
          feedData: {},
          errorMsg: action.data,
        },
      };
    }
    case FEED_LIKE_START: {
      return {
        ...previousState,
        feedLikeData: {
          loading: true,
          feedData: {},
          errorMsg: "",
        },
      };
    }
    case FEED_LIKE_SUCCESS: {
      return {
        ...previousState,
        feedLikeData: {
          loading: false,
          feedData: action.data,
          errorMsg: "",
        },
      };
    }
    case FEED_LIKE_ERROR: {
      return {
        ...previousState,
        feedLikeData: {
          loading: false,
          feedData: {},
          errorMsg: action.data,
        },
      };
    }
    case FEED_COMMENT_START: {
      return {
        ...previousState,
        feedCommentData: {
          loading: true,
          feedData: {},
          errorMsg: "",
        },
      };
    }
    case FEED_COMMENT_SUCCESS: {
      return {
        ...previousState,
        feedCommentData: {
          loading: false,
          feedData: action.data,
          errorMsg: "",
        },
      };
    }
    case FEED_COMMENT_ERROR: {
      return {
        ...previousState,
        feedCommentData: {
          loading: false,
          feedData: {},
          errorMsg: action.data,
        },
      };
    }
    case DELETE_FEED_START: {
      return {
        ...previousState,
        deleteFeedData: {
          loading: true,
          feedData: {},
          errorMsg: "",
        },
      };
    }
    case DELETE_FEED_SUCCESS: {
      return {
        ...previousState,
        deleteFeedData: {
          loading: false,
          feedData: action.data,
          errorMsg: "",
        },
      };
    }
    case DELETE_FEED_ERROR: {
      return {
        ...previousState,
        deleteFeedData: {
          loading: false,
          feedData: {},
          errorMsg: action.data,
        },
      };
    }
    case GET_SEARCH_FEEDS_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState?.feedData?.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_SEARCH_FEEDS_SUCCESS: {
      const offset = action.data.offset;
      const oldData = { ...previousState.feedData.data };
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            status: action.data.status,
            offset: action.data.offset,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_SEARCH_FEEDS_ERROR: {
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState?.feedData?.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case GET_GROUP_FEEDS_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState?.feedData?.data,
          },
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_FEEDS_SUCCESS: {
      const offset = action.data.offset;
      const oldData = { ...previousState.feedData.data };
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState.feedData.data,
            data:
              offset === 0
                ? action.data.data
                : [...oldData?.data, ...action.data.data],
            status: action.data.status,
            offset: action.data.offset,
            offsetStatus: action.data.offsetStatus,
            message: action.data.message,
          },
          errorMsg: "",
        },
      };
    }
    case GET_GROUP_FEEDS_ERROR: {
      return {
        ...previousState,
        feedData: {
          loading: false,
          data: {
            ...previousState?.feedData?.data,
          },
          errorMsg: action.data,
        },
      };
    }
    case UPDATE_COMMENT_AND_REPLY_START: {
      return {
        ...previousState,
        updateCommentAndReplyData: {
          loading: true,
          message: "",
          status: "",
          data: {},
        },
      };
    }
    case UPDATE_COMMENT_AND_REPLY_SUCCESS: {
      const { status, message, data } = action.data;
      // let commentsData = { ...previousState.getFeedCommentsData?.data };
      // commentsData["data"] = commentsData?.data?.map((comment) => {
      //   return data?.type === "comment"
      //     ? comment?.id === data?.id
      //       ? { ...comment, message: data?.message }
      //       : comment
      //     : comment?.id === data?.comment_id
      //       ? {
      //         ...comment,
      //         feed_comment_replys: comment?.feed_comment_replys?.map(
      //           (reply) => {
      //             return reply?.id === data?.id
      //               ? { ...reply, message: data?.message }
      //               : reply;
      //           }
      //         ),
      //       }
      //       : comment;
      // });
      let updatedFeedData = previousState?.feedData?.data?.data?.map(feedItem => {
        if (feedItem.id == data?.feed_id) {
          if( data.type == "comment"){
            return {
              ...feedItem,
              feed_comments: feedItem?.feed_comments.map(comm => {
                if(comm.id == data?.id){
                  return {
                    ...comm,
                    message :   data.message,
                    updatedAt : data.updatedAt
                  }
                } else return comm
              })
            }
          } else {
            return {
              ...feedItem,
              feed_comments: feedItem?.feed_comments.map(comm => {
                if(comm.id == data?.comment_id){
                  return {
                    ...comm,
                    feed_comment_replys : comm.feed_comment_replys.map(reply => {
                      if(reply.id == data?.id){
                        return {
                          ...reply,
                          message :   data.message,
                          updatedAt : data.updatedAt
                        }
                      } else return reply
                    })
                  }
                } else return comm
              })
            }
          }
        } else return feedItem
      })

      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: {
            data: updatedFeedData,
          },
        },
        updateCommentAndReplyData: {
          loading: false,
          status,
          message,
          data,
        },
        // getFeedCommentsData: {
        //   ...previousState.getFeedCommentsData,
        //   data: commentsData,
        // },
      };
    }
    case UPDATE_COMMENT_AND_REPLY_ERROR: {
      const { status, message } = action.data;
      return {
        ...previousState,
        updateCommentAndReplyData: {
          loading: false,
          status,
          message,
          data: {},
        },
      };
    }
    case SOCKET_IO_DELETE_GROUP_FEED: {
      const { group_id } = action.data;
      let feeds = { ...previousState?.feedData?.data };
      feeds["data"] = feeds?.data?.filter((feed) => {
        return feed?.group_id !== group_id;
      });
      return {
        ...previousState,
        feedData: {
          ...previousState?.feedData,
          data: feeds,
        },
      };
    }
    case DELETE_COMMENT_AND_REPLY_START: {
      return {
        ...previousState,
        deleteCommentAndReplyData: {
          loading: true,
          message: "",
          status: "",
          data: {},
        },
      };
    }
    case DELETE_COMMENT_AND_REPLY_SUCCESS: {
      const { status, message, type, comment_id, reply_id, feed_id } =
        action.data;
      // Remove Comment And Reply From Comment Api
      let commentsData = { ...previousState.getFeedCommentsData?.data };
      if (type === "comment") {
        commentsData["data"] = commentsData?.data?.filter((comment) => {
          return comment?.id !== comment_id;
        });
      } else {
        commentsData["data"] = commentsData?.data?.map((comment) => {
          return comment?.id === comment_id
            ? {
              ...comment,
              feed_comment_replys: comment?.feed_comment_replys?.filter(
                (reply) => {
                  return reply?.id !== reply_id;
                }
              ),
            }
            : comment;
        });
      }
      // Remove Comment and Reply from Feed Api
      let newFeedData = { ...previousState.feedData.data };
      if (type === "comment") {
        newFeedData["data"] = newFeedData?.data?.map((feed) => {
          return feed?.id === feed_id
            ? {
              ...feed,
              feed_comments: feed.feed_comments?.filter((comment) => {
                return comment?.id !== comment_id;
              }),
            }
            : feed;
        });
      } else {
        newFeedData["data"] = newFeedData?.data?.map((feed) => {
          return feed?.id === feed_id
            ? {
              ...feed,
              feed_comments: feed?.feed_comments?.map((comment) => {
                return comment?.id === comment_id
                  ? {
                    ...comment,
                    feed_comment_replys:
                      comment?.feed_comment_replys?.filter((reply) => {
                        return reply?.id !== reply_id;
                      }),
                  }
                  : comment;
              }),
            }
            : feed;
        });
      }
      return {
        ...previousState,
        deleteCommentAndReplyData: {
          loading: false,
          status,
          message,
          data: {},
        },
        getFeedCommentsData: {
          ...previousState.getFeedCommentsData,
          data: commentsData,
        },
        feedData: {
          ...previousState.feedData,
          data: newFeedData,
        },
      };
    }
    case DELETE_COMMENT_AND_REPLY_ERROR: {
      const { status, message } = action.data;
      return {
        ...previousState,
        deleteCommentAndReplyData: {
          loading: false,
          status,
          message,
          data: {},
        },
      };
    }
    case GET_FEED_DETAILS_START: {
      return {
        ...previousState,
        getFeedDetailsData: {
          loading: true,
          data: {},
          status: "",
          message: "",
        },
      };
    }
    case GET_FEED_DETAILS_SUCCESS: {
      const { data, status, message } = action.data;
      return {
        ...previousState,
        getFeedDetailsData: {
          loading: false,
          data,
          status,
          message,
        },
      };
    }
    case GET_FEED_DETAILS_ERROR: {
      const { status, message } = action.data;
      return {
        ...previousState,
        getFeedDetailsData: {
          loading: false,
          data: {},
          status,
          message,
        },
      };
    }
    case GET_PROFILE_USER_DATA_START: {
      return {
        ...previousState,
        feedData: {
          loading: true,
          data: {
            ...previousState.feedData.data,
            data: [],
          },
          errorMsg: "",
        },
      };
    }
    default: {
      return previousState || initialState;
    }
  }
};

export default FeedReducers;
