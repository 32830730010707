import React from "react";
import { makeStyles, Paper, Tabs, Tab } from "@material-ui/core";
import classNames from "classnames";
import "../../scss/Tabs.scss";

const useStyle = makeStyles((theme) => ({}));

export default function TabsComponent({
  activeStep = 0,
  setActiveStep,
  tabsList = [],
  className,
  onClick = () => {},
}) {
  const classes = useStyle();
  return (
    <Paper className={classNames("tabsPaper", className)}>
      <Tabs value={activeStep} className="tabs" indicatorColor="primary">
        {tabsList?.map((item, key) => {
          return (
            <Tab
              label={item?.label}
              onClick={() => {
                setActiveStep(key);
                onClick(key);
              }}
              className="tabBtn"
              key={key}
            />
          );
        })}
      </Tabs>
    </Paper>
  );
}
