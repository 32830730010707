import React from "react";
import { makeStyles, TextField, InputAdornment } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  wpSocietyTextFieldRoot: {
    width: "100%",
    "&>div": {
      background: theme.palette.common.white,
      borderRadius: "8px",
      padding: "5px 10px",
      border: `1px solid ${theme.palette.border.borderColor}`,
      "&>div": {
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&>svg": {
          width: "20px",
          height: "20px",
        },
      },
      "&>input,&>textarea": {
        lineHeight: "18px",
        fontSize: "12px",
        color: theme.palette.text.bold,
        fontWeight: theme.palette.fontweight.light400,
        fontFamily: theme.palette.font.boldText.fontFamily,
        padding: "6px 0px 7px",
      },
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.border.borderColor}`,
      },
    },
  },
  newDesign: {
    "&>div": {
      background: theme.palette.common.aliceBlue,
      border: `1px solid ${theme.palette.border.bordertopcolor}`,
    },
    "&:hover": {
      "&>div": {
        border: `1px solid ${theme.palette.border.bordertopcolor}`,
      },
    },
  },
}));

const defaultFunction = () => {};

export default function EndIcon({
  placeholder,
  className,
  gutterBottom,
  position,
  Icon,
  variant,
  onClick = defaultFunction,
  onChange = defaultFunction,
  value,
  type,
  required = true,
  disabled = false,
  disableUnderline = true,
  onKeyDown,
  multiline = false,
  rows,
  minRows,
  maxRows,
  newDesign = false,
  autoFocus,
  setRef,
  setInputRef,
}) {
  const classes = useStyles();
  return (
    <TextField
      className={classNames(
        classes.wpSocietyTextFieldRoot,
        className,
        newDesign && classes.newDesign
      )}
      placeholder={placeholder}
      variant={variant}
      value={value}
      required={required}
      disabled={disabled}
      type={type}
      InputProps={{
        endAdornment: (
          <InputAdornment position={position}>{Icon}</InputAdornment>
        ),
        disableUnderline: { disableUnderline },
      }}
      gutterBottom={gutterBottom}
      onClick={() => onClick()}
      onChange={onChange}
      onKeyDown={onKeyDown}
      multiline={multiline}
      minRows={minRows}
      maxRows={maxRows}
      autoFocus={autoFocus}
      ref={setRef}
      inputRef={setInputRef}
    />
  );
}
