import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import classNames from "classnames";

const useStyle = makeStyles((theme) => ({
  bodyRoot: {
    width: "100%",
    marginBottom: 84,
    [theme.breakpoints.down("sm")]: {
      marginTop: 80,
    },
  },
}));

export default function Body({ children, className }) {
  const classes = useStyle();
  return (
    <Box className={classNames(classes.bodyRoot, className)}>{children}</Box>
  );
}
