import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const getCircularProgressColor = (type) => {
  switch (type) {
    case "primary":
      return "#023047";
    case "secondary":
      return "#FB8500";
    case "white":
      return "#fff";
    default:
      return "#023047";
  }
};

export default function CircularProgressComponent({
  color,
  disableShrink,
  size = 20,
  sx,
  thickness,
  value,
  variant,
  id,
  className,
  style,
  customColor = "primary",
}) {
  return (
    <CircularProgress
      color={color}
      disableShrink={disableShrink}
      size={size}
      sx={sx}
      id={id}
      className={className}
      thickness={thickness}
      value={value}
      variant={variant}
      style={{ color: getCircularProgressColor(customColor), ...style }}
    ></CircularProgress>
  );
}
