import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  EDUCATION_HISTORY_START,
  UPDATE_EDUCATION_HISTORY_START,
  UPDATE_WORK_HISTORY_START,
  WORK_HISTORY_START,
} from "../../actions/UserAction";
import utils from "../../utils/commonUtils";
import LeftRightComponent from "../common/LeftRightComponent";
import EduWorkForm from "../StepperComponents/EduWorkForm";

let newEduDetailFlag = false;
let updateEduDetailFlag = false;
let newWorkDetailFlag = false;
let updateWorkDetailFlag = false;

function EduOrWorkDetailForm({
  addNewEducationDetail,
  educationHistory,
  updateId,
  updateUserEducationHistory,
  setUpdateId,
  updateEducationData,
  userData,
  type,
  addNewEmploymentDetail,
  updateUserWorkHistory,
  employmentData,
  updateWorkData,
  updateEditProfileStatus,
}) {
  const [eduOrWorkData, setEduOrWorkData] = useState({
    heading: "",
    from_date: null,
    to_date: null,
    message: "",
  });
  const [errors, setErrors] = useState({
    heading: false,
    from_date: false,
    to_date: false,
  });

  useEffect(() => {
    setEduOrWorkData({
      heading: "",
      from_date: null,
      to_date: null,
      message: "",
    })
  }, [type])

  const handleChange = (e, key, value) => {
    let obj = { ...eduOrWorkData };
    obj[key] = value;
    setEduOrWorkData(obj);
    if (obj[key]) {
      let errorObj = { ...errors };
      if (errorObj[key]) {
        errorObj[key] = false;
        setErrors(errorObj);
      }
    }
  };

  const verifyewData = (errorObj, dataObj) => {
    let isValid = false;
    let error = { ...errorObj };
    for (let item of Object.keys(errorObj)) {
      if (dataObj[item]) {
        error[item] = false;
        isValid = true;
        continue;
      } else {
        isValid = false;
        error[item] = true;
      }
    }
    setErrors(error);
    return isValid;
  };

  const handleSubmit = () => {
    if (verifyewData(errors, eduOrWorkData)) {
      if (type === "education") {
        addNewEducationDetail(
          eduOrWorkData?.heading,
          eduOrWorkData?.from_date,
          eduOrWorkData?.to_date,
          eduOrWorkData?.message
        );
        newEduDetailFlag = true;
      } else {
        addNewEmploymentDetail(
          eduOrWorkData?.heading,
          eduOrWorkData?.from_date,
          eduOrWorkData?.to_date,
          eduOrWorkData?.message
        );
        newWorkDetailFlag = true;
      }
    }
  };

  const handleUpdate = () => {
    if (verifyewData(errors, eduOrWorkData)) {
      if (type === "education") {
        updateUserEducationHistory(
          utils.toBase64(updateId),
          eduOrWorkData?.heading,
          eduOrWorkData?.from_date,
          eduOrWorkData?.to_date,
          eduOrWorkData?.message
        );
        updateEduDetailFlag = true;
      } else {
        updateUserWorkHistory(
          utils.toBase64(updateId),
          eduOrWorkData?.heading,
          eduOrWorkData?.from_date,
          eduOrWorkData?.to_date,
          eduOrWorkData?.message
        );
        updateWorkDetailFlag = true;
      }
    }
  };

  useEffect(() => {
    if (updateId !== null) {
      if (type === "education") {
        for (let item of userData?.userInfo?.data?.user_education_histories) {
          if (item.id === updateId) {
            setEduOrWorkData({
              heading: item?.university,
              from_date: item?.from_date,
              to_date: item?.to_date,
              message: item?.message,
            });
          }
        }
      } else {
        for (let item of userData?.userInfo?.data?.user_work_histories) {
          if (item?.id === updateId) {
            setEduOrWorkData({
              heading: item?.org_name,
              from_date: item?.from_date,
              to_date: item?.to_date,
              message: item?.message,
            });
          }
        }
      }
    } else {
      setEduOrWorkData({
        heading: "",
        from_date: null,
        to_date: null,
        message: "",
      })
    }
  }, [updateId]);

  const handleClose = () => {
    setEduOrWorkData({
      heading: "",
      from_date: null,
      to_date: null,
      message: "",
    });
    updateEditProfileStatus(false);
    setUpdateId(null);
  };

  useEffect(() => {
    if (
      educationHistory?.userEducationInfo?.status === "1" &&
      newEduDetailFlag
    ) {
      newEduDetailFlag = false;
      handleClose();
    }
    if (
      updateEducationData?.userEducationInfo?.status === "1" &&
      updateEduDetailFlag
    ) {
      updateEduDetailFlag = false;
      handleClose();
    }
    if (employmentData?.userWorkInfo?.status === "1" && newWorkDetailFlag) {
      newWorkDetailFlag = false;
      handleClose();
    }
    if (updateWorkData?.userWorkInfo?.status === "1" && updateWorkDetailFlag) {
      updateWorkDetailFlag = false;
      handleClose();
    }
  }, [educationHistory, updateEducationData, employmentData, updateWorkData]);

  return (
    <LeftRightComponent
      heading={type === "education" ? `${updateId !== null ? "Edit" : "Add"} Education` : `
      ${updateId !== null ? "Edit" : "Add"} Employment`}
    >
      <Box className={"manageMT15"}>
        <EduWorkForm
          type={type}
          data={eduOrWorkData}
          handleChange={handleChange}
          handleClose={handleClose}
          errors={errors}
          handleSubmit={handleSubmit}
          handleUpdate={handleUpdate}
          isUpdate={updateId !== null ? true : false}
          ewhDetails={[1]}
        />
      </Box>
    </LeftRightComponent>
  );
}

const mapStateToProps = (state) => ({
  educationHistory: state.UserData.userEducationData,
  updateEducationData: state.UserData.updateUserEducationData,
  employmentData: state.UserData.userWorkData,
  updateWorkData: state.UserData.updateUserWorkData,
});

const mapDispatchToProps = (dispatch) => ({
  addNewEducationDetail: (university, from_date, to_date, message) =>
    dispatch({
      type: EDUCATION_HISTORY_START,
      university,
      from_date,
      to_date,
      message,
    }),
  updateUserEducationHistory: (id, university, from_date, to_date, message) =>
    dispatch({
      type: UPDATE_EDUCATION_HISTORY_START,
      id,
      university,
      from_date,
      to_date,
      message,
    }),
  addNewEmploymentDetail: (org_name, from_date, to_date, message) =>
    dispatch({
      type: WORK_HISTORY_START,
      org_name,
      from_date,
      to_date,
      message,
    }),
  updateUserWorkHistory: (id, org_name, from_date, to_date, message) =>
    dispatch({
      type: UPDATE_WORK_HISTORY_START,
      id,
      org_name,
      from_date,
      to_date,
      message,
    }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EduOrWorkDetailForm);
